import _ from 'lodash';
import React from 'react';
import { FormattedMessage, intlShape } from 'react-intl';

import * as modalsActions from 'state/modals/actions/modalsActions';
import { clearUserWhoNeedsMoreInfo } from 'state/publisherUserManagement/actions/publisherUserManagementActions';
import {
  getUserWhoNeedsMoreInfoToBeCreated,
  getIsSavingUser,
} from 'state/publisherUserManagement/selectors/publisherUserManagementSelectors';
import { getActivePublisherDetails } from 'state/publishers/selectors/publishersSelectors';
import { getAdminIsSavingUser } from 'state/snapAdmin/selectors/snapAdminSelectors';
import * as subscriptionsActions from 'state/subscriptions/actions/subscriptionsActions';
import * as subscriptionsSelectors from 'state/subscriptions/selectors/subscriptionsSelectors';
import { isSnapchatEmail } from 'state/user/helpers/userHelpers';
import * as userSelectors from 'state/user/selectors/userSelectors';

import { NotificationMethod, SubscriberEvent, SubscriberType } from 'constants/subscriptions';
import { intlConnect } from 'utils/connectUtils';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';
import { serialiseUrn } from 'utils/permissionsUtils';
import {
  ROLES_FOR_USER_CREATION_TABLE,
  INHERITANCE_MAP,
  FORCE_ROLES_SELECTION,
  UserCreationTableRoleName,
} from 'utils/userManagementUtils';

import UserInfoForm from 'views/adminTools/UserInfoForm/UserInfoForm';
import UserRolesTable from 'views/adminTools/UserRolesTable/UserRolesTable';
import UserSubscriptionsTable from 'views/adminTools/UserSubscriptionsTable/UserSubscriptionsTable';
import SDSAlert from 'views/common/components/SDSAlert/SDSAlert';
import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';
import SDSCustomModal from 'views/common/components/SDSCustomModal/SDSCustomModal';
import SDSDialog from 'views/common/components/SDSDialog/SDSDialog';
import SpinnerIcon from 'views/common/components/SpinnerIcon/SpinnerIcon';

import style from './NewUserModal.scss';

import { ModalId } from 'types/modal';
import { Claim, Roles, Scope, Attribute } from 'types/permissions';
import type { Publisher } from 'types/publishers';
import { State } from 'types/rootState';

export const mapStateToProps = (state: State, ownProps: any) => {
  const loggedUserRoles = state.user.info.resourceRoles;
  const publisher = getActivePublisherDetails(state);
  const publisherId = publisher?.id;
  const user = _.get(ownProps, 'options.currentUser', null);
  const userId = user && user.id;
  const subscriptions = subscriptionsSelectors.getAvailableSubscriptionsByPublisherUser(state)({ publisherId, userId });

  return {
    activePublisher: publisher,
    isSuperPublisherSettingsEditor: userSelectors.hasClaimForActivePublisher(
      state,
      Claim.SUPER_PUBLISHER_SETTINGS_EDITOR
    ),
    isSavingUser: getIsSavingUser(state),
    adminIsSavingUser: getAdminIsSavingUser(state),
    needsMoreInfoToBeCreated: !!getUserWhoNeedsMoreInfoToBeCreated(state),
    publishers: userSelectors.getPublishersByIdAll(state),
    subscriptions,
    userId,
    loggedUserRoles,
  };
};

type StateProps = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = {
  fetchSubscriptions: subscriptionsActions.fetchSubscriptions,
  updateUserSubscriptionsForPub: subscriptionsActions.updateUserSubscriptionsForPub,
  showModal: modalsActions.showModal,
  hideModal: modalsActions.hideModal,
  clearUserWhoNeedsMoreInfo,
};

type OwnProps = {
  modalId: ModalId;
  options: {
    addEmployeeToSinglePublisher?: boolean;
    snapAdmin?: boolean;
    currentUser?: any;
    modifyUser: (...args: any[]) => any;
    deleteUser: (...args: any[]) => any;
  };
  publishers?: {
    [key: string]: Publisher;
  };
};

type Props = OwnProps & StateProps;

type RoleState = {
  explicit: boolean;
  forced: boolean;
  roleInheritance: boolean;
};

type NewUserModalState = {
  id: string;
  username: string;
  email: string;
  snapUsername: string;
  subscriptions: any;
  roles: { [key: string]: RoleState };
  submitUserErrorAlertText: string | null;
  isDeleteUserModalVisible: boolean;
  isLoading: boolean;
};

export class NewUserModal extends React.Component<Props, NewUserModalState> {
  static contextTypes = {
    intl: intlShape,
  };

  state: NewUserModalState = {
    id: _.get(this.props, 'options.currentUser.id'),
    username: _.get(this.props, 'options.currentUser.username', ''),
    email: _.get(this.props, 'options.currentUser.email', ''),
    snapUsername: _.get(this.props, 'options.currentUser.snapUsername', ''),
    subscriptions: { ..._.get(this.props, 'subscriptions', {}) },
    submitUserErrorAlertText: null,
    isDeleteUserModalVisible: false,
    isLoading: true,
    roles: {
      [Roles.SUPER_ADMIN]: {
        explicit: false,
        forced: false,
        roleInheritance: false,
      },
      [Roles.ADMIN]: {
        explicit: false,
        forced: false,
        roleInheritance: false,
      },
      [Roles.ANALYST]: {
        explicit: false,
        forced: false,
        roleInheritance: false,
      },
      [Roles.CONTRIBUTOR]: {
        explicit: false,
        forced: false,
        roleInheritance: false,
      },
      [Roles.VIEWER]: {
        explicit: !_.get(this.props, 'options.currentUser.id'),
        forced: false,
        roleInheritance: false,
      },
      [Roles.MONETIZATION_ANALYST]: {
        explicit: false,
        forced: false,
        roleInheritance: false,
      },
      [Roles.ORG_ADMIN]: {
        explicit: false,
        forced: false,
        roleInheritance: false,
      },
      [Roles.BUSINESS_ADMIN]: {
        explicit: false,
        forced: false,
        roleInheritance: false,
      },
      [Roles.CURATOR]: {
        explicit: false,
        forced: false,
        roleInheritance: false,
      },
      [Roles.CURATOR_READER]: {
        explicit: false,
        forced: false,
        roleInheritance: false,
      },
    },
  };

  UNSAFE_componentWillMount() {
    const { currentUser } = this.props.options;
    if (!currentUser) {
      this.setState({ isLoading: false });
      return;
    }
    let newRoles = this.state.roles;
    currentUser.resourceRoles.forEach((role: any) => {
      newRoles = _.merge(newRoles, { [role.roleId]: role });
    });
    this.setState({ roles: newRoles }, () => {
      // make sure inherited roles are disabled
      if (this.state.roles[Roles.ORG_ADMIN]!.explicit) {
        this.setUserRoleSelected(Roles.ORG_ADMIN, true);
      }
    });

    // Do not fetch the subscriptions when displaying super admin roles
    if (this.props.options.snapAdmin) {
      this.setState({ isLoading: false });
      return;
    }

    (this.props as any)
      .fetchSubscriptions({
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        publisherId: this.props.activePublisher.id,
        subscriberType: SubscriberType.DISCOVER_USER,
        userId: this.state.id,
        method: NotificationMethod.EMAIL,
      })
      .then(() => {
        this.setState({ isLoading: false });
      });
  }

  UNSAFE_componentWillReceiveProps(newProps: any) {
    if (JSON.stringify(this.props.subscriptions) !== JSON.stringify(newProps.subscriptions)) {
      this.setState({ subscriptions: { ...newProps.subscriptions } });
    }
  }

  componentWillUnmount() {
    (this.props as any).clearUserWhoNeedsMoreInfo();
  }

  getProfileRolesList = _.memoize(() => {
    return _.reverse(Object.keys(ROLES_FOR_USER_CREATION_TABLE) as UserCreationTableRoleName[]).filter(role => {
      return ROLES_FOR_USER_CREATION_TABLE[role].publisherRole && !ROLES_FOR_USER_CREATION_TABLE[role].orgRole;
    });
  });

  getGlobalRolesList = _.memoize(() => {
    return _.reverse(Object.keys(ROLES_FOR_USER_CREATION_TABLE) as UserCreationTableRoleName[]).filter(role => {
      return !ROLES_FOR_USER_CREATION_TABLE[role].orgRole;
    });
  });

  validateUserInputFieldsAndGetErrorMessage = () => {
    const isEmployee = this.props.options.snapAdmin || this.props.options.addEmployeeToSinglePublisher;
    // If we are modifying existing user, we do not need to validate the fields
    if (this.props.options.currentUser) {
      return null;
    }
    // On boarding non employee with snap auth
    if (!isEmployee) {
      if (!this.state.snapUsername) {
        return getMessageFromId('new-user-missing-snapUsername');
      }
      if (this.state.email && isSnapchatEmail(this.state.email)) {
        return getMessageFromId('new-user-snap-email');
      }
    }
    // If we are adding an employee or we need extra email and username info
    if (this.props.needsMoreInfoToBeCreated || isEmployee) {
      if (!this.state.email || !this.state.email.match(/\S+@\S+\.\S+/)) {
        return getMessageFromId('new-user-missing-email');
      }
      if (!this.state.username) {
        return getMessageFromId('new-user-missing-name');
      }
    }
    // Snap admins should always use snap emails
    if (isEmployee && !isSnapchatEmail(this.state.email)) {
      return getMessageFromId('new-internal-admin-invalid-email');
    }
    return null;
  };

  verifyUser = () => {
    const error = this.validateUserInputFieldsAndGetErrorMessage();
    if (!error) {
      return true;
    }
    this.setState({ submitUserErrorAlertText: error });
    return false;
  };

  reformatUserForSaving = () => {
    const { id, username, email, roles, snapUsername } = this.state;

    const publisher = this.props.activePublisher;
    const resourceRoles = Object.entries(roles).map(([roleId, roleEntity]) => ({
      roleId,
      resource: serialiseUrn({
        scope: Scope.PUBLISHER,
        attribute: Attribute.ID,
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'string | ... Remove this comment to see the full error message
        value: publisher?.id,
      }),
      ..._.omit(roleEntity, 'forced'),
      explicit: !!(roleEntity?.explicit || roleEntity?.forced),
    }));

    return {
      id,
      username,
      email,
      snapUsername: snapUsername?.toLowerCase(),
      resourceRoles,
    };
  };

  handleSubscriptionChange = (subscriptionId: any) => {
    const { subscriptions } = this.state;
    subscriptions[subscriptionId] = !subscriptions[subscriptionId];
    this.setState({ subscriptions });
  };

  updateSubscriptions = async (userId: any) => {
    await (this.props as any).updateUserSubscriptionsForPub({
      userId,
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      publisherId: this.props.activePublisher.id,
      subscriptions: this.state.subscriptions,
    });
  };

  submitUser = async () => {
    if (this.verifyUser()) {
      const result = await this.props.options.modifyUser(this.reformatUserForSaving());
      // If result is null, that means that the user did not exist and we need to ask for more info
      // to be able to successfully create it (email and name). Therefore, prevent screen from hiding
      if (result) {
        if (!this.props.options.snapAdmin) {
          await this.updateSubscriptions(result.payload.result);
        }
        (this.props as any).hideModal(this.props.modalId);
      }
    }
  };

  onConfirmRenderSubmitUserErrorsModal = () => this.setState({ submitUserErrorAlertText: null });

  onOK = () => {
    this.props.options.deleteUser(this.state.id);
    this.setState({ isDeleteUserModalVisible: false });
    (this.props as any).hideModal(this.props.modalId);
  };

  onCancel = () => {
    this.setState({ isDeleteUserModalVisible: false });
  };

  renderDeleteUserModal = () => {
    return (
      <SDSDialog
        visible={this.state.isDeleteUserModalVisible}
        onOk={this.onOK}
        onCancel={this.onCancel}
        data-test="sdsdialog.delete.user"
      >
        <FormattedMessage
          id="confirm-delete-user"
          defaultMessage="Are you sure you wish to delete this user?"
          description="Confirm user deletion"
        />
      </SDSDialog>
    );
  };

  renderSubmitUserErrorsModal = () => {
    return (
      <SDSAlert
        visible={this.state.submitUserErrorAlertText !== null}
        onConfirm={this.onConfirmRenderSubmitUserErrorsModal}
        data-test="modals.newUserModal.sdsAlert.user.errors"
      >
        {this.state.submitUserErrorAlertText}
      </SDSAlert>
    );
  };

  deleteUser = () => {
    this.setState({ isDeleteUserModalVisible: true });
  };

  updateUserRoles = (role: UserCreationTableRoleName) => () => {
    const selected = !this.state.roles[role]!.explicit;
    this.setUserRoleSelected(role, selected);
  };

  setUserRoleSelected = (role: UserCreationTableRoleName, selected: boolean) => {
    const updatedRoles: { [key: string]: any } = {};
    updatedRoles[role] = {
      explicit: selected,
    };
    const inherited = INHERITANCE_MAP[role];
    inherited?.forEach(roleName => {
      updatedRoles[roleName] = {
        explicit: false,
        forced: false,
        roleInheritance: selected,
      };
    });

    const forced = FORCE_ROLES_SELECTION[role];
    forced?.forEach(roleName => {
      updatedRoles[roleName] = {
        explicit: false,
        forced: true,
        roleInheritance: selected,
      };
    });
    this.setState({ roles: _.merge(this.state.roles, updatedRoles) });
  };

  handleUsernameChange = (event: any) => {
    this.setState({ username: event.target.value });
  };

  handleEmailChange = (event: any) => {
    this.setState({ email: event.target.value.trim() });
  };

  handleSnapUsernameChange = (event: any) => {
    // If the user starts typing new snapUsername, we will have to check again if the user exists
    // Therefore we have to invalidate our existing record
    if (this.props.needsMoreInfoToBeCreated) {
      (this.props as any).clearUserWhoNeedsMoreInfo();
      this.setState({
        snapUsername: event.target.value.trim(),
        email: '',
        username: '',
      });
    } else {
      this.setState({ snapUsername: event.target.value.trim() });
    }
  };

  isSubscriptionChecked = (eventId: any) => this.state.subscriptions[eventId];

  hasRoleChecked = (role: RoleState) => {
    return role.explicit || role.roleInheritance;
  };

  saving = () => {
    return this.props.isSavingUser || this.props.adminIsSavingUser;
  };

  submitButtonDisabled = () => {
    return !_.some(this.state.roles, this.hasRoleChecked) || this.saving();
  };

  renderHeaderText = () => {
    if (this.props.options.currentUser) {
      if (this.props.options.snapAdmin) {
        return (
          <FormattedMessage
            id="edit-employee-modal-title"
            defaultMessage="Edit Employee"
            description="Edit Employee Modal Title"
          />
        );
      }
      return (
        <FormattedMessage id="edit-user-modal-title" defaultMessage="Edit User" description="Edit User Modal Title" />
      );
    }
    if (this.props.options.snapAdmin) {
      return (
        <FormattedMessage
          id="new-employee-modal-title"
          defaultMessage="New Employee"
          description="New Employee Modal Title"
        />
      );
    }
    return getMessageFromId('new-user-modal-title');
  };

  renderTooltip = () => {
    // If user only has businessAdmin role, show tooltip explaining it doesn't give any permission in SStudio
    const explicitRoles = Object.keys(_.pickBy(this.state.roles, role => role.explicit));
    if (_.isEqual(explicitRoles, [Roles.BUSINESS_ADMIN])) {
      return (
        <div className={style.contentShareBorder}>
          <FormattedMessage
            id="business-admin-no-permissions"
            defaultMessage="This user cannot access or change any content on this Profile, but does hold a role within the same organisation"
            description="Tooltip explaining why the selected roles dont give any permission to profile"
          />
        </div>
      );
    }
    return null;
  };

  shouldShowDeleteUserButton() {
    const hostUserName = this.props.activePublisher?.hostUsername;
    const toBeDeletedUserName = this.state.snapUsername;
    return this.props.options.currentUser && hostUserName && hostUserName !== toBeDeletedUserName;
  }

  renderButtonActions() {
    const deleteButton = (
      <SDSButton
        type={ButtonType.PRIMARY}
        onClick={this.deleteUser}
        data-test="modals.newUser.delete.button"
        // Disable removing org admin from Story studio level,
        // because it results in losing access to all publishers in this org
        // Org level roles should be managed in business manager instead.
        disabled={this.hasRoleChecked(this.state.roles[Roles.ORG_ADMIN]!)}
      >
        <FormattedMessage id="delete-button-label" defaultMessage="Delete" description="Label for delete button" />
      </SDSButton>
    );

    const submitButton = (
      <SDSButton
        type={ButtonType.PRIMARY}
        onClick={this.submitUser}
        data-test="modals.newUser.submit.button"
        disabled={this.submitButtonDisabled()}
      >
        {this.saving() ? (
          getMessageFromId('saving')
        ) : (
          <FormattedMessage
            id="submit-button-text"
            defaultMessage="Submit"
            description="[Button] generic submit button"
          />
        )}
      </SDSButton>
    );
    return (
      <>
        {this.shouldShowDeleteUserButton() ? deleteButton : <div />}
        {submitButton}
      </>
    );
  }

  handleHideModal = () => (this.props as any).hideModal(this.props.modalId);

  isHandlingEmployee = () => {
    const { options } = this.props;
    const { snapAdmin, currentUser, addEmployeeToSinglePublisher } = options;
    if (snapAdmin || addEmployeeToSinglePublisher) {
      return true;
    }
    if (currentUser && isSnapchatEmail(currentUser.email)) {
      return true;
    }
    return false;
  };

  renderGlobalRoleNameHeader = () => (
    <FormattedMessage
      id="global-roles-table-in-edit-user-modal"
      description="Table header for the global role column in the edit user modal"
      defaultMessage="Global Role"
    />
  );

  renderProfileRoleNameHeader = () => (
    <FormattedMessage
      id="profile-roles-table-in-edit-user-modal"
      description="Table header for the profile role column in the edit user modal"
      defaultMessage="Profile Role"
    />
  );

  renderBMLink = () => {
    return (
      <div className={style.bmLink}>
        <span>In order to change Org Admin Roles, please go to </span>
        <a href={'https://business.snapchat.com/'}>Business Manager.</a>
      </div>
    );
  };

  renderRoles() {
    const { snapAdmin } = this.props.options;
    const { roles } = this.state;

    if (snapAdmin) {
      // @ts-ignore
      const globalRoles = _.pickBy(roles, (value, role) => {
        // @ts-ignore
        return ROLES_FOR_USER_CREATION_TABLE[role] && !ROLES_FOR_USER_CREATION_TABLE[role].orgRole;
      });
      const globalRolesList = this.getGlobalRolesList();

      return (
        <>
          {this.renderBMLink()}
          <UserRolesTable
            data-test="modals.newUser.roles.global"
            renderRoleNameHeader={this.renderGlobalRoleNameHeader}
            rolesList={globalRolesList}
            updateUserRoles={this.updateUserRoles}
            hasRoleChecked={this.hasRoleChecked}
            roles={globalRoles}
            isVisibleOnlyForSuperAdmin={false}
          />
        </>
      );
    }

    const profileRoles = _.pickBy(
      roles,
      (value, role) => !ROLES_FOR_USER_CREATION_TABLE[role as UserCreationTableRoleName].orgRole
    );
    const profileRolesList = this.getProfileRolesList();
    const adminRolesList = [Roles.SUPER_ADMIN, Roles.ORG_ADMIN, Roles.BUSINESS_ADMIN];
    const userAdminRolesList = this.props.loggedUserRoles.filter(
      (role: { roleId: Roles; explicit: boolean; roleInheritance: boolean }) =>
        adminRolesList.includes(role.roleId) && (role.explicit || role.roleInheritance)
    );

    return (
      <>
        {!!userAdminRolesList.length && this.renderBMLink()}
        <UserRolesTable
          data-test="modals.newUser.roles.profile"
          renderRoleNameHeader={this.renderProfileRoleNameHeader}
          rolesList={profileRolesList}
          updateUserRoles={this.updateUserRoles}
          hasRoleChecked={this.hasRoleChecked}
          roles={profileRoles}
          isVisibleOnlyForSuperAdmin={false}
        />
      </>
    );
  }

  render() {
    const { options } = this.props;
    const { snapAdmin, currentUser } = options;
    const { email, username, snapUsername } = this.state;
    return (
      <SDSCustomModal
        visible
        onClose={this.handleHideModal}
        footer={this.renderButtonActions()}
        title={this.renderHeaderText()}
        isBodyCentered
        data-test="modals.newUser"
        width={600}
      >
        <div className={style.root}>
          {this.renderTooltip()}
          {this.state.isLoading ? (
            <SpinnerIcon className={style.spinner} />
          ) : (
            <div className={style.modalBodyContainer}>
              <UserInfoForm
                snapUsername={snapUsername}
                username={username}
                email={email}
                handleSnapUsernameChange={this.handleSnapUsernameChange}
                handleUsernameChange={this.handleUsernameChange}
                handleEmailChange={this.handleEmailChange}
                isAddingNewUser={!currentUser}
                isHandlingEmployee={this.isHandlingEmployee()}
                shouldShowEmailAndName={this.props.needsMoreInfoToBeCreated || !!currentUser}
              />
              {!snapAdmin && (
                <UserSubscriptionsTable
                  availableSubscriptionEvents={Object.keys(this.props.subscriptions) as SubscriberEvent[]}
                  updateUserSubscriptions={this.handleSubscriptionChange}
                  isSubscriptionChecked={this.isSubscriptionChecked}
                  isSubscriptionDisabled={this.props.isSavingUser}
                />
              )}
              {this.renderRoles()}
            </div>
          )}
          {this.renderSubmitUserErrorsModal()}
          {this.renderDeleteUserModal()}
        </div>
      </SDSCustomModal>
    );
  }
}
export default intlConnect(mapStateToProps, mapDispatchToProps)(NewUserModal);
