import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import type { ReactElement } from 'react';

import { PopoverCloseOnMovement } from 'views/common/components/PopoverCloseOnMovement/PopoverCloseOnMovement';

import style from './CompositeSnapMenuPopover.scss';

const POPOVER_ARROW_OFFSET_LEFT = '125px';

type Props = {
  snapId: string;
  children: any;
  menuItems: Array<ReactElement<any>>;
  className?: string;
};

export class CompositeSnapMenuPopover extends React.Component<Props> {
  static contextTypes = {
    getScrollContainer: PropTypes.func,
  };

  render() {
    return (
      <PopoverCloseOnMovement
        className={classNames(style.popover, this.props.className)}
        id="CompositeSnapMenuPopover"
        target={this.props.children}
        closeOnVerticalMovement={false}
        closeOnHorizontalMovement={false}
        arrowOffsetLeft={POPOVER_ARROW_OFFSET_LEFT}
        data-test="compositeMenuPopover"
      >
        {this.props.menuItems}
      </PopoverCloseOnMovement>
    );
  }
}

export default CompositeSnapMenuPopover;
