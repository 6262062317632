import { noop } from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { cross } from 'icons/SDS/allIcons';
import { intlConnect } from 'utils/connectUtils';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';

import SDSButton, { ButtonType, ButtonShape } from 'views/common/components/SDSButton/SDSButton';
import SDSInput from 'views/common/components/SDSInput/SDSInput';
import SDSPanel from 'views/common/components/SDSPanel/SDSPanel';

import style from './OrgDetails.scss';

import type { State as BaseState } from 'types/rootState';

type StateProps = {};
type DispatchProps = {};

type Props = {
  onClose: () => void;
} & StateProps &
  DispatchProps;

type State = {};

export const mapStateToProps = (state: BaseState): StateProps => {
  return {};
};

const mapDispatchToProps: DispatchProps = {};

export class OrgDetails extends React.Component<Props, State> {
  render() {
    return (
      <div className={style.container}>
        <div className={style.header}>
          <div className={style.title}>{getMessageFromId('new-org-details')}</div>
          <div className={style.changes}>
            <FormattedMessage
              id="organisation-details-changes-saved"
              description="Organisation details changes saved message"
              defaultMessage="Changes saved"
            />
            <SDSButton
              type={ButtonType.SECONDARY}
              shape={ButtonShape.CIRCLE}
              inlineIcon={cross}
              onClick={this.props.onClose}
            />
          </div>
        </div>

        <SDSPanel header={getMessageFromId('new-org-details-header')}>
          <SDSPanel.Row>
            <SDSPanel.Column>
              <SDSInput
                labelTitle={getMessageFromId('new-org-details-name')}
                onChange={noop}
                data-test="organisations.orgDetails.newName.input"
              />
            </SDSPanel.Column>
            <div className={style.rightColumn}>
              <SDSPanel.Column>
                <SDSInput
                  labelTitle={getMessageFromId('new-org-details-address')}
                  onChange={noop}
                  data-test="organisations.orgDetails.newAddress.input"
                />
              </SDSPanel.Column>
            </div>
          </SDSPanel.Row>
        </SDSPanel>

        <SDSPanel header={getMessageFromId('new-org-finance-header')}>
          <SDSPanel.Row>
            <SDSPanel.Column>
              <SDSInput
                labelTitle={getMessageFromId('new-org-finance-name')}
                onChange={noop}
                data-test="organisations.orgDetails.newFinanceName.input"
              />
            </SDSPanel.Column>
            <div className={style.rightColumn}>
              <SDSPanel.Column>
                <SDSInput
                  labelTitle={getMessageFromId('new-org-finance-email')}
                  onChange={noop}
                  data-test="organisations.orgDetails.newFinanceEmail.input"
                />
              </SDSPanel.Column>
            </div>
          </SDSPanel.Row>
        </SDSPanel>
      </div>
    );
  }
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(OrgDetails);
