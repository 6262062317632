import React, { ChangeEvent } from 'react';

import * as mediaLibraryActions from 'state/mediaLibrary/actions/mediaLibraryActions';
import * as mediaLibrarySelectors from 'state/mediaLibrary/selectors/mediaLibrarySelectors';
import { isPreviewMuted } from 'state/previews/selectors/previewsSelectors';
import { activePublisherIsShow } from 'state/publishers/selectors/publishersSelectors';

import { upload } from 'icons/SDS/allIcons';
import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';

import SDSButton, { ButtonShape, ButtonType } from 'views/common/components/SDSButton/SDSButton';
import MutePreviewButton from 'views/common/containers/MutePreviewButton/MutePreviewButton';

import zoomInIcon from 'icons/zoom-in.svg';
import zoomOutIcon from 'icons/zoom-out.svg';

import style from './MediaLibraryHeaderButtons.scss';

import { ZoomType } from 'types/grid';
import type { Zoom } from 'types/grid';

type OwnProps = {
  onCancel: () => void;
  onDropFiles: (a: File[]) => void;
  hideCancelButton?: boolean;
};
type StateProps = {
  isShowPublisher: boolean;
  muted: boolean;
  zoom: Zoom;
};
type DispatchProps = {
  toggleZoom: typeof mediaLibraryActions.toggleZoom;
};
type Props = OwnProps & StateProps & DispatchProps;
const mapStateToProps = (state: State): StateProps => {
  return {
    isShowPublisher: activePublisherIsShow(state),
    muted: isPreviewMuted(state),
    zoom: mediaLibrarySelectors.getZoomState(state),
  };
};
const mapDispatchToProps = {
  toggleZoom: mediaLibraryActions.toggleZoom,
};
export class MediaLibraryHeaderButtons extends React.PureComponent<Props> {
  onDropFiles = (event: ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files || []).map(file => {
      const newFile = file;
      (newFile as any).blobUrl = URL.createObjectURL(file);
      return newFile;
    });
    this.props.onDropFiles(files);
    // Reset file type input element
    this.fileInputRef.value = null;
  };

  setFileInputRef = (ref: any) => {
    this.fileInputRef = ref;
  };

  fileInputRef = {
    files: [],
    value: null,
    click: () => {},
  };

  upload = () => {
    if (this.fileInputRef) {
      this.fileInputRef.click();
    }
  };

  acceptedFileTypes = () => {
    if (this.props.isShowPublisher) {
      return 'video/*';
    }
    return 'video/*, image/*';
  };

  render() {
    return (
      <div className={style.actionContainer}>
        <SDSButton
          type={ButtonType.SECONDARY}
          shape={ButtonShape.CIRCLE}
          img={this.props.zoom === ZoomType.ZOOMED_IN ? zoomOutIcon : zoomInIcon}
          onClick={this.props.toggleZoom}
          data-test="mediaLibraryHeaderButtons.zoomButton"
        />
        <MutePreviewButton />
        <SDSButton
          type={ButtonType.SECONDARY}
          shape={ButtonShape.CIRCLE}
          inlineIcon={upload}
          onClick={this.upload}
          data-test="mediaLibraryHeaderButtons.upload"
        />
        {this.props.hideCancelButton ? null : (
          <SDSButton
            data-test="MediaLibraryHeaderButton.cancel"
            type={ButtonType.SECONDARY}
            onClick={this.props.onCancel}
          >
            {getMessageFromId('cancel-button-modal')}
          </SDSButton>
        )}
        <input
          className={style.hidden}
          ref={this.setFileInputRef}
          type="file"
          multiple
          onChange={this.onDropFiles}
          accept={this.acceptedFileTypes()}
          data-test="mediaLibraryHeaderButtons.input"
        />
      </div>
    );
  }
}
export default intlConnect(mapStateToProps, mapDispatchToProps)(MediaLibraryHeaderButtons);
