import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { DropzoneType } from 'config/constants';

import style from './MediaUploader.scss';
// We can't just use video/* as Safari interprets it as .mov only.
const acceptedVideos = 'video/mp4,video/x-m4v,video/*';
const acceptedImages = 'image/png,image/jpeg';
const articleImages = 'image/png,image/jpeg,image/gif';
const acceptedSubtitles = '.vtt,.srt';
const acceptedTopSnapMedia = `${acceptedImages},${acceptedVideos}`;
export const enabledDropzoneConfigs = {
  [DropzoneType.TOPSNAP]: {
    props: {
      activeClassName: style.topsnapDropzoneActive,
      className: style.topsnapDropzone,
      accept: acceptedTopSnapMedia,
    },
  },
  [DropzoneType.TOPSNAP_IMAGE]: {
    props: {
      className: classNames(style.panelMediaComponent, style.enabledComponent),
      accept: `${acceptedImages}`,
    },
  },
  [DropzoneType.TOPSNAP_VIDEO]: {
    props: {
      className: classNames(style.panelMediaComponent, style.enabledComponent),
      accept: acceptedVideos,
    },
  },
  [DropzoneType.TOPSNAP_CLICK_UPLOAD]: {
    props: {
      className: style.clickUpload,
      accept: acceptedTopSnapMedia,
    },
  },
  [DropzoneType.GENERIC_IMAGE_UPLOAD]: {
    props: {
      className: style.clickUpload,
      accept: acceptedImages,
    },
  },
  [DropzoneType.TOPSNAP_SUBTITLES]: {
    props: {
      className: style.wideClickUpload,
      accept: acceptedSubtitles,
    },
  },
  [DropzoneType.EDITION_SUBTITLES]: {
    props: {
      className: style.wideClickUpload,
      accept: acceptedSubtitles,
    },
  },
  [DropzoneType.SINGLE_ASSET_SUBTITLES]: {
    props: {
      className: style.wideClickUpload,
      accept: acceptedSubtitles,
    },
  },
  [DropzoneType.CHEETAH_TILE]: {
    props: {
      className: style.newTile,
      accept: acceptedImages,
    },
  },
  [DropzoneType.NEW_TILE_IMAGE]: {
    props: {
      className: style.newTile,
      accept: `${acceptedImages}`,
    },
  },
  [DropzoneType.REPLACE_TILE_IMAGE]: {
    props: {
      className: style.replaceZone,
      accept: `${acceptedImages}`,
    },
  },
  [DropzoneType.REPLACE]: {
    props: {
      className: style.replaceZone,
      accept: acceptedTopSnapMedia,
    },
  },
  [DropzoneType.SUBTITLE]: {
    props: {
      className: style.replaceZone,
      accept: acceptedSubtitles,
    },
  },
  [DropzoneType.VIDEO_REPLACE]: {
    props: {
      className: style.replaceZone,
      accept: acceptedVideos,
    },
  },
  [DropzoneType.MODAL_VIDEO]: {
    props: {
      className: style.modalVideoClass,
      accept: acceptedVideos,
    },
  },
  [DropzoneType.NEW_OVERLAY]: {
    props: {
      className: style.newOverlay,
      accept: `${acceptedImages}`,
    },
  },
  [DropzoneType.VIDEO]: {
    props: {
      className: style.longformVideo,
      accept: acceptedVideos,
    },
  },
  [DropzoneType.SINGLE_ASSET_STORY]: {
    props: {
      className: classNames(style.panelMediaComponent, style.enabledComponent),
      accept: acceptedVideos,
    },
  },
  [DropzoneType.MODAL_IMAGE]: {
    props: {
      className: style.modalImageClass,
      accept: `${articleImages}`,
    },
  },
  [DropzoneType.VIDEO_CLICK_BROWSE]: {
    props: {
      className: (style as any).clickBrowse,
      accept: acceptedVideos,
    },
  },
  [DropzoneType.POLL_IMAGE]: {
    props: {
      className: style.replaceZone,
      accept: `${acceptedImages}`,
    },
  },
  [DropzoneType.POLL_IMAGE_REPLACE]: {
    props: {
      className: style.replaceZone,
      accept: `${acceptedImages}`,
    },
  },
  [DropzoneType.NEW_TILE_LOGO_ZONE]: {
    props: {
      className: style.tileLogoClass,
      accept: 'image/png',
    },
  },
  [DropzoneType.NEW_TILE_LOGO]: {
    props: {
      className: style.newTile,
      accept: 'image/png',
    },
  },
  [DropzoneType.REPLACE_TILE_LOGO]: {
    props: {
      className: style.replaceZone,
      accept: 'image/png',
    },
  },
  [DropzoneType.PROFILE_IMAGE]: {
    props: {
      className: style.replaceZone,
      accept: 'image/jpeg',
    },
  },
  [DropzoneType.SOUNDTRACK]: {
    props: {
      className: style.audioReplaceZone,
      accept: '.mp3',
    },
  },
  [DropzoneType.SOUNDTRACK_REPLACE]: {
    props: {
      className: style.audioReplaceZone,
      accept: '.mp3',
    },
  },
};
export const disabledDropzoneConfigs = {
  [DropzoneType.TOPSNAP]: {
    className: style.topsnapDropzone,
  },
  [DropzoneType.TOPSNAP_IMAGE]: {
    className: classNames(style.panelMediaComponent, style.disabledComponent),
  },
  [DropzoneType.TOPSNAP_VIDEO]: {
    className: classNames(style.panelMediaComponent, style.disabledComponent),
  },
  [DropzoneType.VIDEO]: {
    className: style.longformVideo,
  },
  [DropzoneType.MODAL_IMAGE]: {
    className: classNames(style.modalImageClass, style.disableModal),
  },
  [DropzoneType.MODAL_VIDEO]: {
    className: classNames(style.modalVideoClass, style.disableModal),
  },
  [DropzoneType.REPLACE]: {
    props: {
      className: classNames(style.replaceZone, style.disabledComponent),
    },
  },
  [DropzoneType.NEW_TILE_LOGO_ZONE]: {
    className: classNames(style.tileLogoClass, style.disableModal),
  },
  [DropzoneType.NEW_TILE_LOGO]: {
    props: {
      className: classNames(style.newTile, style.disabledComponent),
    },
  },
  [DropzoneType.REPLACE_TILE_LOGO]: {
    props: {
      className: classNames(style.replaceZone, style.disabledComponent),
    },
  },
  [DropzoneType.TOPSNAP_SUBTITLES]: {
    className: style.wideClickUpload,
  },
  [DropzoneType.EDITION_SUBTITLES]: {
    className: style.wideClickUpload,
  },
  [DropzoneType.SINGLE_ASSET_SUBTITLES]: {
    className: style.wideClickUpload,
  },
};
// helper PFC to render tooltips for adding / replacing new tile media
// if props.isNew === true, use the newTileMedia tooltip, otherwise use the replaceTileMedia tooltip
const TileMediaTooltip = (
  props: {
    isNew?: boolean;
  } = { isNew: false }
) => {
  const tooltipStyle = props.isNew ? style.tileMediaTooltip : style.replaceTileMediaTooltip;
  return (
    <div className={tooltipStyle}>
      <div className={style.tileMediaTooltipTitle}>
        <FormattedMessage id="new-tile-tooltip-title" description="new-tile-tooltip-title" defaultMessage="IMAGE" />
      </div>
      <br />
      <FormattedMessage
        id="new-tile-tooltip-subtitle"
        description="new-tile-tooltip-subtitle"
        defaultMessage="PNG or JPG"
      />
      <br />
      <FormattedMessage
        id="new-tile-tooltip-body-1"
        description="new-tile-tooltip-body"
        defaultMessage="Minimum width 540px"
      />
      <br />
      <FormattedMessage
        id="new-tile-tooltip-body-2"
        description="new-tile-tooltip-body"
        defaultMessage="Minimum height 900px"
      />
    </div>
  );
};
TileMediaTooltip.propTypes = {
  isNew: PropTypes.bool,
};
const getMaxTopSnapDuration = (maxDurationSeconds: any) => {
  return maxDurationSeconds === null ? 20 : maxDurationSeconds;
};
/* eslint max-len: ["error", 1000] */
export const tooltipText = (maxDurationSeconds: number | undefined | null = null) => {
  const maxTopSnapDuration = getMaxTopSnapDuration(maxDurationSeconds);
  return {
    [DropzoneType.TOPSNAP]: (
      <div className={style.videoTooltip}>
        <div className={style.infoTitle}>
          <FormattedMessage id="topsnap-tooltip-videos" description="videos-tooltip" defaultMessage="VIDEOS" />
        </div>
        <br />
        <FormattedMessage
          id="topsnap-tooltip-info-1"
          description="topsnap-tooltip-info"
          defaultMessage="1080x1920 (9:16 aspect ratio)"
        />
        <br />
        <FormattedMessage
          id="topsnap-tooltip-info-2"
          description="topsnap-tooltip-info"
          defaultMessage="H.264 encoded MOV or MP4"
        />
        <br />
        <FormattedMessage
          id="topsnap-tooltip-info-3"
          description="topsnap-tooltip-info"
          defaultMessage="max length: {maxTopSnapDuration} seconds"
          values={{
            maxTopSnapDuration,
          }}
        />
        <br />
        <FormattedMessage
          id="topsnap-tooltip-info-4"
          description="topsnap-tooltip-info"
          defaultMessage="max file size: 32 MB"
        />
      </div>
    ),
    [DropzoneType.VIDEO]: (
      <div className={style.videoTooltip}>
        <FormattedMessage
          id="longform-tooltip"
          description="longform-tooltip"
          defaultMessage="These assets should be delivered as h264 encoded mov or mp4 files."
        />
      </div>
    ),
    [DropzoneType.MODAL_IMAGE]: (
      <div className={style.imageModal}>
        <div className={style.infoTitle}>
          <FormattedMessage
            id="modal-image-tooltip-title"
            description="modal-image-tooltip-title"
            defaultMessage="GIF REQUIREMENTS"
          />
        </div>
        <br />
        <FormattedMessage
          id="modal-image-tooltip-info1"
          description="modal-image-tooltip-info"
          defaultMessage="300KB or Less"
        />
        <br />
        <br />
        <FormattedMessage
          id="modal-image-tooltip-info2"
          description="modal-image-tooltip-info"
          defaultMessage="For best file size compatibly, try to keep GIFs 300px wide or smaller. If it’s still over the 350KB limit or if the TopSnap is experiencing build failures due to size, try using a GIF optimizer to reduce the resolution, framerate, or number of colors in any GIFs you previously uploaded."
        />
      </div>
    ),
    [DropzoneType.MODAL_VIDEO]: (
      <div className={style.videoModal}>
        <div className={style.infoTitle}>
          <FormattedMessage
            id="modal-video-tooltip-title"
            description="modal-video-tooltip-title"
            defaultMessage="VIDEO REQUIREMENTS"
          />
        </div>
        <br />
        <FormattedMessage
          id="modal-video-tooltip-info"
          description="modal-video-tooltip-info"
          defaultMessage="These assets should be delivered as h.264 encoded mov or mp4 files."
        />
      </div>
    ),
    [DropzoneType.NEW_TILE_IMAGE]: <TileMediaTooltip isNew />,
    [DropzoneType.REPLACE_TILE_IMAGE]: <TileMediaTooltip />,
  };
};
