import classNames from 'classnames';
import { memoize } from 'lodash';
import React from 'react';
import type { ReactNode } from 'react';

import * as featuresSelectors from 'state/features/selectors/featuresSelectors';
import * as mediaLibraryActions from 'state/mediaLibrary/actions/mediaLibraryActions';
import * as mediaLibraryTrayActions from 'state/mediaLibrary/actions/mediaLibraryTrayActions';
import * as mediaLibrarySelectors from 'state/mediaLibrary/selectors/mediaLibrarySelectors';
import * as mediaLibraryTraySelectors from 'state/mediaLibrary/selectors/mediaLibraryTraySelectors';
import * as userSelectors from 'state/user/selectors/userSelectors';

import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';

import SnapGrid from 'views/common/containers/SnapGrid/SnapGrid';
import { mediaViewForFilter } from 'views/mediaLibrary/MediaView/MediaView';
import { isVideo } from 'views/mediaLibrary/utils/MediaLibraryUtils';

import style from './MediaLibraryGrid.scss';

import { isImportedSnapID } from 'types/assets';
import type { Zoom } from 'types/grid';
import type { MediaItem, TargetType, FilterByType } from 'types/mediaLibrary';
import { TargetEnum } from 'types/mediaLibrary';

type OwnProps = {
  mediaItems: MediaItem[];
  target: TargetType;
  showMetadataModal: (a: MediaItem) => void;
  drawerMode?: boolean;
  onDragStart?: () => void;
  onItemDropped?: () => void;
  onDragCancelled?: () => void;
  storySnapsMediaIds: Array<string | undefined | null>;
};

type StateProps = {
  selectedItemsList: MediaItem[];
  selectedItemsIdSet: {
    [x: string]: boolean;
  };
  isLoading: boolean;
  hasMoreResults: boolean;
  zoom: Zoom;
  currentFilter: FilterByType;
  isCurationSnapDownloader: boolean;
};

type DispatchProps = {
  toggleMedia: typeof mediaLibraryTrayActions.toggleMedia;
  clearTray: typeof mediaLibraryTrayActions.clearTray;
  clearAndSelectMedia: typeof mediaLibraryTrayActions.clearAndSelectMedia;
  getMedia: typeof mediaLibraryActions.getMedia;
};

type Props = OwnProps & StateProps & DispatchProps;

const mapStateToProps = (state: State): StateProps => {
  return {
    selectedItemsList: mediaLibraryTraySelectors.getSelectedMediaList(state),
    selectedItemsIdSet: mediaLibraryTraySelectors.getSelectedMediaIdSet(state),
    isLoading: mediaLibrarySelectors.isLoading(state),
    hasMoreResults: mediaLibrarySelectors.hasMoreResults(state),
    zoom: mediaLibrarySelectors.getZoomState(state),
    currentFilter: mediaLibrarySelectors.currentFilter(state),
    isCurationSnapDownloader:
      userSelectors.isCurationSnapDownloader(state) && featuresSelectors.isAdvancedCurationEnabled(state),
  };
};

const mapDispatchToProps = {
  toggleMedia: mediaLibraryTrayActions.toggleMedia,
  clearTray: mediaLibraryTrayActions.clearTray,
  clearAndSelectMedia: mediaLibraryTrayActions.clearAndSelectMedia,
  getMedia: mediaLibraryActions.getMedia,
};

export class MediaLibraryGrid extends React.Component<Props> {
  getMedia = (params: any) => {
    return this.props.getMedia({
      ...params,
      target: this.props.target,
    });
  };

  handleShowMetadataModal = memoize(mediaItem => () => {
    this.props.showMetadataModal(mediaItem);
  });

  createItemView = (key: string, itemViewStyle: any, isSelected: boolean, mediaItem: MediaItem) => {
    // TODO: replace with a separate field for video preview
    const videoPreviewSrc = isVideo(mediaItem.mediaType) ? mediaItem.mediaUrl : null;
    const indexOfSnapMediaBeingUsed = this.props.storySnapsMediaIds.indexOf(mediaItem.id);
    const MediaView = mediaViewForFilter(this.props.currentFilter);

    return (
      <MediaView
        key={key}
        onToggle={this.toggleMedia(isSelected)}
        mediaItem={mediaItem}
        videoPreviewSrc={videoPreviewSrc}
        style={itemViewStyle}
        isSelected={isSelected}
        showMetadataModal={this.handleShowMetadataModal(mediaItem)}
        draggable={this.props.drawerMode}
        selectedItemsList={this.props.selectedItemsList}
        onDragStart={this.props.onDragStart}
        onItemDropped={this.props.onItemDropped}
        onDragCancelled={this.props.onDragCancelled}
        indexOfSnapMediaBeingUsed={indexOfSnapMediaBeingUsed === -1 ? null : indexOfSnapMediaBeingUsed + 1}
        isDownloadable={!isImportedSnapID(mediaItem.id) || this.props.isCurationSnapDownloader}
      />
    );
  };

  toggleMedia = (isCurrentlySelected: boolean) => {
    return (mediaId: string, isShiftSelect: boolean) => {
      // Only allow one media to be selected if targeting Video Attachment editor
      if (this.props.target === TargetEnum.VIDEO_ATTACHMENT && !isCurrentlySelected) {
        this.props.clearAndSelectMedia(mediaId);
      } else {
        this.props.toggleMedia(mediaId, isShiftSelect);
      }
    };
  };

  renderGrid = (
    numGridItems: number,
    renderInfiniteLoader: (a: boolean) => ReactNode,
    renderFullGridSpinner: () => ReactNode,
    renderEmptyView: () => ReactNode
  ) => {
    if (numGridItems === 0) {
      if (!this.props.hasMoreResults && !this.props.isLoading) {
        return renderEmptyView();
      }
      return renderFullGridSpinner();
    }
    return renderInfiniteLoader(this.props.isLoading);
  };

  render() {
    return (
      <SnapGrid
        className={classNames({ [style.gridWithPadding]: !this.props.drawerMode })}
        gridItems={this.props.mediaItems}
        selectedItemsList={this.props.selectedItemsList}
        selectedItemIdSet={this.props.selectedItemsIdSet}
        isLoading={this.props.isLoading}
        zoom={this.props.zoom}
        loadItems={this.getMedia}
        createItemView={this.createItemView}
        createGridView={this.renderGrid}
      />
    );
  }
}

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ forwardRef: boolean; }' is not... Remove this comment to see the full error message
export default intlConnect(mapStateToProps, mapDispatchToProps, { forwardRef: true })(MediaLibraryGrid);
