import Select from 'antd/lib/select';
import React from 'react';
import type { ReactNode } from 'react';

declare module 'antd/lib/select' {
  export interface OptionProps {
    label?: string;
  }
}

export type DropdownOptionWithLabelProp = {
  label: string;
  value: string;
  disabled?: boolean;
  menuLabel: ReactNode;
};

// https://codesandbox.io/s/8pjcq
// When we want to render something different in the dropdown menu and the selected value we can use this component
// to generate our Select.Options. We also have to pass `optionLabelProp = 'label'` to our parent `SDSDropdown` so that
// `Select` correctly renders the options.
export function createSDSDropdownOptionsWithPropLabel(options: Array<DropdownOptionWithLabelProp>): ReactNode {
  if (!options) {
    return null;
  }
  return options.map((option: DropdownOptionWithLabelProp) => (
    <Select.Option
      key={`SDSDropdownOption.${option.value}`}
      value={option.value}
      data-test={`SDSDropdown.selectOptions.${option.value}`}
      disabled={option.disabled}
      label={option.label}
    >
      {option.menuLabel}
    </Select.Option>
  ));
}
