import { MultiSelect } from '@blueprintjs/labs';
import classNames from 'classnames';
import { get } from 'lodash';
import React from 'react';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { sendNotificationMessage } from 'state/notifications/actions/notificationsActions';

import { cross, plus, magicWand } from 'icons/SDS/allIcons';
import { intlConnect } from 'utils/connectUtils';
import { InfoContext, infoMessageConfig } from 'utils/errors/infoMessage/infoMessageUtils';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';
import { localStorage } from 'utils/localStorageUtils';

import Icon from 'views/common/components/Icon/Icon';

import style from './MultiSelectInput.scss';

import { ExtractDispatchProps } from 'types/redux';

type TagType = string;
export type TagOptions = {
  // The list of tags to display in the text box (if tagMapping is defined, the associated name will be displayed instead)
  defaultTags: string[];
  // The list of suggestions to display in the dropdown (if tagMapping is defined, the associated name will be displayed instead)
  suggestions: string[] | undefined | null;
  // Optional: Use if displayed tag should be different to stored value (map value => displayed name)
  tagMapping?: {
    [x: string]: string;
  };
  // Optional: Header to be displayed at the top of the suggestions of this tag type
  sectionHeader?: string | ReactNode;
  // Optional: Class name to be added to tags of this type
  className?: string;
  // Optional: The maximum number of tags of this type that can be selected
  maxSelected?: number;
  // You can render any kind of icon next to the tag
  icon?: string;
};
type TagsMap = {
  [k in TagType]: string[];
};
type OwnProps = {
  tagOptions: {
    [k in TagType]: TagOptions;
  };
  tagTypeOrder: TagType[];
  onChange: (a: TagsMap) => void;
  className?: string;
  searchItems: (query: string) => void;
  disabled?: boolean;
  hideSearchWhenDisabled?: boolean;
  // Optional: If true, pressing enter on any search term will add the exact search term you added, if in any autocomplete list
  // (if in multiple, the tag ordering will determine which will be used)
  addSearchTermOnEnter?: boolean;
  // Optional: If true, suggestions equal to 'null' will be considered "still loading", whereas '[]' will be considered "no results"
  waitForAllSuggestionsLoaded?: boolean;
  // Optional: If true, selected tags won't show up in suggestions
  showSelectedInSuggestions?: boolean;
  openOnFocus?: boolean;
  showResultsIfNoSearchQuery?: boolean;
  // The maximum number of results to display. If there are more results than this, show +X
  maxSelectedToDisplay?: number;
  pastedTags?: TagsMap;
  // Only for the SnapTagInput case
  pasteAutomaticTagsCounter?: number;
  automaticTags?: TagsMap;
  'data-test'?: string;
  enforceSCCTag?: boolean;
  clearedTags?: boolean;
};
type State = {
  tags: TagsMap;
  currentSearch: string;
  pastedAutomaticTags: Set<string | ReactNode>;
};
type Tag = {
  name: string | ReactNode;
  value: string | undefined | null;
  section: TagType;
  isSectionHeader?: boolean;
  isSearchTermOnEnter?: boolean;
  isAdditionalNumber?: boolean;
  isMaxReachedWarning?: boolean;
};
const mapDispatchToProps = {
  sendNotificationMessage,
};
type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;
type Props = OwnProps & DispatchProps;
export class MultiSelectInput extends React.Component<Props, State> {
  state: State = {
    tags: {},
    currentSearch: '',
    pastedAutomaticTags: new Set(),
  };

  UNSAFE_componentWillMount() {
    const { tagOptions } = this.props;
    const tags = {};
    Object.keys(tagOptions).forEach((key: TagType) => {
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'TagOptions | undefined' is not assignable to... Remove this comment to see the full error message
      const options: TagOptions = tagOptions[key];
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      tags[key] = options.defaultTags;
    });
    this.setState({ tags });
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    // Asserts that a tag paste action triggered this
    if (nextProps.pastedTags !== this.props.pastedTags) {
      this.pastedTagsHandler();
    }
    // Asserts that an automatic tag paster action triggered this
    if (nextProps.pasteAutomaticTagsCounter !== this.props.pasteAutomaticTagsCounter) {
      this.pasteAutomaticTagsHandler();
    }
    // Asserts that a clear tag action triggered this
    if (nextProps.clearedTags !== this.props.clearedTags) {
      this.clearTagsHandler();
    }
  }

  updateTagsState = (tags: TagsMap) => {
    const truncatedTags = {};
    let tagsListWasTruncated = false;
    Object.keys(tags).forEach((tagType: TagType) => {
      const currentTagTypeOptions = this.props.tagOptions[tagType];
      const maxTags = (currentTagTypeOptions && currentTagTypeOptions.maxSelected) || null;
      let tagsForCurrentType = tags[tagType];
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      if (maxTags !== null && tagsForCurrentType.length > maxTags) {
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        tagsForCurrentType = tagsForCurrentType.slice(0, maxTags);
        tagsListWasTruncated = true;
      }
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      truncatedTags[tagType] = tagsForCurrentType;
    });
    this.setState({ tags: truncatedTags });
    this.props.onChange(truncatedTags);
    if (tagsListWasTruncated) {
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
      this.props.sendNotificationMessage(infoMessageConfig[InfoContext.TAGS_LIST_TRUNCATED]);
    }
  };

  getSelectedItems = () => {
    const selectedItems = this.formatItems(this.state.tags);
    if (!this.props.maxSelectedToDisplay || this.props.maxSelectedToDisplay >= selectedItems.length) {
      return selectedItems;
    }
    const additionalItemsLength = selectedItems.length - this.props.maxSelectedToDisplay;
    const selectedItemsWithLimit: Tag[] = selectedItems.slice(0, this.props.maxSelectedToDisplay);
    selectedItemsWithLimit.push({
      name: `+${additionalItemsLength}`,
      value: null,
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
      section: this.props.tagTypeOrder[0],
      isAdditionalNumber: true,
    });
    return selectedItemsWithLimit;
  };

  getItemList = (query: string, items: Tag[]) => {
    // Given a search string, return the ordered list of results
    if (query === '' && !this.props.showResultsIfNoSearchQuery) {
      return [];
    }
    if (query !== this.state.currentSearch) {
      this.setState({ currentSearch: query });
      this.props.searchItems(query);
    }
    if (this.props.waitForAllSuggestionsLoaded) {
      let hasFinishedLoading = true;
      Object.keys(this.props.tagOptions).forEach((key: TagType) => {
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        if (!this.props.tagOptions[key].suggestions) {
          hasFinishedLoading = false;
        }
      });
      if (!hasFinishedLoading) {
        return [];
      }
    }
    const suggestionsMap = {};
    Object.keys(this.props.tagOptions).forEach((key: TagType) => {
      const currentType = this.props.tagOptions[key];
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'maxSelected' does not exist on type 'Tag... Remove this comment to see the full error message
      const { maxSelected, suggestions } = currentType;
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      suggestionsMap[key] = [];
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      if (maxSelected == null || maxSelected > this.state.tags[key].length) {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        suggestionsMap[key] = suggestions;
        if (!this.props.showSelectedInSuggestions) {
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          suggestionsMap[key] = suggestionsMap[key].filter((value: any) => {
            return !this.tagExistsInSelectedList(key, value);
          });
        }
      }
    });
    const formattedSuggestions = this.formatItems(suggestionsMap);
    if (this.props.addSearchTermOnEnter) {
      for (let i = 0; i < formattedSuggestions.length; i++) {
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        const name = typeof formattedSuggestions[i].name === 'string' ? formattedSuggestions[i].name : '';
        if ((name as any).toLowerCase() === query.toLowerCase()) {
          formattedSuggestions.unshift({
            name,
            // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
            value: formattedSuggestions[i].value,
            // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
            section: formattedSuggestions[i].section,
            isSearchTermOnEnter: true,
          });
          break;
        }
      }
    }
    return formattedSuggestions;
  };

  hasSelectedMaxTags = (tagType: TagType) => {
    const maxSelected = get(this.props.tagOptions[tagType], 'maxSelected', null);
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    return maxSelected !== null && maxSelected <= this.state.tags[tagType].length;
  };

  getWarningMsgForTag = (tagType: TagType) => {
    const maxSelected = get(this.props.tagOptions[tagType], 'maxSelected', null);
    if (tagType === 'SCC') {
      return (
        <FormattedMessage
          id="category-tag-limit"
          description="Message shown to user when category limit reached"
          defaultMessage="Category tag limit reached ({limit})"
          values={{ limit: maxSelected }}
        />
      );
    }
    if (tagType === 'WIKI') {
      return (
        <FormattedMessage
          id="keyword-tag-limit"
          description="Message shown to user when keyword limit reached"
          defaultMessage="Keyword tag limit reached ({limit})"
          values={{ limit: maxSelected }}
        />
      );
    }
    return null;
  };

  formatItems = (tagsMap: TagsMap) => {
    const formattedTags: Tag[] = [];
    for (let i = 0; i < this.props.tagTypeOrder.length; i++) {
      const key = this.props.tagTypeOrder[i];
      // @ts-expect-error ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type.
      const tags: string[] = tagsMap[key] || [];
      // @ts-expect-error ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type.
      const { sectionHeader } = this.props.tagOptions[key];
      if (sectionHeader) {
        if (this.props.enforceSCCTag && key === 'WIKI' && tags.length === 0) {
          const enforceSCCMessage = (
            <FormattedMessage
              id="enforce-scc-tag-message"
              description="Message shown to user when they need to add a blue category tag first"
              defaultMessage="Please add a blue category tag first"
            />
          );
          formattedTags.push({
            name: sectionHeader,
            value: null,
            section: key,
            isSectionHeader: true,
          });
          formattedTags.push({
            name: enforceSCCMessage,
            value: null,
            section: key,
            isSectionHeader: false,
            isMaxReachedWarning: true,
          });
        }
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
        if (tags.length > 0 || this.hasSelectedMaxTags(key)) {
          formattedTags.push({
            name: sectionHeader,
            value: null,
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
            section: key,
            isSectionHeader: true,
          });
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
          if (this.hasSelectedMaxTags(key)) {
            formattedTags.push({
              // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
              name: this.getWarningMsgForTag(key),
              value: null,
              // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
              section: key,
              isSectionHeader: false,
              isMaxReachedWarning: true,
            });
          }
        }
      }
      // This maps a value => name to display if one exists
      // @ts-expect-error ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type.
      const { tagMapping } = this.props.tagOptions[key];
      let name;
      for (let j = 0; j < tags.length; j++) {
        // @ts-expect-error ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type.
        if (tagMapping && tagMapping[tags[j]]) {
          // @ts-expect-error ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type.
          name = tagMapping[tags[j]];
        } else {
          name = tags[j];
        }
        formattedTags.push({
          name,
          value: tags[j],
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
          section: key,
          isSectionHeader: false,
        });
      }
    }
    return formattedTags;
  };

  tagExistsInSelectedList = (section: TagType, value?: string | null) => {
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    const lowercaseTags = this.state.tags[section].map(tag => {
      return tag.toLowerCase();
    });
    const tagToFind = value || '';
    return lowercaseTags.indexOf(tagToFind.toLowerCase()) !== -1;
  };

  itemRenderer = ({ item, isActive, handleClick }: { item: Tag; isActive: boolean; handleClick: () => void }) => {
    const tag = item;
    const tagExists = this.tagExistsInSelectedList(tag.section, tag.value);
    if (tag.isSearchTermOnEnter) {
      return <div key={`SEARCH-ON-ENTER-${String(tag.value)}`} />;
    }
    if (tag.isSectionHeader) {
      return (
        <div className={style.sectionHeader} key={`${tag.section}-header`}>
          {tag.name}
        </div>
      );
    }
    if (tag.isMaxReachedWarning) {
      return (
        <div className={style.warning} key={`${tag.section}-warning`}>
          {tag.name}
        </div>
      );
    }
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    const customTagClass = this.props.tagOptions[tag.section].className || '';
    const tagParentClasses = classNames({
      [style.autocompleteItem]: true,
      [style.isActive]: isActive,
    });
    const tagClasses = classNames({
      [style.autocompleteTag]: true,
      multiSelectInputActive: isActive,
      multiSelectInputSelected: tagExists,
      [customTagClass]: true,
    });
    return (
      <div className={tagParentClasses} onClick={handleClick} key={`${tag.section}${String(tag.value)}`}>
        <div className={tagClasses} data-test="multiSelectInput.options">
          {tag.name}
          {<Icon className={style.tagIcon} inlineIcon={tagExists ? cross : plus} />}
        </div>
      </div>
    );
  };

  tagProps = (value: ReactNode, index: number) => {
    const tags = this.formatItems(this.state.tags);
    if (index >= tags.length) {
      return {};
    }
    if (this.props.maxSelectedToDisplay && index >= this.props.maxSelectedToDisplay) {
      return {
        className: style.additionalTagsNumber,
      };
    }
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    const sectionIndex = tags[index].section;
    return {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      className: this.props.tagOptions[sectionIndex].className,
    };
  };

  tagRenderer = (tag: Tag) => {
    return tag.isSectionHeader || tag.isMaxReachedWarning ? null : (
      <div className={style.tagOrientation}>
        {tag.name}
        {this.renderIcon(get(this.props.tagOptions, [tag.section, 'icon']))}
        {this.automaticTagsIconRenderer(tag.name)}
      </div>
    );
  };

  automaticTagsIconRenderer = (tagName: string | ReactNode) => {
    if (!this.state.pastedAutomaticTags.has(tagName)) {
      return null;
    }
    return this.renderIcon(magicWand);
  };

  noResults = () => {
    return <div className={style.noResults}>{getMessageFromId('mutli-select-no-results')}</div>;
  };

  handleTagRemove = (tag: ReactNode | undefined | null, index: number, useValue: boolean = false) => {
    const tags = {};
    // Due to a bug in MultiSelect passing the rendered tag as value, we need to extract the value from it (see tag renderer)
    const tagNameToBeRemoved = useValue ? tag : this.extractTagNameFromNode(tag);
    Object.keys(this.state.tags).forEach((key: TagType) => {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      tags[key] = this.state.tags[key].filter(value => {
        let name;
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        if (this.props.tagOptions[key].tagMapping && !useValue) {
          // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
          name = this.props.tagOptions[key].tagMapping[value] || value;
        } else {
          name = value;
        }
        return name !== tagNameToBeRemoved;
      });
    });
    // This only happens when the tag is an automatic tag which is not an useValue case
    if (!useValue) {
      this.handleAutomaticTagRemove(this.extractTagNameFromNode(tag));
    }
    this.updateTagsState(tags);
  };

  extractTagNameFromNode = (tag?: ReactNode | null): string => {
    return get(tag, ['props', 'children', '0'], '').toString();
  };

  handleAutomaticTagRemove = (tagValueToBeRemoved?: string | null) => {
    if (tagValueToBeRemoved && this.state.pastedAutomaticTags.has(tagValueToBeRemoved)) {
      const newAutomaticPastedTags = new Set(this.state.pastedAutomaticTags);
      newAutomaticPastedTags.delete(tagValueToBeRemoved);
      this.setState({
        pastedAutomaticTags: newAutomaticPastedTags,
      });
    }
  };

  handleSingleTagChange = (tag: Tag) => {
    if (this.tagExistsInSelectedList(tag.section, tag.value)) {
      if (tag.value !== this.state.currentSearch) {
        // The tag already exists, but hasn't been entered through typing - therefore the user chose to delete the tag
        this.handleTagRemove(tag.value, 0, true);
      }
      // The text that the user has typed in already exists as a tag, so do nothing
      return;
    }
    // The tag doesn't exist, so add it
    const tags = {};
    Object.keys(this.state.tags).forEach((key: TagType) => {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      tags[key] = this.state.tags[key].slice();
    });
    if (tag.value) {
      const maxSelected = get(this.props.tagOptions[tag.section], 'maxSelected', null);
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      if (maxSelected == null || maxSelected > tags[tag.section].length) {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        tags[tag.section].push(tag.value);
        this.updateTagsState(tags);
      }
    }
  };

  handleMultiTagChange = (tags: Tag[], isAutomaticTags: boolean = false) => {
    const newTagsState = {};
    let anyNewTagsPasted = false;
    Object.keys(this.state.tags).forEach((key: TagType) => {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      newTagsState[key] = this.state.tags[key].slice();
    });
    const pastedAutomaticTagsChange: any = [];
    tags.forEach((tag: Tag) => {
      if (tag.value) {
        const tagValue = tag.value;
        // If the tag is not in the current list then add it
        if (!this.tagExistsInSelectedList(tag.section, tagValue)) {
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          newTagsState[tag.section].push(tagValue);
          anyNewTagsPasted = true;
          if (isAutomaticTags) {
            pastedAutomaticTagsChange.push(tagValue);
          }
        }
      }
    });
    if (anyNewTagsPasted) {
      this.handlePastedAutomaticTagsMultiTagChange(pastedAutomaticTagsChange);
      this.updateTagsState(newTagsState);
    }
  };

  handlePastedAutomaticTagsMultiTagChange = (pastedAutomaticTagsChange: string[]) => {
    const tagMapping = get(this.props, ['tagOptions', 'SCC', 'tagMapping'], null);
    if (tagMapping) {
      // we paste the tags in its displayable form:
      const displayablePastedAutomaticTagsChange = pastedAutomaticTagsChange.map(tag => tagMapping[tag] || tag);
      const newPastedAutomaticTags = new Set(this.state.pastedAutomaticTags);
      displayablePastedAutomaticTagsChange.forEach(newPastedAutomaticTag =>
        newPastedAutomaticTags.add(newPastedAutomaticTag)
      );
      this.setState({
        pastedAutomaticTags: newPastedAutomaticTags,
      });
    }
  };

  pastedTagsHandler = () => {
    const pastedTags = localStorage.getParsedJSON('copiedSnapTags');
    if (pastedTags) {
      const formattedTags = this.formatItems(pastedTags) || [];
      const filteredTags = formattedTags.filter(
        tag => tag.isSectionHeader === false || tag.isMaxReachedWarning === false
      );
      this.handleMultiTagChange(filteredTags);
    }
  };

  pasteAutomaticTagsHandler = () => {
    const automaticTags = this.props.automaticTags;
    if (automaticTags) {
      const formattedAutomaticTags = this.formatItems(automaticTags) || [];
      this.handleMultiTagChange(formattedAutomaticTags, true);
    }
  };

  clearTagsHandler = () => {
    const tags: TagsMap = {};
    Object.keys(this.state.tags).forEach((key: TagType) => {
      tags[key] = [];
    });
    this.updateTagsState(tags);
  };

  renderIcon(icon?: string | null) {
    if (!icon) {
      return null;
    }
    return (
      <div className={style.icon} data-test="multiSelectInput.icon">
        <Icon inlineIcon={icon} />
      </div>
    );
  }

  render() {
    const { className, hideSearchWhenDisabled, disabled, openOnFocus } = this.props;
    const rootClass = classNames(style.multiInput, className, {
      [style.hideSearch]: hideSearchWhenDisabled && disabled,
    });
    return (
      <span className={rootClass} data-test="multiSelectInput.multiInput">
        {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
        <MultiSelect
          items={[]}
          selectedItems={this.getSelectedItems()}
          onItemSelect={this.handleSingleTagChange}
          tagRenderer={this.tagRenderer}
          itemListPredicate={this.getItemList}
          itemRenderer={this.itemRenderer}
          noResults={this.noResults()}
          tagInputProps={{ onRemove: this.handleTagRemove, tagProps: this.tagProps, disabled }}
          popoverProps={{ inline: true }}
          resetOnSelect
          openOnKeyDown={!openOnFocus}
        />
      </span>
    );
  }
}
export default intlConnect(null, mapDispatchToProps)(MultiSelectInput);
