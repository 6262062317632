import React from 'react';
import type { ReactElement } from 'react';
import InlineSVG from 'svg-inline-react';

import { CompositeSnapMenuPopover } from 'views/common/components/CompositeSnapMenuPopover/CompositeSnapMenuPopover';

// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/contextual_menu.svg.inli... Remove this comment to see the full error message
import menuIcon from 'icons/contextual_menu.svg.inline';

import style from './CompositeSnapMenu.scss';

type Props = {
  className?: string;
  snapId: string;
  menuItems: Array<ReactElement<any>>;
};

export class CompositeSnapMenu extends React.Component<Props> {
  render() {
    return (
      <span className={this.props.className} data-test="compositeSnapMenu.menu">
        <CompositeSnapMenuPopover snapId={this.props.snapId} menuItems={this.props.menuItems}>
          <InlineSVG src={menuIcon} className={style.menuButton} />
        </CompositeSnapMenuPopover>
      </span>
    );
  }
}

export default CompositeSnapMenu;
