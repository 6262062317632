import React from 'react';
import { FormattedMessage } from 'react-intl';

import { hideModal } from 'state/modals/actions/modalsActions';
import { sendNotificationMessage } from 'state/notifications/actions/notificationsActions';
import { shouldUseSingleSnapBuilder } from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';
import { ReplaceStoryMediaParams } from 'state/publisherTools/actions/publisherToolsActions';
import {
  initializeSingleAssetStory,
  terminateSingleAssetStory,
} from 'state/singleAssetStory/actions/singleAssetStoryActions';

import { ErrorType, NotificationScope, StatusMessageButton, StatusMessageSeverity } from 'config/constants';
import { PublisherID } from 'src/types/publishers';
import { intlConnect } from 'utils/connectUtils';
import { ErrorContexts } from 'utils/errors/errorConstants';
import { MediaError } from 'utils/media/mediaValidation';

import SDSCheckbox, { CheckboxEvent } from 'views/common/components/SDSCheckbox/SDSCheckbox';
import SDSDialog from 'views/common/components/SDSDialog/SDSDialog';
import style from 'views/modals/components/EpisodeModal/EpisodeModal.scss';
import SingleAssetProgressUploadView from 'views/singleAssetStoryEditor/containers/SingleAssetProgressUploadView/SingleAssetProgressUploadView';

import { AssetType, AssetID } from 'types/assets';
import { EditionID } from 'types/editionID';
import { DiscardStoryMedatada } from 'types/editions';
import { ExtractDispatchProps } from 'types/redux';
import { State } from 'types/rootState';
import { SingleAssetUploadState } from 'types/singleAssetStoryEditor';

type ExternalProps = {
  modalId: string;
  options: {
    publisherId: PublisherID;
    storyId: EditionID;
    onUpdateStory: (params: ReplaceStoryMediaParams) => Promise<void>;
  };
};

const mapStateToProps = (state: State, externalProps: ExternalProps) => {
  return {
    isSingleSnapBuilder: shouldUseSingleSnapBuilder(state)(externalProps.options.storyId),
  };
};

const mapDispatchToProps = {
  hideModal,
  terminateSingleAssetStory,
  initializeSingleAssetStory,
  sendNotificationMessage,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;
type Props = ExternalProps & StateProps & DispatchProps;

type OwnState = {
  singleAssetVideoId: string;
  singleAssetUploadState: SingleAssetUploadState;
  replaceMediaOnUploadComplete: boolean;
  discardMetadata: DiscardStoryMedatada;
  isUpdating: boolean;
};

type CheckboxProps = {
  property: keyof DiscardStoryMedatada;
  label: React.ReactNode;
};

const discardCheckboxes: CheckboxProps[] = [
  {
    property: 'discardAttachments',
    label: (
      <FormattedMessage
        id="single-asset-upload-replace-media-discard-attachments"
        description="Description for a checkbox to discard story attachments when updating media"
        defaultMessage="Remove existing attachments"
      />
    ),
  },
  {
    property: 'discardTiles',
    label: (
      <FormattedMessage
        id="single-asset-upload-replace-media-discard-tiles"
        description="Description for a checkbox to discard story tiles when updating media"
        defaultMessage="Remove existing tiles"
      />
    ),
  },
  {
    property: 'discardTags',
    label: (
      <FormattedMessage
        id="single-asset-upload-replace-media-discard-tags"
        description="Description for a checkbox to discard story tags when updating media"
        defaultMessage="Remove existing tags"
      />
    ),
  },
];

const modalTitle = (
  <FormattedMessage
    id="single-asset-upload-replace-media-title"
    description="Title for replace video modal"
    defaultMessage="Replace video"
  />
);

const horizontalVideoError = (
  <FormattedMessage
    id="single-asset-upload-replace-media-horizontal-error"
    description="Error message displayed to user when video aspect ratio requires it to be uploaded via episode creator"
    defaultMessage="The video you are uploading isn't supported as it hasn't been edited into a vertical format."
  />
);

export class StoryMediaReplacementModal extends React.Component<Props, OwnState> {
  state: OwnState = {
    singleAssetVideoId: '',
    singleAssetUploadState: SingleAssetUploadState.NONE,
    replaceMediaOnUploadComplete: false,
    discardMetadata: {
      discardAttachments: false,
      discardTags: false,
      discardTiles: false,
    },
    isUpdating: false,
  };

  componentDidUpdate(prevProps: Props, prevState: OwnState) {
    if (
      prevState.singleAssetUploadState === SingleAssetUploadState.UPLOADING &&
      this.state.singleAssetUploadState === SingleAssetUploadState.COMPLETE &&
      this.state.replaceMediaOnUploadComplete
    ) {
      this.handleReplaceMedia();
    }
  }

  hideModal = () => {
    this.props.hideModal(this.props.modalId);
  };

  handleOnUploadComplete = (fileInfo: { assetId: AssetID; type: AssetType; file: File }) => {
    this.setState({ singleAssetVideoId: fileInfo.assetId, singleAssetUploadState: SingleAssetUploadState.COMPLETE });
  };

  handleOnUploadStart = () => {
    this.setState({ singleAssetUploadState: SingleAssetUploadState.UPLOADING });
  };

  handleOnUploadFailed = (error: MediaError) => {
    this.setState({ singleAssetUploadState: SingleAssetUploadState.NONE });
    if (error?.errorType === ErrorType.HORIZONTAL_SINGLE_ASSET) {
      this.props.sendNotificationMessage(
        {
          severity: StatusMessageSeverity.ERROR,
          scope: NotificationScope.EDITION,
          plainMessage: horizontalVideoError,
          buttons: [StatusMessageButton.DISMISS],
        },
        { context: ErrorContexts.VALIDATE_MEDIA }
      );
    }
    this.hideModal();
  };

  handleReplaceOnCompleteClick = () => {
    this.setState({ replaceMediaOnUploadComplete: true });
  };

  handleReplaceMedia = () => {
    const { singleAssetVideoId, discardMetadata } = this.state;
    this.setState({ isUpdating: true });
    this.props.options
      .onUpdateStory({
        singleAssetVideoId,
        discardMetadata,
      })
      .then(async () => {
        const { publisherId, storyId } = this.props.options;
        // Reinitialise the single asset editor so the preview and timeline get updated.
        await this.props.terminateSingleAssetStory(storyId);
        return this.props.initializeSingleAssetStory(publisherId, storyId);
      })
      .finally(() => {
        this.setState({ isUpdating: false });
        this.hideModal();
      });
  };

  handleDiscardMetadataChange = (property: keyof DiscardStoryMedatada) => (event: CheckboxEvent<boolean>) => {
    this.setState(state => ({
      discardMetadata: {
        ...state.discardMetadata,
        [property]: event.target.checked,
      },
    }));
  };

  renderDiscardCheckbox = ({ property, label }: CheckboxProps) => {
    return (
      <SDSCheckbox
        disabled={this.state.replaceMediaOnUploadComplete}
        value={this.state.discardMetadata[property]}
        onChange={this.handleDiscardMetadataChange(property)}
        data-test={`StoryMediaReplacementModal.DiscardMetadata.${property}.Checkbox`}
        key={property}
      >
        {label}
      </SDSCheckbox>
    );
  };

  renderDiscardSection = () => {
    return (
      <>
        <FormattedMessage
          id="single-asset-upload-replace-media-warning"
          description="Warning message shown on the replace single asset media popup"
          defaultMessage="If you replace the video in your story the attachments may not appear in the correct position, do you wish to leave their original snap placement or remove them from your story?"
        />
        {discardCheckboxes.map(checkboxProps => this.renderDiscardCheckbox(checkboxProps))}
      </>
    );
  };

  getOkText = () => {
    return this.state.singleAssetUploadState === SingleAssetUploadState.UPLOADING ? (
      <FormattedMessage
        id="single-asset-upload-replace-media-when-upload-completes"
        description="Text for button which when clicked, replaces the story media after upload completes"
        defaultMessage="Replace video when upload completes"
      />
    ) : (
      <FormattedMessage
        id="single-asset-upload-replace-media"
        description="Text for button which when clicked, replaces the story media"
        defaultMessage="Replace video"
      />
    );
  };

  render() {
    const { singleAssetUploadState, replaceMediaOnUploadComplete, singleAssetVideoId, isUpdating } = this.state;
    const isUploading = singleAssetUploadState === SingleAssetUploadState.UPLOADING;
    return (
      <SDSDialog
        title={modalTitle}
        visible
        onCancel={this.hideModal}
        onOk={isUploading ? this.handleReplaceOnCompleteClick : this.handleReplaceMedia}
        okButtonProps={{
          disabled: isUploading ? replaceMediaOnUploadComplete : isUpdating || singleAssetVideoId === '',
        }}
        okText={this.getOkText()}
      >
        <div className={style.modalBodyContainer} data-test="StoryMediaReplacementModal.ModalTitle">
          <SingleAssetProgressUploadView
            uploadState={this.state.singleAssetUploadState}
            handleOnUploadStart={this.handleOnUploadStart}
            handleOnUploadComplete={this.handleOnUploadComplete}
            handleOnUploadFailed={this.handleOnUploadFailed}
            data-test="StoryMediaReplacementModal.SingleAssetProgressUploadView"
          />
          {!this.props.isSingleSnapBuilder && this.renderDiscardSection()}
        </div>
      </SDSDialog>
    );
  }
}
export default intlConnect(mapStateToProps, mapDispatchToProps)(StoryMediaReplacementModal);
