import React from 'react';
import { FormattedMessage } from 'react-intl';

import { setIsSponsored } from 'state/editions/actions/editionsActions';
import { getUserBitmojiAvatarId } from 'state/user/selectors/userSelectors';

import { BITMOJI_TEMPLATES, getBitmojiUrl, DEFAULT_BITMOJI_AVATAR } from 'config/bitmojiConfig';
import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';
import { logGAEvent, GAUserActions } from 'utils/gaUtils';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';

import Icon from 'views/common/components/Icon/Icon';
import ListItemWithToggle from 'views/common/components/ListItem/ListItemWithToggle';
import SDSButton from 'views/common/components/SDSButton/SDSButton';
import SDSModal from 'views/common/components/SDSModal/SDSModal';

import style from './SponsoredContentModal.scss';

import { ExtractDispatchProps } from 'types/redux';

const MODAL_TITLE = (
  <FormattedMessage
    id="sponsored-content-title"
    defaultMessage="Sponsored Content"
    description="Title of sponsored content modal"
  />
);
// Todo update subtitle default message
const MODAL_SUBTITLE = (
  <FormattedMessage
    id="sponsored-content-subtitle"
    defaultMessage="Please ensure that the content adheres to all guidelines, including compliance with all applicable laws, industry codes and regulations and incorporation of any required advertising disclosures."
    description="Subtitle for the sponsored content modal"
  />
);

const mapDispatchToProps = {
  setIsSponsored,
};
type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;

type OwnProps = {
  hideModal: (modalId: string) => void;
  modalId: string;
  options: {
    editionId: number;
    isSponsored: boolean | null;
  };
};

const mapStateToProps = (state: State) => {
  return {
    bitmojiAvatarId: getUserBitmojiAvatarId(state),
  };
};
type StateProps = ReturnType<typeof mapStateToProps>;

type Props = DispatchProps & StateProps & OwnProps;

type OwnState = {
  isSponsored: boolean;
  isSaving: boolean;
};

export class SponsoredContentModal extends React.Component<Props, OwnState> {
  state: OwnState = {
    isSponsored: this.props.options.isSponsored || false,
    isSaving: false,
  };

  closeModal = () => {
    this.props.hideModal(this.props.modalId);
  };

  onIsSponsoredChange = (value: boolean) => {
    this.setState({ isSponsored: value });
  };

  onOk = async () => {
    this.setState({ isSaving: true });
    await this.props.setIsSponsored(this.props.options.editionId, this.state.isSponsored);
    this.closeModal();
    this.setState({ isSaving: false });
    logGAEvent(GAUserActions.EDITION, 'toggle-sponsored', {
      from: Boolean(this.props.options.isSponsored),
      to: this.state.isSponsored,
    });
  };

  renderBitmoji = () => {
    const bitmojiAvatar = this.props.bitmojiAvatarId || DEFAULT_BITMOJI_AVATAR;
    return (
      <div className={style.userAvatar}>
        <Icon
          img={getBitmojiUrl(BITMOJI_TEMPLATES.USER_CHECK, bitmojiAvatar)}
          data-test="SponsoredContentModal.bitmojiIcon"
        />
      </div>
    );
  };

  renderIsSponsoredToggle = () => {
    const toggleProperties = {
      ENABLED: {
        text: (
          <FormattedMessage
            id="enabled-for-sponsorship"
            defaultMessage="This content is enabled for sponsorship"
            description="Text by the 'on' toggle in the sponsored content popup"
          />
        ),
        'data-test': 'SponsoredContentModal.EnabledSponsorshipToggle',
      },
      DISABLED: {
        text: (
          <FormattedMessage
            id="disabled-for-sponsorship"
            defaultMessage="This content is disabled for sponsorship"
            description="Text by the 'off' toggle in the sponsored content popup"
          />
        ),
        'data-test': 'SponsoredContentModal.DisabledSponsorshipToggle',
      },
    };

    return (
      <ListItemWithToggle
        {...(this.state.isSponsored ? toggleProperties.ENABLED : toggleProperties.DISABLED)}
        onChange={this.onIsSponsoredChange}
        value={this.state.isSponsored}
        className={style.toggle}
      />
    );
  };

  renderOkButton(): React.ReactElement {
    return (
      <div>
        <SDSButton data-test="SponsoredContentModal.okButton" onClick={this.onOk} disabled={this.state.isSaving}>
          <div>{getMessageFromId('ok-button-modal')}</div>
        </SDSButton>
      </div>
    );
  }

  render() {
    return (
      <SDSModal
        visible
        width={563}
        closable
        title={MODAL_TITLE}
        subtitle={MODAL_SUBTITLE}
        onCancel={this.closeModal}
        footer={this.renderOkButton()}
      >
        <div className={style.contentWrapper}>
          {this.renderBitmoji()}
          {this.renderIsSponsoredToggle()}
        </div>
      </SDSModal>
    );
  }
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(SponsoredContentModal);
