import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { hideModal as hideModalOptions } from 'state/modals/actions/modalsActions';

import SDSAlert from 'views/common/components/SDSAlert/SDSAlert';
import SDSCheckbox from 'views/common/components/SDSCheckbox/SDSCheckbox';
import SDSDialog from 'views/common/components/SDSDialog/SDSDialog';

import style from './OneTimeConfirmationModal.scss';

export type OneTimeConfirmationModalOptions = {
  title?: JSX.Element;
  body: React.ReactNode | React.ReactNode[];
  onConfirm: (dontShowAgain: boolean) => any;
  onCancel?: (dontShowAgain: boolean) => any;
  disableCancel?: boolean;
  confirmButtonMessage?: JSX.Element;
  cancelButtonMessage?: JSX.Element;
  visible: boolean;
  isBodyCentered?: boolean;
};

type Props = {
  modalId?: string;
  hideModal?: typeof hideModalOptions;
  'data-test'?: string;
  options: OneTimeConfirmationModalOptions;
};

export function OneTimeConfirmationModal(props: Props) {
  const [dontShowAgain, setDontShowAgain] = useState(false);
  const { modalId, hideModal, options } = props;

  const onHide = useCallback(
    (confirmed: boolean) => {
      if (hideModal && modalId) {
        hideModal(modalId, { confirmed });
      }
    },
    [modalId, hideModal]
  );

  const onConfirm = useCallback(() => {
    options.onConfirm(dontShowAgain);
    onHide(true);
  }, [dontShowAgain, onHide, options]);

  const onCancel = useCallback(() => {
    if (options.onCancel) {
      options.onCancel(dontShowAgain);
    }
    onHide(false);
  }, [dontShowAgain, onHide, options]);

  const onCheckboxChange = useCallback(() => {
    setDontShowAgain(!dontShowAgain);
  }, [dontShowAgain]);

  const renderCheckbox = (
    <div className={style.dontShowAgain}>
      <SDSCheckbox
        checked={dontShowAgain}
        onChange={onCheckboxChange}
        data-test="modals.oneTimeConfirmationModal.checkBox"
      >
        <FormattedMessage
          id="do-not-show-this-message-again"
          defaultMessage="Do not show this message again."
          description="Checkbox text to select if the modal message is to be shown again"
        />
      </SDSCheckbox>
    </div>
  );

  return props.options.disableCancel ? (
    <SDSAlert visible={props.options.visible} onConfirm={onConfirm} data-test={`${props['data-test']}.sdsAlert`}>
      {props.options.body}
      {renderCheckbox}
    </SDSAlert>
  ) : (
    <SDSDialog
      visible={props.options.visible}
      onOk={onConfirm}
      onCancel={onCancel}
      okText={props.options.confirmButtonMessage}
      cancelText={props.options.cancelButtonMessage}
      title={props.options.title}
      data-test={`${props['data-test']}.sdsDialog`}
      isBodyCentered={props.options.isBodyCentered}
    >
      {props.options.body}
      {renderCheckbox}
    </SDSDialog>
  );
}

export default OneTimeConfirmationModal;
