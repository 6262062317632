import invariant from 'invariant';
import { omit, get } from 'lodash';
import log from 'loglevel';
import React, { SyntheticEvent } from 'react';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'stri... Remove this comment to see the full error message
import striptags from 'striptags';

type Props = {
  text: string | undefined | null;
  lineLimit: number;
  maxLength?: number;
  disabled: boolean;
  onBlur?: (text: string) => void;
  onChange?: (text: string) => void;
  'data-test'?: string;
};

type State = {
  currentText: string;
};

export default class LineLimitedContentEditable extends React.PureComponent<Props, State> {
  state = {
    currentText: '',
  };

  componentDidMount() {
    const { text } = this.props;
    if (text) {
      this.setTextState(text);
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.text !== this.props.text) {
      this.setTextState(this.props.text);
    }
  }

  getStyleValue(element: Element, property: string): number {
    const computedStyle = window.getComputedStyle(element);
    // @ts-expect-error ts-migrate(7015) FIXME: Element implicitly has an 'any' type because index... Remove this comment to see the full error message
    const propertyValue = parseFloat(computedStyle[property]);

    if (Number.isNaN(Number(propertyValue))) {
      // @ts-expect-error ts-migrate(7015) FIXME: Element implicitly has an 'any' type because index... Remove this comment to see the full error message
      log.warn(`Property value: ${property} from computed style is NaN. The Value was:`, computedStyle[property]);
      return 0;
    }

    return propertyValue;
  }

  setTextState(text?: string | null) {
    this.setState(
      {
        currentText: striptags(text || '').trim(),
      },
      () => {
        if (this.props.onChange) {
          this.props.onChange(this.state.currentText);
        }
      }
    );
  }

  getElementHeight(element: HTMLElement) {
    const paddingTop = this.getStyleValue(element, 'paddingTop');
    const paddingBottom = this.getStyleValue(element, 'paddingBottom');
    return element.offsetHeight - paddingTop - paddingBottom;
  }

  handleOnPaste = (event: React.ClipboardEvent) => {
    const element = event.target;
    invariant(element instanceof HTMLElement, 'element is not an HTMLElement');
    const { lineLimit, maxLength } = this.props;

    const cursorRange = this.getCursorRange();

    // Getting current content and the selection range. This allows us to paste the text in the current cursor position,
    // deleting any text that is selected.
    const currentContent = element.textContent || '';
    const splitContent: [string, string] = [
      currentContent.substring(0, cursorRange[0]),
      currentContent.substring(cursorRange[1]),
    ];
    let contentToAdd = this.sanitizeText(event.clipboardData.getData('text/plain'));

    // Limiting the characters to paste based on passed limits.
    // Avoids doing too many iterations in the below loop (for limiting number of lines)
    const maxCharsToPaste = maxLength && Math.max(0, maxLength - (splitContent[0].length + splitContent[1].length));
    if (maxCharsToPaste !== undefined) {
      contentToAdd = contentToAdd.substring(0, maxCharsToPaste);
    }

    // We need to calculate if the line limit was exceeded. Since the component hasn't received the text yet we need
    // to iterate setting the text, calculating line height and if needed removing one character at a time
    // until the line limit is respected.
    let exceededLineLimit: boolean;
    do {
      element.textContent = splitContent[0] + contentToAdd + splitContent[1];
      this.setCursorPosition(element, cursorRange[0] + contentToAdd.length);

      const numLines = this.getNumLines(element);
      exceededLineLimit = numLines > lineLimit;

      // If line limit is exceeded, remove the last character from the pasted text
      if (exceededLineLimit) {
        contentToAdd = contentToAdd.slice(0, -1);
      }
    } while (contentToAdd.length > 0 && exceededLineLimit);

    this.setTextState(element.textContent || '');

    event.stopPropagation();
    event.preventDefault();
  };

  handleOnInput = (event: SyntheticEvent) => {
    const element = event.target;
    invariant(element instanceof HTMLElement, 'element is not an HTMLElement');
    const { lineLimit, maxLength } = this.props;
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
    const text = this.sanitizeText(element.textContent);

    const charCount = text.length;
    const numLines = this.getNumLines(element);
    // Allow delete when the input already has more lines than maximum
    const inputType = get(event, 'nativeEvent.inputType');
    const isDelete = inputType === 'deleteContentBackward' || inputType === 'deleteContentForward';

    const exceededLineLimit = numLines > lineLimit;
    const exceededCharCount = maxLength && charCount > maxLength;
    if (!isDelete && (exceededLineLimit || exceededCharCount)) {
      const cursorRange = this.getCursorRange();

      // Update text content
      element.textContent = this.state.currentText;

      this.setCursorPosition(element, cursorRange[0] - 1);
    } else {
      this.setTextState(text);
    }
  };

  getNumLines = (element: HTMLElement) => {
    const lineHeight = this.getStyleValue(element, 'lineHeight');
    const elementHeight = this.getElementHeight(element);
    return Math.round(elementHeight / lineHeight);
  };

  getCursorRange = (): [number, number] => {
    // Get current cursor position
    const selection = window.getSelection();
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    return [selection.getRangeAt(0).startOffset, selection.getRangeAt(0).endOffset];
  };

  setCursorPosition = (element: HTMLElement, cursorPosition: number) => {
    const selection = window.getSelection();

    // Move cursor position
    const newCursorPosition = Math.min(Math.max(cursorPosition, 0), (element.textContent || '').length);

    if (!newCursorPosition) {
      return;
    }

    // if the new cursor is already at the start of the line don't do anything else
    if (!element.childNodes[0]) {
      return;
    }

    const range = document.createRange();
    range.setStart(element.childNodes[0], newCursorPosition);
    range.collapse(true);
    selection?.removeAllRanges();
    selection?.addRange(range);
  };

  handleOnBlur = () => {
    if (this.props.onBlur) {
      this.props.onBlur(this.state.currentText);
    }
  };

  sanitizeText(text: string) {
    return text
      .replace(/^\s+|\s+$/g, '') // trim
      .replace(/\s/g, ' '); // replaces &nbsp; with normal spaces (so that word-breaks are respected)
  }

  render() {
    const { text, disabled } = this.props;

    const elementProps = omit(this.props, [
      'onInput',
      'onBlur',
      'text',
      'disabled',
      'lineLimit',
      'dangerouslySetInnerHTML',
    ]);

    /* eslint-disable react/no-danger */
    return (
      <div
        dir="auto"
        contentEditable={!disabled}
        onInput={this.handleOnInput}
        onPaste={this.handleOnPaste}
        // @ts-expect-error ts-migrate(2322) FIXME: Type '((text: string) => void) | (() => void)' is ... Remove this comment to see the full error message
        onBlur={this.handleOnBlur}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null | undefined' is not assignable... Remove this comment to see the full error message
        dangerouslySetInnerHTML={{ __html: text }}
        {...elementProps}
        data-test={this.props['data-test'] ? `${this.props['data-test']}` : 'lineLimitedContentEditable'}
      />
    );
    /* eslint-enabled react/no-danger */
  }
}
