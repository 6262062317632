import classNames from 'classnames';
import React from 'react';

import * as intlMessages from 'utils/intlMessages/intlMessages';

import style from './CompositeSnapOverlayIndexBadge.scss';

type Props = {
  className: string | undefined | null;
  displaySnapIndex: number;
};

export class CompositeSnapOverlayIndexBadge extends React.PureComponent<Props> {
  render() {
    return (
      <div className={classNames(this.props.className, style.container)} data-test="compositeSnapOverlayIndexBadge">
        {intlMessages.getMessageFromId('snap-with-index', { index: this.props.displaySnapIndex })}
      </div>
    );
  }
}
