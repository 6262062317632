import _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import * as componentsSelectors from 'state/editor/selectors/componentsSelectors';
import * as editorSelectors from 'state/editor/selectors/editorSelectors';
import * as mediaSelectors from 'state/media/selectors/mediaSelectors';
import * as modalsActions from 'state/modals/actions/modalsActions';
import { getActivePublisherDetails } from 'state/publishers/selectors/publishersSelectors';
import * as videoLibraryActions from 'state/videoLibrary/actions/videoLibraryActions';
import * as videoLibrarySelectors from 'state/videoLibrary/selectors/videoLibrarySelectors';

import { DropzoneType, UploadPurpose } from 'config/constants';
import { extractSnapIdFromComponentId } from 'utils/componentUtils';
import { intlConnect } from 'utils/connectUtils';
import { GrafanaMetrics } from 'utils/grafanaUtils';
import { incrementCounterByPublisher } from 'utils/grapheneUtils';

import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';
import SDSCustomModal from 'views/common/components/SDSCustomModal/SDSCustomModal';
import MediaUploader from 'views/editor/containers/MediaUploader/MediaUploader';
import VideoLibraryModal from 'views/modals/containers/VideoLibraryModal/VideoLibraryModal';

import style from './UploadVideoModal.scss';

import { AssetID } from 'types/assets';
import { ModalId } from 'types/modal';
import { ExtractDispatchProps } from 'types/redux';
import { State } from 'types/rootState';

export const mapStateToProps = (state: State, ownProps: any) => {
  const activeEditionId = editorSelectors.getActiveEditionId(state);
  const activeComponentId = componentsSelectors.getActiveComponentId(state);
  const publisher = getActivePublisherDetails(state);
  const activeSnapId = activeComponentId ? extractSnapIdFromComponentId(activeComponentId) : null;
  const pendingVideoAssetId = _.get(ownProps, 'modalConfig.pendingVideoAssetId', null);
  const activeVideoUploadCount = mediaSelectors.getActiveUploadCountsForComponentIdByPurpose(state)(
    activeComponentId,
    UploadPurpose.LONGFORM_VIDEO
  );
  const preview = videoLibrarySelectors.getVideoResultById(state)(pendingVideoAssetId);
  const isReadOnly = activeVideoUploadCount > 0;
  return {
    pendingVideoAssetId,
    activeEditionId,
    activeSnapId,
    isReadOnly,
    publisher,
    preview,
  };
};
const mapDispatchToProps = {
  showModal: modalsActions.showModal,
  getVideoMetadata: videoLibraryActions.getVideoMetadata,
  getPreviewState: videoLibrarySelectors.getVideoPreviewState,
};

const VIDEO_METADATA_LOADING_DELAY = 5000;

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;
type OwnProps = {
  modalId: ModalId;
  setModalConfigProperties: (
    modalId: ModalId,
    options: {
      pendingVideoAssetId: AssetID;
    }
  ) => void;
  hideModal: (modalId: ModalId, options?: { payload: any }) => void;
  previewState?: string;
  modalConfig: {
    videoPayload?: any;
  };
  videoSrc?: string;
  options: {
    isDisplayOnly: boolean;
  };
};

type Props = OwnProps & StateProps & DispatchProps;

type OwnState = {
  isProcessingMedia?: boolean;
};

export class UploadVideoModal extends React.Component<Props, OwnState> {
  onUploadComplete = (props: any) => {
    const uploadedAssetId = props.assetId;
    if (uploadedAssetId && !this.props.pendingVideoAssetId) {
      this.props.setModalConfigProperties(this.props.modalId, { pendingVideoAssetId: uploadedAssetId });
    }
  };

  state: OwnState = {
    isProcessingMedia: false,
  };

  onUploadStart = () => {
    this.setState({ isProcessingMedia: true });
  };

  insertVideo = () => {
    const { modalId, hideModal, modalConfig, pendingVideoAssetId, publisher } = this.props;

    // Log usage of the add media to story to grafana
    incrementCounterByPublisher(publisher, GrafanaMetrics.MEDIA_V1, {
      type: 'insert_video',
    });

    const payload = _.get(modalConfig, 'videoPayload', null);
    if (pendingVideoAssetId && payload) {
      hideModal(modalId, { payload });
    }
  };

  videoMetadataTimeout: NodeJS.Timeout | null = null;

  getVideoMetadataTimeout = (assetId: any) => {
    if (!this.props.videoSrc) {
      this.props.getVideoMetadata({ assetId });
      this.videoMetadataTimeout = setTimeout(() => this.getVideoMetadataTimeout(assetId), VIDEO_METADATA_LOADING_DELAY);
    }
  };

  renderVideoUploader() {
    const { isReadOnly, activeEditionId, activeSnapId } = this.props;
    if (this.state.isProcessingMedia) {
      return null;
    }
    return (
      <MediaUploader
        isReadOnly={isReadOnly}
        editionId={activeEditionId}
        snapId={activeSnapId}
        purpose={UploadPurpose.LONGFORM_VIDEO}
        dropzoneType={DropzoneType.MODAL_VIDEO}
        onUploadComplete={this.onUploadComplete}
        onUploadStart={this.onUploadStart}
        isVideoLibraryModal
      />
    );
  }

  renderMediaTable() {
    return (
      <VideoLibraryModal
        pendingVideoAssetId={this.props.pendingVideoAssetId}
        modalId={this.props.modalId}
        setModalConfigProperties={this.props.setModalConfigProperties}
        showModal={(this.props as any).showModal}
      />
    );
  }

  renderMediaInsertButton() {
    const { pendingVideoAssetId, options } = this.props;
    const showInsertButton = !options.isDisplayOnly;
    return showInsertButton ? (
      <div className={style.buttonContainer}>
        <SDSButton
          type={ButtonType.PRIMARY}
          disabled={!pendingVideoAssetId}
          onClick={this.insertVideo}
          data-test="modals.uploadVideo.insert.button"
        >
          <FormattedMessage
            id="insert-selected-video"
            description="insert the selected video into the article attachment"
            defaultMessage="Insert"
          />
        </SDSButton>
      </div>
    ) : null;
  }

  onClose = () => {
    const { modalId, hideModal } = this.props;
    hideModal(modalId);
  };

  render() {
    return (
      <SDSCustomModal
        visible
        onClose={this.onClose}
        title={
          <FormattedMessage
            id="upload-video-modal-title"
            description="Upload video Title from modal"
            defaultMessage="Video Library"
          />
        }
        footer={this.renderMediaInsertButton()}
        width={800}
      >
        <div className={style.rootContainer}>
          {this.renderVideoUploader()}
          {this.renderMediaTable()}
        </div>
      </SDSCustomModal>
    );
  }
}
export default intlConnect(mapStateToProps, mapDispatchToProps)(UploadVideoModal);
