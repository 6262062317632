import Popover from 'antd/lib/popover';
import React from 'react';
import type { ReactNode } from 'react';

type OwnProps = {
  children: ReactNode;
  content: ReactNode;
  title?: string | ReactNode;
  placement?: string;
  trigger?: string;
  overlayClassName?: string;
  mouseEnterDelay?: number;
};

type Props = OwnProps & typeof SDSPopover.defaultProps;

export class SDSPopover extends React.PureComponent<Props> {
  static defaultProps = {
    placement: 'bottom',
    trigger: 'click',
  };

  render() {
    return (
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      <Popover {...this.props}>{this.props.children}</Popover>
    );
  }
}

export default SDSPopover;
