export const CATEGORIES: Array<{ label: string; value: string }> = [
  { value: 'CATEGORY_NEWS', label: 'News' },
  { value: 'CATEGORY_POLITICS', label: 'Politics' },
  { value: 'CATEGORY_WORLD', label: 'World' },
  { value: 'CATEGORY_TECH', label: 'Tech' },
  { value: 'CATEGORY_SCIENCE', label: 'Science' },
  { value: 'CATEGORY_BUSINESS', label: 'Business' },
  { value: 'CATEGORY_LOCAL', label: 'Local' },
  { value: 'CATEGORY_CORONAVIRUS', label: 'Coronavirus' },
  { value: 'CATEGORY_ELECTION', label: 'Election' },
  { value: 'CATEGORY_ENTERTAINMENT', label: 'Entertainment' },
  { value: 'CATEGORY_CULTURE', label: 'Culture' },
  { value: 'CATEGORY_TRENDING', label: 'Trending' },
  { value: 'CATEGORY_GAMING', label: 'Gaming' },
  { value: 'CATEGORY_POP_CULTURE', label: 'Pop Culture' },
  { value: 'CATEGORY_MUSIC', label: 'Music' },
  { value: 'CATEGORY_TV_MOVIES', label: 'TV & Film' },
  { value: 'CATEGORY_MEMES', label: 'Memes' },
  { value: 'CATEGORY_LOLZ_FUN', label: 'Fun' },
  { value: 'CATEGORY_ANIMALS', label: 'Animals' },
  { value: 'CATEGORY_HOROSCOPES', label: 'My Horoscope' },
  { value: 'CATEGORY_SPORTS', label: 'Sports' },
  { value: 'CATEGORY_NFL', label: 'NFL' },
  { value: 'CATEGORY_NBA', label: 'NBA' },
  { value: 'CATEGORY_UFC', label: 'UFC' },
  { value: 'CATEGORY_WWE', label: 'WWE' },
  { value: 'CATEGORY_LIFESTYLE', label: 'Lifestyle' },
  { value: 'CATEGORY_HEALTH', label: 'Health' },
  { value: 'CATEGORY_FITNESS', label: 'Fitness' },
  { value: 'CATEGORY_STYLE', label: 'Style' },
];
