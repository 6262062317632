import _ from 'lodash';
import React from 'react';

import * as featuresSelectors from 'state/features/selectors/featuresSelectors';
import * as mediaLibraryActions from 'state/mediaLibrary/actions/mediaLibraryActions';
import * as mediaLibraryTrayActions from 'state/mediaLibrary/actions/mediaLibraryTrayActions';
import * as mediaLibraryTraySelectors from 'state/mediaLibrary/selectors/mediaLibraryTraySelectors';

import { intlConnect } from 'utils/connectUtils';

import SnapTray from 'views/common/containers/SnapTray/SnapTray';
import MediaView from 'views/mediaLibrary/MediaView/MediaView';
import { isVideo, getDownloadFilename, getDownloadUrl } from 'views/mediaLibrary/utils/MediaLibraryUtils';

import type { SnapId } from 'types/common';
import type { EditionID } from 'types/editionID';
import type { MediaItem } from 'types/mediaLibrary';
import type { PublisherID } from 'types/publishers';
import type { State } from 'types/rootState';

type OwnProps = {
  activeEditionId?: EditionID;
  activeSnapId?: SnapId;
  activePublisherId?: PublisherID;
  activeAttachmentId?: SnapId;
  showMetadataModal: (a: MediaItem) => void;
};

type StateProps = {
  selectedMedia: MediaItem[];
  isStorySearchSnapCurationEnabled: boolean;
};

type DispatchProps = {
  clearTray: typeof mediaLibraryTrayActions.clearTray;
  toggleMedia: typeof mediaLibraryTrayActions.toggleMedia;
  addMediaToStory: typeof mediaLibraryActions.addMediaToStory;
};

type Props = OwnProps & StateProps & DispatchProps;

const mapStateToProps = (state: State): StateProps => {
  return {
    selectedMedia: mediaLibraryTraySelectors.getSelectedMediaList(state),
    isStorySearchSnapCurationEnabled: featuresSelectors.isStorySearchSnapCurationEnabled(state),
  };
};

const mapDispatchToProps = {
  clearTray: mediaLibraryTrayActions.clearTray,
  toggleMedia: mediaLibraryTrayActions.toggleMedia,
  addMediaToStory: mediaLibraryActions.addMediaToStory,
};

export class MediaLibraryTray extends React.Component<Props> {
  getDownloadUrls = () => {
    return _.map(this.props.selectedMedia, item => {
      const filename = getDownloadFilename(item);
      return {
        href: getDownloadUrl(item),
        filename,
      };
    });
  };

  handleShowMetadata = (item: any) => () => {
    this.props.showMetadataModal(item);
  };

  createItemView = ({ key, item, style }: { key: string; item: any; style: any }) => {
    // TODO: replace with a separate field for video preview
    const videoPreviewSrc = isVideo(item.mediaType) ? item.mediaUrl : null;
    return (
      <MediaView
        key={key}
        onToggle={this.props.toggleMedia}
        mediaItem={item}
        videoPreviewSrc={videoPreviewSrc}
        style={style}
        isSelected
        showMetadataModal={this.handleShowMetadata(item)}
      />
    );
  };

  render() {
    return (
      <SnapTray
        selectedSnaps={this.props.selectedMedia}
        addSnapsToStory={this.props.addMediaToStory}
        clearTray={this.props.clearTray}
        createItemView={this.createItemView}
        targetSnapId={this.props.activeSnapId}
        targetEditionId={this.props.activeEditionId}
        targetAttachmentId={this.props.activeAttachmentId}
        publisherId={this.props.activePublisherId}
        allowDownloading={!this.props.isStorySearchSnapCurationEnabled}
        getDownloadUrls={this.getDownloadUrls}
      />
    );
  }
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(MediaLibraryTray);
