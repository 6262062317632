import React from 'react';
import type { ReactNode } from 'react';
import { AutoSizer, Grid } from 'react-virtualized';

import { CURATION_CELL_WIDTH, CURATION_CELL_HEIGHT } from 'views/common/utils/Grid';

import trayStyle from './SnapTraySnapList.scss';

import type { CuratedSnap } from 'types/curation';
import type { MediaItem } from 'types/mediaLibrary';
import type { CellRendererParams } from 'types/virtualized';

type ListItemArray = CuratedSnap[] | MediaItem[];

type OwnProps = {
  selectedItems: ListItemArray;
  createItemView: (a: any) => ReactNode;
};

export class SnapTraySnapList extends React.Component<OwnProps> {
  numCols = 0;

  cellRenderer = ({ rowIndex, columnIndex, key, style, ...rest }: CellRendererParams) => {
    const selectedItem = this.props.selectedItems[columnIndex];
    if (!selectedItem) {
      return null;
    }
    return this.props.createItemView({
      key,
      item: selectedItem,
      style,
    });
  };

  renderGrid = (selectedItems: ListItemArray) => {
    return ({ height, width }: { height: number; width: number }) => {
      return (
        <Grid
          width={width}
          height={height}
          columnCount={this.props.selectedItems.length}
          columnWidth={CURATION_CELL_WIDTH}
          rowCount={1}
          rowHeight={CURATION_CELL_HEIGHT}
          // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
          cellRenderer={this.cellRenderer}
          selectedItems={selectedItems}
        />
      );
    };
  };

  render() {
    return (
      <div className={trayStyle.container}>
        <AutoSizer>{this.renderGrid(this.props.selectedItems)}</AutoSizer>
      </div>
    );
  }
}

export default SnapTraySnapList;
