import classNames from 'classnames';
import React from 'react';

import { ImageSnapPreview } from './ImageSnapPreview/ImageSnapPreview';
import style from './SnapPreview.scss';
import VideoSnapPreview from './VideoSnapPreview/VideoSnapPreview';

import type { TrimMetadata } from 'types/curation';

type OwnProps = {
  snapMediaSrc: string;
  snapOverlaySrc?: string;
  videoPosterSrc: string | undefined | null;
  isVideo: boolean;
  shouldPlayVideo?: boolean;
  muted: boolean;
  onMouseEnter?: () => any;
  onMouseLeave?: () => any;
  trimMetadata?: TrimMetadata;
};

type StateProps = {
  className?: string;
};

type Props = OwnProps & StateProps;

/**
 * A simple shared component for showing preview of a snap image or video
 */
export class SnapPreview extends React.Component<Props> {
  renderSnapPreview(src: string, videoPosterSrc?: string | null) {
    if (this.props.isVideo) {
      return (
        <VideoSnapPreview
          src={src}
          overlaySrc={this.props.snapOverlaySrc}
          posterSrc={videoPosterSrc}
          shouldPlay={this.props.shouldPlayVideo}
          muted={this.props.muted}
          trimMetadata={this.props.trimMetadata}
        />
      );
    }
    return <ImageSnapPreview src={videoPosterSrc || src} />; // if there is a video poster use it otherwise use the src provided
  }

  render() {
    return (
      <div
        className={classNames(this.props.className, style.previewContainer)}
        onMouseEnter={this.props.onMouseEnter}
        onMouseLeave={this.props.onMouseLeave}
        data-test="snapPreview.ImageVideo"
      >
        {this.renderSnapPreview(this.props.snapMediaSrc, this.props.videoPosterSrc)}
      </div>
    );
  }
}

export default SnapPreview;
