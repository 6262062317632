import React, { PureComponent } from 'react';

import style from './ToggleButton.scss';

const toggleIcons = {
  true: <div className="icon icon-arrow-up" />,
  false: <div className="icon icon-arrow-down" />,
};

type OwnProps = {
  onClick: (...args: any[]) => any;
  isArrowUp?: boolean;
  toggleIcons?: {
    true: React.ReactElement;
    false: React.ReactElement;
  };
};

type Props = OwnProps & typeof ToggleButton.defaultProps;

class ToggleButton extends PureComponent<Props> {
  static defaultProps = {
    isArrowUp: false,
  };

  onClick = () => {
    this.props.onClick(this.props.isArrowUp);
  };

  render() {
    return (
      <div className={style.root} onClick={this.onClick} data-test="toggleButton">
        {/* @ts-expect-error ts-migrate(2538) FIXME: Type 'boolean' cannot be used as an index type. */}
        {this.props.toggleIcons ? this.props.toggleIcons[this.props.isArrowUp] : toggleIcons[this.props.isArrowUp]}
      </div>
    );
  }
}

export default ToggleButton;
