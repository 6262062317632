// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
import { Alert } from '@snapchat/snapnet'; // discover-cms/no-snapnet
import log from 'loglevel';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ErrorType } from 'config/constants';
import { errorStrings } from 'utils/router/authErrorUtils';

import style from './LoginErrorView.scss';

const thirdPartyUrl = <b>chrome://settings/content/cookies?search=cookie</b>;

export const accountDoesNotExist = (
  <FormattedMessage
    id="invalid-account-email-support"
    description="Message shown when user logs in with account that does not exist in CMS"
    defaultMessage="No matching user account, please email publish-support@snapchat.com to raise a support ticket."
  />
);

export const unknownError = (
  <FormattedMessage
    id="unknown-error-occurred"
    description="When an error unrecognised by the system is thrown"
    defaultMessage="Unknown error occurred"
  />
);

export const popupClosedByUser = (
  <FormattedMessage
    id="sign-in-popup-closed"
    description="Message when the user closes the sign in popup without selecting an account"
    defaultMessage="Sign in popup closed by user"
  />
);

export const thirdPartyCookies = (
  <FormattedMessage
    id="enable-cookies"
    description="If the user has disabled 3rd party cookies, we cannot authenticate him/her with google"
    defaultMessage={
      "Check 'Block 3rd party cookies' is disabled here: {url}" +
      ', then reload this page and try again. If you still experience login issues, contact us'
    }
    values={{ url: thirdPartyUrl }}
  />
);

export const useSnapAuth = (
  <FormattedMessage
    id="use-snap-auth"
    description="User has already gone through the auth migration process and should use SnapAuth now"
    defaultMessage="The account has been migrated to use Snapchat Authentication. Please log in here."
  />
);

export const employeeUseGoogle = (
  <>
    <FormattedMessage
      id="employee-use-google-auth"
      description="Snap employees should use Google auth rather than snap Auth"
      defaultMessage="Snap employees should use"
    />
    &nbsp;<a href="/v2/login/sc">Google Auth</a>
  </>
);

export const zendeskAgentErrorMsg = (
  <FormattedMessage
    id="zendesk-agent-error-msg"
    description="Zendesk agents have to use a different URL to login"
    defaultMessage={'Click {here} to access Zendesk as an agent'}
    values={{
      here: (
        <a href="https://publish-snapchat.zendesk.com/agent" target="_blank">
          here
        </a>
      ),
    }}
  />
);

export const expiredSession = (
  <FormattedMessage
    id="employee-expired-session"
    description="Message that appears when user gets logged out"
    defaultMessage="Your session has expired. Please login again"
  />
);

type Props = {
  error: string | undefined | null;
};

export const LoginErrorView = (props: Props) => {
  const mapErrorToString = (error: string) => {
    switch (error) {
      case errorStrings.INVALID_ACCOUNT:
        return accountDoesNotExist;
      case errorStrings.UNKNOWN:
        return unknownError;
      case errorStrings.POPUP_CLOSED_BY_USER:
        return popupClosedByUser;
      case errorStrings.ENABLE_THIRD_PARTY_COOKIES:
        return thirdPartyCookies;
      case errorStrings.USE_SNAP_AUTH:
        return useSnapAuth;
      case errorStrings.EMPLOYEE_USE_GOOGLE:
        return employeeUseGoogle;
      case errorStrings.EXPIRED_SESSION:
        return expiredSession;
      case 'Please use one of the options below to sign in to Zendesk.':
        return zendeskAgentErrorMsg;
      default:
        log.warn('Unrecognised error =', error);
        return unknownError;
    }
  };

  if (!props.error || props.error === ErrorType.USER_NEEDS_LOGIN) {
    return null;
  }

  return (
    <Alert bsStyle="danger" className={style.alert}>
      {mapErrorToString(props.error)}
    </Alert>
  );
};

export default LoginErrorView;
