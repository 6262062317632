import React from 'react';
import { FormattedMessage, intlShape } from 'react-intl';
import InlineSVG from 'svg-inline-react';

import { hidden } from 'icons/SDS/allIcons';
import { ROLES_FOR_USER_CREATION_TABLE, UserCreationTableRoleName } from 'utils/userManagementUtils';

import { SDSCheckbox } from 'views/common/components/SDSCheckbox/SDSCheckbox';
import SDSTable from 'views/common/components/SDSTable/SDSTable';

import style from './UserRolesTable.scss';

import { Roles } from 'types/permissions';

type Props = {
  renderRoleNameHeader: () => any;
  hasRoleChecked: (...args: any[]) => any;
  roles: any;
  rolesList: UserCreationTableRoleName[];
  updateUserRoles: (...args: any[]) => any;
  isVisibleOnlyForSuperAdmin: boolean;
};
export class UserRolesTable extends React.Component<Props> {
  static contextTypes = {
    intl: intlShape,
  };

  getDataSource = () => {
    const { roles, hasRoleChecked } = this.props;
    return this.props.rolesList.map(roleName => {
      return {
        key: roleName,
        role: (
          <div className={style.role}>
            <span className={style.rolesCheckbox}>
              <SDSCheckbox
                data-test={`userRolesTable.${roleName}.checkbox`}
                checked={hasRoleChecked(roles[roleName])}
                /* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */
                inline
                disabled={
                  roles[roleName].roleInheritance ||
                  // Removing org admin would result in user losing access to all publishers in this org.
                  // Org level roles should be managed in business manager instead.
                  roleName === Roles.ORG_ADMIN
                }
                onChange={this.props.updateUserRoles(roleName)}
              />
            </span>
            <FormattedMessage {...ROLES_FOR_USER_CREATION_TABLE[roleName].role} />
          </div>
        ),
        description: (
          <div className={style.description}>
            <FormattedMessage {...ROLES_FOR_USER_CREATION_TABLE[roleName].description} />
          </div>
        ),
      };
    });
  };

  getColumnHeaders = () => {
    return [
      {
        title: this.renderRoleNameHeader(),
        dataIndex: 'role',
        key: 'role',
        width: 40,
      },
      {
        title: this.renderRoleDescriptionHeader(),
        dataIndex: 'description',
        key: 'description',
        width: 70,
      },
    ];
  };

  renderRoleDescriptionHeader = () => (
    <FormattedMessage
      id="description-table-in-edit-user-modal"
      defaultMessage="Description"
      description="Table header for the description column in the edit user modal"
    />
  );

  renderRoleNameHeader = () => {
    return (
      <>
        <span>{this.props.renderRoleNameHeader()}</span>
        <span>
          {this.props.isVisibleOnlyForSuperAdmin && (
            <InlineSVG
              src={hidden}
              className={style.hiddenIcon}
              title={
                <FormattedMessage
                  id="regular-users-cannot-see-this-only"
                  description="Tooltip for admin content only"
                  defaultMessage="Regular users cannot see this"
                />
              }
            />
          )}
        </span>
      </>
    );
  };

  render() {
    return (
      <div className={style.rolesTable}>
        <SDSTable
          dataSource={this.getDataSource()}
          columns={this.getColumnHeaders()}
          pagination={false}
          data-test="userRolesTable.table"
        />
      </div>
    );
  }
}

export default UserRolesTable;
