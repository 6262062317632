import classNames from 'classnames';
import invariant from 'invariant';
import { find, get } from 'lodash';
import * as React from 'react';
import { FormattedMessage, intlShape } from 'react-intl';
import reactCSS from 'reactcss';

import * as editorActions from 'state/editor/actions/editorActions';
import * as componentsSelectors from 'state/editor/selectors/componentsSelectors';
import * as editorSelectors from 'state/editor/selectors/editorSelectors';
import * as publishersSelectors from 'state/publishers/selectors/publishersSelectors';
import * as snapsSelectors from 'state/snaps/selectors/snapsSelectors';
import * as tilesActions from 'state/tiles/actions/tilesActions';
import { createTileContainer, getTileIdOrNull } from 'state/tiles/schema/tilesEntityHelpers';
import { hasClaimForActivePublisher } from 'state/user/selectors/userSelectors';

import { CDN_BASE_URLS, CrossOrigin, DropzoneType, UploadPurpose } from 'config/constants';
import { RichSnapComponentTile } from 'src/types/components';
import { intlConnect } from 'utils/connectUtils';
import { incrementCounterByPublisher } from 'utils/grapheneUtils';
import * as intlMessages from 'utils/intlMessages/intlMessages';
import { LogoPosition } from 'utils/logoConfig';
import * as assetUtils from 'utils/media/assetUtils';
import * as colorUtils from 'utils/media/colorUtils';
import * as tileUtils from 'utils/tileUtils';

import CameoTilePanelPreview from 'views/common/components/CameoTilePanelPreview/CameoTilePanelPreview';
import LineLimitedContentEditable from 'views/common/components/LineLimitedContentEditable/LineLimitedContentEditable';
import SpinnerIcon from 'views/common/components/SpinnerIcon/SpinnerIcon';
import ImageMediaUploader from 'views/editor/containers/ImageMediaUploader/ImageMediaUploader';
import { updateIfPropsAndStateChanged } from 'views/propTypes/utils';

import style from './CheetahTilePreview.scss';

import type { AssetID } from 'types/assets';
import { SnapId } from 'types/common';
import { Claim } from 'types/permissions';
import { ExtractDispatchProps } from 'types/redux';
import type { State as RootState } from 'types/rootState';
import { SnapType } from 'types/snaps';
import type { Tile, TileLocalization } from 'types/tiles';

const LINE_LIMIT = 3;
const CHAR_LIMIT = 200;
type OwnProps = {
  tile: Tile;
  isEditable: boolean;
  playOnHover: boolean;
  forceImagePreview: boolean;
  omitHeadline?: boolean;
  useSmallHeadline?: boolean;
  dummyHeadline?: string;
  dummyLogo?: string;
  newTileDefaultProperties?: object;
  tileImageUrl?: string;
};

type State = {
  headline: string;
  importingTopsnap: boolean;
  uploading: boolean;
  snapId: SnapId | null | undefined;
};

const mapStateToProps = (state: RootState, props: OwnProps) => {
  const activeComponent = componentsSelectors.getActiveComponent(state) as RichSnapComponentTile;
  const editionId = editorSelectors.getActiveEditionId(state);
  const snapId = editorSelectors.getActiveWholeSnapId(state);
  const segmentId = editorSelectors.getActiveSegmentReduxId(state);
  let isTileLockedForEdit = false;
  if (snapId && editionId) {
    isTileLockedForEdit = editorSelectors.isTileLockedForEdit(state)({ snapId, editionId });
  }
  const snap = snapId ? snapsSelectors.getSnapById(state)(snapId) : null;
  const topSnap = snap;
  const tileId = getTileIdOrNull(props.tile);
  return {
    publisherId: publishersSelectors.getActivePublisherId(state),
    publisher: publishersSelectors.getActivePublisherDetails(state),
    activeComponent,
    editionId,
    snapId,
    segmentId,
    snap: topSnap,
    isTileLockedForEdit,
    tileEditorState: editorSelectors.getTileEditorState(state)(tileId),
    selectedLanguage: tileId ? editorSelectors.getSelectedTileLanguage(state)(tileId) : null,
    isTileAutogenerator:
      hasClaimForActivePublisher(state, Claim.TILE_AUTOGENERATOR) &&
      !publishersSelectors.activePublisherIsCuration(state),
  };
};
const mapDispatchToProps = {
  handleTileUploaded: tilesActions.handleTileUploaded,
  setTileCroppingAndSave: editorActions.setTileCroppingAndSave,
  createTileFromExistingSource: tilesActions.createTileFromExistingSource,
};
type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;
type StateProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & typeof CheetahTilePreview.defaultProps & DispatchProps & StateProps;

export class CheetahTilePreview extends React.Component<Props, State> {
  static contextTypes = {
    intl: intlShape,
  };

  static defaultProps = {
    isEditable: false,
    playOnHover: false,
    forceImagePreview: false,
    useSmallHeadline: false,
  };

  state: State = {
    headline: '',
    importingTopsnap: false,
    uploading: false,
    snapId: null,
  };

  _isUnmounted: boolean = false;

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    return updateIfPropsAndStateChanged(this.props, this.state, nextProps, nextState);
  }

  componentWillUnmount() {
    this._isUnmounted = true;
  }

  safeSetState = (state: Partial<State>) => {
    if (!this._isUnmounted) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Partial<State>' is not assignabl... Remove this comment to see the full error message
      this.setState(state);
    }
  };

  handleOnUploadStart = () => {
    this.setState({ uploading: true, snapId: this.props.snapId });
  };

  handleOnUploadComplete = ({ assetId, file }: { assetId: AssetID; file: File }) => {
    const { handleTileUploaded, tile, editionId, segmentId, newTileDefaultProperties } = this.props;
    // If a user begins uploading a tile then switches to another tile, we don't want to call setState. This can only be
    // called from a component that's mounted
    this.safeSetState({ uploading: false });
    handleTileUploaded({
      tile,
      asset: { assetId, file },
      editionId,
      snapId: this.state.snapId,
      segmentId,
      newTileDefaultProperties,
    });
  };

  handleOnBlur = (newHeadline: string) => {
    const {
      setTileCroppingAndSave,
      snapId,
      editionId,
      segmentId,
      publisherId,
      activeComponent,
      selectedLanguage,
      publisher,
    } = this.props;
    if (this.tileHeadline() !== newHeadline) {
      if (selectedLanguage) {
        incrementCounterByPublisher(publisher, 'Translations.headline-modified', { language: selectedLanguage });
      }
      const newTile: Partial<Tile> = { ...activeComponent?.tile, ...this.getHeadlineUpdateProperties(newHeadline) };
      setTileCroppingAndSave(createTileContainer(editionId, snapId, segmentId), newTile, publisherId);
    }
  };

  importTopsnap = async () => {
    const {
      snap,
      tile,
      createTileFromExistingSource,
      editionId,
      snapId,
      segmentId,
      newTileDefaultProperties,
    } = this.props;
    if (snap && !this.state.importingTopsnap) {
      this.setState({ importingTopsnap: true });
      await createTileFromExistingSource({
        tile,
        editionId,
        snap,
        snapId,
        segmentId,
        newTileDefaultProperties,
      });
      this.safeSetState({ importingTopsnap: false });
    }
  };

  tileHeadline = (): string => {
    const { selectedLanguage, tile, dummyHeadline } = this.props;
    if (dummyHeadline) {
      return dummyHeadline;
    }
    const defaultHeadline = get(tile, 'headline') || '';
    if (!selectedLanguage) {
      return defaultHeadline;
    }
    const tileLocalizations = this.getTileLocalizations();
    const localization = find(tileLocalizations, { language: selectedLanguage });
    return get(localization, 'headline') || '';
  };

  getTileLocalizations(): TileLocalization[] {
    const { tile } = this.props;
    return get(tile, 'tileLocalizations') || [];
  }

  getHeadlineUpdateProperties(newHeadline: string): Partial<Tile> {
    const { selectedLanguage } = this.props;
    if (!selectedLanguage) {
      return { headline: newHeadline };
    }
    const localizations = this.getTileLocalizations();
    const tileLocalizations = localizations.map((localization: TileLocalization) => {
      return localization.language === selectedLanguage ? { ...localization, headline: newHeadline } : localization;
    });
    return { tileLocalizations };
  }

  placeholderHeadline = (): string => {
    const placeholderMessage = intlMessages.getMessageFromId('headline-placeholder-text-2');
    invariant(placeholderMessage, 'could not find placeholder message');
    return this.context.intl.formatMessage(placeholderMessage.props);
  };

  tileHasImage = (tile: Tile) => {
    return tile.croppedImageAssetId;
  };

  shouldRenderHeadline = () => {
    const { tile, dummyHeadline } = this.props;
    return dummyHeadline || (!this.props.omitHeadline && tile && this.tileHasImage(tile));
  };

  hasTileToRender = () => {
    return tileUtils.buildPreviewImageUrl(this.props.tile) != null;
  };

  renderPentaliftTile = (tile: Tile) => {
    if (tile?.cameoTile) {
      return <CameoTilePanelPreview src={tile.cameoTile.previewUrl} />;
    }
    return this.renderImagePreview(tile);
  };

  renderPentaliftTileWithImageUrl = (tileImageUrl: string) => {
    return this.renderPreviewWrapper(
      <img
        src={tileImageUrl}
        alt=""
        crossOrigin={
          CDN_BASE_URLS.some(cdnBase => tileImageUrl.startsWith(cdnBase))
            ? CrossOrigin.NONE
            : CrossOrigin.USE_CREDENTIALS
        }
        className={style.cropPreviewImage}
        data-test="cheetahTilePreview.cropPreviewImage"
      />,
      style.imagePreview
    );
  };

  renderTile = () => {
    const { tile, tileImageUrl } = this.props;

    if (tileImageUrl) {
      return this.renderPentaliftTileWithImageUrl(tileImageUrl);
    }

    if (!tile) {
      return null;
    }

    return this.renderPentaliftTile(tile);
  };

  renderPreviewWrapper(children: React.ReactElement<any>, className: string) {
    return (
      <div className={className}>
        {children}
        <div className={classNames({ [style.gradient]: get(this.props.tile, 'isHeadlineEnabled') })} />
      </div>
    );
  }

  renderImagePreview(tile: Tile) {
    if (!tile) {
      return null;
    }
    const bitmojiTileTemplateId = get(tile, 'bitmojiTileTemplateId') || null;
    const url = bitmojiTileTemplateId
      ? tileUtils.buildBitmojiImageUrl(bitmojiTileTemplateId)
      : tileUtils.buildPreviewImageUrl(tile);
    const crossOrigin = bitmojiTileTemplateId ? CrossOrigin.NONE : CrossOrigin.USE_CREDENTIALS;
    return (
      url &&
      this.renderPreviewWrapper(
        <img
          src={url}
          alt=""
          crossOrigin={crossOrigin}
          className={style.cropPreviewImage}
          data-test="cheetahTilePreview.cropPreviewImage"
        />,
        style.imagePreview
      )
    );
  }

  renderHeadline() {
    const { isEditable, useSmallHeadline } = this.props;
    return (
      <div
        className={classNames(style.headline, 'tile-headline-override', { [style.small]: useSmallHeadline })}
        data-test="cheetahTilePreview.headlineRender"
      >
        <LineLimitedContentEditable
          text={this.tileHeadline()}
          /* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */
          className={style.optional}
          placeholder={this.placeholderHeadline()}
          lineLimit={LINE_LIMIT}
          maxLength={CHAR_LIMIT}
          onBlur={this.handleOnBlur}
          disabled={this.props.isTileLockedForEdit || !isEditable}
        />
        {isEditable && (
          <div className={style.headlineTimestamp} data-test="cheetahTilePreview.headlineTimeStamp">
            {intlMessages.getMessageFromId('message-today')}
          </div>
        )}
      </div>
    );
  }

  renderLogoGradient = () => {
    const { tile } = this.props;
    if (!tile || !tile.logoImageAssetId || !tile.isLogoEnabled) {
      return null;
    }
    const logoPosition = tile.logoPosition || LogoPosition.TOP;
    const hexColor = get(tile, 'logoReadStateOverlayColor') || '#000000';
    const fadeOut = colorUtils.hexToRgbaStyle(hexColor, 0);
    // Values used here and below come from https://snapchat.quip.com/lzn0AkfmBLIa
    const maxOpacityValue = 0.4;
    const middleOpacity = hexColor === '#000000' ? 0.6 : 1;
    const gradientStyle = reactCSS({
      default: {
        [LogoPosition.TOP]: {
          background: `linear-gradient(${colorUtils.hexToRgbaStyle(hexColor, maxOpacityValue)}, ${fadeOut})`,
        },
        [LogoPosition.MIDDLE]: {
          background: `linear-gradient(${fadeOut}, ${colorUtils.hexToRgbaStyle(hexColor, middleOpacity)}, ${fadeOut})`,
        },
        [LogoPosition.BOTTOM]: {
          background: `linear-gradient(${fadeOut}, ${colorUtils.hexToRgbaStyle(hexColor, maxOpacityValue)})`,
        },
      },
    });
    const gradientClasses = classNames([style.logoGradient, style.imageTile], {
      [style.top]: logoPosition === LogoPosition.TOP,
      [style.middle]: logoPosition === LogoPosition.MIDDLE,
      [style.bottom]: logoPosition === LogoPosition.BOTTOM,
    });
    return <div className={gradientClasses} style={gradientStyle[logoPosition]} />;
  };

  renderDummyLogo = (dummyLogo: string) => {
    const iconClass = classNames(style.publisherIcon, style.top);
    return (
      <img
        src={dummyLogo}
        alt=""
        crossOrigin={CrossOrigin.NONE}
        className={iconClass}
        data-test="cheetahTilePreview.dummyLogo"
      />
    );
  };

  renderPublisherLogo = () => {
    const { tile } = this.props;
    if (!tile || !tileUtils.getLogoAssetId(tile) || !tile.isLogoEnabled) {
      return null;
    }
    const logoImageAssetId = tileUtils.getLogoAssetId(tile);
    const logoUrl = assetUtils.getImagePreviewUrl(logoImageAssetId);
    if (!logoUrl) {
      return null;
    }
    const logoPosition = tileUtils.getLogoPosition(tile) || LogoPosition.TOP;
    const iconClass = classNames(style.publisherIcon, {
      [style.top]: logoPosition === LogoPosition.TOP,
      [style.middle]: logoPosition === LogoPosition.MIDDLE,
      [style.bottom]: logoPosition === LogoPosition.BOTTOM,
    });
    return (
      <img
        src={logoUrl}
        alt=""
        crossOrigin={CrossOrigin.USE_CREDENTIALS}
        className={iconClass}
        data-test="cheetahTilePreview.logo"
      />
    );
  };

  renderAutogenerateButton() {
    return (
      <div className={style.importTopsnap} onClick={this.importTopsnap}>
        {!this.state.importingTopsnap ? (
          intlMessages.getMessageFromId('autogenerate-tile-button')
        ) : (
          <SpinnerIcon className={style.spinnerIcon} />
        )}
      </div>
    );
  }

  render() {
    const { editionId, snapId, isEditable, isTileLockedForEdit, snap, isTileAutogenerator, dummyLogo } = this.props;
    const replace = this.hasTileToRender();
    const snapHasMedia =
      snap &&
      ((snap.type === SnapType.VIDEO && snap.videoAssetId) || (snap.type === SnapType.IMAGE && snap.imageAssetId));
    const shouldShowAutoGenerate =
      isEditable && !isTileLockedForEdit && !replace && snapHasMedia && !this.state.uploading && isTileAutogenerator;
    const rootClasses = classNames(style.root, {
      [style.editable]: isEditable,
    });
    return (
      <div className={rootClasses}>
        {this.renderTile()}
        {(dummyLogo && this.renderDummyLogo(dummyLogo)) || this.renderPublisherLogo()}
        {this.renderLogoGradient()}
        {isEditable && (
          <div className={style.optionDropzone}>
            <ImageMediaUploader
              headline={
                <FormattedMessage id="upload-image-by-url" description="upload image by url" defaultMessage="Upload" />
              }
              key="imageMediaUploader"
              purpose={UploadPurpose.TILE_IMAGE}
              dropzoneType={DropzoneType.CHEETAH_TILE}
              onUploadComplete={this.handleOnUploadComplete}
              onUploadStart={this.handleOnUploadStart}
              editionId={editionId}
              snapId={snapId}
              isReplace={replace}
              isReadOnly={isTileLockedForEdit}
              data-test="richSnapEditor.tileEditor.tileUploader"
            />
          </div>
        )}
        {shouldShowAutoGenerate ? this.renderAutogenerateButton() : null}
        {this.shouldRenderHeadline() && this.renderHeadline()}
      </div>
    );
  }
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(CheetahTilePreview);
