import classNames from 'classnames';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import StatusMessageBar from '../StatusMessageBar/StatusMessageBar';

import SpinnerIcon from 'views/common/components/SpinnerIcon/SpinnerIcon';

import style from './UploadStatusBar.scss';

const uploadMessage = (
  <FormattedMessage id="status-message-uploading" defaultMessage="UPLOADING" description="media is being uploaded" />
);

export function ProgressBarOverlay({ progress }: { progress: number }) {
  const progressBarStyle = {
    width: `${Math.round(progress * 100)}%`,
  };

  return <div className={style.progressBarOverlay} style={progressBarStyle} />;
}

type Props = {
  className?: string;
  barClass?: string;
  progress?: number;
  spinnerClass?: string;
};

export default function UploadStatusBar({ className, barClass, spinnerClass, progress }: Props) {
  return (
    <div className={className}>
      <StatusMessageBar
        className={barClass}
        icon={<SpinnerIcon className={classNames(spinnerClass, style.spinner)} />}
        message={uploadMessage}
      />
      <ProgressBarOverlay progress={Number(progress)} />
    </div>
  );
}
