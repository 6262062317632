import React from 'react';

import style from './PickedCountryCounter.scss';

type Props = {
  numPicked: number;
  numTotal: number;
};

const PickedCountryCounter = ({ numPicked, numTotal }: Props) => (
  <span className={style.root}>
    ({numPicked}/{numTotal})
  </span>
);

export default PickedCountryCounter;
