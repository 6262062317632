import invariant from 'invariant';
import is from 'is_js';
import React from 'react';
import type { ReactNode } from 'react';
import { DropTarget as dropTarget } from 'react-dnd';
import type { DropTargetMonitor, ConnectDropTarget } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';

import { DragAndDropTypes } from 'types/mediaLibraryDrawer';

type Props = {
  canDrop: boolean;
  children: (isDropzoneActive: boolean) => ReactNode | ReactNode;
  connectDropTarget: (node: ReactNode) => ReactNode;
  isOver: boolean;
  isOverCurrent: boolean;
  onDrop: (files?: any[] | null, mediaLibraryItems?: any | null) => void;
  shallow?: boolean;
};
const isDropzoneActive = ({ shallow, isOverCurrent, isOver, canDrop }: any) => {
  // isOverCurrent is a shallow version of isOver, meaning that it will only consider the area covered by
  // the current element (children) minus all the other areas that are covered by other elements.
  // isOver will be true whenever the mouse is over the bounding box of the current element (children).
  // In this case it will still return true if the mouse is over an area covered by other element as long
  // as the mouse is within the bounding box of the current element (children).
  const isOverComponent = shallow ? isOverCurrent : isOver;
  // If the mouse is over the component and we can drop elements, then the dropzone
  // should be set to active
  return isOverComponent && canDrop;
};
export class FileDropzoneTarget extends React.Component<Props> {
  renderChildren = () => {
    const { children, isOver, isOverCurrent, shallow, canDrop } = this.props;
    invariant(children, 'Children on FileDropzoneTarget cannot be empty');
    if (is.function(children)) {
      return children(
        isDropzoneActive({
          shallow,
          isOverCurrent,
          isOver,
          canDrop,
        })
      );
    }
    return children;
  };

  render() {
    return this.props.connectDropTarget(this.renderChildren());
  }
}
export const dropzoneTarget = {
  drop(props: Props, monitor: DropTargetMonitor<any>) {
    if (props.onDrop && monitor) {
      const isOverCurrent = monitor.isOver({ shallow: true });
      const isOver = monitor.isOver();
      const canDrop = monitor.canDrop();
      // If the dropzone is not active, then do not call onDrop prop
      if (
        !isDropzoneActive({
          shallow: props.shallow,
          isOverCurrent,
          isOver,
          canDrop,
        })
      ) {
        return;
      }
      let files = null;
      if (monitor.getItem().files) {
        const droppedFiles = monitor.getItem().files;
        files = droppedFiles.map((file: any) =>
          Object.assign(file, {
            blobUrl: URL.createObjectURL(file),
          })
        );
      }
      props.onDrop(files, monitor.getItem().mediaLibraryItems);
    }
  },
};
const collect = (connect: ConnectDropTarget, monitor: DropTargetMonitor) => ({
  connectDropTarget: (connect as any).dropTarget(),
  isOver: monitor.isOver(),
  isOverCurrent: monitor.isOver({ shallow: true }),
  canDrop: monitor.canDrop(),
});
export const getDropTargets = (isSubscribeSnap: boolean) => {
  return isSubscribeSnap
    ? Object.freeze([NativeTypes.FILE, DragAndDropTypes.MEDIA_LIBRARY_ITEM])
    : Object.freeze([NativeTypes.FILE, DragAndDropTypes.MEDIA_LIBRARY_ITEM, DragAndDropTypes.COMMUNITY_SNAP]);
};
// A DropzoneTarget that accepts both files and media library items (dragged from Media Library Drawer)
// @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'readonly string[]' is not assign... Remove this comment to see the full error message
const subscribeSnapDropTarget = dropTarget(getDropTargets(true), dropzoneTarget, collect)(FileDropzoneTarget);
// @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'readonly string[]' is not assign... Remove this comment to see the full error message
const defaultDropTarget = dropTarget(getDropTargets(false), dropzoneTarget, collect)(FileDropzoneTarget);
export function fileAndMediaDropzoneTarget(isSubscribeSnap: boolean) {
  if (isSubscribeSnap) {
    return subscribeSnapDropTarget;
  }
  return defaultDropTarget;
}
// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(connect: ConnectDropTarget, mon... Remove this comment to see the full error message
export default dropTarget(NativeTypes.FILE, dropzoneTarget, collect)(FileDropzoneTarget);
