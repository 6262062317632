import classNames from 'classnames';
import React from 'react';
import InlineSVG from 'svg-inline-react';

// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'images/checkmark.svg.inline' o... Remove this comment to see the full error message
import checkmark from 'images/checkmark.svg.inline';

import style from './CompositeSnapCheckbox.scss';

type Props = {
  className?: string;
  isChecked: boolean | undefined | null;
  // When the checkbox is disabled, cursor does not change when hovering on it
  isDisabled?: boolean;
  onToggle?: () => any;
};

export class CompositeSnapCheckbox extends React.PureComponent<Props> {
  render() {
    const checkmarkClasses = classNames(style.checkbox, {
      [style.checked]: this.props.isChecked,
      [style.hover]: !this.props.isChecked,
      [style.defaultCursorOnHover]: !this.props.isDisabled,
    });
    return (
      <span
        className={classNames(this.props.className)}
        onClick={this.props.onToggle}
        data-test="compositeSnapCheckBox"
      >
        <InlineSVG src={checkmark} className={checkmarkClasses} />
      </span>
    );
  }
}

export default CompositeSnapCheckbox;
