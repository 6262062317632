import classNames from 'classnames';
import { get } from 'lodash';
import React from 'react';

import * as editorActions from 'state/editor/actions/editorActions';
import * as editorSelectors from 'state/editor/selectors/editorSelectors';
import * as snapsSelectors from 'state/snaps/selectors/snapsSelectors';

import { TileSpecifications, TileFormatConfigType } from 'config/tileConfig';
import { supportChat } from 'icons/SDS/allIcons';
import { State } from 'src/types/rootState';
import { assertArg } from 'utils/assertionUtils';
import { intlConnect } from 'utils/connectUtils';

import Icon from 'views/common/components/Icon/Icon';
import SpinnerIcon from 'views/common/components/SpinnerIcon/SpinnerIcon';
import CheetahTilePreview from 'views/editor/containers/CheetahTilePreview/CheetahTilePreview';
import { updateIfPropsAndStateChanged } from 'views/propTypes/utils';

import style from './TilePreview.scss';

// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '!sass-variables-loader!../Rich... Remove this comment to see the full error message
import styleVars from '!sass-variables-loader!../RichSnapEditorVariables.scss';

// @ts-expect-error ts-migrate(2307)
assertArg(styleVars.tileSpacing, 'tileSpacing').is.string();
const tileSpacing = parseFloat(styleVars.tileSpacing);

const mapStateToProps = (state: State, ownProps: any) => {
  const id = editorSelectors.getActiveWholeSnapId(state);

  return {
    isLoading: snapsSelectors.isLoadingById(state)(id),
    isSaving: snapsSelectors.isSavingById(state)(id),
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    error: snapsSelectors.getErrorById(state)(id),
  };
};

const mapDispatchToProps = {
  setEditorConfigTileProperties: editorActions.setEditorConfigTileProperties,
};

type OwnTilePreviewProps = {
  className?: string;
  format: TileFormatConfigType;
  roundCorners?: boolean;
  tile?: any;
  isEditable: boolean;
  variant?: boolean;
  playOnHover?: boolean;
  forceImagePreview?: boolean;
  isLoading?: boolean;
  isSaving?: boolean;
  error?: any;
  setEditorConfigTileProperties?: (...args: any[]) => any;
  scale?: number;
  omitHeadline?: boolean;
  useSmallHeadline?: boolean;
  dummyHeadline?: string;
  dummyLogo?: string;
  newTileDefaultProperties?: any;
  tileImageUrl?: string;
};

type TilePreviewState = any;

type TilePreviewProps = OwnTilePreviewProps & typeof TilePreview.defaultProps;

export class TilePreview extends React.Component<TilePreviewProps, TilePreviewState> {
  static defaultProps = {
    variant: false,
    scale: 1,
    roundCorners: false,
    isEditable: false,
    forceImagePreview: false,
    useSmallHeadline: false,
  };

  static mapStateToProps = mapStateToProps;

  shouldComponentUpdate(nextProps: TilePreviewProps, nextState: TilePreviewState) {
    return updateIfPropsAndStateChanged(this.props, this.state, nextProps, nextState);
  }

  calculateDimensions(config: TileFormatConfigType, scale: number) {
    const dimensions = TileSpecifications[config.cropType];

    return {
      width: `${dimensions.width * scale - tileSpacing}px`,
      height: `${dimensions.height * scale - tileSpacing}px`,
    };
  }

  renderPreview() {
    const {
      tile,
      isEditable,
      playOnHover,
      forceImagePreview,
      useSmallHeadline,
      omitHeadline,
      dummyHeadline,
      dummyLogo,
      newTileDefaultProperties,
    } = this.props;
    return (
      <CheetahTilePreview
        playOnHover={playOnHover}
        isEditable={isEditable}
        forceImagePreview={forceImagePreview}
        tile={tile}
        key={get(tile, 'id')}
        omitHeadline={omitHeadline}
        useSmallHeadline={useSmallHeadline}
        dummyHeadline={dummyHeadline}
        dummyLogo={dummyLogo}
        newTileDefaultProperties={newTileDefaultProperties}
        tileImageUrl={this.props.tileImageUrl}
      />
    );
  }

  render() {
    const { format, isLoading, isSaving, error, className, scale: propsScale, roundCorners } = this.props;

    // In order for scaling calculations to be accurate we need to multiply the scale of
    // the tile itself (i.e. `config.scale`, which equates to 1/2, 1/3 etc) by the scaling
    // being applied to the tile preview component when rendered.
    const scale = format.scale * propsScale;
    const dimensions = this.calculateDimensions(format, scale);
    const isError = !!error;
    const rootClasses = classNames(style.root, className, {
      [style.rounded]: roundCorners,
    });

    return (
      <div className={rootClasses} style={{ ...dimensions }}>
        {!isError && (isLoading || isSaving) && <SpinnerIcon className={style.loading} />}
        {isError && <Icon className={style.error} inlineIcon={supportChat} />}
        <div className={style.tileContainer}>{this.renderPreview()}</div>
      </div>
    );
  }
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(TilePreview);
