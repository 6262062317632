import React from 'react';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { AssetType } from 'types/assets';

export const LogoPosition = {
  TOP: 'TOP',
  MIDDLE: 'MIDDLE',
  BOTTOM: 'BOTTOM',
};

export type LogoPositionEnum = typeof LogoPosition[keyof typeof LogoPosition];

export const LOGO_POSITION_OFF = 'OFF';

type LogoPositionOption = {
  value: LogoPositionEnum;
  label: ReactNode;
};

export const LOGO_POSITION_OPTIONS_ALL: LogoPositionOption[] = [
  {
    value: LogoPosition.TOP,
    label: <FormattedMessage id="drop-down-top" description="Drop down message top" defaultMessage="Top" />,
  },
  {
    value: LogoPosition.MIDDLE,
    label: <FormattedMessage id="drop-down-middle" description="Drop down message middle" defaultMessage="Middle" />,
  },
  {
    value: LogoPosition.BOTTOM,
    label: <FormattedMessage id="drop-down-bottom" description="Drop down message bottom" defaultMessage="Bottom" />,
  },
  {
    value: LOGO_POSITION_OFF,
    label: <FormattedMessage id="logo-config-drop-down-off" description="Drop down message off" defaultMessage="Off" />,
  },
];

// TODO: This is temporary - details in https://jira.sc-corp.net/browse/PUB-3059
export const LOGO_POSITION_OPTIONS_VIDEO: LogoPositionOption[] = LOGO_POSITION_OPTIONS_ALL.filter(
  option => option.value !== LogoPosition.BOTTOM && option.value !== LogoPosition.MIDDLE
);

export const LOGO_POSITION_OPTIONS_IMAGE: LogoPositionOption[] = LOGO_POSITION_OPTIONS_ALL.filter(
  option => option.value !== LogoPosition.BOTTOM && option.value !== LogoPosition.MIDDLE
);
export const ENABLED_LOGO_POSITION_OPTIONS_IMAGE: LogoPositionOption[] = LOGO_POSITION_OPTIONS_IMAGE.filter(
  option => option.value !== LOGO_POSITION_OFF
);

export function getLogoPositionsByAssetType(assetType: AssetType) {
  if (assetType === AssetType.VIDEO) {
    return LOGO_POSITION_OPTIONS_VIDEO;
  }
  return LOGO_POSITION_OPTIONS_IMAGE;
}
