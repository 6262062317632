import classNames from 'classnames';
import invariant from 'invariant';
import React from 'react';
import ReactDOM from 'react-dom';

import { CrossOrigin } from 'config/constants';

import VideoPreviewLoadingBar from 'views/common/components/VideoPreviewLoadingBar/VideoPreviewLoadingBar';

import style from './VideoSnapPreview.scss';

import type { TrimMetadata } from 'types/curation';

const VIDEO_PROGRESS_POLLING_INTERVAL_MS = 100;

type OwnProps = {
  src: string;
  overlaySrc?: string;
  posterSrc: string | undefined | null;
  shouldPlay?: boolean;
  muted: boolean;
  trimMetadata?: TrimMetadata;
};

type StateProps = {
  muted: boolean;
};

type OwnState = {
  loaded: boolean;
  progressPercentage: number;
  playing: boolean;
};

type Props = OwnProps & StateProps;

export class VideoSnapPreview extends React.Component<Props, OwnState> {
  state = {
    loaded: false,
    progressPercentage: 0,
    playing: false,
  };

  updateInterval: NodeJS.Timeout | null = null;

  componentDidUpdate() {
    if (this.props.shouldPlay && !this.state.playing) {
      this.startVideo();
    } else if (!this.props.shouldPlay && this.state.playing) {
      this.stopVideo();
    }
  }

  componentWillUnmount() {
    this.disableProgressPolling();
  }

  isAutoPlay = () => !!this.props.posterSrc;

  onLoaded = () => {
    this.setState({
      loaded: true,
    });
    if (this.isAutoPlay()) {
      this.onPlaying();
    }
  };

  onVideoRef = (video?: HTMLVideoElement | null) => {
    // TODO(chen): avoid findDOMNode
    const node = ReactDOM.findDOMNode(video);
    invariant(!node || node instanceof HTMLVideoElement, 'node is an HTML video element');
    this.video = node;
  };

  onPlaying = () => {
    // Please note, when autoPlay is enabled, this event will not fired until video finished playing the first round, we have to set
    // playing to true in onLoaded method otherwise the preview poster is not hidden and user will only heard the audio.
    this.setState({ playing: true });
    this.enableProgressPolling();
  };

  setProgress = () => {
    if (this.video) {
      const totalDuration = this.video.duration;
      const { currentTime } = this.video;

      const progressPercentage = Math.floor((100.0 * currentTime) / totalDuration);

      this.setState({
        progressPercentage,
      });
    }
  };

  // We need to pool the progress of the video at regular intervals to make sure that the loading bar
  // is not too jumpy. Updates from the `currentTime` listener do not come in regular intervals, so the loading bar
  // would look jumpy.
  // This does not affect performance as the interval is only triggered if the user is hovering over the component.
  enableProgressPolling = () => {
    this.disableProgressPolling();
    this.updateInterval = setInterval(this.setProgress, VIDEO_PROGRESS_POLLING_INTERVAL_MS);
  };

  disableProgressPolling = () => {
    if (this.updateInterval) {
      clearInterval(this.updateInterval);
    }
  };

  // Allow us to loop the video from a specific time range.
  onTimeUpdate = () => {
    if (!this.video || !this.props.trimMetadata) {
      return;
    }
    const trimStartInSecond = this.props.trimMetadata.trimStart / 1000;
    const trimEndInSecond = this.props.trimMetadata.trimEnd / 1000;
    const currentTime = this.video.currentTime;

    if (currentTime > trimEndInSecond || currentTime < trimStartInSecond) {
      this.video.currentTime = trimStartInSecond;
      this.video.play();
    }
  };

  startVideo = () => {
    const videoDOMobject = this.video;

    if (videoDOMobject && videoDOMobject instanceof HTMLMediaElement) {
      if (videoDOMobject.paused) {
        videoDOMobject.currentTime = 0;
        this.videoPlay = videoDOMobject.play().catch(e => {
          // Playback has been interrupted because of another pause() call. Just swallow it
          // https://developers.google.com/web/updates/2017/06/play-request-was-interrupted
        });
      }
    }
  };

  stopVideo = () => {
    const videoDOMobject = this.video;

    if (videoDOMobject && videoDOMobject instanceof HTMLMediaElement) {
      if (!videoDOMobject.paused && this.videoPlay) {
        this.videoPlay
          .then(() => {
            videoDOMobject.pause();
            videoDOMobject.currentTime = 0;
          })
          .catch((e: any) => {});
        this.videoPlay = null;
      }
    }

    this.setState({
      playing: false,
      progressPercentage: 0,
    });
  };

  video: HTMLVideoElement | undefined | null;

  videoPlay: Promise<void> | null = null;

  renderVideoView = (overlayImageClass: string, videoClass: string) => {
    const { src, trimMetadata } = this.props;
    // See https://www.w3.org/TR/media-frags/#naming-time
    let srcWithTimeRange: string;
    if (!trimMetadata) {
      srcWithTimeRange = src;
    } else {
      srcWithTimeRange = `${src}#t=${trimMetadata.trimStart / 1000},${trimMetadata.trimStart / 1000}`;
    }

    return (
      <>
        {this.props.overlaySrc && (
          <img
            className={overlayImageClass}
            crossOrigin={CrossOrigin.USE_CREDENTIALS}
            src={this.props.overlaySrc}
            alt="Snap overlay"
          />
        )}
        <video
          key={this.props.src}
          draggable="false"
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; key: string; draggable:... Remove this comment to see the full error message
          alt="Snap preview"
          className={videoClass}
          crossOrigin={CrossOrigin.USE_CREDENTIALS}
          ref={this.onVideoRef}
          onLoadedData={this.onLoaded}
          onPlaying={this.onPlaying}
          onEnded={this.disableProgressPolling}
          onPause={this.disableProgressPolling}
          onWaiting={this.disableProgressPolling}
          preload="auto"
          muted={this.props.muted}
          loop
          autoPlay={this.isAutoPlay()}
          onTimeUpdate={this.onTimeUpdate}
        >
          <source src={srcWithTimeRange} />
        </video>
      </>
    );
  };

  render() {
    const overlayImageClass = classNames(style.overlayImage, {
      [style.hide]: !this.state.playing,
      [style.displayNone]: !this.props.overlaySrc,
    });
    const videoClass = classNames(style.snapVideoPreview, {
      [style.hide]: !this.state.loaded,
    });
    const previewImageClass = classNames(style.previewImage, {
      [style.hide]: this.state.playing,
      [style.displayNone]: !this.props.posterSrc,
    });

    return (
      <div className={style.snapPreviewContainer} data-test="videoSnapPreview.snapPreview">
        <div className={style.videoImageContainer} data-test="videoSnapPreview.snapVideoImage">
          <img
            className={previewImageClass}
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null | undefined' is not assignable... Remove this comment to see the full error message
            src={this.props.posterSrc}
            crossOrigin={CrossOrigin.USE_CREDENTIALS}
            alt="Snap preview"
          />
          {this.props.shouldPlay || !this.props.posterSrc ? this.renderVideoView(overlayImageClass, videoClass) : null}
        </div>
        {this.props.shouldPlay ? (
          <VideoPreviewLoadingBar className={style.loadingBar} progressPercentage={this.state.progressPercentage} />
        ) : null}
      </div>
    );
  }
}

export default VideoSnapPreview;
