import React from 'react';

import { intlConnect } from 'utils/connectUtils';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';

import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';
import SDSPanel from 'views/common/components/SDSPanel/SDSPanel';
import { OrgDetails } from 'views/organisations/containers/OrgDetails/OrgDetails';

import logo from 'images/logo.svg';

import style from './CreateNewOrg.scss';

import type { State as BaseState } from 'types/rootState';

const Modes = {
  MAIN: 'MAIN',
  ORG_DETAILS: 'ORG_DETAILS',
};

type ModesType = typeof Modes[keyof typeof Modes];
type StateProps = {};
type DispatchProps = {};
type Props = {} & StateProps & DispatchProps;

type State = {
  mode: ModesType;
};

export const mapStateToProps = (state: BaseState): StateProps => {
  return {};
};

const mapDispatchToProps: DispatchProps = {};

export class CreateNewOrg extends React.Component<Props, State> {
  state = {
    mode: Modes.MAIN,
  };

  handleBackToMainScreen = () => {
    this.setState({ mode: Modes.MAIN });
  };

  newOrganisation = () => {
    this.setState({ mode: Modes.ORG_DETAILS });
  };

  renderHeader() {
    return (
      <div className={style.panelHeader}>
        <div>{getMessageFromId('new-org-details')}</div>
        <SDSButton type={ButtonType.PRIMARY} onClick={this.newOrganisation}>
          {getMessageFromId('new-org-button')}
        </SDSButton>
      </div>
    );
  }

  renderMainScreen() {
    return (
      <>
        <div className={style.header}>
          <img className={style.logo} src={logo} alt="Story Studio" />
        </div>
        <SDSPanel header={this.renderHeader()}>Details go here.....</SDSPanel>
      </>
    );
  }

  renderPage() {
    switch (this.state.mode) {
      case Modes.ORG_DETAILS:
        return <OrgDetails onClose={this.handleBackToMainScreen} />;
      case Modes.MAIN:
      default:
        return this.renderMainScreen();
    }
  }

  render() {
    return <div className={style.container}>{this.renderPage()}</div>;
  }
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(CreateNewOrg);
