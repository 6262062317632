import moment from 'moment-timezone';
import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';
import SDSDropdown, { DropdownType } from 'views/common/components/SDSDropdown/SDSDropdown';
import { createSDSDropdownOptions } from 'views/common/components/SDSDropdownOptions/SDSDropdownOptions';
import SDSLabel from 'views/common/components/SDSLabel/SDSLabel';
import SDSModal from 'views/common/components/SDSModal/SDSModal';
import { REVENUE_COUNTRY_OPTIONS, RevenueCountry } from 'views/modals/components/RevenueCSVModal/RevenueCountry';

import style from './AnalyticsEditionCSVModal.scss';

import { ModalId } from 'types/modal';

type Props = {
  modalId: ModalId;
  hideModal: (modalId: string) => void;
  options: {
    from: moment.Moment;
    to: moment.Moment;
  };
};

export enum Granularity {
  DAY = 'DAY',
  MONTH = 'MONTH',
  TOTAL = 'TOTAL',
}

const GRANULARITY_OPTIONS = [
  {
    label: (
      <FormattedMessage
        id="revenue-csv-granularity-day"
        description="Label for revenue csv download granularity dropdown option day"
        defaultMessage="Day"
      />
    ),
    value: Granularity.DAY,
  },
  {
    label: (
      <FormattedMessage
        id="revenue-csv-granularity-month"
        description="Label for revenue csv download granularity dropdown option month"
        defaultMessage="Month"
      />
    ),
    value: Granularity.MONTH,
  },
  {
    label: (
      <FormattedMessage
        id="revenue-csv-granularity-total"
        description="Label for revenue csv download granularity dropdown option total"
        defaultMessage="Total"
      />
    ),
    value: Granularity.TOTAL,
  },
];

export enum Breakdown {
  STORY = 'STORY',
  STORY_AND_COUNTRY = 'STORY_AND_COUNTRY',
}

const BREAKDOWN_OPTIONS = [
  {
    label: (
      <FormattedMessage
        id="edition-csv-breakdown-story"
        description="Label for edition csv download breakdown dropdown option story"
        defaultMessage="Story"
      />
    ),
    value: Breakdown.STORY,
  },
  {
    label: (
      <FormattedMessage
        id="edition-csv-breakdown-story-country"
        description="Label for edition csv download breakdown dropdown option story and country"
        defaultMessage="Story And Country"
      />
    ),
    value: Breakdown.STORY_AND_COUNTRY,
  },
];

export default function AnalyticsEditionCSVModal({ options, modalId, hideModal }: Props) {
  const [granularity, setGranularity] = useState<Granularity>(Granularity.DAY);
  const [isDownloading] = useState<boolean>(false);
  const [breakdown, setBreakdown] = useState<Breakdown>(Breakdown.STORY);
  const [country, setCountry] = useState<string | null>(null);

  const closeModal = useCallback(() => {
    hideModal(modalId);
  }, [hideModal, modalId]);

  const onGranularityChange = useCallback((updatedGranularity: Granularity) => {
    setGranularity(updatedGranularity);
  }, []);

  const onBreakdownChange = useCallback((updatedBreakdown: Breakdown) => {
    if (updatedBreakdown === Breakdown.STORY_AND_COUNTRY) {
      const firstCountry = Object.keys(RevenueCountry)[0]!;
      setCountry(firstCountry);
    } else {
      setCountry(null);
    }

    setBreakdown(updatedBreakdown);
  }, []);

  const onCountryChange = useCallback((updatedCountry: RevenueCountry) => {
    setCountry(updatedCountry);
  }, []);

  const downloadEditionCSV = useCallback(async () => {}, []);

  return (
    <SDSModal visible width={400} closable footer={false} onCancel={closeModal}>
      <div className={style.container}>
        <SDSLabel
          withoutDefaultMargin
          data-test="AnalyticsEditionCSVModal.breakdownLabel"
          className={style.label}
          labelTitle={
            <FormattedMessage
              id="edition-csv-breakdown"
              description="Label for edition csv download breakdown dropdown"
              defaultMessage="Breakdown"
            />
          }
        >
          <SDSDropdown
            disableClear
            type={DropdownType.GREY}
            data-test="AnalyticsEditionCSVModal.breakdownDropdown"
            value={breakdown}
            onChange={onBreakdownChange}
          >
            {createSDSDropdownOptions(BREAKDOWN_OPTIONS)}
          </SDSDropdown>
        </SDSLabel>
        <SDSLabel
          withoutDefaultMargin
          data-test="AnalyticsEditionCSVModal.granularityLabel"
          className={style.label}
          labelTitle={
            <FormattedMessage
              id="edition-csv-granularity"
              description="Label for edition csv download granularity dropdown"
              defaultMessage="Granularity"
            />
          }
        >
          <SDSDropdown
            disableClear
            type={DropdownType.GREY}
            data-test="AnalyticsEditionCSVModal.granularityDropdown"
            value={granularity}
            onChange={onGranularityChange}
          >
            {createSDSDropdownOptions(GRANULARITY_OPTIONS)}
          </SDSDropdown>
        </SDSLabel>
        {breakdown === Breakdown.STORY_AND_COUNTRY && (
          <SDSLabel
            withoutDefaultMargin
            data-test="AnalyticsEditionCSVModal.countryLabel"
            className={style.label}
            labelTitle={
              <FormattedMessage
                id="edition-csv-country"
                description="Label for edition csv download country dropdown"
                defaultMessage="Country"
              />
            }
          >
            <SDSDropdown
              disableClear
              type={DropdownType.GREY}
              data-test="AnalyticsEditionCSVModal.countryDropdown"
              value={country ?? undefined}
              onChange={onCountryChange}
            >
              {createSDSDropdownOptions(REVENUE_COUNTRY_OPTIONS)}
            </SDSDropdown>
          </SDSLabel>
        )}
        <SDSButton
          type={ButtonType.PRIMARY}
          onClick={downloadEditionCSV}
          data-test="AnalyticsEditionCSVModal.downloadCSVButton"
          className={style.exportButton}
          loading={isDownloading}
          disabled={isDownloading}
        >
          {
            <FormattedMessage
              id="edition-csv-export-download-button"
              description="Label for edition csv export download button"
              defaultMessage="Download CSV"
            />
          }
        </SDSButton>
      </div>
    </SDSModal>
  );
}
