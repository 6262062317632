import React from 'react';
import type { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

import { timer as recentIcon } from 'icons/SDS/allIcons';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';

import curationIcon from 'images/curation.svg';
import videoSnapsIcon from 'images/video_edit.svg';

import { FilterByEnum, TargetEnum } from 'types/mediaLibrary';
import type { FilterByType, TargetType } from 'types/mediaLibrary';

export type MediaLibraryFilterData = {
  key: FilterByType;
  targets: TargetType[];
  icon?: string;
  inlineIcon?: string;
  text: ReactElement<any>;
};

export const filtersData: MediaLibraryFilterData[] = [
  {
    key: FilterByEnum.RECENT,
    targets: [TargetEnum.TOP_SNAP, TargetEnum.VIDEO_ATTACHMENT],
    inlineIcon: recentIcon,
    text: getMessageFromId('filters-recent'),
  },
  {
    key: FilterByEnum.SNAPS,
    targets: [TargetEnum.TOP_SNAP],
    icon: videoSnapsIcon,
    text: (
      <FormattedMessage
        id="media-library-filters-snaps"
        description="Show all snaps in Media Library"
        defaultMessage="Snaps"
      />
    ),
  },
  {
    key: FilterByEnum.VIDEO_ATTACHMENTS,
    targets: [TargetEnum.VIDEO_ATTACHMENT],
    icon: videoSnapsIcon,
    text: (
      <FormattedMessage
        id="media-library-filters-video-attachments"
        description="Show video attachments in Media Library"
        defaultMessage="Video Attachments"
      />
    ),
  },
  {
    key: FilterByEnum.CURATED_SNAPS,
    targets: [TargetEnum.TOP_SNAP],
    icon: curationIcon,
    text: (
      <FormattedMessage
        id="media-library-filters-curate-snaps"
        description="Show curate snaps in Media Library"
        defaultMessage="Community Snaps"
      />
    ),
  },
];
