import { enumObject } from 'utils/enum';

export type MinimisedTray = 'MINIMISED';
export type MediumTray = 'MEDIUM';
export type MaximisedTray = 'MAXIMISED';
export type TrayState = MinimisedTray | MediumTray | MaximisedTray;

export const TrayStateType: {
  MINIMISED: MinimisedTray;
  MEDIUM: MediumTray;
  MAXIMISED: MaximisedTray;
} = enumObject({
  MINIMISED: 'MINIMISED',
  MEDIUM: 'MEDIUM',
  MAXIMISED: 'MAXIMISED',
});
