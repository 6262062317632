import { once, memoize } from 'lodash';

import { CheckboxState, DEFAULT_ANALYTICS_GEO } from 'config/constants';

import { CountryList } from 'views/onboarding/containers/SettingsView/tabs/SuperAdminTab/countryLanguageLists';

import { CountryCodeCategory, DerivedState } from 'types/countries';
import type { Regions, Country, Region } from 'types/countries';

const getSensitiveCountries = once(() => {
  return CountryList.filter(country => country.sensitive);
});

const getSensitiveCountryCodes = once(() => {
  return getSensitiveCountries().map(country => country.value.toUpperCase());
});

export const getSensitiveCountryNames = once(() => {
  return getSensitiveCountries().map(country => country.label);
});

export const isSensitiveCountry = memoize((countryCode: string) => {
  return getSensitiveCountryCodes().includes(countryCode.toUpperCase());
});

export const getNonSensitiveCountryCodes = once(() => {
  return CountryList.filter(country => !country.sensitive).map(country => country.value.toUpperCase());
});

export const iterateAllCountries = (callback: (b: Country, a?: Region | null) => void, regions?: Regions | null) => {
  // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
  const regionValues: Region[] = Object.values(regions) as any;
  regionValues.forEach((region: Region) => {
    const countries: Country[] = Object.values(region.countries) as any;
    countries.forEach((country: Country) => callback(country, region));
  });
};

export const defaultCategoryUsedInMultiSelection = (
  categories: CountryCodeCategory[],
  category: CountryCodeCategory
) => {
  // If multiple categories are used, we should ignore the default category as it is the "all selected" category
  return categories.length > 1 && category === CountryCodeCategory.DEFAULT;
};

export const getCheckboxStates = ({
  countryCategories,
  countryIterator = iterateAllCountries,
  regions,
}: {
  countryCategories: CountryCodeCategory[];
  countryIterator?: (b: (b: Country, a?: Region | null) => void, a?: Regions | null) => void;
  regions?: Regions;
}): DerivedState => {
  // This computes the "parent" states, so in the case of a list of countries, computes the region checkbox states, num picked, etc.
  // In the case of a list of regions, it computes the global checkbox states, num picked, etc.
  const pickedCountryCodes = {};
  const numPicked = {};
  let numTotal = 0;
  const checkboxState = {};

  countryCategories.forEach(category => {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    numPicked[category] = 0;
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    checkboxState[category] = CheckboxState.UNCHECKED;
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    pickedCountryCodes[category] = [];
  });

  countryIterator(country => {
    numTotal++;
    countryCategories.forEach(category => {
      if (defaultCategoryUsedInMultiSelection(countryCategories, category)) {
        const areAllCategoriesSelected = countryCategories.every(allCategory => {
          return (country.picked && country.picked[allCategory]) || allCategory === CountryCodeCategory.DEFAULT;
        });
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        numPicked[category] += areAllCategoriesSelected ? 1 : 0;
      } else {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        numPicked[category] += country.picked && country.picked[category] ? 1 : 0;
      }

      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      if (country.picked && country.picked[category] && Array.isArray(pickedCountryCodes[category])) {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        pickedCountryCodes[category].push(country.code);
      }
    });
  }, regions);

  let areSomeChecked = false;
  countryCategories.forEach(category => {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    if (numPicked[category] === numTotal) {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      checkboxState[category] = CheckboxState.CHECKED;
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      pickedCountryCodes[category] = DEFAULT_ANALYTICS_GEO;
      areSomeChecked = true;
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    } else if (numPicked[category] !== 0) {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      checkboxState[category] = CheckboxState.INDETERMINATE;
      areSomeChecked = true;
    }
  });

  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const areAllChecked = checkboxState[CountryCodeCategory.DEFAULT] === CheckboxState.CHECKED;
  if (areSomeChecked && !areAllChecked) {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    checkboxState[CountryCodeCategory.DEFAULT] = CheckboxState.INDETERMINATE;
  }

  return {
    checkboxState,
    numPicked,
    numTotal,
    pickedCountryCodes,
  } as DerivedState;
};

export const generateCheckboxStateForRow = (country: Country, countryCategories: CountryCodeCategory[]) => {
  const someSelected =
    country.picked &&
    countryCategories.some(category => {
      if (defaultCategoryUsedInMultiSelection(countryCategories, category)) {
        return false;
      }
      return country.picked && country.picked[category];
    });
  let checkboxState = CheckboxState.UNCHECKED;
  if (someSelected) {
    const allSelected =
      country.picked &&
      countryCategories.every(category => {
        return (
          defaultCategoryUsedInMultiSelection(countryCategories, category) ||
          (country.picked && country.picked[category])
        );
      });
    checkboxState = allSelected ? CheckboxState.CHECKED : CheckboxState.INDETERMINATE;
  }
  return checkboxState;
};
