import { get, isEqual } from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import { FormattedDate, FormattedMessage, intlShape } from 'react-intl';
import { Column } from 'react-virtualized';

import * as modalsActions from 'state/modals/actions/modalsActions';
import { getSnapchatEmployee, hasClaimForActivePublisher } from 'state/user/selectors/userSelectors';
import type { User } from 'state/user/userState';

import { plus, search } from 'icons/SDS/allIcons';
import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';
import { computeDeltaTimeUnits, formatRelativeTime } from 'utils/dateUtils';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';
import { ModalType } from 'utils/modalConfig';
import { rolesForPublisher } from 'utils/permissionsUtils';
import * as userManagementUtils from 'utils/userManagementUtils';

import MultiSelectPopover from 'views/common/components/MultiSelectPopover/MultiSelectPopover';
import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';
import SDSInput from 'views/common/components/SDSInput/SDSInput';
import TableWithCustomFilter from 'views/common/components/TableWithCustomFilter/TableWithCustomFilter';
import shareContent from 'views/common/components/UserManagement/role.svg';
import PageHeaderWithButtons from 'views/common/containers/PageHeaderWithButtons/PageHeaderWithButtons';

import style from './UserManagement.scss';

import { Roles, Claim, ResourceRole } from 'types/permissions';
import type { Publisher } from 'types/publishers';

// Controls what users to hide when they only have these roles.
const SINGLE_ROLES_TO_HIDE = [Roles.BUSINESS_ADMIN];
const SYSTEM_USERNAMES = new Set(['happeningnowsystem']);

const mapStateToProps = (state: State) => ({
  isSnapchatEmployee: getSnapchatEmployee(state),
  isUserSuperAdmin: hasClaimForActivePublisher(state, Claim.SUPER_ADMIN_VIEWER),
});
const mapDispatchToProps = {
  showModal: modalsActions.showModal,
};

type UserManagementProps = {
  userList: string[];
  users: {
    [key: string]: User;
  };
  emailDomain: string[];
  rolesTextList: FormattedMessage.MessageDescriptor[];
  snapAdmin?: boolean;
  editUser: (...args: any[]) => any;
  createUser: (...args: any[]) => any;
  deleteUser: (...args: any[]) => any;
  publisher?: Publisher;
  isSnapchatEmployee?: boolean;
  isUserSuperAdmin: boolean;
};
type UserManagementState = any;
export class UserManagement extends React.Component<UserManagementProps, UserManagementState> {
  static contextTypes = {
    intl: intlShape,
  };

  globalSearchButtonNode: any;

  state = {
    query: '',
    selectedEmailDomain: this.props.emailDomain,
    selectedRoles: this.props.rolesTextList,
  };

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (!isEqual(this.props.emailDomain, nextProps.emailDomain)) {
      // this actually have no chance to get called, it's just written in case.
      this.setState({ selectedEmailDomain: nextProps.emailDomain });
    }
    if (!isEqual(this.props.rolesTextList, nextProps.rolesTextList)) {
      // this actually have no chance to get called, it's just written in case.
      this.setState({ selectedRoles: nextProps.rolesTextList });
    }
  }

  onSearchChange = (event: any) => {
    const query = get(event, 'target.value', '');
    this.setState({ query });
  };

  onClearSearch = () => {
    this.setState({ query: '' });
  };

  getRowClassName = ({ index }: any) => {
    if (index < 0) {
      return style.tableHeader;
    }
    return style.tableRow;
  };

  getUserData = (index: any, filterUserList: any) => {
    return this.props.users[filterUserList[index]];
  };

  changeEmailDomainFilter = (newSelectedItems: any) => {
    this.setState({ selectedEmailDomain: newSelectedItems });
  };

  changeRolesFilter = (newSelectedItems: any) => {
    this.setState({ selectedRoles: newSelectedItems });
  };

  filteredQuery = (user: any, queryValue: string) => {
    return user.username.toLowerCase().includes(queryValue) || user.email.toLowerCase().includes(queryValue);
  };

  filteredEmailDomain = (user: any, filteredEmailDomainSet: any) => {
    const { email } = user;
    return filteredEmailDomainSet.has(email.substring(email.lastIndexOf('@')));
  };

  filteredRoles = (user: any, filteredRolesSet: any) => {
    if (SYSTEM_USERNAMES.has(user.snapUsername)) {
      return true;
    }
    const shownRoleList = userManagementUtils.hideRoles(
      user.resourceRoles.filter((role: any) => !SINGLE_ROLES_TO_HIDE.includes(role.roleId) && role.explicit)
    );
    if (shownRoleList.length === 0) {
      return filteredRolesSet.has(userManagementUtils.ROLES_TO_INTL_MESSAGE[Roles.DEFAULT_NO_PERMISSION].id);
    }
    return shownRoleList.some(
      (role: ResourceRole) =>
        role.roleId !== Roles.DEFAULT_NO_PERMISSION &&
        filteredRolesSet.has(get(userManagementUtils.ROLES_FOR_USER_CREATION_TABLE[role.roleId], 'role.id', null))
    );
  };

  filteredSystemUser = (user: any) => {
    const isSystemUser = SYSTEM_USERNAMES.has(user.snapUsername);

    // Do not show system users to non-super admins. This is to prevent publishers interacting with (e.g. removing)
    // system users which will break functionality.
    return !isSystemUser || this.props.isUserSuperAdmin;
  };

  filterSearch = () => {
    const originArray = this.props.userList || [];
    const queryValue = this.state.query.trim().toLowerCase();
    const filteredEmailDomainSet = new Set(this.state.selectedEmailDomain);
    const filteredRolesSet = new Set(this.state.selectedRoles.map(message => message.id));

    return originArray.filter(userId => {
      const user = this.props.users[userId];

      return (
        this.filteredQuery(user, queryValue) &&
        this.filteredEmailDomain(user, filteredEmailDomainSet) &&
        this.filteredRoles(user, filteredRolesSet) &&
        this.filteredSystemUser(user)
      );
    });
  };

  createNewUser = (user: any) => {
    this.props.createUser(user);
  };

  emailHeaderRenderer = () => {
    return (
      <div className={style.headerStyle}>
        <FormattedMessage
          id="email-column-header"
          description="Column header with label Email on user management table"
          defaultMessage="Email"
        />
        <MultiSelectPopover
          /* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */
          selectedItems={this.state.selectedEmailDomain}
          allValues={this.props.emailDomain}
          style={style.popoverHeader}
          id="status"
          onChange={this.changeEmailDomainFilter}
        />
      </div>
    );
  };

  rolesHeaderRenderer = () => {
    const { snapAdmin } = this.props;
    return (
      <div className={style.headerStyle}>
        <FormattedMessage
          id="roles-column-header"
          description="Column header with label Roles on user management table"
          defaultMessage="Roles"
        />
        {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
        <MultiSelectPopover
          selectedItems={this.state.selectedRoles}
          allValues={this.props.rolesTextList}
          style={style.popoverHeader}
          className={snapAdmin && style.widthStyle}
          id="status"
          onChange={this.changeRolesFilter}
        />
      </div>
    );
  };

  snapUsernamesHeaderRenderer = () => {
    return (
      <FormattedMessage
        id="snapUsername-column-header"
        description="Column Header with label Snapchat username on user management table"
        defaultMessage="Snapchat username"
      />
    );
  };

  lastLoggedInHeaderRenderer = () => {
    return (
      <FormattedMessage
        id="lastLoggedIn-column-header"
        description="Column Header with label Last logged in on user management table"
        defaultMessage="Last logged in"
      />
    );
  };

  renderNewUserModal = ({ addEmployeeToSinglePublisher = false }) => () => {
    const options = {
      snapAdmin: this.props.snapAdmin,
      modifyUser: this.props.createUser,
      addEmployeeToSinglePublisher,
    };
    const isAddingEmployee = addEmployeeToSinglePublisher || this.props.snapAdmin;
    (this.props as any).showModal(
      ModalType.NEW_USER,
      `User Management ${isAddingEmployee ? 'Snap Admin' : 'Admin'}`,
      options
    );
  };

  renderEditUserModal = (index: any, filterUserList: any) => {
    const options = {
      snapAdmin: this.props.snapAdmin,
      currentUser: this.getUserData(index, filterUserList),
      modifyUser: this.props.editUser,
      deleteUser: this.props.deleteUser,
    };
    (this.props as any).showModal(
      ModalType.NEW_USER,
      `User Management ${this.props.snapAdmin ? 'Snap Admin' : 'Admin'}`,
      options
    );
  };

  renderRoles = ({ rowData }: any) => {
    const { publisher } = this.props;
    const roles: ResourceRole[] = rowData.resourceRoles;
    const rolesToIterate = publisher ? rolesForPublisher(roles, publisher) : roles;
    const explicitRoles = rolesToIterate.filter(role => role.explicit);
    if (explicitRoles.length === 0) {
      return (
        <div className={style.contentshareContainer}>
          {this.context.intl.formatMessage(userManagementUtils.ROLES_TO_INTL_MESSAGE[Roles.DEFAULT_NO_PERMISSION])}
        </div>
      );
    }
    const prettyPrintRoles = userManagementUtils
      .prettyPrintRoles(explicitRoles)
      .map((role: any) => this.context.intl.formatMessage(role));
    return (
      <div className={style.contentshareContainer}>
        {prettyPrintRoles.join(', ')}
        {explicitRoles.length === 1 && explicitRoles[0]?.roleId === Roles.BUSINESS_ADMIN && (
          <div className={style.imageContainer}>
            <img className={style.contentShareImage} src={shareContent} alt="contentShare" />
          </div>
        )}
      </div>
    );
  };

  renderSnapUsernames = ({ rowData }: any) => {
    return [rowData.snapUsername];
  };

  renderLastLoggedIn = ({ rowData }: any) => {
    if (!rowData.lastLoggedIn) {
      return getMessageFromId('none');
    }
    const lastLoggedIn = new Date(rowData.lastLoggedIn);
    const hms = computeDeltaTimeUnits(lastLoggedIn);
    // If the difference is 2 days or more we display a date string in US format
    if (hms.hours > 47) {
      return <FormattedDate value={lastLoggedIn.getTime()} timeZone={moment.tz.guess()} />;
    }
    return formatRelativeTime(true, hms.hours, hms.minutes ? hms.minutes : 1, 0);
  };

  associateElement = (element: any) => {
    this.globalSearchButtonNode = element;
  };

  renderSearchBox = () => {
    return (
      <div className={style.searchBoxContainer}>
        <SDSInput
          value={this.state.query}
          onChange={this.onSearchChange}
          inlineIcon={search}
          autoFocus
          data-test="common.userManagement.searchBox.input"
        />
      </div>
    );
  };

  renderCreateButton = ({ addEmployeeToSinglePublisher = false }) => {
    const dataTest = addEmployeeToSinglePublisher
      ? 'userManagement.createButton.addEmployeeToSinglePub'
      : 'userManagement.createButton.addUser';
    return (
      <SDSButton
        type={ButtonType.PRIMARY}
        inlineIcon={plus}
        data-test={dataTest}
        onClick={this.renderNewUserModal({ addEmployeeToSinglePublisher })}
      >
        {addEmployeeToSinglePublisher
          ? getMessageFromId('new-snap-employee-modal-title')
          : getMessageFromId('new-user-modal-title')}
      </SDSButton>
    );
  };

  renderPageHeaderRight = () => {
    return (
      <div className={style.rightHeaderClass}>
        {this.renderSearchBox()}
        {this.props.isSnapchatEmployee && !this.props.snapAdmin
          ? this.renderCreateButton({ addEmployeeToSinglePublisher: true })
          : null}
        {this.renderCreateButton({ addEmployeeToSinglePublisher: false })}
      </div>
    );
  };

  renderPageHeader = () => {
    const newHeaderText = (
      <FormattedMessage
        id="user-management-header-title"
        description="User management header title"
        defaultMessage="Users"
      />
    );

    const subHeader = this.props.snapAdmin ? (
      ''
    ) : (
      <FormattedMessage
        id="user-management-subheader"
        description="Currently viewing users of publisher"
        defaultMessage="Currently viewing users for {publisherName}"
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        values={{ publisherName: this.props.publisher.mutablePublisherName }}
      />
    );
    return (
      <PageHeaderWithButtons
        header={newHeaderText}
        subHeader={subHeader}
        rightElements={this.renderPageHeaderRight()}
      />
    );
  };

  rowGetter = ({ index }: any) => this.getUserData(index, this.filterSearch());

  editUserModal = ({ index }: any) => this.renderEditUserModal(index, this.filterSearch());

  render() {
    const filterUserList = this.filterSearch();
    const rowCount = filterUserList.length;
    return (
      <div className={style.root}>
        {this.renderPageHeader()}
        <div className={style.table}>
          <TableWithCustomFilter
            ref="Table"
            rowGetter={this.rowGetter}
            rowCount={rowCount}
            className={(style as any).userTable}
            rowClassName={this.getRowClassName}
            rowHeight={90}
            onRowClick={this.editUserModal}
          >
            <Column
              label={getMessageFromId('name-column-header')}
              disableSort
              dataKey="username"
              className={style.username}
              width={400}
            />
            <Column headerRenderer={this.emailHeaderRenderer} disableSort dataKey="email" width={400} />
            <Column
              headerRenderer={this.rolesHeaderRenderer}
              dataKey="role"
              disableSort
              cellRenderer={this.renderRoles}
              width={400}
            />
            <Column
              headerRenderer={this.snapUsernamesHeaderRenderer}
              dataKey="snapUsername"
              disableSort
              cellRenderer={this.renderSnapUsernames}
              width={400}
            />
            <Column
              headerRenderer={this.lastLoggedInHeaderRenderer}
              dataKey="lastLoggedIn"
              disableSort
              cellRenderer={this.renderLastLoggedIn}
              width={400}
            />
          </TableWithCustomFilter>
        </div>
      </div>
    );
  }
}
export default intlConnect(mapStateToProps, mapDispatchToProps)(UserManagement);
