import React, { Component } from 'react';

import { CheckboxState } from 'config/constants';
import type { CheckboxStateEnum } from 'config/constants';
import {
  defaultCategoryUsedInMultiSelection,
  getCheckboxStates,
  generateCheckboxStateForRow,
} from 'utils/countryUtils';

import PickedCountryCounter from 'views/common/components/CountryPicker/PickedCountryCounter/PickedCountryCounter';
import enforceLocalScroll from 'views/common/components/CountryPicker/enforceLocalScroll';
import ToggleButton from 'views/common/components/ToggleButton/ToggleButton';
import TriStateCheckbox from 'views/common/components/TriStateCheckbox/TriStateCheckbox';

import style from './RegionPicker.scss';

import { CountryCodeCategory } from 'types/countries';
import type { Countries } from 'types/countries';

type Props = {
  onCheckCountry: (d: string, c: string, b: CountryCodeCategory, a: CheckboxStateEnum) => void;
  onCheckRegion: (c: string, b: CountryCodeCategory, a: CheckboxStateEnum) => void;
  onToggle: (b: string, a: boolean) => void;
  name: string;
  isExpanded: boolean;
  countryCategories: CountryCodeCategory[];
  countries: Countries;
};

class RegionPicker extends Component<Props> {
  componentDidMount() {
    document.addEventListener('mousewheel', this.onMousewheel, true);
  }

  componentWillUnmount() {
    window.removeEventListener('mousewheel', this.onMousewheel);
  }

  onMousewheel = (event: Event) => enforceLocalScroll(event, this.refs.countryGroup);

  onToggle = () => {
    this.props.onToggle(this.props.name, this.props.isExpanded);
  };

  onCheckCountry = (countryCode: string, checkboxState: CheckboxStateEnum) => {
    const regionName = this.props.name;
    this.props.onCheckCountry(regionName, countryCode, CountryCodeCategory.DEFAULT, checkboxState);
  };

  onCheckCountryCategory = (
    countryCode: string,
    checkboxState: CheckboxStateEnum,
    category: CountryCodeCategory,
    event: Event
  ) => {
    event.stopPropagation();
    const regionName = this.props.name;
    this.props.onCheckCountry(regionName, countryCode, category, checkboxState);
  };

  onCheckRegion = (event: Event, checkboxState: CheckboxStateEnum) => {
    this.onCheckRegionCategory(CountryCodeCategory.DEFAULT, checkboxState);
  };

  onCheckRegionCategory = (category: CountryCodeCategory, checkboxState: CheckboxStateEnum) => {
    const regionName = this.props.name;
    this.props.onCheckRegion(regionName, category, checkboxState);
  };

  getDerivedState() {
    const { countryCategories } = this.props;
    const { checkboxState, numPicked, numTotal } = getCheckboxStates({
      countryCategories,
      countryIterator: this.iterateCountries,
    });

    return {
      checkboxState,
      numTotal,
      numPicked: numPicked[CountryCodeCategory.DEFAULT],
    };
  }

  iterateCountries = (callback: any) => {
    Object.values(this.props.countries).forEach(callback);
  };

  renderCountries() {
    return (
      <div ref="countryGroup" className={style.countryGroup}>
        {Object.keys(this.props.countries).map(key => {
          const country = this.props.countries[key];
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Country | undefined' is not assi... Remove this comment to see the full error message
          const checkboxState = generateCheckboxStateForRow(country, this.props.countryCategories);
          return (
            <div
              key={key}
              className={style.countryPicker}
              // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
              onClick={state => this.onCheckCountry(country.code, checkboxState)} // eslint-disable-line
              data-test="regionPicker.region.eachCountry"
            >
              <TriStateCheckbox checkboxState={checkboxState} />
              {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
              <div title={country.name} className={style.label}>
                {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
                {country.name}
              </div>
              {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
              {this.props.countryCategories.length > 1 && country.picked
                ? this.props.countryCategories.map(category => {
                    if (defaultCategoryUsedInMultiSelection(this.props.countryCategories, category)) {
                      return null;
                    }
                    const categoryCheckboxState =
                      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                      country.picked && country.picked[category] ? CheckboxState.CHECKED : CheckboxState.UNCHECKED;

                    const onChangeHandler = (event: any) =>
                      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                      this.onCheckCountryCategory(country.code, categoryCheckboxState, category, event);
                    return (
                      <TriStateCheckbox
                        key={category}
                        className={style.sideCheckbox}
                        checkboxState={categoryCheckboxState}
                        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
                        onChange={onChangeHandler} // eslint-disable-line
                      />
                    );
                  })
                : null}
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    const { checkboxState, numPicked, numTotal } = this.getDerivedState();

    return (
      <div className={style.root}>
        <div className={style.regionPicker} data-test="regionPicker.region">
          <TriStateCheckbox
            checkboxState={checkboxState[CountryCodeCategory.DEFAULT]}
            // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
            onChange={this.onCheckRegion}
          />

          <div onClick={this.onToggle} className={style.label}>
            <span title={this.props.name} className={style.name}>
              {this.props.name}
            </span>
            <span className={style.count}>
              <PickedCountryCounter numPicked={numPicked} numTotal={numTotal} />
            </span>
          </div>

          {this.props.countryCategories.length > 1
            ? this.props.countryCategories.map(category => {
                if (defaultCategoryUsedInMultiSelection(this.props.countryCategories, category)) {
                  return null;
                }
                const onChangeHandler = (event: any, newCheckboxState: any) =>
                  this.onCheckRegionCategory(category, newCheckboxState);
                return (
                  <TriStateCheckbox
                    key={category}
                    className={style.sideCheckbox}
                    checkboxState={checkboxState[category]}
                    // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
                    onChange={onChangeHandler} // eslint-disable-line
                  />
                );
              })
            : null}

          <div className={style.expansionToggle}>
            <ToggleButton onClick={this.onToggle} isArrowUp={this.props.isExpanded} />
          </div>
        </div>

        {this.props.isExpanded ? this.renderCountries() : null}
      </div>
    );
  }
}

export default RegionPicker;
