import type { Enum } from '../utils/enum';

import { enumObject } from 'utils/enum';

export const FeedStatusEnum = enumObject({
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  LOADING: 'LOADING',
  FAILED: 'FAILED',
});

export type FeedStatusType = Enum<typeof FeedStatusEnum>;

export enum FeedType {
  RSS = 'RSS',
  ATOM = 'ATOM',
  API = 'API',
}

export enum FeedApiType {
  ASSOCIATED_PRESS = 'ASSOCIATED_PRESS',
  REUTERS_REST = 'REUTERS_REST',
}
