// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
import { Row } from '@snapchat/snapnet'; // discover-cms/no-snapnet
import classNames from 'classnames';
import is from 'is_js';
import _ from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import ReactDOM from 'react-dom';
import { defineMessages, FormattedMessage, intlShape } from 'react-intl';

import * as editorActions from 'state/editor/actions/editorActions';
import * as componentsSelectors from 'state/editor/selectors/componentsSelectors';
import * as editorSelectors from 'state/editor/selectors/editorSelectors';
import * as mediaSelectors from 'state/media/selectors/mediaSelectors';
import { DEFAULT_VIDEO_LIBRARY_SORT_OPTION } from 'state/videoLibrary/actions/videoLibraryActionConfig';
import * as videoLibraryActions from 'state/videoLibrary/actions/videoLibraryActions';
import { SearchOption } from 'state/videoLibrary/reducers/videoLibraryReducers';
import * as videoLibrarySelectors from 'state/videoLibrary/selectors/videoLibrarySelectors';

import { DropzoneType, UploadPurpose } from 'config/constants';
import { State } from 'src/types/rootState';
import { idFromBrightcoveReferenceId } from 'utils/brightcoveUtils';
import { intlConnect } from 'utils/connectUtils';
import * as gaUtils from 'utils/gaUtils';
import { ModalType } from 'utils/modalConfig';

import FileUploadDeleteButton from 'views/common/components/FileUploadDeleteButton/FileUploadDeleteButton';
import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';
import { Spinner, SpinnerLabels, SpinnerSizes } from 'views/common/components/Spinner/Spinner';

import playIcon from './playIcon.svg';
import selectedMark from './selectedMark.svg';

import style from './VideoLibraryModal.scss';

import type { AssetID } from 'types/assets';
import type { ModalId } from 'types/modal';
import { ExtractDispatchProps } from 'types/redux';

const messages = defineMessages({
  placeholder: {
    id: 'longform-video-search-placeholder',
    description: 'longform video search input placeholder',
    defaultMessage: 'Search',
  },
  processing: {
    id: 'longform-video-processing',
    description: 'longform video processing during upload something',
    defaultMessage: 'Processing...',
  },
});
const HEADER = [
  <FormattedMessage
    id="longform-video-header-name"
    description="The header of the longform video Name"
    defaultMessage="NAME"
  />,
  <FormattedMessage
    id="longform-video-header-subtitle"
    description="The header of the longform video subtitle"
    defaultMessage="SUBTITLE"
  />,
  <FormattedMessage
    id="longform-video-header-length"
    description="The header of the longform video length"
    defaultMessage="LENGTH"
  />,
  <FormattedMessage
    id="longform-video-header-date"
    description="The header of the longform video date"
    defaultMessage="DATE"
  />,
  <span />,
];
export const mapStateToProps = (state: State) => {
  const searchResults = videoLibrarySelectors.getCurrentSearchResults(state);
  const activeComponent = componentsSelectors.getActiveComponent(state);
  const activeComponentId = activeComponent ? (activeComponent as any).componentId : null;
  const activeSubtitleUploadCount = mediaSelectors.getActiveUploadCountsForComponentIdByPurpose(state)(
    activeComponentId,
    UploadPurpose.SUBTITLE
  );
  const activeSnapId = activeComponent ? (activeComponent as any).snap.id : null;
  const activeEditionId = editorSelectors.getActiveEditionId(state);
  const getSubtitlesAreProcessing = (id: any) => videoLibrarySelectors.subtitleProcessingStatusByVideoId(state)(id);
  const getSubtitleInformation = (id: any) => videoLibrarySelectors.getVideoSubtitlesByVideoId(state)(id);
  return {
    searchResults,
    activeComponent,
    activeSnapId,
    activeEditionId,
    getSubtitleInformation,
    activeSubtitleUploadCount,
    getSubtitlesAreProcessing,
  };
};
const mapDispatchToProps = {
  deleteVideo: videoLibraryActions.deleteVideo,
  deleteVideoSubtitle: videoLibraryActions.deleteVideoSubtitle,
  flagVideoSubtitleProcessing: videoLibraryActions.flagVideoSubtitleProcessing,
  pollUntilVideoHasSubtitles: videoLibraryActions.pollUntilVideoHasSubtitles,
  setCurrentSearchAndFetch: videoLibraryActions.setCurrentSearchAndFetch,
  fetchVideoInformation: videoLibraryActions.fetchVideoInformation,
  setEditorConfigProperties: editorActions.setEditorConfigProperties,
};

type VideoPayload = {
  id: AssetID;
  images: {
    poster: {
      src: string;
    };
  };
};

type OwnProps = {
  pendingVideoAssetId: AssetID;
  mediaPerPage: number;
  minPages: number;
  modalId: ModalId;
  showModal: (
    modalType: ModalType,
    videoLibraryModal: string,
    options: {
      assetId: AssetID;
    }
  ) => void;
  setModalConfigProperties: (
    modalId: ModalId,
    options: {
      pendingVideoAssetId: AssetID;
      videoPayload: VideoPayload;
    }
  ) => void;
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;
type Props = OwnProps & StateProps & DispatchProps;

type VideoLibraryModalState = {
  currentPageCursor: string;
  currentPageNumber: number;
};

type PageConfig = {
  currentPage: number;
  maxPage: number;
  offset?: string;
};

type MediaResult = {
  payload: {
    offset: string;
  };
};

export class VideoLibraryModal extends React.Component<Props, VideoLibraryModalState> {
  static contextTypes = {
    intl: intlShape,
  };

  static defaultProps = {
    minPages: 1,
    mediaPerPage: 10,
  };

  state = {
    currentPageCursor: '',
    currentPageNumber: 1,
  };

  element: any;

  async UNSAFE_componentWillMount() {
    const searchOptions = this.generateSearchTerms('');
    await this.fetchMediaData(searchOptions);
  }

  onSearch = _.debounce(searchTerm => {
    const searchOptions = this.generateSearchTerms(searchTerm);
    (this.props as any).setCurrentSearchAndFetch(searchOptions);
  }, 400);

  onSubtitleDelete = (assetId: any) => () => {
    gaUtils.logGAEvent(gaUtils.GAUserActions.MEDIA, 'video-delete-subtitle');
    return (this.props as any)
      .deleteVideoSubtitle({ assetId })
      .then(() => (this.props as any).fetchVideoInformation({ assetId }));
  };

  onSubtitleUploadComplete = (assetId: any) => () => {
    // After a subtitle field is uploaded, there's a short processing time on the
    // Brightcove side before the subtitle track starts appearing in the response.
    // To account for this we mark the video as processing and then periodically
    // reload the video data until the subtitles start coming through.
    gaUtils.logGAEvent(gaUtils.GAQoSMetrics.MEDIA, 'subtitle-upload-complete');
    (this.props as any).flagVideoSubtitleProcessing({ assetId });
    return (this.props as any).pollUntilVideoHasSubtitles({ assetId });
  };

  getPageConfig(): PageConfig | null {
    if (this.props.searchResults.loading) {
      return null;
    }
    const { searchResults } = this.props;
    const { params } = searchResults;

    // When we are reading data from dynamo tables
    // the pagination format changes to 1-of-many
    const lastItemRead = JSON.stringify(params.offset);
    const currentPage = this.state.currentPageNumber;
    return {
      currentPage,
      maxPage: this.state.currentPageNumber,
      offset: lastItemRead,
    };
  }

  /* eslint-disable react/sort-comp */
  timeZone = moment.tz.guess();

  /* eslint-enable react/sort-comp */
  logSearchEvent = () => {
    gaUtils.logGAEvent(gaUtils.GAUserActions.MEDIA, 'modal-update-search-param');
  };

  selectVideo = (assetId: any, videoPayload: any) => () => {
    gaUtils.logGAEvent(gaUtils.GAUserActions.MEDIA, 'modal-select-video');
    this.props.setModalConfigProperties(this.props.modalId, {
      pendingVideoAssetId: assetId,
      videoPayload,
    });
  };

  generateSearchTerms = (searchTerm: string, offset = '') => {
    const limit = this.props.mediaPerPage;
    return {
      searchTerm,
      offset,
      sort: DEFAULT_VIDEO_LIBRARY_SORT_OPTION,
      limit,
    };
  };

  changePage = (nextPageNumber: number, nextPageCursor: string) => async () => {
    gaUtils.logGAEvent(gaUtils.GAUserActions.MEDIA, 'modal-change-page');
    const searchOptions = { ...this.props!.searchResults!.params };
    this.setState({
      currentPageNumber: nextPageNumber,
    });
    searchOptions.offset = nextPageCursor;
    searchOptions.keepExistingMedia = true;
    await this.fetchMediaData(searchOptions);
  };

  private async fetchMediaData(searchOptions: SearchOption) {
    const response: Array<MediaResult> = await (this.props as any).setCurrentSearchAndFetch(searchOptions);
    const videoCountResult = response?.lastItem;
    if (videoCountResult) {
      const nextPageCursor = videoCountResult.payload?.offset;
      this.setState({
        currentPageCursor: nextPageCursor,
      });
    }
  }

  renderRow = (row: any, key: any, headerRow: any, assetId: any, videoPayload: any) => {
    const isSelected = assetId && assetId === this.props.pendingVideoAssetId;
    // posterSrc exist means ready to inject, else should not allow to select
    const posterSrc = _.get(videoPayload, ['images', 'poster', 'src'], null);
    const rowClass = classNames({
      [style.headerRow]: headerRow,
      [style.row]: !headerRow,
      [style.selectedRow]: isSelected,
      [style.nonSelectRow]: !headerRow && !isSelected && posterSrc,
    });
    return (
      <Row
        className={rowClass}
        key={key}
        onClick={!isSelected && posterSrc ? this.selectVideo(assetId, videoPayload) : null}
      >
        {row.map((eachCol: any, index: any) => {
          const colKey = `${assetId}-${index}`;
          if (index === 0) {
            return (
              <div className={style.col} key={colKey}>
                {eachCol}
              </div>
            );
          }
          return (
            <div className={style.col} key={colKey}>
              <div className={style.centerChild}>{eachCol}</div>
            </div>
          );
        })}
      </Row>
    );
  };

  renderPageButton(delta: number, icon: any, isReverse = false, canNavigate: boolean = false) {
    const pageConfig = this.getPageConfig();
    if (pageConfig === null) {
      return null;
    }
    const nextPageNumber = this.state.currentPageNumber + delta;
    const disabled = nextPageNumber > pageConfig.maxPage || nextPageNumber < this.props.minPages;
    const nextPageCursor = this.state.currentPageCursor;

    const ButtonClass = classNames({
      [style.buttonRoot]: true,
      [style.readOnly]: disabled,
    });
    const ButtonOpacityClass = classNames({
      [style.buttonOpacity]: disabled,
    });
    const ButtonImageClass = classNames({
      [style.reverseButton]: isReverse,
    });
    return (
      // @ts-expect-error ts-migrate(2322) FIXME: Type '(() => void) | null' is not assignable to ty... Remove this comment to see the full error message
      <div className={ButtonClass} onClick={!disabled ? this.changePage(nextPageNumber, nextPageCursor) : null}>
        <div className={style.buttonContainer}>
          <div className={ButtonOpacityClass}>
            <img className={ButtonImageClass} src={icon} alt="icon" />
          </div>
        </div>
      </div>
    );
  }

  renderLoadMoreButton(canNavigate: boolean = false) {
    const nextPageNumber = this.state.currentPageNumber + 1;
    const nextPageCursor = this.state.currentPageCursor;
    return (
      <SDSButton
        data-test={'load-more'}
        type={ButtonType.PRIMARY}
        onClick={this.changePage(nextPageNumber, nextPageCursor)}
        disabled={!canNavigate}
      >
        <FormattedMessage id="load-more" description="Allows user load more media items" defaultMessage="Load More" />
      </SDSButton>
    );
  }

  renderPageNum() {
    const pageConfig = this.getPageConfig();
    if (pageConfig === null) {
      return null;
    }
    return (
      <span className={style.pageNumberText}>
        <FormattedMessage
          id="page-number"
          description="Informs the user of the current page number and the maximum page number"
          defaultMessage="Page {currentPage}/{maxPage}"
          values={{ currentPage: this.state.currentPageNumber, maxPage: pageConfig.maxPage }}
        />
      </span>
    );
  }

  onPreviewClick = (assetId: any) => (event: any) => {
    event.stopPropagation();
    gaUtils.logGAEvent(gaUtils.GAUserActions.MEDIA, 'modal-play-video');
    this.props.showModal(ModalType.PLAY_VIDEO, VideoLibraryModal.name, {
      assetId,
    });
  };

  renderPreview = ({ posterSrc, alt }: any, result: any, assetId: any) => {
    const { pendingVideoAssetId } = this.props;
    const centerIcon =
      pendingVideoAssetId && pendingVideoAssetId === assetId ? (
        <img src={selectedMark} alt="selected icon" />
      ) : (
        <img src={playIcon} alt="play icon" />
      );
    const previewContent = posterSrc ? (
      <div className={classNames(style.previewContainer, style.canPlay)} onClick={this.onPreviewClick(assetId)}>
        <img src={posterSrc} alt={alt} />
        <div className={style.videoPlayButton}>{centerIcon}</div>
      </div>
    ) : (
      <div className={classNames(style.previewContainer, style.blankView)}>
        <Spinner loading noOffset size={SpinnerSizes.MEDIUM} />
      </div>
    );
    const previewName = (
      <div className={style.previewDetail}>
        <div className={style.detailContainer}>
          <div className={style.previewName}>{result.name}</div>
          <div className={style.previewId}>({assetId})</div>
        </div>
      </div>
    );
    return (
      <div className={style.previewRoot}>
        {previewContent}
        {previewName}
      </div>
    );
  };

  renderSubtitle = (assetId: any) => {
    if (this.props.getSubtitlesAreProcessing(assetId)) {
      return <span className={style.processStyle}>{this.context.intl.formatMessage(messages.processing)}</span>;
    }
    return (
      <FileUploadDeleteButton
        key={assetId}
        snapId={this.props.activeSnapId}
        editionId={this.props.activeEditionId}
        onDelete={this.onSubtitleDelete(assetId)}
        purpose={UploadPurpose.SUBTITLE}
        dropzoneType={DropzoneType.SUBTITLE}
        assetId={assetId}
        data-test="videoModal"
        noBorder
        onUploadComplete={this.onSubtitleUploadComplete(assetId)}
        fileInfo={this.props.getSubtitleInformation(assetId)}
        subtitlesLanguage="en-US"
      />
    );
  };

  renderLength = (duration: any) => {
    return (
      <div className={style.dateClass}>
        {duration ? moment.utc(duration).format('mm:ss') : this.context.intl.formatMessage(messages.processing)}
      </div>
    );
  };

  renderDate = (date: any) => {
    return <div className={style.dateClass}>{moment(date).tz(this.timeZone).format('MMM DD, YYYY')}</div>;
  };

  renderResults() {
    const { results, endpoint, loading } = this.props.searchResults;
    if (is.array(results) && !results.length && !loading) {
      return (
        <div className={style.searchEmpty}>
          <FormattedMessage
            id="no-results-found-longform"
            description="Displayed when user searches for something and nothing is found"
            defaultMessage="No Results Found"
          />
        </div>
      );
    }
    if (!results) {
      return (
        <Spinner className={style.loadingCenter} loading message={SpinnerLabels.LOADING} size={SpinnerSizes.MEDIUM} />
      );
    }
    return results.map((result: any, index: any) => {
      const key = `${endpoint}-${index}`;
      const posterSrc = _.get(result, ['images', 'poster', 'src'], null);
      const alt = _.get(result, ['name']);
      const date = _.get(result, ['createdAt']);
      const duration = _.get(result, ['duration']);
      const assetId = idFromBrightcoveReferenceId(_.get(result, 'referenceId'));
      const videoPayload = {
        id: assetId,
        images: {
          poster: {
            src: posterSrc,
          },
        },
      };
      return this.renderRow(
        [
          this.renderPreview({ posterSrc, alt }, result, assetId),
          this.renderSubtitle(assetId),
          this.renderLength(duration),
          this.renderDate(date),
        ],
        key,
        false,
        assetId,
        videoPayload
      );
    });
  }

  onChangeHandler = (event: any) => {
    const { currentTarget } = event;
    const searchTerm = currentTarget.value;
    return this.onSearch(searchTerm);
  };

  onFormElementRef = (element: any) => {
    this.element = ReactDOM.findDOMNode(element);
  };

  render() {
    return (
      <div>
        {this.renderRow(HEADER, 0, true, null, null)}
        <div className={style.results}>{this.renderResults()}</div>
        {this.renderPagination()}
      </div>
    );
  }

  renderPagination() {
    const canNavigateForward = this.canNavigate(this.state.currentPageCursor);
    return <div className={style.pageNumber}>{this.renderLoadMoreButton(canNavigateForward)}</div>;
  }

  canNavigate(pageCursor: string) {
    return pageCursor?.length !== 0;
  }
}
export default intlConnect(mapStateToProps, mapDispatchToProps)(VideoLibraryModal);
