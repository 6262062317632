import Select from 'antd/lib/select';
import classNames from 'classnames';
import { memoize, sortBy } from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import * as organisationsActions from 'state/organisations/actions/organisationsActions';
import * as organisationsSelectors from 'state/organisations/selectors/organisationsSelectors';
import { getPublishersUncached } from 'state/publishers/actions/publishersActions';
import * as publishersSelectors from 'state/publishers/selectors/publishersSelectors';
import * as routerActions from 'state/router/actions/routerActions';
import * as snapAdminActions from 'state/snapAdmin/actions/snapAdminActions';
import { userId } from 'state/user/selectors/userSelectors';
import type { UserId } from 'state/user/userState';

import { check, cross, warningTriangle } from 'icons/SDS/allIcons';
import { intlConnect } from 'utils/connectUtils';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';
import {
  GeneratedPublisherType,
  generatePublisherDetails,
  PUBLISHER_DEFAULTS,
  SHOW_PUBLISHER_DEFAULTS,
} from 'utils/managePublisherUtils';

import Icon from 'views/common/components/Icon/Icon';
import SDSButton, { ButtonType, ButtonShape } from 'views/common/components/SDSButton/SDSButton';
import SDSDropdown, { DropdownSize, DropdownType } from 'views/common/components/SDSDropdown/SDSDropdown';
import SDSInput from 'views/common/components/SDSInput/SDSInput';
import SDSLabel from 'views/common/components/SDSLabel/SDSLabel';
import SDSPanel from 'views/common/components/SDSPanel/SDSPanel';
import SpinnerIcon from 'views/common/components/SpinnerIcon/SpinnerIcon';
import PageHeaderWithButtons from 'views/common/containers/PageHeaderWithButtons/PageHeaderWithButtons';
import PublisherDetailOption from 'views/onboarding/components/PublisherDetailOptionRow/PublisherDetailOptionRow';
import {
  BUTTONS_DEFINITION,
  DROPDOWNS_DEFINITION,
} from 'views/onboarding/containers/SettingsView/tabs/SuperAdminTab/superAdminSettingsConfig';
import SettingDropdownForm from 'views/snapAdmin/components/SettingDropdownForm/SettingDropdownForm';

import style from './NewPublisherPage.scss';

import type { Org } from 'types/organisations';
import { PublisherID, TierLevel } from 'types/publishers';
import { ExtractDispatchProps } from 'types/redux';
import type { State as BaseState } from 'types/rootState';

type StateProps = {
  orgsList: Org[];
  activePublisherId: PublisherID | undefined | null;
  userId: UserId;
};
type State = {
  name: string;
  publisherType: string;
  adminName?: string;
  adminEmail?: string;
  location?: string;
  tier: TierLevel;
  existingOrg: boolean;
  orgId: string | null;
  orgName: string | null;
  test: boolean;
  currentOrgQuery: string | undefined | null;
  isSaving: boolean;
};
const TEST_DROPDOWN_OPTIONS = BUTTONS_DEFINITION.test.buttons.map(button => ({
  value: button.key,
  label: button.text,
}));
const ORG_OPTIONS = [
  {
    key: true,
    text: (
      <FormattedMessage
        id="create-publisher-org-existing"
        description="Option to create publisher with an existing org"
        defaultMessage="Existing"
      />
    ),
  },
  {
    key: false,
    text: (
      <FormattedMessage
        id="create-publisher-org-new"
        description="Option to create publisher with a new org"
        defaultMessage="New Organisation"
      />
    ),
  },
];

export const mapStateToProps = (state: BaseState): StateProps => {
  const orgsList = sortBy(organisationsSelectors.getOrgsList(state), ['orgName', 'orgId']);
  return {
    orgsList,
    activePublisherId: publishersSelectors.getActivePublisherId(state),
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'string'.
    userId: userId(state),
  };
};
const mapDispatchToProps = {
  loadAllOrgs: organisationsActions.loadAllOrgs,
  goToSettings: routerActions.goToSettings,
  goToHomepage: routerActions.goToHomepage,
  createNewPublisher: snapAdminActions.createNewPublisher,
  updatePublishersList: getPublishersUncached,
};
type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;
type Props = DispatchProps & StateProps;
export class NewPublisherPage extends React.Component<Props, State> {
  state = {
    publisherType: GeneratedPublisherType.PUBLISHER,
    tier: TierLevel.PREMIUM,
    test: true,
    isSaving: false,
    currentOrgQuery: null,
    name: '',
    existingOrg: true,
    orgId: null,
    orgName: null,
  };

  componentDidMount() {
    this.props.loadAllOrgs();
  }

  isFormComplete = () => {
    const isOrgComplete = this.state.existingOrg ? Boolean(this.state.orgId) : Boolean(this.state.orgName);
    return (
      this.state.name &&
      this.state.publisherType /* this.state.adminName && this.state.adminEmail && this.state.location && */ &&
      this.state.tier &&
      isOrgComplete &&
      this.state.test != null
    );
  };

  handleOnChange = memoize(key => (value: any) => {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ [x: number]: any; }' is not as... Remove this comment to see the full error message
    this.setState({ [key]: value });
  });

  handleDropdownOnChange = memoize(key => (value: string) => {
    return this.handleOnChange(key)(value);
  });

  handleInputOnChange = memoize(key => (event: any) => {
    const {
      target: { value },
    } = event;
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ [x: number]: any; }' is not as... Remove this comment to see the full error message
    this.setState({ [key]: value });
  });

  handleCreatePublisher = () => {
    if (this.isFormComplete()) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'name' does not exist on type '{}'.
      const { name, type, isOurStories } = generatePublisherDetails(this.state);
      const { tier, orgName, orgId, existingOrg } = this.state;
      const isShow = tier === TierLevel.SHOW;

      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      const trimmedOrgName = !existingOrg && orgName ? orgName.trim() : null;

      let newPublisher = {
        name,
        type,
        isOurStories,
        tier,
        orgId: existingOrg ? orgId : null,
        orgName: !existingOrg ? trimmedOrgName : null,
        ...PUBLISHER_DEFAULTS,
      };
      if (isShow) {
        newPublisher = {
          ...newPublisher,
          ...SHOW_PUBLISHER_DEFAULTS,
        };
      }
      this.setState({ isSaving: true });
      this.props
        .createNewPublisher(newPublisher)
        .then((data: any) => {
          this.setState({ isSaving: false });
          const publisherId = Object.keys(data.payload.entities.publishers)[0];
          this.props.updatePublishersList(this.props.userId, true);
          this.props.goToSettings(publisherId);
        })
        .catch(() => {
          this.setState({ isSaving: false });
        });
    }
  };

  handleClose = () => {
    if (this.props.activePublisherId) {
      this.props.goToHomepage({ overwriteHistory: true, publisherId: this.props.activePublisherId });
    }
  };

  renderSideButtons = () => {
    return (
      <div className={style.sideButtons}>
        {this.state.isSaving ? <SpinnerIcon className={style.savingSpinner} /> : null}
        <SDSButton
          type={ButtonType.PRIMARY}
          onClick={this.handleCreatePublisher}
          inlineIcon={check}
          disabled={!this.isFormComplete() || this.state.isSaving}
          data-test="NewPublisherPage.createPublisherButton"
        >
          {getMessageFromId('new-publisher-create')}
        </SDSButton>
        <SDSButton
          type={ButtonType.SECONDARY}
          inlineIcon={cross}
          shape={ButtonShape.CIRCLE}
          onClick={this.handleClose}
          data-test="NewPublisherPage.closePublisherButton"
        />
      </div>
    );
  };

  renderOrgDropdown = () => {
    return this.props.orgsList.length < 1 ? (
      <div className={style.textInput} data-test="NewPublisherPage.new-publisher.orgSpinner">
        <SDSLabel labelTitle={getMessageFromId('new-publisher-org')}>
          <SpinnerIcon className={style.orgLoading} />
        </SDSLabel>
      </div>
    ) : (
      <div className={classNames(style.inputContainer, style.formInputContainer)}>
        <SDSLabel labelTitle={getMessageFromId('new-publisher-org')}>
          <SDSDropdown
            disableClear
            onChange={this.handleDropdownOnChange('orgId')}
            value={this.state.orgId || undefined}
            size={DropdownSize.MEDIUM}
            type={DropdownType.GREY}
            data-test="NewPublisherPage.new-publisher.orgId"
          >
            {this.props.orgsList.map(org => (
              <Select.Option
                key={`SDSDropdownOption.${org.orgId}`}
                value={org.orgId}
                data-test={`SDSDropdown.selectOptions.${org.orgId}`}
              >
                <div>{org.orgName}</div>
                <div className={style.orgDropdownId}>{org.orgId}</div>
              </Select.Option>
            ))}
          </SDSDropdown>
        </SDSLabel>
      </div>
    );
  };

  renderOrgInput = () => {
    return (
      <div className={classNames(style.textInput, style.inputContainer, style.formInputContainer)}>
        <SDSInput
          labelTitle={
            <FormattedMessage
              id="create-publisher-org-name"
              description="New organisation name"
              defaultMessage="Organisation"
            />
          }
          value={this.state.orgName || undefined}
          onChange={this.handleInputOnChange('orgName')}
          maxLength={50}
          data-test="NewPublisherPage.new-publisher.orgName"
        />
      </div>
    );
  };

  renderOrgSection = () => {
    return (
      <>
        <PublisherDetailOption
          title={
            <FormattedMessage
              id="create-publisher-org-title"
              description="Select to create a publisher with an existing or a new org"
              defaultMessage="Use existing organisation or create a new one"
            />
          }
          selectedKey={this.state.existingOrg}
          onKeySelected={this.handleOnChange('existingOrg')}
          buttons={ORG_OPTIONS}
          data-test="NewPublisherPage.new-publisher.existingOrg"
        />
        {this.state.existingOrg ? this.renderOrgDropdown() : this.renderOrgInput()}
      </>
    );
  };

  render() {
    const { orgId, orgName, existingOrg } = this.state;
    let warningMessage = null;
    if (existingOrg && orgId) {
      warningMessage = getMessageFromId('new-org-warning-existing-org');
    } else if (!existingOrg && orgName) {
      warningMessage = getMessageFromId('new-org-warning-new-org');
    }
    return (
      <div className={(style as any).container}>
        <PageHeaderWithButtons
          header={getMessageFromId('new-publisher-header')}
          rightElements={this.renderSideButtons()}
        />
        <SDSPanel className={classNames(style.orgWarning, { [style.orgWarningShow]: !!warningMessage })}>
          <div className={style.warningContents}>
            <Icon inlineIcon={warningTriangle} className={style.warningIcon} />
            {warningMessage}
          </div>
        </SDSPanel>
        <SDSPanel header={getMessageFromId('new-publisher-details-header')} className={style.settingsCard}>
          <SDSPanel.Row>
            <SDSPanel.Column>
              <div className={style.textInput}>
                <SDSInput
                  labelTitle={getMessageFromId('publisher-details-publication-name')}
                  onChange={this.handleInputOnChange('name')}
                  maxLength={50}
                  data-test="NewPublisherPage.new-publisher.name"
                />
              </div>
              <SettingDropdownForm
                title={DROPDOWNS_DEFINITION.publisherType.title}
                currentOption={this.state.publisherType}
                enabledOptions={DROPDOWNS_DEFINITION.publisherType.buttons}
                onChange={this.handleDropdownOnChange('publisherType')}
                data-test="NewPublisherPage.new-publisher.type"
              />
              <SettingDropdownForm
                title={DROPDOWNS_DEFINITION.tier.title}
                currentOption={this.state.tier}
                enabledOptions={DROPDOWNS_DEFINITION.tier.buttons}
                onChange={this.handleDropdownOnChange('tier')}
                data-test="NewPublisherPage.new-publisher.tier"
              />
            </SDSPanel.Column>
            <SDSPanel.Column>
              {this.renderOrgSection()}
              <SettingDropdownForm
                title={BUTTONS_DEFINITION.test.title}
                currentOption={this.state.test}
                enabledOptions={TEST_DROPDOWN_OPTIONS}
                onChange={this.handleDropdownOnChange('test')}
                data-test="NewPublisherPage.new-publisher.test"
              />
            </SDSPanel.Column>
          </SDSPanel.Row>
        </SDSPanel>
      </div>
    );
  }
}
export default intlConnect(mapStateToProps, mapDispatchToProps)(NewPublisherPage);
