// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
import { Popover, Overlay } from '@snapchat/snapnet'; // discover-cms/no-snapnet
import classNames from 'classnames';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'glob... Remove this comment to see the full error message
import global from 'global';
import _ from 'lodash';
import React from 'react';

import { updateIfPropsAndStateChanged } from 'views/propTypes/utils';

type PopoverWrapperProps = {
  id?: string;
  className?: string;
  children?: React.ReactNode | React.ReactNode[];
  arrowOffsetLeftOverride?: string;
  classNameOverride?: string;
};
function PopoverWrapper(props: PopoverWrapperProps) {
  const childProps = _.omit(props, ['className', 'children', 'arrowOffsetLeftOverride', 'classNameOverride']);
  return (
    // When used with OverlayTrigger, the value of Popover.arrowOffset is injected by the Position component
    // To adjust it, we need to intercept the calculated offsets and override the value.
    <Popover
      {...childProps}
      arrowOffsetLeft={props.arrowOffsetLeftOverride}
      className={classNames(props.classNameOverride, props.className)}
      id={props.id}
    >
      {props.children}
    </Popover>
  );
}
type OwnPopoverCloseOnMovementProps = {
  id?: string;
  target?: React.ReactElement;
  arrowOffsetLeft?: string;
  className?: string;
  onHide?: (...args: any[]) => any;
  onShow?: (...args: any[]) => any;
  closeOnVerticalMovement?: boolean;
  closeOnHorizontalMovement?: boolean;
  closeOnResize?: boolean;
  container?: (...args: any[]) => any;
  'data-test'?: string;
  placement?: string;
  isHoverPopover?: boolean;
  animation?: boolean;
  rootClassName?: string;
};
type PopoverCloseOnMovementState = any;
type PopoverCloseOnMovementProps = OwnPopoverCloseOnMovementProps & typeof PopoverCloseOnMovement.defaultProps;
export class PopoverCloseOnMovement extends React.Component<PopoverCloseOnMovementProps, PopoverCloseOnMovementState> {
  static defaultProps = {
    onHide: () => {},
    onShow: () => {},
    closeOnVerticalMovement: true,
    closeOnHorizontalMovement: true,
    closeOnResize: true,
    container: () => null,
    placement: 'bottom',
    isHoverPopover: false,
    animation: true,
  };

  hadFirstScroll: any;

  hasListeners: any;

  lastScrollLeft: any;

  lastScrollTop: any;

  targetElement: any;

  state = {
    show: false,
  };

  shouldComponentUpdate(nextProps: PopoverCloseOnMovementProps, nextState: PopoverCloseOnMovementState) {
    return updateIfPropsAndStateChanged(this.props, this.state, nextProps, nextState);
  }

  componentWillUnmount() {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
    this.hidePopover();
  }

  onResize = () => {
    if (this.props.closeOnResize) {
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
      this.hidePopover();
    }
  };

  onScroll = (e: any) => {
    // If we can't detect direction assume the worst and close the popup
    if (!_.get(e, ['target'])) {
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
      this.hidePopover();
      return;
    }
    if (this.hadFirstScroll) {
      const verticalDelta = e.target.scrollTop - this.lastScrollTop;
      const horizontalDelta = e.target.scrollLeft - this.lastScrollLeft;
      if (this.props.closeOnVerticalMovement && verticalDelta !== 0) {
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
        this.hidePopover();
      } else if (this.props.closeOnHorizontalMovement && horizontalDelta !== 0) {
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
        this.hidePopover();
      }
      return;
    }
    this.hadFirstScroll = true;
    this.lastScrollTop = e.target.scrollTop;
    this.lastScrollLeft = e.target.scrollLeft;
  };

  togglePopover = (event: any) => {
    event.stopPropagation();
    if (this.state.show) {
      this.props.onHide();
    } else {
      this.props.onShow();
    }
    this.setState({ show: !this.state.show });
  };

  showPopover = () => {
    if (!this.state.show) {
      this.props.onShow();
      this.setState({ show: true });
    }
  };

  hidePopover = (event: any) => {
    if (this.hasListeners) {
      global.window.removeEventListener('scroll', this.onScroll, true);
      global.window.removeEventListener('resize', this.onResize, true);
      this.hasListeners = false;
    }
    if (this.state.show) {
      this.props.onHide();
      this.setState({ show: false });
    }
    if (event) {
      // Prevent click event from propagating to views behind the child
      event.stopPropagation();
    }
  };

  render() {
    if (this.state.show && !this.hasListeners) {
      this.hadFirstScroll = false;
      global.window.addEventListener('scroll', this.onScroll, {
        capture: true,
      });
      global.window.addEventListener('resize', this.onResize, {
        once: true,
        capture: true,
      });
      this.hasListeners = true;
    }
    const triggerOptions = {};
    if (this.props.isHoverPopover) {
      (triggerOptions as any).onMouseEnter = this.showPopover;
      (triggerOptions as any).onMouseOver = this.showPopover;
      (triggerOptions as any).onMouseLeave = this.hidePopover;
    } else {
      (triggerOptions as any).onClick = this.togglePopover;
    }
    // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
    const target = React.cloneElement(this.props.target, {
      ref: (element: any) => {
        this.targetElement = element;
      },
      ...triggerOptions,
    });
    return (
      <div className={this.props.rootClassName}>
        {target}
        <Overlay
          placement={this.props.placement}
          show={this.state.show}
          target={this.targetElement}
          onHide={this.hidePopover}
          rootClose
          animation={this.props.animation}
          container={this.props.container}
        >
          <PopoverWrapper
            /* @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: ReactNode; onClick: (event: any)... Remove this comment to see the full error message */
            onClick={this.hidePopover}
            classNameOverride={this.props.className}
            arrowOffsetLeftOverride={this.props.arrowOffsetLeft}
            id={this.props.id}
            data-test={this.props['data-test']}
          >
            {this.props.children}
          </PopoverWrapper>
        </Overlay>
      </div>
    );
  }
}
export default PopoverCloseOnMovement;
