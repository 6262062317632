import type { MediaItem } from 'types/mediaLibrary';

export type MediaLibrarySourceItems = {
  mediaLibraryItems: MediaItem[];
};

export const DragAndDropTypes = {
  MEDIA_LIBRARY_ITEM: 'MEDIA_LIBRARY_ITEM',
  COMMUNITY_SNAP: 'COMMUNITY_SNAP',
};
