import Slider, { SliderMarks } from 'antd/lib/slider';
import classNames from 'classnames';
import React from 'react';

import style from './SDSSlider.scss';

export type Range = {
  start: number;
  end: number;
};

type Props = {
  marks?: SliderMarks;
  step: number | null;
  min: number;
  max: number;
  range: boolean;
  onAfterChange?: (value: number) => void; // fires on mouse up (letting go of handle),
  onChange?: (value: number) => void; // fires when value changes (moving handle),
  onAfterRangeChange?: (value: [number, number]) => void;
  onRangeChange?: (value: [number, number]) => void;
  tipFormatter?: ((value: number) => string) | null;
  defaultValue?: number;
  defaultRange?: Range;
  noBackground?: boolean;
  value?: number | [number, number];
};

export class SDSSlider extends React.PureComponent<Props> {
  onChange = (value: number | [number, number]) => {
    if (this.props.onChange && !this.props.range) {
      this.props.onChange(value as number);
    } else if (this.props.onRangeChange && this.props.range) {
      this.props.onRangeChange(value as [number, number]);
    }
  };

  onAfterChange = (value: number | [number, number]) => {
    if (this.props.onAfterChange && !this.props.range) {
      this.props.onAfterChange(value as number);
    } else if (this.props.onAfterRangeChange && this.props.range) {
      this.props.onAfterRangeChange(value as [number, number]);
    }
  };

  render() {
    let defaultValue: number | [number, number] | undefined;

    if (this.props.defaultValue) {
      defaultValue = this.props.defaultValue;
    } else if (this.props.defaultRange) {
      defaultValue = [this.props.defaultRange.start, this.props.defaultRange.end];
    }

    const containerStyle = classNames(style.sliderContainer, {
      [style.noBackground]: this.props.noBackground,
    });

    const base = {
      marks: this.props.marks,
      range: this.props.range,
      step: this.props.step,
      min: this.props.min,
      max: this.props.max,
      defaultValue,
      onAfterChange: this.onAfterChange,
      onChange: this.onChange,
      tipFormatter: this.props.tipFormatter,
    };

    const uncontrolled = <Slider {...base} />;

    const controlled = <Slider value={this.props.value} {...base} />;

    return <div className={containerStyle}>{this.props.value ? controlled : uncontrolled}</div>;
  }
}

export default SDSSlider;
