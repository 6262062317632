import Modal from 'antd/lib/modal';
import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { reloadSnaps } from 'state/editions/actions/editionsActions';
import * as editionsSelectors from 'state/editions/selectors/editionsSelectors';
import { getActivePublisherDetails } from 'state/publishers/selectors/publishersSelectors';
import { getAllSnaps } from 'state/snaps/selectors/snapsSelectors';

import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';
import { GrafanaMetrics } from 'utils/grafanaUtils';
import { incrementCounterByPublisher } from 'utils/grapheneUtils';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';

import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';
import SDSDropdown from 'views/common/components/SDSDropdown/SDSDropdown';
import { createSDSDropdownOptions } from 'views/common/components/SDSDropdownOptions/SDSDropdownOptions';

import style from './SnapTrayAddToStoryModal.scss';

import type { SnapId } from 'types/common';
import type { EditionID } from 'types/editionID';
import { Publisher } from 'types/publishers';
import { ExtractDispatchProps } from 'types/redux';
import type { Snap } from 'types/snaps';

type OwnProps = {
  visible: boolean;
  hideModal: () => void;
  onEditionSelected: (b: EditionID) => void;
  numSelectedSnaps: number;
};
type StateProps = {
  storiesOptions: Array<any>;
  publisher: Publisher | null;
  snaps: {
    [k in SnapId | string]: Snap;
  };
};
type Props = OwnProps & StateProps & DispatchProps;
type OwnState = {
  selectedEditionId: EditionID | undefined | null;
};
const mapStateToProps = (state: State) => {
  return {
    storiesOptions: editionsSelectors.getEditableStoriesLabelValuePairs(state),
    publisher: getActivePublisherDetails(state),
    snaps: getAllSnaps(state),
  };
};
const mapDispatchToProps = {
  loadSnapsForEdition: reloadSnaps,
};
type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;
export class SnapTrayAddToStoryModal extends React.Component<Props, OwnState> {
  state = {
    selectedEditionId: null,
  };

  onChange = (newValue: any) => {
    this.setState({
      selectedEditionId: Number(newValue),
    });
  };

  onClickSendSnaps = () => {
    if (this.state.selectedEditionId) {
      // Log usage of the add media to story to grafana
      incrementCounterByPublisher(this.props.publisher, GrafanaMetrics.MEDIA_V2, { type: 'addMediaToStory' });

      this.props.onEditionSelected(this.state.selectedEditionId);
    }
  };

  getAddToStoryMessage = () => {
    return (
      <FormattedMessage
        id="snap-tray-add-to-story-modal"
        description="Add snaps to a story"
        defaultMessage="Send {snapCount, plural, one {1 snap} other {# snaps}}"
        values={{ snapCount: this.props.numSelectedSnaps }}
      />
    );
  };

  render() {
    return (
      <Modal
        className={classNames('storyStudio', style.modal)}
        visible={this.props.visible}
        footer={null}
        onCancel={this.props.hideModal}
        closable={false}
      >
        <div className={style.title} data-test="snapTrayAddToStoryModal.title">
          <FormattedMessage
            id="snap-tray-add-to-story-modal-title"
            description="Add snaps to story dialogue title"
            defaultMessage="Add to Story"
          />
        </div>
        <div className={style.storyHeader}>{getMessageFromId('story')}</div>
        <div>
          <SDSDropdown
            className={style.dropdown}
            onChange={this.onChange}
            data-test="snapTrayAddToStoryModal.dropDown"
            placeholder={
              <FormattedMessage
                id="snap-tray-add-to-story-modal-dropdown-placeholder"
                description="Add snaps to story dialogue dropdown placeholder"
                defaultMessage="Choose a story"
              />
            }
            disableClear
          >
            {createSDSDropdownOptions(this.props.storiesOptions)}
          </SDSDropdown>
        </div>

        <div className={style.footer}>
          <SDSButton
            type={ButtonType.SECONDARY}
            onClick={this.props.hideModal}
            data-test="snapTrayAddToStoryModal.cancelButton"
          >
            {getMessageFromId('cancel-button-modal')}
          </SDSButton>
          <SDSButton
            type={ButtonType.PRIMARY}
            onClick={this.onClickSendSnaps}
            disabled={!this.state.selectedEditionId}
            data-test="snapTrayAddToStoryModal.add"
          >
            {this.getAddToStoryMessage()}
          </SDSButton>
        </div>
      </Modal>
    );
  }
}
export default intlConnect(mapStateToProps, mapDispatchToProps)(SnapTrayAddToStoryModal);
