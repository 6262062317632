import classNames from 'classnames';
import React, { PureComponent } from 'react';
import type { ReactElement } from 'react';

import { hasClaimForActivePublisher } from 'state/user/selectors/userSelectors';

import {
  check,
  cross,
  starFavorite,
  starFavoriteFilled,
  tree,
  globe,
  imageGallery,
  magicWand,
  warningCircle,
  profileSingle,
} from 'icons/SDS/allIcons';
import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';

import { CompositeSnapBottomPanel } from 'views/common/components/CompositeSnapBottomPanel/CompositeSnapBottomPanel';
import { CompositeSnapCheckbox } from 'views/common/components/CompositeSnapCheckbox/CompositeSnapCheckbox';
import { CompositeSnapMenu } from 'views/common/components/CompositeSnapMenu/CompositeSnapMenu';
import { CompositeSnapOverlayIndexBadge } from 'views/common/components/CompositeSnapOverlayIndexBadge/CompositeSnapOverlayIndexBadge';
import Icon from 'views/common/components/Icon/Icon';

import style from './CompositeSnapOverlay.scss';

import type { ModerationAudience, ModerationModeEnum, SnapSource } from 'types/curation';
import { ModerationAudienceType, ModerationMode } from 'types/curation';
import { Claim } from 'types/permissions';

type OwnProps = {
  snapId: string;
  isVideo: boolean;
  additionalText: string;
  dateTimestamp: number;
  isHovering: boolean;
  isSelected: boolean | undefined | null;
  onToggle: (event: any) => any;
  menuItems: Array<ReactElement<any>>;
  indexOfSnapMediaBeingUsed?: number;
  username?: string | null;
  hasOverlayUrl?: boolean;
  snapSource?: SnapSource;
  isCuratedSnap: boolean;
  isStarred: boolean;
  isHighQuality: boolean;
  moderationStatus: void | ModerationAudience;
  moderationMode: void | ModerationModeEnum;
  multiSnapBundleId: string;
  isEvergreen: boolean | undefined | null;
  isUniversal: boolean | undefined | null;
};

type StateProps = {
  isAdvancedCurator: boolean;
};

type Props = OwnProps & StateProps;

const mapStateToProps = (state: State): StateProps => {
  return {
    isAdvancedCurator: hasClaimForActivePublisher(state, Claim.ADVANCED_CURATOR),
  };
};

export class CompositeSnapOverlay extends PureComponent<Props> {
  renderBottomPanel = (bottomPanelClasses: any) => {
    return (
      <CompositeSnapBottomPanel
        className={bottomPanelClasses}
        isVideo={this.props.isVideo}
        additionalText={this.props.additionalText}
        dateTimestamp={this.props.dateTimestamp}
      />
    );
  };

  renderModerationModeIcon = () => {
    const icon = getModerationModeIcon(this.props.moderationMode);
    return <Icon inlineIcon={icon} className={style.moderationIcon} />;
  };

  renderModerationStatusIcon = () => {
    const { moderationStatus } = this.props;
    if (moderationStatus === undefined) {
      return null;
    }

    return (
      <div className={getModerationStatusStyle(moderationStatus)}>
        <Icon inlineIcon={getModerationStatusIcon(moderationStatus)} className={style.moderationIcon} />
      </div>
    );
  };

  render() {
    const bottomPanelClasses = classNames(style.bottomPanel, { [style.hide]: this.props.isHovering });
    const borderClasses = classNames(style.border, { [style.selectedBorder]: this.props.isSelected });
    const checkboxClasses = classNames(style.checkbox, {
      [style.hide]: !this.props.isHovering && !this.props.isSelected,
    });
    const menuClasses = classNames(style.menu, { [style.hide]: !this.props.isHovering });
    const badgeClasses = classNames(style.badge, { [style.hide]: this.props.isHovering || this.props.isSelected });
    const statusClasses = classNames(style.statusContainer, {
      [style.hide]:
        !this.props.isStarred &&
        !this.props.isHighQuality &&
        !this.props.multiSnapBundleId &&
        this.props.moderationStatus === undefined,
    });

    return (
      <div className={style.container} onClick={this.props.onToggle}>
        {this.renderBottomPanel(bottomPanelClasses)}
        <div className={borderClasses} />
        <div className={statusClasses}>
          {this.renderModerationModeIcon()}
          {this.renderModerationStatusIcon()}
          {this.props.isEvergreen && <Icon inlineIcon={tree} className={style.whiteIcon} />}
          {this.props.isUniversal && <Icon inlineIcon={globe} className={style.whiteIcon} />}
          {(this.props.isHighQuality || this.props.isStarred) && (
            <Icon inlineIcon={this.props.isStarred ? starFavoriteFilled : starFavorite} className={style.starIcon} />
          )}
          {this.props.multiSnapBundleId && <Icon inlineIcon={imageGallery} className={style.whiteIcon} />}
        </div>
        <CompositeSnapCheckbox className={checkboxClasses} isChecked={this.props.isSelected} />
        {this.props.indexOfSnapMediaBeingUsed ? (
          <CompositeSnapOverlayIndexBadge
            className={badgeClasses}
            displaySnapIndex={this.props.indexOfSnapMediaBeingUsed}
          />
        ) : null}
        <CompositeSnapMenu className={menuClasses} snapId={this.props.snapId} menuItems={this.props.menuItems} />
      </div>
    );
  }
}

function getModerationStatusIcon(audience: ModerationAudience | void): string {
  switch (audience) {
    case ModerationAudienceType.ANYONE_CAN_VIEW:
      return check;
    case ModerationAudienceType.NOBODY_CAN_VIEW:
      return cross;
    case ModerationAudienceType.NOBODY_IN_SENSITIVE_COUNTRY_CAN_VIEW:
    case ModerationAudienceType.NOBODY_IN_MODERATE_COUNTRY_CAN_VIEW:
      return warningCircle;
    default:
      return '';
  }
}

function getModerationStatusStyle(audience: ModerationAudience | void): string {
  switch (audience) {
    case ModerationAudienceType.ANYONE_CAN_VIEW:
      return style.greenCircle;
    case ModerationAudienceType.NOBODY_CAN_VIEW:
      return style.redCircle;
    case ModerationAudienceType.NOBODY_IN_SENSITIVE_COUNTRY_CAN_VIEW:
    case ModerationAudienceType.NOBODY_IN_MODERATE_COUNTRY_CAN_VIEW:
      return style.amberCircle;
    default:
      return '';
  }
}

function getModerationModeIcon(mode: ModerationModeEnum | void): string {
  switch (mode) {
    case ModerationMode.ModerationModeManual:
      return profileSingle;
    case ModerationMode.ModerationModeAuto:
      return magicWand;
    default:
      return '';
  }
}
export default intlConnect(mapStateToProps, null)(CompositeSnapOverlay);
