// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
import { Row, Col } from '@snapchat/snapnet'; // discover-cms/no-snapnet
import React from 'react';
import InlineSVG from 'svg-inline-react';

// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'images/gear.svg.inline' or its... Remove this comment to see the full error message
import gearIcon from 'images/gear.svg.inline';

import style from './PageHeaderWithButtons.scss';

type Props = {
  header: React.ReactNode;
  subHeader?: React.ReactNode;
  rightElements?: React.ReactNode;
};

export default class PageHeaderWithButtons extends React.Component<Props> {
  render() {
    const { header, subHeader, rightElements } = this.props;
    return (
      <Row className={style.root}>
        <Col xs={12} className={style.container}>
          <div className={style.left}>
            <InlineSVG src={gearIcon} className={style.gearIcon} alt="" />
            <h4>{header}</h4>
            <div className={style.subHeader}>{subHeader}</div>
          </div>
          <div>{rightElements}</div>
        </Col>
      </Row>
    );
  }
}
