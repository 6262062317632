import moment from 'moment-timezone';
import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { getAuthType, getToken } from 'state/auth/selectors/authSelectors';
import { getActiveCreatorHostUserId, getActivePublisherId } from 'state/user/selectors/userSelectors';

import { exportRevenueCSV } from 'views/analytics/utils/exportCSV';
import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';
import SDSDropdown, { DropdownType } from 'views/common/components/SDSDropdown/SDSDropdown';
import { createSDSDropdownOptions } from 'views/common/components/SDSDropdownOptions/SDSDropdownOptions';
import SDSLabel from 'views/common/components/SDSLabel/SDSLabel';
import SDSModal from 'views/common/components/SDSModal/SDSModal';
import { REVENUE_COUNTRY_OPTIONS, RevenueCountry } from 'views/modals/components/RevenueCSVModal/RevenueCountry';

import style from './RevenueCSVModal.scss';

import { ModalId } from 'types/modal';

type Props = {
  modalId: ModalId;
  hideModal: (modalId: string) => void;
  options: {
    from: moment.Moment;
    to: moment.Moment;
  };
};

export enum Granularity {
  DAY = 'DAY',
  MONTH = 'MONTH',
  TOTAL = 'TOTAL',
}

const GRANULARITY_OPTIONS = [
  {
    label: (
      <FormattedMessage
        id="revenue-csv-granularity-day"
        description="Label for revenue csv download granularity dropdown option day"
        defaultMessage="Day"
      />
    ),
    value: Granularity.DAY,
  },
  {
    label: (
      <FormattedMessage
        id="revenue-csv-granularity-month"
        description="Label for revenue csv download granularity dropdown option month"
        defaultMessage="Month"
      />
    ),
    value: Granularity.MONTH,
  },
  {
    label: (
      <FormattedMessage
        id="revenue-csv-granularity-total"
        description="Label for revenue csv download granularity dropdown option total"
        defaultMessage="Total"
      />
    ),
    value: Granularity.TOTAL,
  },
];

export enum Breakdown {
  STORY = 'STORY',
  STORY_AND_REVENUE_TYPE = 'STORY_AND_REVENUE_TYPE',
  STORY_AND_COUNTRY = 'STORY_AND_COUNTRY',
}

const BREAKDOWN_OPTIONS = [
  {
    label: (
      <FormattedMessage
        id="revenue-csv-breakdown-story"
        description="Label for revenue csv download breakdown dropdown option story"
        defaultMessage="Story"
      />
    ),
    value: Breakdown.STORY,
  },
  {
    label: (
      <FormattedMessage
        id="revenue-csv-breakdown-story-and-revenue-type"
        description="Label for revenue csv download breakdown dropdown option story and revenue type"
        defaultMessage="Story and Revenue Type"
      />
    ),
    value: Breakdown.STORY_AND_REVENUE_TYPE,
  },
  {
    label: (
      <FormattedMessage
        id="revenue-csv-breakdown-story-and-country"
        description="Label for revenue csv download breakdown dropdown option story and country"
        defaultMessage="Story and Country"
      />
    ),
    value: Breakdown.STORY_AND_COUNTRY,
  },
];

export enum RevenueType {
  SNAP_SOLD = 'SNAP_SOLD',
  PARTNER_SOLD = 'PARTNER_SOLD',
}

const REVENUE_TYPE_OPTIONS = [
  {
    label: (
      <FormattedMessage
        id="revenue-csv-revenue-type-snap-sold"
        description="Label for revenue csv download revenue type snap sold"
        defaultMessage="Snap Sold"
      />
    ),
    value: RevenueType.SNAP_SOLD,
  },
  {
    label: (
      <FormattedMessage
        id="revenue-csv-revenue-type-partner-sold"
        description="Label for revenue csv download revenue type partner sold"
        defaultMessage="Partner Sold"
      />
    ),
    value: RevenueType.PARTNER_SOLD,
  },
];

export default function RevenueCSVModal({ options, modalId, hideModal }: Props) {
  const [granularity, setGranularity] = useState<Granularity>(Granularity.DAY);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [breakdown, setBreakdown] = useState<Breakdown>(Breakdown.STORY);
  const [revenueType, setRevenueType] = useState<RevenueType | null>(null);
  const [country, setCountry] = useState<string | null>(null);
  const hostUserId = useSelector(getActiveCreatorHostUserId);
  const publisherId = useSelector(getActivePublisherId);
  const authType = useSelector(getAuthType);
  const token = useSelector(getToken);

  const closeModal = useCallback(() => {
    hideModal(modalId);
  }, [hideModal, modalId]);

  const onGranularityChange = useCallback((updatedGranularity: Granularity) => {
    setGranularity(updatedGranularity);
  }, []);

  const onBreakdownChange = useCallback((updatedBreakdown: Breakdown) => {
    if (updatedBreakdown === Breakdown.STORY) {
      setRevenueType(null);
      setCountry(null);
    } else if (updatedBreakdown === Breakdown.STORY_AND_COUNTRY) {
      const firstCountry = Object.keys(RevenueCountry)[0]!;
      setCountry(firstCountry);
      setRevenueType(null);
    } else {
      setRevenueType(RevenueType.SNAP_SOLD);
      setCountry(null);
    }

    setBreakdown(updatedBreakdown);
  }, []);

  const onRevenueTypeChange = useCallback((updatedRevenueType: RevenueType) => {
    setRevenueType(updatedRevenueType);
  }, []);

  const onCountryChange = useCallback((updatedCountry: RevenueCountry) => {
    setCountry(updatedCountry);
  }, []);

  const downloadRevenueCSV = useCallback(async () => {
    if (hostUserId && authType && publisherId && token) {
      setIsDownloading(true);
      await exportRevenueCSV({
        hostUserId,
        publisherId,
        startDate: options.from.format('YYYY-MM-DD'),
        endDate: options.to.format('YYYY-MM-DD'),
        granularity,
        revenueType,
        country,
        authType,
        token,
      }).finally(() => {
        setIsDownloading(false);
        hideModal(modalId);
      });
    }
  }, [
    authType,
    granularity,
    hideModal,
    hostUserId,
    modalId,
    options.from,
    options.to,
    publisherId,
    revenueType,
    country,
    token,
  ]);

  return (
    <SDSModal visible width={400} closable footer={false} onCancel={closeModal}>
      <div className={style.container}>
        <SDSLabel
          withoutDefaultMargin
          data-test="RevenueCSVModal.breakdownLabel"
          className={style.label}
          labelTitle={
            <FormattedMessage
              id="revenue-csv-breakdown"
              description="Label for revenue csv download breakdown dropdown"
              defaultMessage="Breakdown"
            />
          }
        >
          <SDSDropdown
            disableClear
            type={DropdownType.GREY}
            data-test="RevenueCSVModal.breakdownDropdown"
            value={breakdown}
            onChange={onBreakdownChange}
          >
            {createSDSDropdownOptions(BREAKDOWN_OPTIONS)}
          </SDSDropdown>
        </SDSLabel>
        <SDSLabel
          withoutDefaultMargin
          data-test="RevenueCSVModal.granularityLabel"
          className={style.label}
          labelTitle={
            <FormattedMessage
              id="revenue-csv-granularity"
              description="Label for revenue csv download granularity dropdown"
              defaultMessage="Granularity"
            />
          }
        >
          <SDSDropdown
            disableClear
            type={DropdownType.GREY}
            data-test="RevenueCSVModal.granularityDropdown"
            value={granularity}
            onChange={onGranularityChange}
          >
            {createSDSDropdownOptions(GRANULARITY_OPTIONS)}
          </SDSDropdown>
        </SDSLabel>
        {breakdown === Breakdown.STORY_AND_REVENUE_TYPE && (
          <SDSLabel
            withoutDefaultMargin
            data-test="RevenueCSVModal.revenueTypeLabel"
            className={style.label}
            labelTitle={
              <FormattedMessage
                id="revenue-csv-revenue-type"
                description="Label for revenue csv download revenue type dropdown"
                defaultMessage="Revenue Type"
              />
            }
          >
            <SDSDropdown
              disableClear
              type={DropdownType.GREY}
              data-test="RevenueCSVModal.revenueTypeDropdown"
              value={revenueType ?? undefined}
              onChange={onRevenueTypeChange}
            >
              {createSDSDropdownOptions(REVENUE_TYPE_OPTIONS)}
            </SDSDropdown>
          </SDSLabel>
        )}
        {breakdown === Breakdown.STORY_AND_COUNTRY && (
          <SDSLabel
            withoutDefaultMargin
            data-test="RevenueCSVModal.countryLabel"
            className={style.label}
            labelTitle={
              <FormattedMessage
                id="revenue-csv-country"
                description="Label for revenue csv download country dropdown"
                defaultMessage="Country"
              />
            }
          >
            <SDSDropdown
              disableClear
              type={DropdownType.GREY}
              data-test="RevenueCSVModal.countryDropdown"
              value={country ?? undefined}
              onChange={onCountryChange}
            >
              {createSDSDropdownOptions(REVENUE_COUNTRY_OPTIONS)}
            </SDSDropdown>
          </SDSLabel>
        )}
        <SDSButton
          type={ButtonType.PRIMARY}
          onClick={downloadRevenueCSV}
          data-test="RevenueCSVModal.downloadCSVButton"
          className={style.exportButton}
          loading={isDownloading}
          disabled={isDownloading}
        >
          {
            <FormattedMessage
              id="revenue-csv-export-download-button"
              description="Label for revenue csv export download button"
              defaultMessage="Download CSV"
            />
          }
        </SDSButton>
      </div>
    </SDSModal>
  );
}
