import React from 'react';

import Icon from 'views/common/components/Icon/Icon';

import style from './DebugIcons.scss';

function importAll(r: any) {
  return r.keys().reduce((obj: any, key: any) => {
    return {
      ...obj,
      [key]: r(key),
    };
  }, {});
}
const icons = importAll((require as any).context('icons', true, /\.(png|svg|inline)$/));
const images = importAll((require as any).context('images', true, /\.(png|svg|inline)$/));
const views = importAll((require as any).context('views', true, /\.(png|svg|inline)$/));
export default class UserInfoForm extends React.Component {
  sanitizeIconName(name: any) {
    return name.split('/').slice(-1)[0].split('.')[0];
  }

  renderIcon(name: any, icon: any, index: any) {
    const iconProps = name.includes('.inline') ? { inlineIcon: icon } : { img: icon };
    return (
      <div className={style.cell} key={index}>
        <Icon {...iconProps} />
        <div className={style.cellTitle}>{this.sanitizeIconName(name)}</div>
      </div>
    );
  }

  render() {
    const iconsMap = {
      ...views,
      ...images,
      ...icons,
    };
    const iconList = Object.keys(iconsMap).map((name, index) => this.renderIcon(name, iconsMap[name], index));
    return <div className={style.body}>{iconList}</div>;
  }
}
