import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';
import { defineMessages, intlShape, injectIntl } from 'react-intl';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'upde... Remove this comment to see the full error message
import u from 'updeep';

import SDSTooltip, { TooltipPosition } from '../SDSTooltip/SDSTooltip';

import { COUNTRY_CODE_ORDER, DEFAULT_ANALYTICS_GEO } from 'config/constants';
import { globe } from 'icons/SDS/allIcons';
import { iterateAllCountries, getCheckboxStates, defaultCategoryUsedInMultiSelection } from 'utils/countryUtils';

import CommaSeparatedList from 'views/common/components/CommaSeparatedList/CommaSeparatedList';
import REGIONS from 'views/common/components/CountryPicker/regions.json';
import Icon from 'views/common/components/Icon/Icon';
import ToggleButton from 'views/common/components/ToggleButton/ToggleButton';

import CountryDropdown from './CountryDropdown/CountryDropdown';
import style from './CountryPicker.scss';

import { CountryCodeCategory } from 'types/countries';
import type {
  Region,
  Country,
  Regions,
  PickedCountryCodesForCategory,
  PickedCountryCodes,
  DerivedState,
} from 'types/countries';

type OnChangeParams = {
  pickedCountryCodes: PickedCountryCodes;
};
export type OnToggleParams = OnChangeParams & {
  isDropdownVisible: boolean;
};
type OwnProps = {
  className?: string;
  dropdownClassName?: string;
  onChange: (a: OnChangeParams) => void;
  onToggle: (a: OnToggleParams) => void;
  pickedCountryCodes: PickedCountryCodes;
  numCountries: number;
  width: number;
  countryCodeCategories: CountryCodeCategory[];
  disabled: boolean;
};
type State = {
  isDropdownVisible: boolean;
  currentPickedCountryCodes: PickedCountryCodes;
};
const messages = defineMessages({
  countryListPlaceholder: {
    id: 'country-search-country-list-placeholder',
    description: 'Country Search country list placeholder',
    defaultMessage: 'Make a selection',
  },
});
export const areCountryCodesEqual = (prev: PickedCountryCodesForCategory, next: PickedCountryCodesForCategory) => {
  if (prev === 'Global' && next === 'Global') {
    return true;
  }
  if (Array.isArray(prev) && Array.isArray(next)) {
    if (_.isEqual(prev.slice().sort(), next.slice().sort())) {
      return true;
    }
  }
  return false;
};
type Props = OwnProps & Omit<typeof CountryPicker.defaultProps, 'pickedCountryCodes'>;
export class CountryPicker extends React.Component<Props, State> {
  private tooltipWrapper = React.createRef<HTMLDivElement>();

  static defaultProps = {
    pickedCountryCodes: { [CountryCodeCategory.DEFAULT]: [] },
    numCountries: 4,
    width: 200,
    countryCodeCategories: [CountryCodeCategory.DEFAULT],
  };

  static contextTypes = {
    intl: intlShape,
  };

  static mapToPickedCountries(pickedCountries: PickedCountryCodes | string[] | string): PickedCountryCodes {
    if (typeof pickedCountries === 'string') {
      return {
        [CountryCodeCategory.DEFAULT]: DEFAULT_ANALYTICS_GEO,
      };
    }
    return Array.isArray(pickedCountries) ? { [CountryCodeCategory.DEFAULT]: pickedCountries } : pickedCountries;
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      isDropdownVisible: false,
      currentPickedCountryCodes: CountryPicker.mapToPickedCountries(props.pickedCountryCodes),
    };
  }

  componentDidMount() {
    document.addEventListener('click', this.onHandleClickOutside, true);
    document.addEventListener('keydown', this.onEscKeydown, true);
  }

  UNSAFE_componentWillReceiveProps(props: Props, state: State) {
    this.setState({ currentPickedCountryCodes: CountryPicker.mapToPickedCountries(props.pickedCountryCodes) });
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onHandleClickOutside, true);
    document.removeEventListener('keydown', this.onEscKeydown, true);
  }

  onHandleClickOutside = (event: { target: any }) => {
    const $node = ReactDOM.findDOMNode(this);
    if (!this.state.isDropdownVisible) {
      return;
    }
    if (
      (!$node || !$node.contains(event.target)) &&
      (!this.tooltipWrapper.current || !this.tooltipWrapper.current.contains(event.target))
    ) {
      this.onToggle();
    }
  };

  onEscKeydown = (event: KeyboardEvent) => {
    let isEscape = false;
    if ('key' in event) {
      isEscape = event.key === 'Escape' || event.key === 'Esc';
    } else {
      isEscape = (event as any).keyCode === 27;
    }
    if (!isEscape) {
      return;
    }
    this.onToggle();
  };

  onToggle = () => {
    if (!this.props.disabled) {
      const regions = this.countryCodesToRegions(this.state.currentPickedCountryCodes);
      const { pickedCountryCodes } = this.getDerivedState(regions);
      this.setState(
        { isDropdownVisible: !this.state.isDropdownVisible, currentPickedCountryCodes: pickedCountryCodes },
        () => {
          this.props.onToggle({
            isDropdownVisible: this.state.isDropdownVisible,
            pickedCountryCodes,
          });
        }
      );
    }
  };

  onChange = (newRegions: Regions) => {
    const { pickedCountryCodes } = this.getDerivedState(newRegions);
    this.props.onChange({
      pickedCountryCodes,
    });
    this.setState({ currentPickedCountryCodes: pickedCountryCodes });
  };

  getDerivedState = _.memoize(
    (regions: Regions): DerivedState => {
      return getCheckboxStates({ countryCategories: this.props.countryCodeCategories, regions });
    }
  );

  getSelectedAreas = (regions: Regions): Array<string> => {
    let selectedAreas: any = [];
    let selectedCountries: any = [];
    let areAllSelected = true;
    const regionValues: Region[] = Object.values(regions) as any;
    regionValues.forEach((region: Region) => {
      let isRegionFullySelected = true;
      const regionCountriesSelected: any = [];
      const countries: Country[] = Object.values(region.countries) as any;
      countries.forEach((country: Country) => {
        const isSelected = this.props.countryCodeCategories.some(category => {
          if (defaultCategoryUsedInMultiSelection(this.props.countryCodeCategories, category)) {
            return false;
          }
          return country.picked && country.picked[category];
        });
        isRegionFullySelected = isRegionFullySelected && isSelected;
        areAllSelected = areAllSelected && isSelected;
        if (isSelected) {
          regionCountriesSelected.push(country.name);
        }
      });
      if (isRegionFullySelected) {
        selectedAreas.push(region.name);
      } else {
        selectedCountries = selectedCountries.concat(regionCountriesSelected);
      }
    });
    if (areAllSelected) {
      return [DEFAULT_ANALYTICS_GEO];
    }
    selectedAreas = selectedAreas.concat(selectedCountries);
    return selectedAreas;
  };

  countryCodesToRegions = _.memoize((pickedCountryCodes: PickedCountryCodes) => {
    const patch = {};
    iterateAllCountries((country, region) => {
      if (region) {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        patch[region.name] = patch[region.name] || { countries: {} };
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        patch[region.name].countries = patch[region.name].countries || {};
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        patch[region.name].countries[country.code] = {
          picked: this.props.countryCodeCategories.reduce((acc, category) => {
            // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            acc[category] =
              pickedCountryCodes[category] &&
              (pickedCountryCodes[category] === 'Global' ||
                // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                pickedCountryCodes[category].indexOf(country.code) !== -1 ||
                // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                pickedCountryCodes[category].indexOf(country.code.toLowerCase()) !== -1);
            return acc;
          }, {}),
        };
      }
    }, REGIONS);
    return u(patch, REGIONS);
  });

  renderCountryDropdown = (regions: Regions, derivedState: DerivedState) => {
    return (
      <div ref={this.tooltipWrapper} className={classNames(style.tooltipWrapper, this.props.dropdownClassName)}>
        <CountryDropdown
          className={this.props.dropdownClassName}
          onChange={this.onChange}
          derivedState={derivedState}
          countryCategories={this.props.countryCodeCategories}
          regions={regions}
        />
      </div>
    );
  };

  render() {
    const regions = this.countryCodesToRegions(this.state.currentPickedCountryCodes);
    const derivedState = this.getDerivedState(regions);
    const selectedAreas = this.getSelectedAreas(regions);
    // Prevents tooltip from scrolling with page --> https://github.com/ant-design/ant-design/issues/3438
    function getTooltipPopupContainer(trigger?: HTMLElement) {
      return trigger?.parentElement as HTMLElement;
    }
    return (
      <div style={{ width: this.props.width }} className={classNames(style.root, this.props.className)}>
        <SDSTooltip
          // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
          placement={TooltipPosition.BOTTOM}
          title={this.renderCountryDropdown(regions, derivedState)}
          trigger={'click'}
          overlayClassName={classNames(this.props.dropdownClassName, style.tooltip)}
          visible={this.state.isDropdownVisible}
          getPopupContainer={getTooltipPopupContainer}
        >
          <div
            className={classNames(style.selectedCountries, { [style.isEnabled]: !this.props.disabled })}
            onClick={this.onToggle}
          >
            {selectedAreas.length ? (
              <div className={classNames('countries', style.countries)} data-test="countryPicker.country">
                <Icon inlineIcon={globe} className={style.icon} />
                <CommaSeparatedList
                  order={COUNTRY_CODE_ORDER}
                  values={selectedAreas}
                  limit={this.props.numCountries}
                  preventOrdering
                />
              </div>
            ) : (
              <div className={classNames('countries', style.countries, style.placeholder)}>
                {this.context.intl.formatMessage(messages.countryListPlaceholder)}
              </div>
            )}
            {!this.props.disabled && (
              <ToggleButton
                onClick={_.noop}
                isArrowUp={this.state.isDropdownVisible}
                data-test="countryPicker.toggleButton"
              />
            )}
          </div>
        </SDSTooltip>
      </div>
    );
  }
}
// @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'typeof CountryPicker' is not ass... Remove this comment to see the full error message
export default injectIntl(CountryPicker);
