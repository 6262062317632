import Modal from 'antd/lib/modal';
import classNames from 'classnames';
import React from 'react';
import { FormattedMessage, FormattedDate, FormattedTime } from 'react-intl';

import { download, plus, trash } from 'icons/SDS/allIcons';
import * as intlMessages from 'utils/intlMessages/intlMessages';
import { redirectToUrl } from 'utils/locationUtils';

import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';
import SnapPreview from 'views/common/components/SnapPreview/SnapPreview';

import phoneImage from 'images/phone.svg';

import style from './SnapGridInfoModal.scss';

type Props = {
  mediaId: string;
  mediaSrc: string;
  allowDownloading?: boolean;
  downloadUrl?: string;
  videoPosterSrc?: string;
  isVideo: boolean;
  fileName: string;
  dateTimestamp: number;
  width?: number;
  height?: number;
  duration?: number;
  muted: boolean;
  isAddedToTray: boolean;
  isVisible: boolean;
  hide: () => void;
  onToggle: (a: string) => any;
  creatorUsername: string | undefined | null;
};

type OwnState = {
  isHoveringOnPreview: boolean;
};

export class SnapGridInfoModal extends React.PureComponent<Props, OwnState> {
  state = {
    isHoveringOnPreview: false,
  };

  onMouseEnterSnapPreview = () => {
    this.setState({ isHoveringOnPreview: true });
  };

  onMouseLeaveSnapPreview = () => {
    this.setState({ isHoveringOnPreview: false });
  };

  getDurationInfo = (duration?: number) => {
    if (!duration) {
      return null;
    }
    return [
      <div className={style.duration} key="SnapGridInfoModal.duration">
        <FormattedMessage id="snap-grid-info-duration" description="Video duration" defaultMessage="Duration" />
      </div>,
      <div
        className={style.durationValue}
        key="SnapGridInfoModal.durationValue"
        data-test="snapGridInfoModal.durationValue"
      >
        <FormattedMessage
          id="snap-grid-info-duration-value"
          description="Video duration in seconds"
          defaultMessage="{duration}s"
          values={{
            duration: duration / 1000,
          }}
        />
      </div>,
    ];
  };

  getResolutionInfo = (width?: number, height?: number) => {
    if (!width || !height) {
      return null;
    }
    return [
      <div className={style.resolution} key="SnapGridInfoModal.resolution">
        <FormattedMessage id="snap-grid-info-resolution" description="Media resolution" defaultMessage="Resolution" />
      </div>,
      <div
        className={style.resolutionValue}
        key="SnapGridInfoModal.resolutionValue"
        data-test="snapGridInfoModal.resolutionValue"
      >
        <FormattedMessage
          id="snap-grid-info-resolution-value"
          description="Media resolution value in pixels"
          defaultMessage="{width}x{height}px"
          values={{
            width,
            height,
          }}
        />
      </div>,
    ];
  };

  toggleAddRemoveButton = () => this.props.onToggle(this.props.mediaId);

  renderAddRemoveButton() {
    const text = !!this.props.isAddedToTray ? ( // eslint-disable-line no-extra-boolean-cast
      <FormattedMessage
        id="snap-grid-info-modal-remove-from-tray"
        description="Message indicating to the user that they can remove this snap to their tray"
        defaultMessage="Remove from tray"
      />
    ) : (
      <FormattedMessage
        id="snap-grid-info-modal-add-to-tray"
        description="Message indicating to the user that they can add this snap to their tray"
        defaultMessage="Add to tray"
      />
    );
    return (
      <SDSButton
        inlineIcon={this.props.isAddedToTray ? trash : plus}
        type={this.props.isAddedToTray ? ButtonType.SECONDARY : ButtonType.PRIMARY}
        onClick={this.toggleAddRemoveButton}
        data-test="SnapGridInfoModal.addRemoveButton"
      >
        {text}
      </SDSButton>
    );
  }

  redirectToDownloadUrl = () => {
    // satisfy Flow
    if (this.props.downloadUrl) {
      redirectToUrl(this.props.downloadUrl);
    }
  };

  renderDownloadButton = () => {
    if (this.props.allowDownloading && this.props.downloadUrl) {
      return (
        <SDSButton
          type={ButtonType.SECONDARY}
          inlineIcon={download}
          onClick={this.redirectToDownloadUrl}
          data-test="SnapGridInfoModal.downloadButton"
        >
          {intlMessages.getMessageFromId('download')}
        </SDSButton>
      );
    }
    return null;
  };

  render() {
    return (
      <Modal
        // Adding storyStudio class so that certain styling that's only available
        // to elements under storyStudio will be applied here as well
        className={classNames('storyStudio', style.modal)}
        visible={this.props.isVisible}
        onCancel={this.props.hide}
        footer={null}
      >
        <div className={style.modalBody}>
          <div className={style.previewContainer}>
            <SnapPreview
              className={style.snapPreview}
              snapMediaSrc={this.props.mediaSrc}
              videoPosterSrc={this.props.videoPosterSrc}
              isVideo={this.props.isVideo}
              shouldPlayVideo={this.state.isHoveringOnPreview}
              muted={this.props.muted}
              onMouseEnter={this.onMouseEnterSnapPreview}
              onMouseLeave={this.onMouseLeaveSnapPreview}
            />
            <img src={phoneImage} alt="Phone" />
          </div>
          <div className={style.metadata}>
            <div className={style.mediaBadge}>
              <FormattedMessage
                id="snap-grid-info-uploaded-media"
                description="Media uploaded by the user"
                defaultMessage="Uploaded media"
              />
            </div>
            <div className={style.fileName}>{this.props.fileName}</div>
            <div className={style.timestamp}>
              {intlMessages.getMessageFromId('datetime', {
                date: <FormattedDate value={this.props.dateTimestamp} day="2-digit" month="2-digit" year="2-digit" />,
                time: (
                  <FormattedTime
                    value={this.props.dateTimestamp}
                    hour="numeric"
                    minute="numeric"
                    hour12={false}
                    timeZoneName="short"
                  />
                ),
              })}
            </div>
            <div className={style.mediaType} data-test="snapGridInfoModal.mediaType">
              <FormattedMessage id="snap-grid-info-media-type" description="Media type" defaultMessage="Media type" />
            </div>
            <div className={style.mediaTypeValue} data-test="snapGridInfoModal.mediaValue">
              {this.props.isVideo ? (
                <FormattedMessage
                  id="snap-grid-info-media-type-value-video"
                  description="Displayed when the media is a video"
                  defaultMessage="Video"
                />
              ) : (
                <FormattedMessage
                  id="snap-grid-info-media-type-value-image"
                  description="Displayed when the media is an image"
                  defaultMessage="Image"
                />
              )}
            </div>
            {this.getResolutionInfo(this.props.width, this.props.height)}
            {this.getDurationInfo(this.props.duration)}
            {this.props.creatorUsername && (
              <div>
                <div className={style.creatorUsername} data-test="snapGridInfoModal.userName">
                  <FormattedMessage
                    id="snap-grid-info-creator-username"
                    description="Username of snap creator"
                    defaultMessage="Creator username"
                  />
                </div>
                <div className={style.creatorUsernameValue} data-test="snapGridInfoModal.userValue">
                  {this.props.creatorUsername}
                </div>
              </div>
            )}
          </div>
          <div className={style.buttonsContainer}>
            {this.renderDownloadButton()}
            {this.renderAddRemoveButton()}
          </div>
        </div>
      </Modal>
    );
  }
}

export default SnapGridInfoModal;
