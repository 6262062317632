import React from 'react';

import * as modalsActions from 'state/modals/actions/modalsActions';

import { intlConnect } from 'utils/connectUtils';

import SDSAlert from 'views/common/components/SDSAlert/SDSAlert';

import { ModalBaseProps } from 'types/modal';
import { ExtractDispatchProps } from 'types/redux';

export type AlertOptions = {
  onConfirm: () => void | Promise<void>;
  body?: React.ReactNode;
};

type OwnProps = {
  'data-test'?: string;
  options: AlertOptions;
};

const mapDispatchToProps = {
  hideModal: modalsActions.hideModal,
};

type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;
type Props = ModalBaseProps & OwnProps & DispatchProps;

export class AlertModal extends React.Component<Props> {
  onConfirmClick = async () => {
    this.props.hideModal(this.props.modalId);
    await this.props.options.onConfirm();
  };

  render() {
    return (
      <SDSAlert visible onConfirm={this.onConfirmClick} data-test={`${this.props['data-test']}.sdsAlert`}>
        {this.props.options.body}
      </SDSAlert>
    );
  }
}
export default intlConnect(null, mapDispatchToProps)(AlertModal);
