import React from 'react';
import { FormattedMessage } from 'react-intl';
import { List } from 'react-virtualized';
import InlineSVG from 'svg-inline-react';

import * as mediaActions from 'state/media/actions/mediaActions';
import * as mediaLibraryActions from 'state/mediaLibrary/actions/mediaLibraryActions';
import * as publishersSelectors from 'state/publishers/selectors/publishersSelectors';

import { UploadPurpose } from 'config/constants';
import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';
import * as intlMessages from 'utils/intlMessages/intlMessages';
import u from 'utils/safeUpdeep';

import SDSDialog from 'views/common/components/SDSDialog/SDSDialog';
import SDSDropdown from 'views/common/components/SDSDropdown/SDSDropdown';
import { createSDSDropdownOptions } from 'views/common/components/SDSDropdownOptions/SDSDropdownOptions';

// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/attention.svg.inline' or... Remove this comment to see the full error message
import attention from 'icons/attention.svg.inline';

import style from './MediaLibraryUploadModal.scss';

import { UploadAsEnum } from 'types/mediaLibrary';
import type { UploadedMedia, UploadAsType, TargetType } from 'types/mediaLibrary';
import type { Publisher } from 'types/publishers';

const MEDIA_LIST_ROW_HEIGHT = 46;
const BARE_MODAL_HEIGHT = 180; // height of modal without any media item
const LIST_WIDTH = 472;
type UploadAsTypesMap = {
  [x: number]: UploadAsType;
};
type OwnProps = {
  visible: boolean;
  files: File[];
  mediaMetadata: UploadedMedia[];
  hideModal: () => void;
  target: TargetType;
};
type StateProps = {
  activePublisher: Publisher;
};
type DispatchProps = {
  uploadMediaGetResult: typeof mediaActions.uploadMediaGetResult;
  getMedia: typeof mediaLibraryActions.getMedia;
};
type Props = OwnProps & StateProps & DispatchProps;
type OwnState = {
  // UploadAs types user selected. A map from list item index to uploadAs type
  // The values can be null if user did not make a selection. If so, the first
  // valid uploadAs option in mediaMetadata will be used for uploading
  uploadAsTypes: UploadAsTypesMap;
};
const mapDispatchToProps = {
  uploadMediaGetResult: mediaActions.uploadMediaGetResult,
  getMedia: mediaLibraryActions.getMedia,
};
const mapStateToProps = (state: State) => {
  return {
    activePublisher: publishersSelectors.getActivePublisherDetails(state),
  };
};
const uploadAsOptions = [
  {
    label: intlMessages.getMessageFromId('snap'),
    value: UploadAsEnum.SNAP,
  },
  {
    label: intlMessages.getMessageFromId('attachment'),
    value: UploadAsEnum.ATTACHMENT,
  },
];
export class MediaLibraryUploadModal extends React.Component<Props, OwnState> {
  state = {
    uploadAsTypes: {},
  };

  onChange = (index: number) => {
    return (newValue: any) => {
      this.setState({
        uploadAsTypes: u({ [index]: newValue }, this.state.uploadAsTypes),
      });
    };
  };

  addToLibrary = () => {
    const promises = this.props.files.map((file, index) => {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      if (this.props.mediaMetadata[index].validationError) {
        return Promise.resolve();
      }
      const nonNullUploadAsType =
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        this.state.uploadAsTypes[index] ||
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        this.props.mediaMetadata[index].uploadAsOptions[0];
      const params = {
        // Default to TOP_SNAP if value is null
        purpose:
          nonNullUploadAsType === UploadAsEnum.ATTACHMENT ? UploadPurpose.LONGFORM_VIDEO : UploadPurpose.TOP_SNAP,
        editionId: null,
        maxDurationSeconds: this.props.activePublisher.topsnapLimit,
      };
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 4 arguments, but got 3.
      return this.props.uploadMediaGetResult(file, this.props.mediaMetadata[index].fileType, params);
    });
    return Promise.all(promises)
      .then(() => {
        // We need to wait for some time for the uploaded files to be propagated. Although both uploading
        // media and claiming media calls have returned by this time, we need a short wait before fetching
        // again, otherwise the returned list of media will be exactly the same.
        // TODO: use a more reliable way to determine when to fetch again
        setTimeout(() => {
          this.props.getMedia({
            keepExistingSnaps: false,
            target: this.props.target,
          });
        }, 1000);
        this.hideModalAndResetState();
      })
      .catch(err => {});
  };

  hideModalAndResetState = () => {
    this.props.hideModal();
  };

  rowRenderer = (files: File[], uploadAsTypes: UploadAsTypesMap) => {
    return ({ key, index, isScrolling, isVisible, style: rowStyle }: any) => {
      const file = files[index];
      const metadata = this.props.mediaMetadata[index];
      const metadataDescription = () => {
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        return metadata.fileType === 'video' ? (
          <FormattedMessage
            id="media-library-upload-modal-video-file-metadata"
            description="Video metadata - media type, and video duration"
            defaultMessage="{duration}s video"
            // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
            values={{ duration: metadata.duration ? Math.round(metadata.duration) : null }}
          />
        ) : (
          <FormattedMessage
            id="media-library-upload-modal-image-file-metadata"
            description="Image metadata - including dimensions and media type"
            defaultMessage="Image, {width}x{height}px"
            values={{
              // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
              width: metadata.width,
              // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
              height: metadata.height,
            }}
          />
        );
      };
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      const validationError = metadata.validationError
        ? // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
          intlMessages.getMessageFromIntlMessage(metadata.validationError)
        : null;
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      const filteredUploadAsOptions = uploadAsOptions.filter(option => metadata.uploadAsOptions.includes(option.value));
      return (
        <div key={key} style={rowStyle} className={style.mediaRow}>
          <div className={style.fileNameTypeAndError}>
            {validationError ? (
              <div className={style.attentionContainer} data-test="mediaLibraryUploadModal.failIcon">
                <InlineSVG className={style.attentionIcon} src={attention} />
              </div>
            ) : null}
            <div className={(style as any).fileNameAndType}>
              <div className={style.fileName} data-test="mediaLibraryUploadModal.fileName">
                {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
                {file.name}
              </div>
              <div className={style.mediaTypeAndResolution}>{validationError || metadataDescription()}</div>
            </div>
          </div>
          <div className={style.dropdownContainer}>
            {validationError ? (
              <div className={style.incompatibleText} data-test="mediaLibraryUploadModal.incompatible">
                Incompatible
              </div>
            ) : (
              <SDSDropdown
                className={style.dropdown}
                /* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */
                value={uploadAsTypes[index] || filteredUploadAsOptions[0].value}
                onChange={this.onChange(index)}
                disableClear
                data-test="mediaLibraryUploadModal.dropdown"
              >
                {createSDSDropdownOptions(filteredUploadAsOptions)}
              </SDSDropdown>
            )}
          </div>
        </div>
      );
    };
  };

  renderFiles = (files: File[], uploadAsTypes: UploadAsTypesMap) => {
    // Show as many media items in one screen as possible, while constraining the
    // height between [height of one media item] and [window height - 100px - bare minimum modal height]
    const requestedHeight = files.length * MEDIA_LIST_ROW_HEIGHT;
    const minConstraint = MEDIA_LIST_ROW_HEIGHT;
    const maxConstraint = window.innerHeight - 100 - BARE_MODAL_HEIGHT;
    const listHeight = Math.max(minConstraint, Math.min(maxConstraint, requestedHeight));
    return (
      <List
        className={style.mediaList}
        width={LIST_WIDTH}
        height={listHeight}
        rowCount={files.length}
        rowHeight={MEDIA_LIST_ROW_HEIGHT}
        rowRenderer={this.rowRenderer(files, uploadAsTypes)}
      />
    );
  };

  render() {
    const canAnyFileBeUploaded = !!this.props.mediaMetadata.find(metadata => metadata.uploadAsOptions.length > 0);
    return (
      <SDSDialog
        visible={this.props.visible}
        onCancel={this.hideModalAndResetState}
        cancelText={
          <FormattedMessage
            id="media-library-upload-modal-cancel"
            description="Cancel upload dialogue"
            defaultMessage="Cancel"
          />
        }
        onOk={this.addToLibrary}
        okText={
          <FormattedMessage
            id="media-library-upload-modal-add-to-library"
            description="Add uploaded media to Media Library"
            defaultMessage="Add to Library"
          />
        }
        title={
          <FormattedMessage
            id="media-library-upload-modal-title"
            description="Upload media dialogue title"
            defaultMessage="Upload Media"
          />
        }
        okButtonProps={{ disabled: !canAnyFileBeUploaded }}
      >
        <div data-test="mediaLibraryUploadModal.secondaryTitle">
          <FormattedMessage
            id="media-library-upload-modal-secondary-title"
            description="Upload media dialogue secondary title"
            defaultMessage="Choose how your media will be used (changing later will result in degraded image quality)"
          />
        </div>
        <div className={style.body}>
          <div className={style.columnHeader}>
            <div className={style.fileInfoHeader}>
              <FormattedMessage
                id="media-library-upload-modal-header-file-info"
                description="File info column of media list"
                defaultMessage="File info"
              />
            </div>
            <div>
              <FormattedMessage
                id="media-library-upload-modal-header-media-type"
                description="Media type column of media list"
                defaultMessage="Media type"
              />
            </div>
          </div>
          {this.renderFiles(this.props.files, this.state.uploadAsTypes)}
        </div>
      </SDSDialog>
    );
  }
}
export default intlConnect(mapStateToProps, mapDispatchToProps)(MediaLibraryUploadModal);
