import { Scope, Attribute } from 'types/permissions';
import type { ResourceRole, Urn, ParsedUrn } from 'types/permissions';
import type { Publisher } from 'types/publishers';

export const parseUrn = (urn: Urn): ParsedUrn => {
  // urn has the format urn:org_scope:resource_scope:attribute_type:attribute_value
  // e.g. - urn:discover:publisher:id:123
  //      - urn:discover:global::
  const [, , scope, attribute, value] = urn.split(':');
  return {
    scope: scope ? Scope[scope as keyof typeof Scope] : Scope.AD_PUBLISHERS,
    attribute: attribute ? Attribute[attribute as keyof typeof Attribute] : null,
    value: value || null,
  };
};

export const serialiseUrn = (urn: ParsedUrn): Urn => {
  return ['urn', 'discover', urn.scope, urn.attribute, urn.value].join(':');
};

export const rolesForPublisher = (roles: Array<ResourceRole>, publisher?: Publisher | null): Array<ResourceRole> => {
  return roles.filter(role => {
    const parsedUrn = parseUrn(role.resource);
    return (
      parsedUrn.scope === Scope.PUBLISHER &&
      parsedUrn.attribute === Attribute.ID &&
      publisher &&
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | null | undefined' is no... Remove this comment to see the full error message
      parseInt(parsedUrn.value, 10) === publisher.id
    );
  });
};
