import classNames from 'classnames';
import is from 'is_js';
import { memoize, some } from 'lodash';
import React from 'react';
import { DragSource } from 'react-dnd';
import type { ConnectDragSource, DragSourceMonitor, DragSourceConnector, ConnectDragPreview } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { FormattedMessage } from 'react-intl';

import { isPreviewMuted } from 'state/previews/selectors/previewsSelectors';

import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';
import { redirectToUrl } from 'utils/locationUtils';
import { createAssetUrl } from 'utils/media/assetUtils';

import CompositeSnapOverlay from 'views/common/components/CompositeSnapOverlay/CompositeSnapOverlay';
import SnapPreview from 'views/common/components/SnapPreview/SnapPreview';
import { isVideo, getDownloadUrl } from 'views/mediaLibrary/utils/MediaLibraryUtils';

import style from './MediaView.scss';

import { FilterByEnum, MediaTypeEnum } from 'types/mediaLibrary';
import type { MediaItem, FilterByType } from 'types/mediaLibrary';
import type { MediaLibrarySourceItems } from 'types/mediaLibraryDrawer';
import { DragAndDropTypes } from 'types/mediaLibraryDrawer';
import type { Style } from 'types/virtualized';

type OwnProps = {
  onToggle: (mediaId: string, isShiftSelect: boolean) => any;
  style?: Style;
  mediaItem: MediaItem;
  videoPosterSrc?: string;
  isSelected: boolean | undefined | null;
  showMetadataModal: () => void;
  draggable?: boolean;

  connectDragSource?: ConnectDragSource;

  connectDragPreview: ConnectDragPreview;
  isDragging?: boolean;
  selectedItemsList: MediaItem[];
  onDragStart?: () => void;
  onItemDropped?: () => void;
  onDragCancelled?: () => void;
  indexOfSnapMediaBeingUsed?: number;
  isDownloadable: boolean;
};

type StateProps = {
  muted: boolean;
};

type OwnMediaViewProps = OwnProps & StateProps;

type OwnState = {
  isHovering: boolean;
};

const mapStateToProps = (state: State): StateProps => {
  return {
    muted: isPreviewMuted(state),
  };
};

type Props = OwnMediaViewProps & typeof MediaView.defaultProps;

export class MediaView extends React.PureComponent<Props, OwnState> {
  static defaultProps = {
    isDownloadable: true,
  };

  state = {
    isHovering: false,
  };

  componentDidMount() {
    // Disabling the preview since it is handled by MediaViewDragLayer
    this.props.connectDragPreview(getEmptyImage(), { captureDraggingState: true });
  }

  onToggle = (event: any) => {
    this.props.onToggle(this.props.mediaItem.id, Boolean(event.shiftKey));
  };

  onMouseEnter = () => {
    this.setState({ isHovering: true });
  };

  onMouseLeave = () => {
    this.setState({ isHovering: false });
  };

  renderDownloadItem = () => {
    if (!this.props.isDownloadable) {
      return null;
    }
    return (
      <div key="MediaView.download" className={style.menuItem} onClick={this.downloadFile}>
        {getMessageFromId('download')}
      </div>
    );
  };

  downloadFile = () => redirectToUrl(getDownloadUrl(this.props.mediaItem));

  getMenuItems = () => {
    return [
      <div key="MediaView.moreInfo" className={style.menuItem} onClick={this.props.showMetadataModal}>
        <FormattedMessage
          id="media-library-menu-more-info"
          description="View more information of a snap"
          defaultMessage="More info"
        />
      </div>,
      this.renderDownloadItem(),
    ];
  };

  generateAssetUrl = (mediaItem: MediaItem) => {
    const options = {
      // Video attachment using HLS format, since cms does not support HLS player yet, we force to use TRANSCODED_MEDIA asset for video
      // attachment type.
      ...(is.truthy(mediaItem.mediaType === MediaTypeEnum.VIDEO_ATTACHMENT) && { assetType: 'TRANSCODED_MEDIA' }),
    };
    return createAssetUrl(this.props.mediaItem.id, options);
  };

  render() {
    const view = (
      <div
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'Style | undefined' is not assignable to type... Remove this comment to see the full error message
        style={this.props.style}
        className={classNames(style.container, { [style.dragging]: this.props.isDragging })}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        data-test="mediaView.container"
      >
        <SnapPreview
          className={style.snapPreview}
          snapMediaSrc={this.generateAssetUrl(this.props.mediaItem)}
          videoPosterSrc={this.props.videoPosterSrc}
          isVideo={isVideo(this.props.mediaItem.mediaType)}
          shouldPlayVideo={this.state.isHovering}
          muted={this.props.muted}
        />
        <CompositeSnapOverlay
          additionalText={this.props.mediaItem.fileName}
          dateTimestamp={this.props.mediaItem.createdAt}
          onToggle={this.onToggle}
          isHovering={this.state.isHovering}
          isSelected={this.props.isSelected}
          snapId={this.props.mediaItem.id}
          isVideo={isVideo(this.props.mediaItem.mediaType)}
          menuItems={this.getMenuItems()}
          indexOfSnapMediaBeingUsed={this.props.indexOfSnapMediaBeingUsed}
        />
      </div>
    );
    if (this.props.draggable && this.props.connectDragSource) {
      return this.props.connectDragSource(view);
    }
    return view;
  }
}

export const mediaViewSource = {
  beginDrag(props: Props): MediaLibrarySourceItems {
    if (props.onDragStart) {
      props.onDragStart();
    }
    if (some(props.selectedItemsList, props.mediaItem)) {
      return {
        mediaLibraryItems: props.selectedItemsList,
      };
    }
    return {
      mediaLibraryItems: [props.mediaItem],
    };
  },

  isDragging(props: Props, monitor: DragSourceMonitor): boolean {
    const sourceItems: MediaLibrarySourceItems = monitor.getItem();
    return sourceItems.mediaLibraryItems && some(sourceItems.mediaLibraryItems, props.mediaItem);
  },

  endDrag(props: Props, monitor: DragSourceMonitor) {
    if (monitor.didDrop() && props.onItemDropped) {
      props.onItemDropped();
    } else if (!monitor.didDrop() && props.onDragCancelled) {
      props.onDragCancelled();
    }
  },
};

const collectSource = (connect: DragSourceConnector, monitor: DragSourceMonitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging(),
});

export const dragAndDropTypeForFilter = (filter: FilterByType) => {
  return filter === FilterByEnum.CURATED_SNAPS ? DragAndDropTypes.COMMUNITY_SNAP : DragAndDropTypes.MEDIA_LIBRARY_ITEM;
};

export const mediaViewForFilter = memoize((filter: FilterByType) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2-3 arguments, but got 1.
  return intlConnect(mapStateToProps)(
    DragSource(dragAndDropTypeForFilter(filter), mediaViewSource, collectSource)(MediaView)
  );
});

export default intlConnect(
  mapStateToProps,
  null
)(DragSource(DragAndDropTypes.MEDIA_LIBRARY_ITEM, mediaViewSource, collectSource)(MediaView));
