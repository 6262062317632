import { Moment } from 'moment';
import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { formatDate, formatDateAndTime } from 'utils/timezoneUtils';

export default defineMessages({
  // Tooltip messages
  tooltipStoryTimeAvailable: {
    id: 'tooltip-story-time-available',
    description: 'Date appearing in tooltip when story is live',
    defaultMessage: 'Story became available on {date}',
  },
  tooltipStoryTimeUnavailable: {
    id: 'tooltip-story-time-unavailable',
    description: 'Date appearing in tooltip when story is unavailable',
    defaultMessage: 'Story became unavailable on {date}',
  },
  tooltipStoryTimeScheduledUnavailable: {
    id: 'tooltip-story-time-scheduled-unavailable',
    description: 'Date appearing in tooltip when story is scheduled to become unavailable',
    defaultMessage: 'Story will become unavailable on {date}',
  },
  tooltipStoryTimeScheduledLive: {
    id: 'tooltip-story-time-scheduled-live',
    description: 'Date appearing in tooltip when story is scheduled to become live',
    defaultMessage: 'Story will become available on {date}',
  },
  // Homepage row messages
  storyTimeAvailable: {
    id: 'story-time-available',
    description: 'Date appearing when story is live',
    defaultMessage: '{date}',
  },
});

export function formatWithDateAndTime(
  message: FormattedMessage.MessageDescriptor,
  date: number | Moment
): React.ReactNode {
  return <FormattedMessage {...message} values={{ date: formatDateAndTime(date) }} />;
}

export function formatWithDate(message: FormattedMessage.MessageDescriptor, date: number | Moment): React.ReactNode {
  return <FormattedMessage {...message} values={{ date: formatDate(date) }} />;
}
