import React from 'react';

import * as mediaLibraryActions from 'state/mediaLibrary/actions/mediaLibraryActions';
import { currentFilter } from 'state/mediaLibrary/selectors/mediaLibrarySelectors';
import * as publisherStoryEditorActions from 'state/publisherStoryEditor/actions/publisherStoryEditorActions';
import * as publisherSelectors from 'state/publishers/selectors/publishersSelectors';

import { intlConnect } from 'utils/connectUtils';

import SDSDropdown from 'views/common/components/SDSDropdown/SDSDropdown';
import { createSDSDropdownOptions } from 'views/common/components/SDSDropdownOptions/SDSDropdownOptions';
import MediaLibraryHeaderButtons from 'views/mediaLibrary/MediaLibraryHeaderButtons/MediaLibraryHeaderButtons';
import { filtersData } from 'views/mediaLibrary/config/MediaLibraryFiltersConfig';

import style from './MediaLibraryDrawerHeader.scss';

import type { FilterByType } from 'types/mediaLibrary';
import { FilterByEnum, TargetEnum } from 'types/mediaLibrary';
import type { State } from 'types/rootState';

type OwnProps = {
  onDropFiles: (a: File[]) => void;
  hideCommunitySnaps: boolean;
  isShowPublisher: boolean;
};

type DispatchProps = {
  currentFilter: FilterByType;
  setFilter: typeof mediaLibraryActions.setFilter;
  toggleMediaLibraryDrawer: typeof publisherStoryEditorActions.toggleMediaLibraryDrawer;
};

type Props = OwnProps & DispatchProps;

export const mapStateToProps = (state: State) => {
  return {
    currentFilter: currentFilter(state),
    hideCommunitySnaps: publisherSelectors.hideCommunitySnaps(state),
    isShowPublisher: publisherSelectors.activePublisherIsShow(state),
  };
};

const mapDispatchToProps = {
  setFilter: mediaLibraryActions.setFilter,
  toggleMediaLibraryDrawer: publisherStoryEditorActions.toggleMediaLibraryDrawer,
};

export class MediaLibraryDrawerHeader extends React.PureComponent<Props> {
  componentDidMount() {
    if (this.props.currentFilter === FilterByEnum.VIDEO_ATTACHMENTS) {
      this.onSelectFilter(FilterByEnum.VIDEO_SNAPS);
    }
  }

  onSelectFilter = (key: FilterByType) => {
    this.props.setFilter(key, TargetEnum.TOP_SNAP);
  };

  render() {
    let targetFilters = filtersData.filter(filter => filter.targets.indexOf(TargetEnum.TOP_SNAP) !== -1);

    if (this.props.hideCommunitySnaps) {
      targetFilters = targetFilters.filter(filter => filter.key !== FilterByEnum.CURATED_SNAPS);
    }

    if (this.props.isShowPublisher) {
      targetFilters = targetFilters.filter(filter => filter.key !== FilterByEnum.IMAGE_SNAPS);
    }

    const dropdownItems = targetFilters.map(filter => {
      return { value: filter.key, label: filter.text };
    });
    return (
      <div className={style.container}>
        <SDSDropdown
          className={style.dropdown}
          onChange={this.onSelectFilter}
          defaultValue={FilterByEnum.RECENT}
          disableClear
          dropdownWidthMatchContent
          data-test="mediaLibraryDrawerHeader.dropDown"
        >
          {createSDSDropdownOptions(dropdownItems)}
        </SDSDropdown>
        <MediaLibraryHeaderButtons
          onCancel={this.props.toggleMediaLibraryDrawer}
          onDropFiles={this.props.onDropFiles}
        />
      </div>
    );
  }
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(MediaLibraryDrawerHeader);
