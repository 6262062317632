import React from 'react';

import { CrossOrigin } from 'config/constants';

import style from './ImageSnapPreview.scss';

type OwnProps = {
  src: string;
};

type StateProps = {
  className?: string;
};

type Props = OwnProps & StateProps;

export class ImageSnapPreview extends React.Component<Props> {
  image: HTMLImageElement | undefined | null;

  render() {
    return (
      <img
        src={this.props.src}
        crossOrigin={CrossOrigin.USE_CREDENTIALS}
        alt="Snap preview"
        className={style.snapImagePreview}
        data-test="snapImagePreview"
      />
    );
  }
}

export default ImageSnapPreview;
