import classNames from 'classnames';
import React, { Component } from 'react';

import { CheckboxState } from 'config/constants';

import { updateIfPropsAndStateChanged } from 'views/propTypes/utils';

import style from './TriStateCheckbox.scss';

type OwnProps = {
  checkboxState?: any; // TODO: PropTypes.oneOf(Object.values(CheckboxState))
  onChange?: (...args: any[]) => any;
  className?: string;
};

type State = any;

type Props = OwnProps & typeof TriStateCheckbox.defaultProps;

export default class TriStateCheckbox extends Component<Props, State> {
  static defaultProps = {
    checkboxState: CheckboxState.UNCHECKED,
    onChange: () => {},
  };

  static checkboxStyles = {
    [CheckboxState.INDETERMINATE]: style.indeterminate,
    [CheckboxState.CHECKED]: style.checked,
    [CheckboxState.UNCHECKED]: style.unchecked,
  };

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    return updateIfPropsAndStateChanged(this.props, this.state, nextProps, nextState);
  }

  /**
   * onClick pass the old status and let parent component decides which state to go
   */
  onClick = (event: any) => {
    this.props.onChange(event, this.props.checkboxState);
  };

  render() {
    return (
      <div className={classNames(style.root, this.props.className)} onClick={this.onClick}>
        <div
          className={classNames(TriStateCheckbox.checkboxStyles[this.props.checkboxState], style.checkbox)}
          data-test="tristateCheckbox.checkbox"
        />
      </div>
    );
  }
}
