import React from 'react';
import { FormattedMessage } from 'react-intl';

import { fetchEditionBuildStatus } from 'state/buildStatus/actions/buildStatusActions';
import { generateSubtitlesForActivePublisher } from 'state/contentRecognition/actions/contentRecognitionActions';
import { reloadSnaps } from 'state/editions/actions/editionsActions';

import { LocalStorage } from 'config/constants';
import { intlConnect } from 'utils/connectUtils';
import * as grapheneUtils from 'utils/grapheneUtils';
import { localStorage } from 'utils/localStorageUtils';

import OneTimeConfirmationModal, {
  OneTimeConfirmationModalOptions,
} from 'views/modals/components/OneTimeConfirmationModal/OneTimeConfirmationModal';

import type { EditionID } from 'types/editions';

type OwnProps = {
  modalId: string;
  options: {
    storyId: EditionID;
  };
};

type DispatchProps = {
  fetchEditionBuildStatus: typeof fetchEditionBuildStatus;
  generateSubtitles: (a: EditionID) => Promise<void>;
  reloadSnaps: typeof reloadSnaps;
};

type Props = OwnProps & DispatchProps;

const mapDispatchToProps = {
  generateSubtitles: generateSubtitlesForActivePublisher,
  reloadSnaps,
  fetchEditionBuildStatus,
};

export class GenerateSubtitlesConfirmModal extends React.Component<Props> {
  onSubtitlesGenerationModalConfirm = (dontShowAgain: boolean) => {
    localStorage.setItem(LocalStorage.DISMISS_GENERATE_SUBTITLES_MODAL, String(dontShowAgain));
    const editionId = this.props.options.storyId;
    grapheneUtils.incrementCounter('ScheduleButton.confirmSubtitlesGeneration', {
      dontShowAgain: String(dontShowAgain),
    });
    return this.props
      .generateSubtitles(editionId)
      .then(() => this.props.reloadSnaps(editionId))
      .then(() => this.props.fetchEditionBuildStatus({ editionId, noBailout: true }));
  };

  onSubtitlesGenerationModalCancel = (dontShowAgain: boolean) => {
    localStorage.setItem(LocalStorage.DISMISS_GENERATE_SUBTITLES_MODAL, String(dontShowAgain));
    grapheneUtils.incrementCounter('ScheduleButton.cancelSubtitlesGeneration', {
      dontShowAgain: String(dontShowAgain),
    });
  };

  render() {
    const options: OneTimeConfirmationModalOptions = {
      onConfirm: this.onSubtitlesGenerationModalConfirm,
      onCancel: this.onSubtitlesGenerationModalCancel,
      confirmButtonMessage: (
        <FormattedMessage
          id="subtitles-generation-prompt-confirm"
          defaultMessage="Yes, Auto-generate"
          description="Button caption to executue the auto generation of the subtitles"
        />
      ),
      cancelButtonMessage: (
        <FormattedMessage
          id="subtitles-generation-prompt-cancel"
          defaultMessage="No, thanks"
          description="Button caption to dismiss the subtitle auto generation"
        />
      ),
      title: (
        <FormattedMessage
          id="subtitles-generation-prompt-title"
          defaultMessage="Subtitles"
          description="Headline at the prompt to auto generate subtitles"
        />
      ),
      body: (
        <>
          <FormattedMessage
            id="subtitles-generation-prompt-message-line-1"
            defaultMessage="We noticed that your story doesn't have any subtitles. Would you like to auto-generate them?"
            description="First line of message to prompt users to click to auto generate subtitles"
          />
          <br />
          <br />
          <FormattedMessage
            id="subtitles-generation-prompt-message-line-2"
            defaultMessage="Proof-reading is strongly recommended."
            description="Second line of the message to prompt users to click to auto generate subtitles"
          />
        </>
      ),
      visible: true,
      isBodyCentered: true,
    };

    const newProps = { ...this.props, options };

    return <OneTimeConfirmationModal {...newProps} />;
  }
}

export default intlConnect(null, mapDispatchToProps)(GenerateSubtitlesConfirmModal);
