import React from 'react';
import { FormattedMessage } from 'react-intl';

import { isMobile, isDesktop } from 'utils/platform/platformUtils';

import stuntedGhost from 'images/stuntedGhost.svg';

import style from './UnsupportedBrowser.scss';

const clickHereMessage = (
  <FormattedMessage id="click-here" description="'click here' link for user to click" defaultMessage="click here" />
);

const chromeDownloadLink = 'https://www.google.com/chrome/browser/desktop/index.html';

type Props = {};

type State = any;

export class UnsupportedBrowser extends React.Component<Props, State> {
  state = {
    loaded: false,
    allowUserToProceed: false,
  };

  UNSAFE_componentWillMount() {
    // The css might not be loaded yet so we must wait or the page will look awful for an instant.
    if (document.readyState === 'complete') {
      this.setState({ loaded: true });
    } else {
      global.window.addEventListener('load', this.updateLoadedState, true);
    }

    this.setState({ allowUserToProceed: isMobile() || isDesktop() });
  }

  updateLoadedState = () => {
    this.setState({ loaded: true });
  };

  clickToProceed = () => {
    this.setState({ allowUserToProceed: true });
  };

  renderUnsupportedBrowserPage() {
    const link = (
      <a target="_blank" href={chromeDownloadLink} className={style.clickHere}>
        {clickHereMessage}
      </a>
    );

    return (
      <div className={style.root}>
        <img src={stuntedGhost} alt="Unsupported Browser" />
        <FormattedMessage
          id="unsupported-browser-line1"
          description="Unsupported browser message line 1"
          defaultMessage="Your current browser is unsupported for this application."
        />
        <FormattedMessage
          id="unsupported-browser-line2"
          description="Unsupported browser message line 2"
          defaultMessage="Please {clickHere} to get Chrome."
          values={{ clickHere: link }}
        />
      </div>
    );
  }

  render() {
    if (!this.state.allowUserToProceed) {
      if (!this.state.loaded) {
        return null;
      }

      if (!isMobile() || !isDesktop()) {
        return this.renderUnsupportedBrowserPage();
      }
    }

    return React.Children.only(this.props.children);
  }
}

export default UnsupportedBrowser;
