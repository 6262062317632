import is from 'is_js';
import _ from 'lodash';
import React from 'react';
import { FormattedMessage, intlShape } from 'react-intl';

import { isFeedCheckboxesDisplayEnabled } from 'state/features/selectors/featuresSelectors';
import * as feedsActions from 'state/feeds/actions/feedsActions';
import * as feedsSelectors from 'state/feeds/selectors/feedsSelectors';
import { AUTO_PUBLISH, MANUAL_PUBLISH } from 'state/feeds/stages/feedsStageConfig';
import * as modalsActions from 'state/modals/actions/modalsActions';
import * as publishersSelectors from 'state/publishers/selectors/publishersSelectors';
import * as stagesActions from 'state/stages/actions/stagesActions';
import * as stagesSelectors from 'state/stages/selectors/stagesSelectors';
import { hasClaimForActivePublisher } from 'state/user/selectors/userSelectors';

import { EMPTY_ARRAY, StageType } from 'config/constants';
import { intlConnect } from 'utils/connectUtils';
import { getLocalisedMessageFromId, getMessageFromId, registerIntlMessage } from 'utils/intlMessages/intlMessages';

import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';
import { SDSCheckbox } from 'views/common/components/SDSCheckbox/SDSCheckbox';
import SDSCustomModal from 'views/common/components/SDSCustomModal/SDSCustomModal';
import SDSDropdown from 'views/common/components/SDSDropdown/SDSDropdown';
import { createSDSDropdownOptions } from 'views/common/components/SDSDropdownOptions/SDSDropdownOptions';
import SDSInput from 'views/common/components/SDSInput/SDSInput';
import SDSTable from 'views/common/components/SDSTable/SDSTable';
import SpinnerIcon from 'views/common/components/SpinnerIcon/SpinnerIcon';
import StatusRow from 'views/common/components/StatusRow/StatusRow';
import { CATEGORIES } from 'views/modals/containers/RSSFeedModal/categories';
import RSSFeedOptionsButton from 'views/modals/containers/RSSFeedOptionsButton/RSSFeedOptionsButton';

import style from './RSSFeedModal.scss';

import type { Feed, FeedID } from 'types/feeds';
import type { HNCategoryID } from 'types/hnCategories';
import { Claim } from 'types/permissions';
import type { BusinessProfileID, Publisher } from 'types/publishers';
import type { State } from 'types/rootState';
import { FeedApiType, FeedStatusEnum, FeedType } from 'types/rssFeed';
import type { FeedStatusType } from 'types/rssFeed';

const feedsTitleMsg = (
  <FormattedMessage id="hn-feeds-title" description="Modal title for managing RSS feeds" defaultMessage="Feeds" />
);

const statusMsg = (
  <FormattedMessage id="hn-feed-status" description="Happening Now feed status" defaultMessage="Status" />
);

registerIntlMessage({
  intlMessage: <FormattedMessage id="hn-feed-name" description="Happening Now feed name" defaultMessage="Name" />,
  params: [],
});

const defaultCategoryMsg = (
  <FormattedMessage
    id="hn-feed-default-category"
    description="Happening Now feed default category"
    defaultMessage="Default Category"
  />
);

registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="hn-feed-select-default-category"
      description="Placeholder text for category select dropdown"
      defaultMessage="Select Category"
    />
  ),
  params: [],
});

const defaultTemplateMsg = (
  <FormattedMessage
    id="hn-feed-default-template"
    description="Happening Now feed default template"
    defaultMessage="Default Template"
  />
);

const defaultUseContentMsg = (
  <FormattedMessage
    id="hn-feed-default-use-content"
    description="Feed use content from feed"
    defaultMessage={'Content From Feed'}
  />
);

const defaultUseMediaContentMsg = (
  <FormattedMessage
    id="hn-feed-default-use-media-content"
    description="Feed use media from feed"
    defaultMessage={'Media From Feed'}
  />
);

const defaultUseHeadlineFromFeed = (
  <FormattedMessage
    id="hn-feed-default-use-media-headline-content"
    description="Feed use media headline from feed"
    defaultMessage={'Headline From Feed'}
  />
);

const defaultUseUrlFromFeed = (
  <FormattedMessage
    id="hn-feed-default-use-url-content"
    description="Feed use url from feed"
    defaultMessage={'Url From Feed'}
  />
);

registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="hn-feed-template-id"
      description="Template ID for this Happening Now feed"
      defaultMessage="Template ID"
    />
  ),
  params: [],
});

registerIntlMessage({
  intlMessage: <FormattedMessage id="hn-feed-type" description="The type of the feed" defaultMessage="Type" />,
  params: [],
});

registerIntlMessage({
  intlMessage: <FormattedMessage id="hn-feed-api-type" description="The API type" defaultMessage="API Type" />,
  params: [],
});

const autoPublishMsg = (
  <FormattedMessage
    id="hn-feed-auto-publish"
    description="Whether this Happening Now feed's stories will be auto-submitted"
    defaultMessage="Publish Type"
  />
);

registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="hn-feed-auto-publish-option"
      description="Label for auto-submit option"
      defaultMessage="Auto-submit"
    />
  ),
  params: [],
});

registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="hn-feed-manual-publish-option"
      description="Label for manual publish option"
      defaultMessage="Manual"
    />
  ),
  params: [],
});

registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="hn-feed-approval-delay"
      description="Length of time in minutes before Happening Now story is auto-submitted"
      defaultMessage="Auto-submit Delay (mins)"
    />
  ),
  params: [],
});

type FeedDataRecord = {
  key: FeedID;
  status?: FeedStatusType;
  url?: string;
  categoryId?: HNCategoryID;
  templateId?: string;
  autoPublish?: boolean;
  approvalDelay: number;
  approvalDelayRef: {
    current: null | HTMLInputElement;
  };
  enabled?: boolean;
  name?: string;
  useContentFromFeed?: boolean;
  useMediaFromFeed?: boolean;
  useMediaHeadlineFromFeed?: boolean;
  useUrlFromFeed?: boolean;
  error?: string;
  feedType?: FeedType;
  apiType?: FeedApiType;
  isPlaceholder?: boolean;
};

type OwnProps = {
  modalId: string;
};

type StateProps = {
  businessProfileId: BusinessProfileID;
  publisher: Publisher;
  feedsLoading: boolean;
  feeds: Array<Feed> | undefined | null;
  isSuperAdmin: boolean;
  areStagesNetDirty: boolean;
  isFeedCheckboxesDisplayEnabled: boolean;
};

type DispatchProps = {
  hideModal: typeof modalsActions.hideModal;
  getFeedsByOwnerId: (a: BusinessProfileID) => Promise<unknown>;
  updateFeed: (
    feedId: FeedID,
    name?: string | null,
    categoryId?: HNCategoryID | null,
    templateId?: string | null,
    autoPublish?: boolean | null,
    approvalDelay?: number | null,
    enabled?: boolean | null,
    useContentFromFeed?: boolean | null,
    useMediaFromFeed?: boolean | null,
    useMediaHeadlineFromFeed?: boolean | null,
    useUrlFromFeed?: boolean | null,
    feedType?: FeedType,
    apiType?: FeedApiType
  ) => Promise<unknown>;
  deleteFeed: (a: FeedID) => Promise<unknown>;
  updateProperties: typeof stagesActions.updateProperties;
  commitData: typeof stagesActions.commitData;
  discardData: typeof stagesActions.discardData;
};

type Props = OwnProps & StateProps & DispatchProps;

type OwnState = {
  feedDataRecords: Array<FeedDataRecord>;
  hasPlaceholder: boolean;
  saving: boolean;
};

const mapStateToProps = (state: State) => {
  const businessProfileId = publishersSelectors.getActivePublisherBusinessProfileId(state);
  const feeds = businessProfileId && feedsSelectors.getFeedsByOwnerId(state)(businessProfileId);
  return {
    businessProfileId,
    publisher: publishersSelectors.getActivePublisherDetails(state),
    feedsLoading: feedsSelectors.getFeedsByOwnerIdLoading(state)(businessProfileId),
    feeds,
    isSuperAdmin: hasClaimForActivePublisher(state, Claim.SUPER_ADMIN_VIEWER),
    isFeedCheckboxesDisplayEnabled: isFeedCheckboxesDisplayEnabled(state),
    areStagesNetDirty: stagesSelectors.areStagesNetDirty(state)(
      (feeds || ([] as Feed[])).map(x => x.id),
      StageType.HN_FEED
    ),
  };
};

const mapDispatchToProps = {
  hideModal: modalsActions.hideModal,
  getFeedsByOwnerId: feedsActions.getFeedsByOwnerId,
  updateFeed: feedsActions.updateFeed,
  deleteFeed: feedsActions.deleteFeed,
  updateProperties: stagesActions.updateProperties,
  commitData: stagesActions.commitData,
  discardData: stagesActions.discardData,
};

export class RSSFeedModal extends React.Component<Props, OwnState> {
  static placeholderFeedIdPrefix = 'NEW_HN_FEED';

  static validFeedUrlProtocolRegex = RegExp('^(http|https)://');

  static generatePlaceholderFeedId = () => `${RSSFeedModal.placeholderFeedIdPrefix}_${Date.now()}`;

  static contextTypes = {
    intl: intlShape,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      feedDataRecords: this.props.feeds ? this.props.feeds.map(this.toFeedDataRecord) : [],
      hasPlaceholder: false,
      saving: false,
    };
  }

  componentDidUpdate(prevProps: Props, prevState: OwnState) {
    // Initiating with existing feed records.
    if (prevState?.feedDataRecords.length === 0 && this.props.feeds!.length > 0) {
      const feedDataRecords = this.props.feeds ? this.props.feeds.map(this.toFeedDataRecord) : [];
      this.setState({ feedDataRecords }); // eslint-disable-line
      return;
    }

    // Deleting existing record in modal.
    if (prevState?.feedDataRecords.filter(record => !record.isPlaceholder).length !== this.props.feeds!.length) {
      const updatedRecordKeys = this.props.feeds!.map(record => record.id);
      const feedDataRecords = prevState?.feedDataRecords.filter(
        record => record.isPlaceholder || updatedRecordKeys.includes(record.key)
      );
      this.setState({ feedDataRecords }); // eslint-disable-line
    }
  }

  componentDidMount() {
    this.props.getFeedsByOwnerId(this.props.businessProfileId);
  }

  closeModal = () => {
    this.props.hideModal(this.props.modalId);
    ((this.props.feeds || []) as Feed[]).forEach(feed => {
      this.props.discardData(feed.id);
    });
  };

  handlePropertyUpdate = _.memoize(
    (feedId, propertyName) => (e: any) => {
      let updatedValue;
      if (e.target && e.target.type === 'checkbox') {
        updatedValue = e.target.checked;
      } else {
        updatedValue = e.target ? e.target.value : e;
      }
      this.props.updateProperties(feedId, {
        [propertyName]: updatedValue,
      });
    },
    (feedId, propertyName) => `${feedId}__${propertyName}`
  );

  getApprovalDelayRef = _.memoize((feedId: FeedID) => React.createRef<HTMLInputElement>());

  handleAutoPublishChange = _.memoize(
    (record: FeedDataRecord) => (value: string) => {
      this.handlePropertyUpdate(record.key, 'autoPublish')(value);

      // For simplicity (in initial implementation), relied on uncontrolled form inputs and used the staging area to hold only what actually
      // changed: https://github.sc-corp.net/Snapchat/discover-cms/pull/5095#discussion_r1886488
      // A drawback of this approach was that pending (unsaved) user changes were not reflected in component state, which was fine at the time
      // because each form input was independent of each other.
      // However, https://jira.sc-corp.net/browse/PUB-6780 meant `approvalDelay` input is now also dependent on `autoPublish` input value.
      //
      // Fully implementing staging area for feeds and using this as source of data for this component would fix this, but Table component
      // requires all feeds to be returned as 1 object in mapStateToProps. This led to a perceptibly slower UI as every change led to the
      // entire component to being re-rendered.
      //
      // As such, using React refs as a dirty workaround for now.

      const autoPublish = value === AUTO_PUBLISH;
      const approvalDelayElement = record.approvalDelayRef.current;

      if (approvalDelayElement) {
        approvalDelayElement.value = autoPublish ? `${record.approvalDelay}` : '';
        approvalDelayElement.readOnly = !autoPublish;
      }
    },
    record => `${record.key}_${record.approvalDelay}`
  );

  handleSave = (feeds: Array<FeedDataRecord>) => () => {
    this.setState(
      {
        saving: true,
      },
      () => {
        Promise.all(
          feeds.map(feed => {
            return this.props.commitData(feed.key, StageType.HN_FEED);
          })
        )
          .then(() => {
            this.closeModal();
          })
          .catch(() => {
            this.setState({ saving: false });
          });
      }
    );
  };

  updateToggleState = _.memoize(
    (feedId, feedEnabled) => async () => {
      return this.props.updateFeed(feedId, null, null, null, null, null, !feedEnabled);
    },
    (feedId, currentValue) => `${feedId}__${currentValue}`
  );

  handleEnableToggle = (feedId: FeedID, feedEnabled?: boolean) => {
    return () => {
      const feedDataRecords = this.state.feedDataRecords.map(feed => {
        if (feed.key === feedId) {
          return { ...feed, enabled: !feedEnabled, status: this.calculateFeedStatus(feed.error, !feedEnabled) };
        }
        return feed;
      });
      this.setState({ feedDataRecords });
      this.updateToggleState(feedId, feedEnabled)();
    };
  };

  handleDelete = _.memoize(feedId => () => {
    this.props.deleteFeed(feedId);
    this.props.discardData(feedId);
  });

  isValidUrl = (url: string) => {
    return url.length !== 0 && RSSFeedModal.validFeedUrlProtocolRegex.test(url) && is.url(url);
  };

  validateUrlInput = (record: FeedDataRecord) => {
    return (urlInput: string) => {
      if (!record.isPlaceholder) {
        // Skip url validation for existing records for backward compatibility.
        return true;
      }
      const result = !!urlInput && this.isValidUrl(urlInput);
      const feedDataRecords = this.state.feedDataRecords.map(feed => {
        if (feed.key === record.key) {
          return { ...feed, url: urlInput };
        }
        return feed;
      });
      this.setState({ feedDataRecords });
      return result;
    };
  };

  validateNameInput = (record: FeedDataRecord) => {
    return (nameInput: string) => {
      const result = !!nameInput;
      const feedDataRecords = this.state.feedDataRecords.map(feed => {
        if (feed.key === record.key) {
          return { ...feed, name: nameInput };
        }
        return feed;
      });
      this.setState({ feedDataRecords });
      return result;
    };
  };

  validateTemplateIdInput = (record: FeedDataRecord) => {
    return (templateIdInput: string) => {
      const result = !!templateIdInput;
      const feedDataRecords = this.state.feedDataRecords.map(feed => {
        if (feed.key === record.key) {
          return { ...feed, templateId: templateIdInput };
        }
        return feed;
      });
      this.setState({ feedDataRecords });
      return result;
    };
  };

  areInputsValid() {
    return this.state.feedDataRecords.every(record => {
      return (!record.isPlaceholder || (record.url && this.isValidUrl(record.url))) && record.name && record.templateId;
    });
  }

  renderStatus = (feedStatus: FeedStatusType) => <StatusRow status={feedStatus} />;

  renderUrl = (feedUrl: string, record: FeedDataRecord) => (
    <SDSInput
      className={style.textInput}
      validateInput={this.validateUrlInput(record)}
      value={feedUrl}
      disabled={!!feedUrl && !record.isPlaceholder}
      placeholder={getLocalisedMessageFromId(this.context, 'url-msg')}
      onChange={this.handlePropertyUpdate(record.key, 'url')}
      data-test="rssFeedModal.url"
    />
  );

  renderName = (feedName: string, record: FeedDataRecord) => (
    <SDSInput
      className={style.textInput}
      validateInput={this.validateNameInput(record)}
      value={feedName}
      placeholder={getLocalisedMessageFromId(this.context, 'hn-feed-name')}
      onChange={this.handlePropertyUpdate(record.key, 'name')}
      data-test="rssFeedModal.name"
    />
  );

  renderCategory = (feedCategoryId: HNCategoryID, record: FeedDataRecord) => {
    return (
      <SDSDropdown
        dropdownWidthMatchContent
        className={style.dropdownInput}
        defaultValue={feedCategoryId}
        placeholder={getLocalisedMessageFromId(this.context, 'hn-feed-select-default-category')}
        disableClear
        onChange={this.handlePropertyUpdate(record.key, 'categoryId')}
        data-test="rssFeedModal.selectCategoryDropdown"
      >
        {createSDSDropdownOptions(CATEGORIES)}
      </SDSDropdown>
    );
  };

  renderTemplate = (templateId: string, record: FeedDataRecord) => (
    <SDSInput
      className={style.textInput}
      validateInput={this.validateTemplateIdInput(record)}
      value={templateId}
      placeholder={getLocalisedMessageFromId(this.context, 'hn-feed-template-id')}
      onChange={this.handlePropertyUpdate(record.key, 'templateId')}
      data-test="rssFeedModal.template"
    />
  );

  renderCheckBox = (propertyName: string) => (value: boolean, record: FeedDataRecord) => (
    <div className={style.checkboxInput}>
      <SDSCheckbox
        onChange={this.handlePropertyUpdate(record.key, propertyName)}
        defaultChecked={value}
        data-test={`rssFeedModal.${propertyName}`}
      />
    </div>
  );

  renderUseContentFromFeed = (useContentFromFeed: boolean, record: FeedDataRecord) => (
    <div className={style.checkboxInput}>
      <SDSCheckbox
        onChange={this.handlePropertyUpdate(record.key, 'useContentFromFeed')}
        defaultChecked={useContentFromFeed}
      />
    </div>
  );

  renderUseMediaFromFeed = (useMediaFromFeed: boolean, record: FeedDataRecord) => (
    <div className={style.checkboxInput}>
      <SDSCheckbox
        onChange={this.handlePropertyUpdate(record.key, 'useMediaFromFeed')}
        defaultChecked={useMediaFromFeed}
      />
    </div>
  );

  renderUseMediaHeadlineFromFeed = (useMediaHeadlineFromFeed: boolean, record: FeedDataRecord) => (
    <div className={style.checkboxInput}>
      <SDSCheckbox
        onChange={this.handlePropertyUpdate(record.key, 'useMediaHeadlineFromFeed')}
        defaultChecked={useMediaHeadlineFromFeed}
      />
    </div>
  );

  renderUseUrlFromFeed = (useUrlFromFeed: boolean, record: FeedDataRecord) => (
    <div className={style.checkboxInput}>
      <SDSCheckbox onChange={this.handlePropertyUpdate(record.key, 'useUrlFromFeed')} defaultChecked={useUrlFromFeed} />
    </div>
  );

  approvalOptions = _.memoize(() => [
    { label: getLocalisedMessageFromId(this.context, 'hn-feed-auto-publish-option'), value: AUTO_PUBLISH },
    { label: getLocalisedMessageFromId(this.context, 'hn-feed-manual-publish-option'), value: MANUAL_PUBLISH },
  ]);

  renderAutoPublish = (autoPublish: boolean, record: FeedDataRecord) => {
    const currentValue = autoPublish ? AUTO_PUBLISH : MANUAL_PUBLISH;
    return (
      <SDSDropdown
        dropdownWidthMatchContent
        className={style.dropdownInput}
        defaultValue={currentValue}
        disableClear
        onChange={this.handleAutoPublishChange(record)}
        data-test="rssFeedModal.autoPublish"
      >
        {createSDSDropdownOptions(this.approvalOptions())}
      </SDSDropdown>
    );
  };

  renderApprovalDelay = (approvalDelay: number, record: FeedDataRecord) => {
    const currentValue = record.autoPublish ? approvalDelay : '';
    return (
      <input
        className={style.textInput}
        defaultValue={currentValue}
        readOnly={!record.autoPublish}
        placeholder={getLocalisedMessageFromId(this.context, 'hn-feed-approval-delay')}
        onChange={this.handlePropertyUpdate(record.key, 'approvalDelay')}
        data-test="rssFeedModal.approvalDelay"
        ref={record.approvalDelayRef}
      />
    );
  };

  renderOptions = (feedId: FeedID, record: FeedDataRecord) => {
    if (!feedId) {
      return null;
    }

    return (
      <RSSFeedOptionsButton
        isEnabled={!!record.enabled}
        handleEnableToggle={this.handleEnableToggle(feedId, record.enabled)}
        handleDelete={this.handleDelete(feedId)}
      />
    );
  };

  renderType = (feedId: FeedID, record: FeedDataRecord) => {
    const options = Object.keys(FeedType).map(feedType => ({
      label: feedType, // This is only for super admins
      value: feedType,
    }));

    return (
      <SDSDropdown
        dropdownWidthMatchContent
        className={style.dropdownInput}
        defaultValue={record.feedType || FeedType.RSS}
        disableClear
        disabled={!!record.feedType}
        onChange={this.handlePropertyUpdate(record.key, 'feedType')}
        data-test="rssFeedModal.selectFeedTypeDropdown"
      >
        {createSDSDropdownOptions(options)}
      </SDSDropdown>
    );
  };

  renderApiType = (feedId: FeedID, record: FeedDataRecord) => {
    const options = Object.keys(FeedApiType).map(apiType => ({
      label: apiType, // This is only for super admins
      value: apiType,
    }));

    return (
      <SDSDropdown
        dropdownWidthMatchContent
        className={style.dropdownInput}
        defaultValue={record.apiType}
        disableClear
        disabled={!!record.apiType}
        onChange={this.handlePropertyUpdate(record.key, 'apiType')}
        data-test="rssFeedModal.selectApiTypeDropdown"
      >
        {createSDSDropdownOptions(options)}
      </SDSDropdown>
    );
  };

  getFeedColumns = () => {
    const autoPublishColumns = this.props.publisher.permitAutoPublishFromFeed
      ? [
          {
            title: autoPublishMsg,
            dataIndex: 'autoPublish',
            className: style.rssColumn,
            render: this.renderAutoPublish,
          },
          {
            title: getMessageFromId('hn-feed-approval-delay'),
            dataIndex: 'approvalDelay',
            className: style.rssColumn,
            render: this.renderApprovalDelay,
          },
        ]
      : EMPTY_ARRAY;

    const optionsColumn = {
      dataIndex: 'id',
      className: style.rssColumn,
      render: this.renderOptions,
    };

    const nonGatedColumns = [
      {
        title: statusMsg,
        dataIndex: 'status',
        className: style.rssColumn,
        render: this.renderStatus,
      },
      {
        title: getMessageFromId('url-msg'),
        dataIndex: 'url',
        className: style.rssColumn,
        render: this.renderUrl,
      },
      {
        title: getMessageFromId('hn-feed-name'),
        dataIndex: 'name',
        className: style.rssColumn,
        render: this.renderName,
      },
      {
        title: defaultCategoryMsg,
        dataIndex: 'categoryId',
        className: style.rssColumn,
        render: this.renderCategory,
      },
      {
        title: defaultTemplateMsg,
        dataIndex: 'templateId',
        className: style.rssColumn,
        render: this.renderTemplate,
      },
    ];

    const superAdminColumns =
      this.props.isSuperAdmin || this.props.isFeedCheckboxesDisplayEnabled
        ? [
            {
              title: defaultUseContentMsg,
              dataIndex: 'useContentFromFeed',
              className: style.rssColumn,
              render: this.renderCheckBox('useContentFromFeed'),
            },
            {
              title: defaultUseMediaContentMsg,
              dataIndex: 'useMediaFromFeed',
              className: style.rssColumn,
              render: this.renderCheckBox('useMediaFromFeed'),
            },
            {
              title: defaultUseHeadlineFromFeed,
              dataIndex: 'useMediaHeadlineFromFeed',
              className: style.rssColumn,
              render: this.renderCheckBox('useMediaHeadlineFromFeed'),
            },
            {
              title: defaultUseUrlFromFeed,
              dataIndex: 'useUrlFromFeed',
              className: style.rssColumn,
              render: this.renderCheckBox('useUrlFromFeed'),
            },
            {
              title: getMessageFromId('hn-feed-type'),
              dataIndex: 'feedType',
              className: style.rssColumn,
              render: this.renderType,
            },
            {
              title: getMessageFromId('hn-feed-api-type'),
              dataIndex: 'apiType',
              className: style.rssColumn,
              render: this.renderApiType,
            },
          ]
        : EMPTY_ARRAY;

    return [...nonGatedColumns, ...autoPublishColumns, ...superAdminColumns, optionsColumn];
  };

  calculateFeedStatus = (feedError?: string, feedEnabled?: boolean): FeedStatusType => {
    if (feedError) {
      return FeedStatusEnum.FAILED;
    }

    return feedEnabled ? FeedStatusEnum.ACTIVE : FeedStatusEnum.INACTIVE;
  };

  toFeedDataRecord = (feed: Feed): FeedDataRecord => {
    return {
      ...feed,
      key: feed.id,
      url: feed.url,
      name: feed.name,
      templateId: feed.templateId,
      status: this.calculateFeedStatus(feed.error, feed.enabled),
      approvalDelay: Math.floor(feed.approvalDelay / 60),
      approvalDelayRef: this.getApprovalDelayRef(feed.id),
    };
  };

  title = (feeds: Array<FeedDataRecord>) => <h2 data-test="rssFeedModalTitle">{feedsTitleMsg}</h2>;

  renderFeeds = (feeds: Array<FeedDataRecord>) => (
    <SDSTable columns={this.getFeedColumns()} dataSource={feeds} pagination={false} />
  );

  renderFooter = () => {
    const feedsToRender = this.state.feedDataRecords;
    return (
      <>
        <SDSButton type={ButtonType.PRIMARY} onClick={this.newPlaceholderFeed} data-test="add-feed-button">
          {getMessageFromId('add-feed')}
        </SDSButton>
        <SDSButton
          type={ButtonType.PRIMARY}
          disabled={
            this.state.saving || (!this.props.areStagesNetDirty && !this.state.hasPlaceholder) || !this.areInputsValid()
          }
          onClick={this.handleSave(feedsToRender)}
          data-test="save-button"
        >
          {getMessageFromId('save-changes')}
        </SDSButton>
      </>
    );
  };

  newPlaceholderFeed = () => {
    const defaultApprovalDelay = this.props.publisher.timeUntilAutoPublishFeedStory || 0;
    const feedId = RSSFeedModal.generatePlaceholderFeedId();
    this.setState({
      feedDataRecords: [
        ...this.state.feedDataRecords,
        {
          key: feedId,
          approvalDelay: Math.floor(defaultApprovalDelay / 60),
          approvalDelayRef: this.getApprovalDelayRef(feedId),
          isPlaceholder: true,
        },
      ],
      hasPlaceholder: true,
    });
  };

  render() {
    const feedsToRender = this.state.feedDataRecords;
    return (
      <SDSCustomModal
        visible
        width={1400}
        onClose={this.closeModal}
        title={
          <FormattedMessage
            id="hn-feeds-title"
            description="Modal title for managing RSS feeds"
            defaultMessage="Feeds"
          />
        }
        footer={this.renderFooter()}
        isBodyCentered
      >
        {this.props.feedsLoading ? <SpinnerIcon className={style.spinner} /> : this.renderFeeds(feedsToRender)}
      </SDSCustomModal>
    );
  }
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(RSSFeedModal);
