import React from 'react';
import { FormattedMessage, intlShape } from 'react-intl';

import { SUBSCRIBE_EVENT_TABLE, SubscriberEvent } from 'constants/subscriptions';

import SDSCheckbox from 'views/common/components/SDSCheckbox/SDSCheckbox';
import SDSTable from 'views/common/components/SDSTable/SDSTable';

import style from './UserSubscriptionsTable.scss';

type Props = {
  availableSubscriptionEvents: Array<SubscriberEvent>;
  isSubscriptionChecked: (a: SubscriberEvent) => boolean;
  isSubscriptionDisabled: boolean;
  updateUserSubscriptions: (a: SubscriberEvent) => void;
};

export class UserSubscriptionsTable extends React.Component<Props> {
  static contextTypes = {
    intl: intlShape,
  };

  onChangeHandler = (subscriptionEvent: SubscriberEvent) => () => this.props.updateUserSubscriptions(subscriptionEvent);

  getDataSource = () => {
    return this.props.availableSubscriptionEvents.map(subscriptionEvent => {
      const subscriptionEventItem = SUBSCRIBE_EVENT_TABLE[subscriptionEvent];
      return {
        key: subscriptionEvent,
        role: (
          <div className={style.subscription}>
            <span className={style.checkbox}>
              <SDSCheckbox
                data-test={`userSubscriptionsTable.${subscriptionEvent}.checkbox`}
                checked={this.props.isSubscriptionChecked(subscriptionEvent)}
                // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
                inline
                disabled={this.props.isSubscriptionDisabled}
                onChange={this.onChangeHandler(subscriptionEvent)}
              />
            </span>
            <FormattedMessage {...subscriptionEventItem.name} />
          </div>
        ),
        description: (
          <div className={style.description}>
            <FormattedMessage {...subscriptionEventItem.description} />
          </div>
        ),
      };
    });
  };

  renderSubscriptionHeader = () => (
    <FormattedMessage id="email-edit-user-modal" defaultMessage="Email" description="email field in edit user modal" />
  );

  renderDescriptionHeader = () => (
    <FormattedMessage
      id="description-table-in-edit-user-modal"
      defaultMessage="Description"
      description="Table header for the description column in the edit user modal"
    />
  );

  getColumnHeaders = () => {
    return [
      {
        title: this.renderSubscriptionHeader(),
        dataIndex: 'role',
        key: 'role',
        width: 40,
      },
      {
        title: this.renderDescriptionHeader(),
        dataIndex: 'description',
        key: 'description',
        width: 70,
      },
    ];
  };

  render() {
    if (this.props.availableSubscriptionEvents.length === 0) {
      return null;
    }
    return (
      <div className={style.subscriptionTable}>
        <SDSTable dataSource={this.getDataSource()} columns={this.getColumnHeaders()} pagination={false} />
      </div>
    );
  }
}

export default UserSubscriptionsTable;
