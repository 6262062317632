import classNames from 'classnames';
import React from 'react';
import type { ReactNode } from 'react';
import { FormattedDate, FormattedTime } from 'react-intl';

import { play, gallery } from 'icons/SDS/allIcons';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';

import Icon from 'views/common/components/Icon/Icon';

import style from './CompositeSnapBottomPanel.scss';

type Props = {
  className?: string;
  additionalText: string;
  dateTimestamp: number;
  isVideo: boolean;
};

export class CompositeSnapBottomPanel extends React.PureComponent<Props> {
  renderTopLineOfPanel(icon: ReactNode) {
    return (
      <div className={style.iconContainer}>
        {icon}
        {this.props.additionalText}
      </div>
    );
  }

  getIcon() {
    return this.props.isVideo ? play : gallery;
  }

  render() {
    const icon = <Icon className={style.snapTypeIcon} inlineIcon={this.getIcon()} />;

    const dateTimeView = getMessageFromId('datetime', {
      date: <FormattedDate value={this.props.dateTimestamp} day="2-digit" month="2-digit" year="2-digit" />,
      time: (
        <FormattedTime
          value={this.props.dateTimestamp}
          hour="numeric"
          minute="numeric"
          hour12={false}
          timeZoneName="short"
        />
      ),
    });

    return (
      <div className={classNames(style.container, this.props.className)}>
        <div className={style.text} data-test="compositeSnapBottomPanel.name">
          {this.renderTopLineOfPanel(icon)}
        </div>
        {!!this.props.dateTimestamp && (
          <div className={style.text} data-test="compositeSnapBottomPanel.time">
            {dateTimeView}
          </div>
        )}
      </div>
    );
  }
}

export default CompositeSnapBottomPanel;
