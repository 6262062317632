import * as toWebVTT from 'srt-webvtt';

import { UploadPurpose } from 'config/constants';
import { incrementCounter } from 'utils/grapheneUtils';
import { blobUrlToFile } from 'utils/media/fileUtils';

import { FileLike } from 'types/common';

export async function srtToVtt(file: FileLike): Promise<FileLike> {
  try {
    // @ts-ignore: we define our own FileLike type to have additional fields
    const vttUrl = await toWebVTT.default(file);
    const vttFile = blobUrlToFile(vttUrl, file.name);
    incrementCounter('subtitles.srtToVttConvert.Success');

    return vttFile;
  } catch {
    incrementCounter('subtitles.srtToVttConvert.Error');
    return Promise.reject(new Error('Failed to convert srt to vtt'));
  }
}

export function isSubtitlePurpose(purpose: UploadPurpose): boolean {
  switch (purpose) {
    case UploadPurpose.EDITION_SUBTITLES:
    case UploadPurpose.TOPSNAP_SUBTITLES:
    case UploadPurpose.SUBTITLE:
      return true;
    default:
      return false;
  }
}
