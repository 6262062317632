import React from 'react';

import { contextMenu } from 'icons/SDS/allIcons';

import SDSButton, { ButtonType, ButtonShape } from 'views/common/components/SDSButton/SDSButton';
import RSSFeedOptionsButtonPopover from 'views/modals/containers/RSSFeedOptionsButtonPopover/RSSFeedOptionsButtonPopover';

type Props = {
  isEnabled: boolean;
  handleEnableToggle: () => void;
  handleDelete: () => void;
};

export class RSSFeedOptionsButton extends React.Component<Props> {
  render() {
    return (
      <RSSFeedOptionsButtonPopover {...this.props}>
        <SDSButton
          type={ButtonType.SECONDARY}
          shape={ButtonShape.CIRCLE}
          inlineIcon={contextMenu}
          data-test="rssFeed.option.button"
        />
      </RSSFeedOptionsButtonPopover>
    );
  }
}

export default RSSFeedOptionsButton;
