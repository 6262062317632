import classNames from 'classnames';
import invariant from 'invariant';
import is from 'is_js';
import { noop } from 'lodash';
import log from 'loglevel';
import type { ChangeEvent, ReactNode } from 'react';
import * as React from 'react';
import Dropzone from 'react-dropzone';
import { defineMessages, FormattedMessage, intlShape } from 'react-intl';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'svg-inline-loader?removeSVGTag... Remove this comment to see the full error message
// eslint-disable-next-line import/no-extraneous-dependencies, import/no-duplicates
import uploadImage from 'svg-inline-loader?removeSVGTagAttrs=false!./icons/upload-image.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'svg-inline-loader?removeSVGTag... Remove this comment to see the full error message
// eslint-disable-next-line import/no-extraneous-dependencies, import/no-duplicates
import uploadVideo from 'svg-inline-loader?removeSVGTagAttrs=false!./icons/upload-video.svg.inline';
import InlineSVG from 'svg-inline-react';

import { setEditionPropertiesAndSave } from 'state/editions/actions/editionsActions';
import * as editorSelectors from 'state/editor/selectors/editorSelectors';
import * as featuresSelectors from 'state/features/selectors/featuresSelectors';
import * as mediaActions from 'state/media/actions/mediaActions';
import * as modalActions from 'state/modals/actions/modalsActions';
import { openPublisherDetailsModal } from 'state/publishers/actions/publishersActions';
import * as publishersSelectors from 'state/publishers/selectors/publishersSelectors';
import * as subtitlesActions from 'state/subtitles/actions/subtitlesActions';
import * as videoLibraryActions from 'state/videoLibrary/actions/videoLibraryActions';

import {
  DropzoneType,
  ErrorType,
  FileType,
  ReplaceDropzoneType,
  UploadFormat,
  UploadPurpose,
  VIDEO_UPLOAD_ANALYTICS,
} from 'config/constants';
import { upload } from 'icons/SDS/allIcons';
import { assertArg } from 'utils/assertionUtils';
import { buildComponentIdForSnapId } from 'utils/componentUtils';
import { intlConnect } from 'utils/connectUtils';
import { createActionLogger, GAQoSMetrics, getGAParamsForUploadPurpose, logGAEvent } from 'utils/gaUtils';
import { GrafanaMetrics } from 'utils/grafanaUtils';
import * as grapheneUtils from 'utils/grapheneUtils';
import { incrementCounterByPublisher } from 'utils/grapheneUtils';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';
import { getFileType, getMimeType } from 'utils/media/fileUtils';
import { isSubtitlePurpose, srtToVtt } from 'utils/media/subtitleUtils';
import { logTiming, SiteSpeed } from 'utils/metricsUtils';
import { ModalType } from 'utils/modalConfig';

import { DialogModalOptions } from 'views/common/components/DialogModal/DialogModal';
import Icon from 'views/common/components/Icon/Icon';
import RestrictedDropzone from 'views/common/components/RestrictedDropzone/RestrictedDropzone';
import { ButtonShape, ButtonType, SDSButton } from 'views/common/components/SDSButton/SDSButton';
import SDSInput from 'views/common/components/SDSInput/SDSInput';
import SpinnerIcon from 'views/common/components/SpinnerIcon/SpinnerIcon';
import UploadStatusBar from 'views/common/components/UploadStatusBar/UploadStatusBar';
import addInfoButton from 'views/common/icons/info.svg';

import imageInfo from './icons/imageInfo.svg';
import uploadImageModal from './icons/upload-image-modal.svg';
import addButtonImg from 'images/+.svg';

import style from './MediaUploader.scss';
import { disabledDropzoneConfigs, enabledDropzoneConfigs, tooltipText } from './MediaUploaderConfig';

import { isAssetID, toAssetID } from 'types/assets';
import type { FileLike, SnapId } from 'types/common';
import type { MediaValidation } from 'types/media';
import type { AssetID } from 'types/polls';
import { Publisher } from 'types/publishers';
import type { State } from 'types/rootState';

type OnUploadComplete = (obj: { assetId: AssetID }) => void;
type OnUploadFailed = (obj: any) => void;
export const messages = defineMessages({
  placeholder: {
    id: 'upload-image-url-placeholder',
    description: 'Placeholder text for the image url input field',
    defaultMessage: 'Enter an Image URL',
  },
  errorUrl: {
    id: 'upload-image-url-error',
    description: 'the upload image URL is not valid',
    defaultMessage: 'Input image URL is not valid',
  },
});

const mapStateToProps = (state: State, props: OwnProps) => {
  const publisher = publishersSelectors.getActivePublisherDetails(state);
  return {
    publisher,
    activePublisherId: publisher?.id,
    isSpectaclesVideoEnabled: featuresSelectors.isSpectaclesVideoEnabled(state),
    topsnapDurationLimit: editorSelectors.getTopsnapDurationLimit(state),
    hasAddedPublisherDetails: publishersSelectors.activePublisherHasAddedRequiredDetails(state),
  };
};

const mapDispatchToProps = {
  loadSubtitles: subtitlesActions.loadSubtitles,
  showModal: modalActions.showModal,
  uploadLibraryVideo: videoLibraryActions.uploadLibraryVideo,
  uploadModalLibraryVideo: videoLibraryActions.uploadModalLibraryVideo,
  uploadMediaAndFinalize: mediaActions.uploadMediaAndFinalize,
  validateMedia: mediaActions.validateMedia,
  openPublisherDetailsModal,
};
type Id = number | string;
type OnFile = (a: FileLike | FileLike[]) => unknown;
export type OwnProps = {
  'data-test'?: string;
  assetId?: Id | null;
  children?: ReactNode | ((a: { onFile: OnFile }) => ReactNode);
  className?: string;
  customValidationOptions?: MediaValidation;
  disableClick?: boolean;
  dropzoneType?: string;
  editionId?: Id;
  enabled?: boolean;
  entityId?: Id;
  handleBlobOnly?: (a: string) => void;
  hasUploadError?: boolean;
  isVideoLibraryModal?: boolean;
  isReadOnly?: boolean;
  isSpectaclesVideoEnabled?: boolean;
  isUploading?: boolean;
  // Provides the caller a hook so that they can be notified when something is uploaded
  onMultiFileUploadComplete?: (a: { assetIds: Array<AssetID> }) => void;
  onUploadComplete?: OnUploadComplete;
  onUploadFailed?: OnUploadFailed;
  onUploadStart?: () => void;
  processing?: boolean;
  publisherId?: Id | null;
  purpose: typeof UploadPurpose[keyof typeof UploadPurpose];
  renderVideoInfo?: boolean;
  showId?: Id;
  showUploadStatus?: boolean;
  skipMediaValidation?: boolean;
  snapId?: SnapId;
  subtitlesMediaIds?: Array<string>;
  subtitlesLanguage?: string;
  topsnapDurationLimit?: number;
  uploadFormat?: typeof UploadFormat[keyof typeof UploadFormat];
  uploadMessageClassName?: string;
};
type LongFormAnalyticsFn = (a: string) => void;
type Media = FileLike | string;
type UploadMediaAndFinalizeParams = {
  fileType: string | undefined | null;
  media: Media;
  params: {};
  setEditionPropertiesAndSave?: typeof setEditionPropertiesAndSave;
};
export type ReduxProps = {
  activePublisherId: Id;
  publisher: Publisher;
  loadSubtitles: typeof subtitlesActions.loadSubtitles;
  topsnapDurationLimit: number;
  showModal: typeof modalActions.showModal;
  hasAddedPublisherDetails: boolean;
  uploadLibraryVideo: (
    b: FileLike,
    a: LongFormAnalyticsFn
  ) => Promise<{
    assetId: unknown;
  }>;
  uploadMediaAndFinalize: (
    a: UploadMediaAndFinalizeParams
  ) => Promise<{
    assetId: unknown;
  }>;
  uploadModalLibraryVideo: (
    b: FileLike,
    a: LongFormAnalyticsFn
  ) => Promise<{
    assetId: unknown;
  }>;
  validateMedia: typeof mediaActions.validateMedia;
  openPublisherDetailsModal: (component: string) => Promise<void>;
};
export type Props = OwnProps & ReduxProps;
export type OwnState = {
  errorMessage: string | null;
  hoverTooltip: boolean;
  uploading: boolean;
};
type PrivateProps = Props & typeof MediaUploader.defaultProps;

export class MediaUploader extends React.Component<PrivateProps, OwnState> {
  static defaultProps = {
    customValidationOptions: {},
    disableClick: false,
    enabled: true,
    isVideoLibraryModal: false,
    isReadOnly: false,
    onMultiFileUploadComplete: noop,
    onUploadComplete: noop,
    onUploadFailed: noop,
    onUploadStart: noop,
    renderVideoInfo: true,
    uploadFormat: UploadFormat.ONE_FILE,
  };

  static contextTypes = {
    intl: intlShape,
  };

  state = {
    hoverTooltip: false,
    errorMessage: null,
    uploading: false,
  };

  // @ts-expect-error ts-migrate(2564) FIXME: Property 'unmounted' has no initializer and is not... Remove this comment to see the full error message
  unmounted: boolean; // eslint-disable-line react/sort-comp

  // @ts-expect-error ts-migrate(2416) FIXME: Property 'props' in type 'MediaUploader' is not as... Remove this comment to see the full error message
  props: Props;

  element: HTMLInputElement | undefined | null;

  componentWillUnmount() {
    this.unmounted = true;
  }

  // @ts-expect-error ts-migrate(1055) FIXME: Type 'unknown' is not a valid async function retur... Remove this comment to see the full error message
  onFile = async (file: string | FileLike | FileLike[]): unknown => {
    const {
      topsnapDurationLimit,
      snapId,
      assetId,
      customValidationOptions,
      validateMedia,
      entityId,
      onUploadComplete,
      onMultiFileUploadComplete,
      isVideoLibraryModal,
      handleBlobOnly,
      publisherId,
      subtitlesMediaIds,
      isSpectaclesVideoEnabled,
      skipMediaValidation,
      editionId,
      subtitlesLanguage,
    } = this.props;
    const { purpose, publisher } = this.props;
    const format = this.props.uploadFormat;
    this.logEvent();

    // Log usage of the add media to story to grafana
    incrementCounterByPublisher(publisher, GrafanaMetrics.MEDIA_V1, {
      type: 'upload_media_event',
    });

    if (purpose === UploadPurpose.TILE_IMAGE && !this.props.hasAddedPublisherDetails) {
      this.props.openPublisherDetailsModal('SingleAssetPublisher').then(() => {});
      return Promise.resolve();
    }

    if (handleBlobOnly) {
      invariant(!Array.isArray(file), 'single file');
      // https://github.com/react-dropzone/react-dropzone#word-of-caution-when-working-with-previews
      invariant(typeof (file as any).preview === 'string', 'file has a preview blob URL');
      const fileType = format === UploadFormat.URL ? FileType.IMAGE : getFileType(file);
      const filePreview = (file as any).preview;
      await (validateMedia(filePreview, fileType, customValidationOptions) as any).then(() =>
        handleBlobOnly(filePreview)
      );
      return Promise.resolve();
    }
    let componentId = '';
    if (snapId) {
      componentId = buildComponentIdForSnapId(snapId);
    }
    if (purpose !== UploadPurpose.LONGFORM_VIDEO) {
      const maxDurationSeconds = is.number(topsnapDurationLimit) ? topsnapDurationLimit : null;
      const params = {
        assetId,
        componentId,
        customValidationOptions,
        editionId,
        entityId: entityId || undefined,
        force: skipMediaValidation || undefined,
        isSpectaclesVideoEnabled,
        maxDurationSeconds,
        publisherId: publisherId || undefined,
        purpose,
        snapId,
        subtitlesLanguage,
      };
      switch (format) {
        case UploadFormat.ONE_FILE: {
          invariant(!Array.isArray(file), 'single file');
          if (this.props.onUploadStart) {
            this.props.onUploadStart();
          }
          const fileType = getFileType(file);
          invariant(typeof file === 'object' && typeof file.type === 'string', 'file');
          if (purpose === UploadPurpose.TOP_SNAP && fileType === FileType.VIDEO && subtitlesMediaIds?.length) {
            this.showKeepSubtitlesAlert(file, format, params, onUploadComplete);
            return Promise.resolve();
          }

          const shouldConvertSrtToVtt = isSubtitlePurpose(purpose) && getMimeType(file) === 'text/srt';
          const convertedFile = shouldConvertSrtToVtt ? await srtToVtt(file) : file;
          return this.uploadAction(convertedFile, format, params, onUploadComplete).catch(error => {
            if (!error.alreadyHandled) {
              throw error;
            }
          });
        }
        case UploadFormat.MULTIPLE_FILES: {
          invariant(Array.isArray(file), 'multiple files');
          if (this.props.onUploadStart) {
            this.props.onUploadStart();
          }
          const promises = file.map(tempFile => this.uploadAction(tempFile, format, params, onUploadComplete));
          // Wait for all uploads to complete, then trigger onMultiFileUploadComplete
          return Promise.all(promises).then((assetIds: Array<AssetID>) => {
            if (onMultiFileUploadComplete) {
              onMultiFileUploadComplete({ assetIds });
            }
          });
        }
        case UploadFormat.URL: {
          const urlFile = file;
          invariant(!Array.isArray(urlFile), 'URL');
          if (this.props.onUploadStart) {
            this.props.onUploadStart();
          }
          invariant(
            typeof urlFile === 'string' || (typeof urlFile === 'object' && typeof (file as any).type === 'string'),
            'urlFile'
          );
          return Promise.resolve()
            .then(() => this.uploadAction(urlFile, format, params, onUploadComplete))
            .catch(error => {
              if (error && error.errorType === ErrorType.URI_TYPE_ERROR && !this.unmounted) {
                this.setState({ errorMessage: this.context.intl.formatMessage(messages.errorUrl) });
              } else {
                throw error;
              }
            });
        }
        default: {
          throw new Error(`Unhandled format ${String(format)}`);
        }
      }
    } else {
      invariant(!Array.isArray(file), 'single file');
      if (this.props.onUploadStart) {
        this.props.onUploadStart();
      }
      let currentActionValue = 0;
      const { activePublisherId } = this.props;
      invariant(typeof file === 'object' && file.type, 'file');
      const longFormAnalyticsFn = createActionLogger(GAQoSMetrics.MEDIA, `${file.name}_${activePublisherId}`, () => {
        currentActionValue += 1;
        return currentActionValue;
      });
      longFormAnalyticsFn(VIDEO_UPLOAD_ANALYTICS.MEDIA_UPLOAD);
      if (isVideoLibraryModal) {
        const uploadStartTimeMs = new Date().getTime();
        return this.props
          .uploadModalLibraryVideo(file, longFormAnalyticsFn)
          .then((uploadResult: { assetId: unknown }) => {
            const uploadedAssetId = uploadResult && uploadResult.assetId;
            if (onUploadComplete && uploadedAssetId && isAssetID(uploadedAssetId)) {
              onUploadComplete({ assetId: toAssetID(uploadedAssetId) });
            }
          })
          .then(() => logTiming(SiteSpeed.MEDIA_UPLOAD, 'video-modal-upload-and-processing', uploadStartTimeMs));
      }
      return this.props
        .uploadLibraryVideo(file, longFormAnalyticsFn)
        .then((uploadResult: { assetId: unknown }) => {
          longFormAnalyticsFn(VIDEO_UPLOAD_ANALYTICS.UPLOAD_COMPLETE);
          return uploadResult;
        })
        .then((uploadResult: { assetId: unknown }) => {
          const uploadedAssetId = uploadResult && uploadResult.assetId;
          if (onUploadComplete && uploadedAssetId && isAssetID(uploadedAssetId)) {
            onUploadComplete({ assetId: toAssetID(uploadedAssetId) });
          }
        });
    }
  };

  getChild() {
    const enabled = this.getEnabled();
    const { isUploading, dropzoneType } = this.props;
    switch (dropzoneType) {
      case DropzoneType.TOPSNAP:
      case DropzoneType.VIDEO:
        return this.dragAndDropText();
      default:
      case DropzoneType.TOPSNAP_IMAGE:
        // @ts-expect-error ts-migrate(2551) FIXME: Property 'enabledSVG' does not exist on type 'type... Remove this comment to see the full error message
        return <InlineSVG className={enabled ? style.enabledSVG : style.disabled} src={uploadImage} />;
      case DropzoneType.TOPSNAP_VIDEO:
        // @ts-expect-error ts-migrate(2551) FIXME: Property 'enabledSVG' does not exist on type 'type... Remove this comment to see the full error message
        return <InlineSVG className={enabled ? style.enabledSVG : style.disabled} src={uploadVideo} />;
      case DropzoneType.TOPSNAP_CLICK_UPLOAD:
        return (
          <SDSButton
            type={ButtonType.SECONDARY}
            disabled={!enabled}
            data-test="mediaUploader.topsnap-click-upload-button"
            loading={isUploading}
          >
            <FormattedMessage id="topsnap-click-to-upload" description="Click to upload" defaultMessage="Upload" />
          </SDSButton>
        );
      case DropzoneType.GENERIC_IMAGE_UPLOAD:
        return (
          <SDSButton
            type={ButtonType.SECONDARY}
            shape={ButtonShape.CIRCLE}
            data-test="MediaUploader"
            inlineIcon={upload}
            loading={isUploading}
            disabled={this.props.isReadOnly}
          />
        );
      case DropzoneType.EDITION_SUBTITLES:
      case DropzoneType.SINGLE_ASSET_SUBTITLES:
      case DropzoneType.TOPSNAP_SUBTITLES:
        invariant(dropzoneType, 'Dropzone is not defined');
        return (
          <SDSButton
            disabled={isUploading || !enabled}
            inlineIcon={!isUploading ? upload : undefined}
            type={ButtonType.WHITE}
            loading={isUploading}
            block
            data-test={`MediaUploader.${dropzoneType}.button`}
          >
            {getMessageFromId('upload-button-label')}
          </SDSButton>
        );
      case DropzoneType.SUBTITLE:
        return (
          <img
            src={addButtonImg}
            className={classNames(style.uploadSubtitle, { [style.disabled]: !enabled })}
            alt={`add ${this.props.purpose}`}
          />
        );
      case DropzoneType.SOUNDTRACK_REPLACE:
      case DropzoneType.POLL_IMAGE_REPLACE:
      case DropzoneType.REPLACE_TILE_LOGO:
      case DropzoneType.REPLACE:
        return (
          <div className={style.overlayReplaceButtonContainer}>
            <SDSButton type={ButtonType.SECONDARY} disabled={!enabled} data-test="mediaUploader.replace-button">
              <FormattedMessage
                id="snap-panel-replace"
                description="Snap panel replace button"
                defaultMessage="Replace"
              />
            </SDSButton>
          </div>
        );
      case DropzoneType.REPLACE_TILE_IMAGE:
        return this.renderReplaceTileImage(enabled);
      case DropzoneType.NEW_OVERLAY:
        return (
          <img
            className={classNames(style.newOverlayIcon, { [style.disabled]: !enabled })}
            src={addButtonImg}
            alt="add overlay"
          />
        );
      case DropzoneType.NEW_TILE_IMAGE:
      case DropzoneType.CHEETAH_TILE:
        return this.renderNewTileImage(enabled);
      case DropzoneType.SOUNDTRACK:
        return this.renderAudioUpload();
      case DropzoneType.NEW_TILE_LOGO:
      case DropzoneType.POLL_IMAGE:
        return (
          <img
            src={addButtonImg}
            className={classNames(style.uploadImage, { [style.disabled]: !enabled })}
            alt={`add ${this.props.purpose}`}
          />
        );
      case DropzoneType.MODAL_IMAGE:
        if (this.props.uploadFormat === UploadFormat.MULTIPLE_FILES) {
          return this.dragAndDropImageModal(enabled);
        }
        if (this.props.uploadFormat === UploadFormat.URL) {
          return this.uploadImageByUrlInput(enabled);
        }
        log.error(`Unhandled format ${String(this.props.uploadFormat)} for MODAL_IMAGE`);
        return null;
      case DropzoneType.MODAL_VIDEO:
        return this.dragAndDropVideoModal(enabled);
      case DropzoneType.NEW_TILE_LOGO_ZONE:
        return this.dragAndDropTileLogo(enabled);
    }
  }

  getEnabled() {
    return Boolean(this.props.enabled && !this.props.isReadOnly);
  }

  uploadAction = (
    file: FileLike | string,
    format: any,
    params: {},
    onUploadComplete?: OnUploadComplete
  ): Promise<AssetID> => {
    const fileType = format === UploadFormat.URL ? FileType.IMAGE : getFileType(file);
    this.setState({ uploading: true });
    const { purpose, dropzoneType } = this.props;
    const metricsDimensions = {
      purpose: purpose || 'EMPTY',
      dropzoneType: dropzoneType || 'EMPTY',
      fileType: fileType || 'EMPTY',
    };
    const stopwatch = grapheneUtils.createTimer();
    return this.props
      .uploadMediaAndFinalize({
        media: file,
        fileType,
        params,
        setEditionPropertiesAndSave,
      })
      .then((result: { assetId: unknown }) => {
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
        assertArg(result).is.object();
        invariant(isAssetID(result.assetId), 'Malformed asset ID');
        stopwatch('MediaUploader.uploadComplete', metricsDimensions);
        if (onUploadComplete) {
          invariant(fileType !== null, 'fileType');
          onUploadComplete({
            assetId: toAssetID(result.assetId),
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ assetId: string; type: string;... Remove this comment to see the full error message
            type: fileType.toUpperCase(),
            file,
          });
        }
        if (!this.unmounted) {
          this.setState({ uploading: false });
        }
        return Promise.resolve(toAssetID(result.assetId));
      })
      .catch(error => {
        if (!this.unmounted) {
          this.setState({ uploading: false });
        }
        stopwatch('MediaUploader.uploadFailed', metricsDimensions);
        if (this.props.onUploadFailed) {
          this.props.onUploadFailed(error);
        }
        return Promise.reject(error);
      });
  };

  logEvent() {
    const params = getGAParamsForUploadPurpose(this.props.purpose, 'upload');
    if (params) {
      const replace = this.props.dropzoneType && ReplaceDropzoneType.has(this.props.dropzoneType);
      logGAEvent(params.category, params.action, { replace });
    }
  }

  hoverTooltip = () => {
    this.setState({ hoverTooltip: true });
  };

  leaveTooltip = () => {
    this.setState({ hoverTooltip: false });
  };

  submitFile = (enabled: boolean, value: string | FileLike | FileLike[]) =>
    enabled && value ? this.onFile(value) : null;

  handleOnChange = (event: ChangeEvent<EventTarget>) => {
    // @ts-expect-error ts-migrate(2740) FIXME: Type 'EventTarget' is missing the following proper... Remove this comment to see the full error message
    this.element = event.target;
    if (this.state.errorMessage) {
      this.setState({ errorMessage: null });
    }
  };

  clickSubmitFile = (enabled: boolean) => () => {
    if (this.element) {
      return this.submitFile(enabled, this.element.value);
    }
    return Promise.reject(new Error('No Element'));
  };

  handlePressEnter = (enabled: boolean) => () => {
    if (this.element) {
      this.submitFile(enabled, this.element.value);
    }
  };

  uploadImageByUrlInput = (enabled: boolean) => {
    return (
      <div>
        <div className={style.uploadImageByUrlContainer}>
          <SDSInput
            labelTitle={getMessageFromId('url-msg')}
            disabled={!enabled}
            placeholder={this.context.intl.formatMessage(messages.placeholder)}
            onChange={this.handleOnChange}
            data-test="editor.mediaUploader.uploadImageByUrl.input"
            onPressEnter={this.handlePressEnter(enabled)}
            errorMessage={this.state.errorMessage}
          />
          <div className={style.buttonContainer}>
            <SDSButton
              type={ButtonType.PRIMARY}
              disabled={!enabled}
              onClick={this.clickSubmitFile(enabled)}
              data-test="editor.mediaUploader.uploadImageByUrl.button"
            >
              <FormattedMessage id="upload-image-by-url" description="upload image by url" defaultMessage="Upload" />
            </SDSButton>
          </div>
        </div>
      </div>
    );
  };

  dragAndDropText = () => {
    const { topsnapDurationLimit, renderVideoInfo, uploadMessageClassName } = this.props;
    const maxDurationSeconds = is.number(topsnapDurationLimit) ? topsnapDurationLimit : null;
    const dropZoneType = this.props.dropzoneType;
    const text = tooltipText(maxDurationSeconds);
    const infoTooltip = dropZoneType && text && text[dropZoneType];
    const uploadMessage = (
      <FormattedMessage id="upload-your-snap" description="Upload snap area" defaultMessage="Upload" />
    );
    return (
      <div className={style.dragAndDropText}>
        {renderVideoInfo && (
          <img
            className={style.videoInfo}
            src={addInfoButton}
            alt="info"
            onMouseEnter={this.hoverTooltip}
            onMouseLeave={this.leaveTooltip}
          />
        )}
        {this.state.hoverTooltip && infoTooltip}
        <InlineSVG src={upload} />
        {/* @ts-expect-error ts-migrate(2551) FIXME: Property 'uploadMessage' does not exist on type 't... Remove this comment to see the full error message */}
        <div className={classNames(style.uploadMessage, uploadMessageClassName)}>{uploadMessage}</div>
        <div className={style.clickToBrowseNotice}>
          <FormattedMessage id="upload-instructions" description="Click to upload" defaultMessage="Drop file here" />
        </div>
      </div>
    );
  };

  dragAndDropImageModal = (enabled: boolean) => {
    if (!enabled) {
      return <SpinnerIcon />;
    }
    const dropZoneType = this.props.dropzoneType;
    const text = tooltipText();
    const infoTooltip = dropZoneType && text && text[dropZoneType];
    return (
      <div className={style.dragAndDropText}>
        <img
          className={style.imageInfo}
          src={imageInfo}
          alt="info"
          onMouseEnter={this.hoverTooltip}
          onMouseLeave={this.leaveTooltip}
        />
        {this.state.hoverTooltip && infoTooltip}
        <img className={style.uploadImageModalIcon} src={uploadImageModal} alt="uploadModal" />
        <h4>
          <FormattedMessage
            id="upload-your-image-modal"
            description="Upload your image from modal"
            defaultMessage="Upload your image"
          />
        </h4>
        <div className={style.modalHelperInfo}>
          <FormattedMessage
            id="upload-modal-helper-info-image"
            description="upload modal helper info of image"
            defaultMessage="This is where you’ll upload your image. Drop file here or click to upload"
          />
        </div>
      </div>
    );
  };

  dragAndDropVideoModal = (enabled: boolean) => {
    if (!enabled) {
      return <SpinnerIcon />;
    }
    const dropZoneType = this.props.dropzoneType;
    const text = tooltipText();
    const infoTooltip = dropZoneType && text && text[dropZoneType];
    return (
      <div className={classNames(style.dragAndDropText, style.dragDropVideo)}>
        <img
          className={style.imageInfo}
          src={imageInfo}
          alt="info"
          onMouseEnter={this.hoverTooltip}
          onMouseLeave={this.leaveTooltip}
        />
        {this.state.hoverTooltip && infoTooltip}
        <img className={style.uploadImageModalIcon} src={uploadImageModal} alt="uploadModal" />
        <h4>
          <FormattedMessage
            id="upload-your-video-modal"
            description="Upload your video from modal"
            defaultMessage="Upload your Video"
          />
        </h4>
        <div className={style.modalHelperInfo}>
          <FormattedMessage
            id="upload-modal-info-helper--video"
            description="upload modal info helper of video"
            defaultMessage="Drop file here or click to upload"
          />
        </div>
      </div>
    );
  };

  dragAndDropTileLogo = (enabled: boolean) => {
    if (!enabled) {
      return <SpinnerIcon />;
    }
    return (
      <div>
        <img className={style.uploadTileLogoIcon} src={uploadImageModal} alt="uploadModal" />
      </div>
    );
  };

  showKeepSubtitlesAlert = (
    file: FileLike | string,
    format: any,
    inParams: {},
    onUploadComplete?: OnUploadComplete
  ) => {
    const options: DialogModalOptions = {
      visible: true,
      isBodyCentered: true,
      title: (
        <FormattedMessage
          id="keep-subtitles-title"
          description="Title for message asking if subtitles should be retained when the video is replaced with a new one"
          defaultMessage="Keep subtitles?"
        />
      ),
      body: (
        <FormattedMessage
          id="keep-subtitles-text"
          description="Message asking if subtitles should be retained when the video is replaced with a new one"
          defaultMessage="Would you like to keep your current subtitles attached to your new video?
          Any subtitles with timing beyond the new video duration will not be shown"
        />
      ),
      okText: (
        <FormattedMessage
          id="keep-subtitles-confirm"
          description="Selection choice to keep subtitles while uploading a new video"
          defaultMessage="Keep subtitles"
        />
      ),
      onConfirm: () => this.handleKeepSubtitles(file, format, inParams, true, onUploadComplete),
      cancelText: (
        <FormattedMessage
          id="keep-subtitles-delete"
          description="Selection choice to delete subtitles while uploading a new video"
          defaultMessage="Delete subtitles"
        />
      ),
      onCancel: () => this.handleKeepSubtitles(file, format, inParams, false, onUploadComplete),
    };
    this.props.showModal(ModalType.DIALOG, 'NewUserModal', options);
  };

  handleKeepSubtitles = (
    file: FileLike | string,
    format: any,
    inParams: {},
    keepSubtitles: boolean,
    onUploadComplete?: OnUploadComplete
  ) => {
    const { subtitlesMediaIds } = this.props;
    const params = {
      subtitlesMediaIds: keepSubtitles ? subtitlesMediaIds : [],
      ...inParams,
    };
    this.uploadAction(file, format, params, onUploadComplete);
  };

  // render the replace button for uploading snap tile images including an info hover
  renderReplaceTileImage(enabled: boolean) {
    const infoTooltip = tooltipText()[DropzoneType.REPLACE_TILE_IMAGE];
    return (
      <span>
        <SDSButton
          type={ButtonType.SECONDARY}
          data-test="mediaUploader.replace-tile-button"
          // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
          onMouseEnter={this.hoverTooltip}
          onMouseLeave={this.leaveTooltip}
          disabled={!enabled}
        >
          <FormattedMessage id="tile-panel-replace" description="Tile panel replace button" defaultMessage="Replace" />
        </SDSButton>
        {this.state.hoverTooltip && infoTooltip}
      </span>
    );
  }

  // render the + button for uploading snap tile images including an info hover
  renderNewTileImage(enabled: boolean) {
    const infoTooltip = tooltipText()[DropzoneType.NEW_TILE_IMAGE];
    return (
      <span>
        <img
          className={classNames(style.newTileIcon, { [style.disabled]: !enabled })}
          src={addButtonImg}
          alt="add tile"
          onMouseEnter={this.hoverTooltip}
          onMouseLeave={this.leaveTooltip}
        />
        {this.state.hoverTooltip && infoTooltip}
      </span>
    );
  }

  renderAudioUpload() {
    return (
      <div className={style.audioUpload}>
        <Icon inlineIcon={upload} className={style.uploadIcon} data-test="editor.mediaUploader.audioUpload.icon" />
        <span className={style.text}>
          <FormattedMessage
            id="audio-upload-description"
            description="Instructions for user to drop mp3 file or click to upload"
            defaultMessage="Drop mp3 here or click to upload"
          />
        </span>
      </div>
    );
  }

  render() {
    const { dropzoneType } = this.props;
    const enabled = this.getEnabled();
    const { uploadFormat } = this.props;
    if (enabled) {
      const config = dropzoneType ? enabledDropzoneConfigs[dropzoneType] : null;
      const configProps = config && config.props;
      const activeClassName = classNames(
        configProps && configProps.activeClassName,
        // Enables to override the style of the media uploader by caller :global(.media-uploader-active)
        'media-uploader-active'
      );
      const classes = classNames(configProps && configProps.className, this.props.className, {
        // Adding uploading style to the media uploader in case we are in uploading state.
        // This enables the caller to override the style :global(.media-uploader-uploading)
        'media-uploader-uploading': this.state.uploading || this.props.processing,
      });
      if (!is.object(config)) {
        log.error('config should be defined');
        return null;
      }
      // Lets keep flow happy
      invariant(config, 'config should be defined');
      if (this.state.uploading && this.props.showUploadStatus) {
        return <UploadStatusBar />;
      }
      switch (uploadFormat) {
        case UploadFormat.ONE_FILE:
          return (
            <RestrictedDropzone
              {...config.props}
              // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
              activeClassName={activeClassName}
              className={classes}
              onFile={this.onFile}
              data-test={this.props['data-test']}
              disableClick={this.props.disableClick}
            >
              {this.props.children || this.getChild()}
            </RestrictedDropzone>
          );
        case UploadFormat.MULTIPLE_FILES:
          return (
            <Dropzone
              {...config.props}
              activeClassName={activeClassName}
              onDrop={this.onFile}
              className={classes}
              data-test={this.props['data-test']}
              disableClick={this.props.disableClick}
            >
              {this.props.children || this.getChild()}
            </Dropzone>
          );
        case UploadFormat.URL:
          // you can customize your UI
          // and you need to make sure call onFile inside it
          return this.getChild();
        default:
          log.error(`Unhandled format ${String(uploadFormat)}`);
          return null;
      }
    }
    if (uploadFormat !== UploadFormat.URL) {
      const config = dropzoneType && disabledDropzoneConfigs[dropzoneType];
      const classes = classNames(config ? config.className : style.defaultWrapper, this.props.className);
      return (
        <div className={classes} data-test={this.props['data-test']}>
          {this.props.children || this.getChild()}
        </div>
      );
    }
    return this.getChild();
  }
}

// TODO use this in the rest of the code base post migration
export default intlConnect(mapStateToProps, mapDispatchToProps)(MediaUploader);
