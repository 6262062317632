// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
import { Popover } from '@snapchat/snapnet'; // discover-cms/no-snapnet
import classNames from 'classnames';
import is from 'is_js';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, intlShape } from 'react-intl';

import { EMPTY_ARRAY, KeyCode } from 'config/constants';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';

import SDSCheckbox from 'views/common/components/SDSCheckbox/SDSCheckbox';
import ToggleButton from 'views/common/components/ToggleButton/ToggleButton';

import style from './MultiSelectPopover.scss';

export const allLabel = (
  <FormattedMessage id="select-all-label" description="select All label in popover checkbox" defaultMessage="All" />
);
type OwnProps = {
  style?: string;
  className?: string;
  statusLabelId?: string;
  hideSelectedItems?: boolean;
  selectedItems?: (string | FormattedMessage.MessageDescriptor)[];
  allValues?: (string | FormattedMessage.MessageDescriptor)[];
  isUsingMessageIds?: boolean;
  id: string;
  onChange: (...args: any[]) => any;
  length?: number;
  emptySelection?: React.ReactNode;
  toggleIcons?: {
    true: React.ReactElement;
    false: React.ReactElement;
  };
  controlled?: boolean;
};
type State = any;
type Props = OwnProps & typeof MultiSelectPopover.defaultProps;
class MultiSelectPopover extends Component<Props, State> {
  static contextTypes = {
    intl: intlShape,
  };

  static defaultProps = {
    selectedItems: [],
    emptySelection: (
      <div className={style.emptySelected}>
        <FormattedMessage
          id="no-select-text"
          description="show this text when there is no selected item in the filter checkbox"
          defaultMessage="No select"
        />
      </div>
    ),
    length: 23,
  };

  node: any;

  state = {
    isDropdownVisible: false,
    selectedItems: this.props.selectedItems || [],
  };

  componentDidMount() {
    document.addEventListener('click', this.onHandleClickOutside, true);
    document.addEventListener('keydown', this.onEscKeydown, true);
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    // able to reset the state when changing the allValues props
    if (this.props.controlled || !_.isEqual(this.props.allValues, nextProps.allValues)) {
      // nothing selected is the same as everything selected, but should differently in the UI, so even though the
      // props coming in are different, don't force the UI to show all selected
      if (_.isEqual(this.state.selectedItems, []) && _.isEqual(nextProps.selectedItems, nextProps.allValues)) {
        return;
      }
      this.setState({ selectedItems: nextProps.selectedItems || EMPTY_ARRAY });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onHandleClickOutside, true);
    document.removeEventListener('keydown', this.onEscKeydown, true);
  }

  onHandleClickOutside = (event: any) => {
    if (!this.state.isDropdownVisible) {
      return;
    }
    if (!this.node || !this.node.contains(event.target)) {
      this.onToggle();
    }
  };

  onEscKeydown = (event: any) => {
    const isEscape = event.keyCode === KeyCode.ESCAPE;
    if (!isEscape || !this.state.isDropdownVisible) {
      return;
    }
    this.onToggle();
  };

  onToggle = () => {
    this.setState({ isDropdownVisible: !this.state.isDropdownVisible });
  };

  itemToKey = (item: any) => {
    if (is.object(item)) {
      return item.id;
    }
    return item;
  };

  selectItem = (item: any, checkboxState: any) => () => {
    let newSelectedItems;
    if (!checkboxState) {
      newSelectedItems = this.state.selectedItems.concat([item]);
    } else {
      newSelectedItems = this.state.selectedItems.filter(element => this.itemToKey(element) !== this.itemToKey(item));
    }
    this.setState({ selectedItems: newSelectedItems });
    this.props.onChange(newSelectedItems);
  };

  isAllChecked = () => {
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    return this.state.selectedItems.length === this.props.allValues.length;
  };

  changeAll = () => {
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    if (this.state.selectedItems.length === this.props.allValues.length) {
      this.setState({ selectedItems: EMPTY_ARRAY });
      this.props.onChange(EMPTY_ARRAY);
    } else {
      this.setState({ selectedItems: this.props.allValues });
      this.props.onChange(this.props.allValues);
    }
  };

  displayMessage = (item: any) => {
    if (this.props.isUsingMessageIds) {
      return getMessageFromId(item);
    }
    return is.object(item) ? this.context.intl.formatMessage(item) : item;
  };

  assignNode = (element: any) => {
    this.node = element;
  };

  renderCheckBoxList = () => {
    return (
      <div
        className={classNames(style.searchResults, this.props.className)}
        data-test="multiSelectPopover.renderCheckBox"
      >
        <div key={`${this.props.id}All`} className={style.searchResult}>
          <SDSCheckbox checked={this.isAllChecked()} onChange={this.changeAll} />
          <div className={style.label}>{allLabel}</div>
        </div>
        {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
        {this.props.allValues.map(item => {
          const checkboxState = this.state.selectedItems.some(
            selected => this.itemToKey(selected) === this.itemToKey(item)
          );
          return (
            <div key={this.itemToKey(item)} className={style.searchResult} data-test="multiSelectPopover.searchResult">
              <SDSCheckbox checked={checkboxState} onChange={this.selectItem(item, checkboxState)} />
              <div className={style.label} data-test="multiSelectPopover.text">
                {this.displayMessage(item)}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  renderSelectedItems = () => {
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    if (this.props.hideSelectedItems || this.state.selectedItems.length === this.props.allValues.length) {
      return null;
    }
    if (this.state.selectedItems.length === 0) {
      return this.props.emptySelection;
    }
    const { length } = this.props;
    const prepareSelectedContent = this.state.selectedItems.map(item => {
      return is.object(item) ? this.context.intl.formatMessage(item) : item;
    });
    const renderSelectedContent = prepareSelectedContent.join(', ');
    return (
      <span>
        {renderSelectedContent.length <= length && renderSelectedContent}
        {renderSelectedContent.length > length && `${renderSelectedContent.slice(0, length + 1)}...`}
      </span>
    );
  };

  renderSelectLabel = () => {
    if (!this.props.statusLabelId) {
      return null;
    }
    return (
      <span onClick={this.onToggle} className={style.label} data-test="multiselectPopOver.label">
        {getMessageFromId(this.props.statusLabelId)}
      </span>
    );
  };

  render() {
    return (
      <div className={classNames(style.root, this.props.style)} ref={this.assignNode}>
        {this.renderSelectLabel()}
        <ToggleButton
          onClick={this.onToggle}
          /* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */
          isArrowUp={this.state.selectedItems.length < this.props.allValues.length}
          toggleIcons={this.props.toggleIcons}
        />
        <div className={style.selectedItems}>{this.renderSelectedItems()}</div>
        {this.state.isDropdownVisible && (
          <Popover className={style.popoverStyle} placement="bottom" id={this.props.id}>
            <div className={(style as any).content}>{this.renderCheckBoxList()}</div>
          </Popover>
        )}
      </div>
    );
  }
}
export default MultiSelectPopover;
