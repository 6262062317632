import React from 'react';

import { toggleMutePreview, setPreviewVolume } from 'state/previews/actions/previewsActions';
import { isPreviewMuted, getPreviewVolume } from 'state/previews/selectors/previewsSelectors';
import {
  shouldUseSingleAssetEditor,
  getActiveEditionId,
} from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';

import { soundAudio, mute } from 'icons/SDS/allIcons';
import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';

import SDSButton, { ButtonShape, ButtonType } from 'views/common/components/SDSButton/SDSButton';
import SDSPopover from 'views/common/components/SDSPopover/SDSPopover';
import SDSSlider from 'views/common/components/SDSSlider/SDSSlider';

import style from './MutePreviewButton.scss';

const VOLUME_STEP = 0.05;
const MIN_VOLUME = 0;
const MAX_VOLUME = 1;

type StateProps = {
  muted: boolean;
  volume: number;
  isSingleAssetStoryEditor: boolean;
};

type DispatchProps = {
  toggleMutePreview: typeof toggleMutePreview;
  setPreviewVolume: typeof setPreviewVolume;
};

type Props = StateProps & DispatchProps;

const mapStateToProps = (state: State): StateProps => {
  const storyId = getActiveEditionId(state);
  return {
    muted: isPreviewMuted(state),
    volume: getPreviewVolume(state),
    isSingleAssetStoryEditor: storyId ? shouldUseSingleAssetEditor(state)(storyId) : false,
  };
};

const mapDispatchToProps = {
  toggleMutePreview,
  setPreviewVolume,
};

export class MutePreviewButton extends React.PureComponent<Props> {
  renderSlider() {
    return (
      <div className={style.sliderContainer}>
        <SDSSlider
          range={false}
          step={VOLUME_STEP}
          min={MIN_VOLUME}
          max={MAX_VOLUME}
          onChange={this.props.setPreviewVolume}
          tipFormatter={null}
          defaultValue={this.props.volume}
          data-test="MutePreviewButton.slider"
        />
      </div>
    );
  }

  renderButton = () => {
    return (
      <SDSButton
        type={ButtonType.WHITE_ON_GREY}
        shape={ButtonShape.CIRCLE}
        inlineIcon={this.props.muted ? mute : soundAudio}
        onClick={this.props.toggleMutePreview}
        data-test="MutePreviewButton.button"
      />
    );
  };

  render() {
    if (this.props.isSingleAssetStoryEditor) {
      return (
        <SDSPopover trigger="hover" content={this.renderSlider()} data-test="MutePreviewButton.popover">
          <div>{this.renderButton()}</div>
        </SDSPopover>
      );
    }

    return this.renderButton();
  }
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(MutePreviewButton);
