import React from 'react';

import { isAdvancedCurationEnabled } from 'state/features/selectors/featuresSelectors';
import * as mediaLibraryActions from 'state/mediaLibrary/actions/mediaLibraryActions';
import { currentFilter } from 'state/mediaLibrary/selectors/mediaLibrarySelectors';
import * as publisherSelectors from 'state/publishers/selectors/publishersSelectors';

import { intlConnect } from 'utils/connectUtils';
import { GrafanaMetrics } from 'utils/grafanaUtils';
import { incrementCounterByPublisher } from 'utils/grapheneUtils';

import Icon from 'views/common/components/Icon/Icon';
import Menu from 'views/common/components/SDSMenu/SDSMenu';
import { filtersData } from 'views/mediaLibrary/config/MediaLibraryFiltersConfig';

import style from './MediaLibraryFilters.scss';

import type { FilterByType, TargetType } from 'types/mediaLibrary';
import { FilterByEnum, TargetEnum } from 'types/mediaLibrary';
import { Publisher } from 'types/publishers';
import type { State } from 'types/rootState';

type OwnProps = {
  target: TargetType;
  hideCommunitySnaps: boolean;
  advancedCurationEnabled: boolean;
  isShowPublisher: boolean;
  currentFilter: FilterByType;
  publisher: Publisher;
};

type DispatchProps = {
  setFilter: typeof mediaLibraryActions.setFilter;
};

type Props = OwnProps & DispatchProps;

export const mapStateToProps = (state: State) => {
  return {
    hideCommunitySnaps: publisherSelectors.hideCommunitySnaps(state),
    advancedCurationEnabled: isAdvancedCurationEnabled(state),
    isShowPublisher: publisherSelectors.activePublisherIsShow(state),
    currentFilter: currentFilter(state),
    publisher: publisherSelectors.getActivePublisherDetails(state),
  };
};

const mapDispatchToProps = {
  setFilter: mediaLibraryActions.setFilter,
};

export class MediaLibraryFilters extends React.PureComponent<Props> {
  onClick = ({ key }: { key: FilterByType }) => {
    incrementCounterByPublisher(this.props.publisher, GrafanaMetrics.MEDIA_V2, {
      type: 'mediaFiltering',
      filter: key,
    });
    this.props.setFilter(key, this.props.target);
  };

  render() {
    const mediaFilters = filtersData;

    let targetFilters =
      this.props.target === TargetEnum.ANY
        ? mediaFilters
        : mediaFilters.filter(filter => filter.targets.indexOf(this.props.target) !== -1);

    if (this.props.hideCommunitySnaps && !this.props.advancedCurationEnabled) {
      targetFilters = targetFilters.filter(filter => filter.key !== FilterByEnum.CURATED_SNAPS);
    }

    if (this.props.isShowPublisher) {
      targetFilters = targetFilters.filter(filter => filter.key !== FilterByEnum.IMAGE_SNAPS);
    }

    const menuItems = targetFilters.map(filter => {
      return (
        <Menu.Item key={filter.key} data-test={filter.key} className={style.filterItem}>
          <Icon inlineIcon={filter.inlineIcon} img={filter.icon} className={style.icon} />
          {filter.text}
        </Menu.Item>
      );
    });
    return (
      <div>
        <Menu defaultSelectedKeys={[this.props.currentFilter]} onClick={this.onClick}>
          {menuItems}
        </Menu>
      </div>
    );
  }
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(MediaLibraryFilters);
