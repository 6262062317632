import React from 'react';

import * as routerActions from 'state/router/actions/routerActions';

import { media } from 'icons/SDS/allIcons';
import { intlConnect } from 'utils/connectUtils';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';

import Icon from 'views/common/components/Icon/Icon';
import MediaLibraryHeaderButtons from 'views/mediaLibrary/MediaLibraryHeaderButtons/MediaLibraryHeaderButtons';

import style from './MediaLibraryHeader.scss';

import type { SnapId } from 'types/common';
import type { EditionID } from 'types/editionID';
import type { PublisherID } from 'types/publishers';
import { SnapType } from 'types/snaps';

type OwnProps = {
  onDropFiles: (a: File[]) => void;
  hideCancelButton?: boolean;
  publisherId: PublisherID;
  editionId?: EditionID;
  snapId?: SnapId;
  attachmentId?: number;
};

type DispatchProps = {
  goToSnap: typeof routerActions.goToSnap;
  goToAttachment: typeof routerActions.goToAttachment;
};

type Props = OwnProps & DispatchProps;

const mapDispatchToProps = {
  goToSnap: routerActions.goToSnap,
  goToAttachment: routerActions.goToAttachment,
};

export class MediaLibraryHeader extends React.PureComponent<Props> {
  onCancel = () => {
    const { publisherId, editionId, snapId, attachmentId } = this.props;
    if (attachmentId) {
      this.props.goToAttachment({
        publisherId,
        editionId,
        snapId,
        attachmentId,
        // For now, the only attachment type supported is LFV. This may change in the future
        // and this logic should be updated accordingly
        attachmentType: SnapType.LONGFORM_VIDEO,
        overwriteHistory: false,
      });
    } else if (snapId) {
      this.props.goToSnap({
        publisherId,
        editionId,
        snapId,
        overwriteHistory: false,
      });
    }
  };

  render() {
    return (
      <div className={style.container}>
        <div className={style.titleContainer}>
          <Icon className={style.titleIcon} inlineIcon={media} />
          <span className={style.title} data-test="mediaLibraryHeader.title">
            {getMessageFromId('media-library-title')}
          </span>
        </div>
        <MediaLibraryHeaderButtons
          onCancel={this.onCancel}
          onDropFiles={this.props.onDropFiles}
          hideCancelButton={this.props.hideCancelButton}
        />
      </div>
    );
  }
}

export default intlConnect(null, mapDispatchToProps)(MediaLibraryHeader);
