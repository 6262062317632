export default (event: any, container: any) => {
  if (!container) {
    return;
  }

  if (container !== event.target && !container.contains(event.target)) {
    return;
  }

  // it will scroll over top, reposition the scroll bar at top
  if (event.wheelDeltaY > container.scrollTop) {
    /* eslint-disable no-param-reassign */
    container.scrollTop = 0;
    /* eslint-enable no-param-reassign */
    event.preventDefault();
    return;
  }

  // it will scroll over bottom, reposition the scroll bar at bottom
  if (container.scrollTop + container.clientHeight - container.scrollHeight > event.wheelDeltaY) {
    /* eslint-disable no-param-reassign */
    container.scrollTop = container.scrollHeight - container.clientHeight;
    /* eslint-enable no-param-reassign */
    event.preventDefault();
  }
};
