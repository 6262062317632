import { ZoomType } from 'types/grid';
import type { Zoom, GridDimensions } from 'types/grid';

export const CURATION_CELL_WIDTH = 170;
export const CURATION_CELL_HEIGHT = 290;

export const CURATION_CELL_LARGE_WIDTH = CURATION_CELL_WIDTH * 2;
export const CURATION_CELL_LARGE_HEIGHT = CURATION_CELL_HEIGHT * 2;

export type GridStyle = {
  paddingLeft?: number;
  paddingRight?: number;
};

export const getGridSnapWidth = (zoom: Zoom, dimensions?: GridDimensions) => {
  if (dimensions) {
    return dimensions[zoom].width;
  }
  return zoom === ZoomType.ZOOMED_OUT ? CURATION_CELL_WIDTH : CURATION_CELL_LARGE_WIDTH;
};

export const getGridSnapHeight = (zoom: Zoom, dimensions?: GridDimensions) => {
  if (dimensions) {
    return dimensions[zoom].height;
  }
  return zoom === ZoomType.ZOOMED_OUT ? CURATION_CELL_HEIGHT : CURATION_CELL_LARGE_HEIGHT;
};

export const getNumberOfCols = (gridWidth: number, zoom: Zoom, dimensions?: GridDimensions) => {
  return Math.max(1, Math.floor(gridWidth / getGridSnapWidth(zoom, dimensions)));
};
