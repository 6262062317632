import 'react-select/scss/default.scss';
import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import type { ChangeEvent } from 'react';
import { intlShape, FormattedMessage } from 'react-intl';

import { getAuthType } from 'state/auth/selectors/authSelectors';
import * as episodesActions from 'state/episodes/actions/episodesActions';
import * as episodesSelectors from 'state/episodes/selectors/episodesSelectors';
import * as extrasSelectors from 'state/extras/selectors/extrasSelectors';
import * as featuresSelectors from 'state/features/selectors/featuresSelectors';
import { isSingleAssetStoryEditorEnabled } from 'state/features/selectors/featuresSelectors';
import * as modalsActions from 'state/modals/actions/modalsActions';
import * as publisherStoryEditorSelectors from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';
import type {
  CreateOrUpdateEpisodeWithEpisodeMetadataParams,
  CreateOrUpdateEpisodeParams,
} from 'state/publisherTools/actions/publisherToolsActions';
import * as publishersSelectors from 'state/publishers/selectors/publishersSelectors';
import { getActivePublisherBusinessProfileId } from 'state/publishers/selectors/publishersSelectors';
import * as seasonsSelectors from 'state/seasons/selectors/seasonsSelectors';
import * as showsActions from 'state/shows/actions/showsActions';
import * as showsSelectors from 'state/shows/selectors/showsSelectors';
import { getActivePublisherId } from 'state/user/selectors/userSelectors';

import { AuthType, ErrorType, MAX_EPISODES_PER_SEASON } from 'config/constants';
import { State } from 'src/types/rootState';
import * as adSnapIndexUtils from 'utils/adSnapIndexUtils';
import * as creativeSuiteAPI from 'utils/apis/creativeSuiteAPI';
import { onEnterKey } from 'utils/browserUtils';
import { intlConnect } from 'utils/connectUtils';
import { getLocalisedMessageFromId, getMessageFromId, registerIntlMessage } from 'utils/intlMessages/intlMessages';
import type { MediaError } from 'utils/media/mediaValidation';

import AlertBox, { AlertType } from 'views/common/components/AlertBox/AlertBox';
import SDSDialog from 'views/common/components/SDSDialog/SDSDialog';
import SDSDropdown, { DropdownType } from 'views/common/components/SDSDropdown/SDSDropdown';
import { createSDSDropdownOptionsWithPropLabel } from 'views/common/components/SDSDropdownOptionsWithPropLabel/SDSDropdownOptionsWithPropLabel';
import SDSInput, { InputType } from 'views/common/components/SDSInput/SDSInput';
import { validateMinMaxValue } from 'views/common/components/SDSInput/inputValidationUtils';
import SDSLabel from 'views/common/components/SDSLabel/SDSLabel';
import SDSSwitch from 'views/common/components/SDSSwitch/SDSSwitch';
import SingleAssetProgressUploadView from 'views/singleAssetStoryEditor/containers/SingleAssetProgressUploadView/SingleAssetProgressUploadView';

import style from './EpisodeModal.scss';

import { AssetType } from 'types/assets';
import type { AssetID } from 'types/polls';
import { ExtractDispatchProps } from 'types/redux';
import type { Show, Season, Episode, Extra } from 'types/shows';
import { SingleAssetUploadState } from 'types/singleAssetStoryEditor';

registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="single-asset-upload-unsaved-upload-text"
      description="Text warning the user that if they continue to close the upload modal, their upload will be lost"
      defaultMessage="You have an unsaved upload that will be lost. Are you sure you want to continue?"
    />
  ),
  params: [],
});
const NEW_SEASON_ID = 'new_id';
const NEW_EPISODE_NUMBER = 1;
type ExternalProps = {
  modalId: string;
  hideModal: (modalId: string, results?: {}) => void;
  options: {
    existingTitle: string;
    isUpdateModal?: boolean;
    onSaveEpisodeOrExtra: (params: CreateOrUpdateEpisodeWithEpisodeMetadataParams) => void;
    onSaveStory: (params: CreateOrUpdateEpisodeParams) => void;
    'data-test': string;
  };
};
type StateProps = {
  existingSeason?: Season | null;
  publisherId: number | null;
  businessProfileId: string | null;
  publisherFormalName: string;
  getPrimaryLanguageMessage: ReturnType<typeof publishersSelectors.getPrimaryLanguageMessage>;
  isExtrasEnabled: boolean;
  defaultNumSnaps: number;
  show: Show | undefined | null;
  episode: Episode | undefined | null;
  extra: Extra | undefined | null;
  isBitmojiContentEnabled: boolean;
  isSingleAssetEnabled: boolean;
  isAutomaticEpisodeAllocationEnabled: boolean;
  isEpisodeEditable: boolean;
  isStoryEditable: boolean | undefined | null;
  isSnapSSO: boolean;
};
type OwnState = {
  title: string;
  isPartOfShow: boolean;
  numberOfSnaps: number;
  selectedSeasonId: string;
  selectedEpisodeNumber: number;
  seasonDisplayName: string | undefined | null;
  hasMaxNumberOfEpisodes: boolean;
  isExtra: boolean;
  singleAssetVideoId: string;
  singleAssetUploadState: SingleAssetUploadState;
  createStoryOnUploadComplete: boolean;
};
const mapStateToProps = (state: State, ownProps: ExternalProps): StateProps => {
  const activePublisher = publishersSelectors.getActivePublisherDetails(state);
  const activeStory = publisherStoryEditorSelectors.getActiveEdition(state);
  const authType = getAuthType(state);
  const isSnapSSO = authType === AuthType.SNAPCHAT;
  const episode = activeStory && episodesSelectors.getEpisodeById(state)(activeStory.id);
  const extra = activeStory && extrasSelectors.getExtraById(state)(activeStory.id);
  // for now, just take the first show in the list, else null if
  // the publisher hasn't created one yet
  const shows = showsSelectors.getShows(state)(activePublisher?.id);
  const existingSeasonId = _.get(episode, ['seasonId']) || _.get(extra, ['seasonId']);
  const currentShow = _.get(shows, [0], null);
  const isBitmojiContentEnabled = featuresSelectors.isBitmojiContentEnabled(state);
  const isAutomaticEpisodeAllocationEnabled = showsSelectors.isAutomaticEpisodeAllocationEnabled(state);
  const isStoryEditable =
    activeStory && !publisherStoryEditorSelectors.getStoryIsReadOnlyOrSaving(state)(activeStory.id);
  const isEpisodeEditable = showsSelectors.canEditEpisodeMetadata(state);
  const isAdvancedCurator = featuresSelectors.isAdvancedCurationEnabled(state);
  return {
    getPrimaryLanguageMessage: publishersSelectors.getPrimaryLanguageMessage(state),
    isExtrasEnabled: featuresSelectors.isExtrasEnabled(state),
    publisherId: getActivePublisherId(state),
    businessProfileId: getActivePublisherBusinessProfileId(state),
    publisherFormalName: activePublisher?.formalName || '',
    defaultNumSnaps: adSnapIndexUtils.getMinSnapsToSatisfyAdRulesByPublisher(activePublisher, isAdvancedCurator),
    existingSeason: existingSeasonId ? seasonsSelectors.getSeasonById(state)(existingSeasonId) : null,
    show: currentShow,
    episode,
    extra,
    isBitmojiContentEnabled,
    isSingleAssetEnabled: isSingleAssetStoryEditorEnabled(state),
    isAutomaticEpisodeAllocationEnabled,
    isEpisodeEditable,
    isStoryEditable,
    isSnapSSO,
  };
};
const mapDispatchToProps = {
  hideModal: modalsActions.hideModal,
  loadShows: showsActions.loadShows,
  openEpisodeCreatorModal: episodesActions.openEpisodeCreatorModal,
};
type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;
type Props = ExternalProps & DispatchProps & StateProps;
export class EpisodeModal extends React.Component<Props, OwnState> {
  state: OwnState = {
    selectedSeasonId: NEW_SEASON_ID,
    selectedEpisodeNumber: NEW_EPISODE_NUMBER,
    seasonDisplayName: null,
    title: '',
    isPartOfShow: false,
    numberOfSnaps: 0,
    hasMaxNumberOfEpisodes: false,
    isExtra: false,
    singleAssetVideoId: '',
    singleAssetUploadState: SingleAssetUploadState.NONE,
    createStoryOnUploadComplete: false,
  };

  static contextTypes = {
    intl: intlShape,
  };

  componentDidMount() {
    const isPartOfShow = !!this.props.episode || !!this.props.extra;
    const isExtra = !!this.props.extra;
    if (this.props.publisherId && this.props.businessProfileId) {
      this.props.loadShows(this.props.publisherId, this.props.businessProfileId);
    }
    this.updateState(this.props, { isPartOfShow, isExtra });
  }

  componentDidUpdate(prevProps: Props, prevState: OwnState) {
    if (
      prevState.singleAssetUploadState === SingleAssetUploadState.UPLOADING &&
      this.state.singleAssetUploadState === SingleAssetUploadState.COMPLETE
    ) {
      if (this.state.createStoryOnUploadComplete) {
        this.saveTitle();
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (!_.isEqual(this.props.show, nextProps.show)) {
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
      this.updateState(nextProps);
    }
  }

  onChange = (event: ChangeEvent<EventTarget>) => {
    const title = (event.target as any).value;
    this.setState({ title });
  };

  onIsPartOfShowChange = () => {
    this.setState(prevState => ({ isPartOfShow: !prevState.isPartOfShow }));
  };

  onSeasonDisplayNameChange = (event: ChangeEvent<EventTarget>) => {
    this.setState({ seasonDisplayName: (event.target as any).value });
  };

  repopulateEmptyInput = async () => {
    if (this.state.seasonDisplayName) {
      return;
    } // don't replace if the user entered something in the input
    const seasonDisplayName = await this.getSelectedPlaceholderDisplayName();
    this.setState({ seasonDisplayName });
  };

  onEpisodeChange = (event: ChangeEvent<EventTarget>) => {
    this.setState({ selectedEpisodeNumber: parseInt((event.target as any).value, 10) });
  };

  onExtraChange = () => {
    this.setState(prevState => ({ isExtra: !prevState.isExtra }));
  };

  onEnterSaveTitle = onEnterKey((event: any) => {
    event.preventDefault();
    if (this.state.singleAssetUploadState === SingleAssetUploadState.UPLOADING) {
      this.handleCreateOnCompleteClick();
    } else if (this.props.options?.isUpdateModal) {
      this.saveTitle();
    }
  });

  onHide = () => {
    this.props.hideModal(this.props.modalId);
  };

  onNumberOfSnapsChange = (event: ChangeEvent<EventTarget>) => {
    this.setState({ numberOfSnaps: parseInt((event.target as any).value, 10) });
  };

  onSeasonChange = (selectedSeasonId: string) => {
    if (selectedSeasonId === NEW_SEASON_ID) {
      this.setState({
        selectedSeasonId,
        seasonDisplayName: null,
        selectedEpisodeNumber: NEW_EPISODE_NUMBER,
      });
    }
    const currentSeason = this.getSelectedSeason(selectedSeasonId);
    const episodes = _.get(currentSeason, ['episodes'], []);
    const lastEpisode = _.last(episodes);
    const currentEpisodeNumber = lastEpisode ? (lastEpisode as any).episodeNumber + 1 : NEW_EPISODE_NUMBER;
    const hasMaxNumberOfEpisodes = episodes.length >= MAX_EPISODES_PER_SEASON;
    this.setState({ selectedSeasonId, selectedEpisodeNumber: currentEpisodeNumber, hasMaxNumberOfEpisodes });
  };

  getSelectedPlaceholderDisplayName() {
    const seasonNumber = this.getSeasonNumber();
    return getLocalisedMessageFromId(this.context, 'season-placeholder-with-season-number', { number: seasonNumber });
  }

  getSelectedSeason = (seasonId: string) => {
    const seasons = _.get(this.props, ['show', 'seasons']);
    return _.find(seasons, season => season.id === seasonId);
  };

  getSeasonNumber = () => {
    const { selectedSeasonId } = this.state;
    const selectedSeason = this.getSelectedSeason(selectedSeasonId);
    const isNewSeason = selectedSeasonId === NEW_SEASON_ID;
    const existingSeasons = _.get(this.props.show, ['seasons'], []);
    const previousSeasonNumber = _.get(_.last(existingSeasons), ['seasonNumber'], 0);
    return isNewSeason ? previousSeasonNumber + 1 : _.get(selectedSeason, 'seasonNumber');
  };

  isCurrentSeason = (seasonId: string) => {
    const seasons = _.get(this.props, ['show', 'seasons']);
    if (!seasons) {
      return false;
    }
    const lastSeason = _.last(seasons);
    return _.get(lastSeason, 'id') === seasonId;
  };

  isSeasonDisabled = () => {
    if (!this.state.isPartOfShow) {
      return true;
    }
    const isUpdateModal = _.get(this.props, ['options', 'isUpdateModal'], false);
    return isUpdateModal && (!!this.props.episode || !!this.props.extra);
  };

  isEpisodeNumberDisabled = () => {
    if (!this.state.isPartOfShow) {
      return true;
    }
    return !_.get(this.props, ['options', 'isUpdateModal'], true);
  };

  isExtraToggleDisabled = () => {
    if (!this.state.isPartOfShow || this.props.extra) {
      return true;
    }
    return !_.get(this.props, ['options', 'isUpdateModal'], true);
  };

  updateState(props: Props, additionalState: Partial<OwnState>) {
    const { existingSeason, show: currentShow } = props;
    const existingEpisode = this.props.episode;
    const seasons = _.get(currentShow, ['seasons'], []);
    const currentSeason = _.get(seasons, [seasons.length - 1], null);
    const episodes = _.get(currentSeason, ['episodes'], []);
    const lastSeason = _.last(seasons);
    const lastEpisode = _.last(episodes);
    const currentSeasonId = lastSeason ? lastSeason.id : NEW_SEASON_ID;
    const currentEpisodeNumber = lastEpisode ? lastEpisode.episodeNumber + 1 : NEW_EPISODE_NUMBER;
    const selectedEpisodeNumber = _.get(existingEpisode, 'episodeNumber', currentEpisodeNumber);
    const hasMaxNumberOfEpisodes = episodes.length >= MAX_EPISODES_PER_SEASON;
    const selectedSeasonId = _.get(existingSeason, 'id', currentSeasonId);
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ title: string; isPartOfShow?: ... Remove this comment to see the full error message
    this.setState({
      selectedSeasonId,
      title: _.get(props, ['options', 'existingTitle'], ''),
      numberOfSnaps: props.defaultNumSnaps,
      selectedEpisodeNumber,
      hasMaxNumberOfEpisodes,
      ...additionalState,
    });
  }

  saveEpisodeOrExtra = (storyId: string, isExtra: boolean) => {
    const { title, numberOfSnaps, selectedSeasonId, isPartOfShow, seasonDisplayName, singleAssetVideoId } = this.state;
    const showId = _.get(this.props, ['show', 'id'], null);
    const selectedSeason = this.getSelectedSeason(selectedSeasonId);
    const seasonId = _.get(selectedSeason, 'id');
    const isNewSeason = selectedSeasonId === NEW_SEASON_ID;
    const seasonNumber = this.getSeasonNumber();
    const episodeNumber = isExtra ? null : this.state.selectedEpisodeNumber;
    const isEpisodeOrPartOfShow = isPartOfShow || this.props.isEpisodeEditable;
    this.props.options.onSaveEpisodeOrExtra({
      id: storyId,
      title,
      isPartOfShow: isEpisodeOrPartOfShow,
      numberOfSnaps,
      showId,
      seasonId,
      seasonNumber,
      episodeNumber,
      seasonDisplayName: isNewSeason ? seasonDisplayName : null,
      singleAssetVideoId,
      isStoryEditable: this.props.isStoryEditable,
    });
    this.onHide();
  };

  saveStory = () => {
    const { title, numberOfSnaps, singleAssetVideoId } = this.state;
    this.props.options.onSaveStory({
      title,
      numberOfSnaps,
      singleAssetVideoId,
    });
    this.onHide();
  };

  saveTitle = () => {
    if (this.props.isAutomaticEpisodeAllocationEnabled && !this.props.isEpisodeEditable) {
      this.saveStory();
    } else {
      const isUnlinkingExtra = !this.state.isPartOfShow && this.props.extra;
      const isCreatingExtra = this.state.isPartOfShow && this.state.isExtra;
      if (isUnlinkingExtra || isCreatingExtra) {
        const storyId = _.get(this.props.extra, 'id');
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
        this.saveEpisodeOrExtra(storyId, true);
      } else {
        const storyId = _.get(this.props.episode, 'id');
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
        this.saveEpisodeOrExtra(storyId, false);
      }
    }
  };

  renderStoryNameOption = () => {
    return (
      <SDSInput
        labelTitle={getMessageFromId('story-name-field')}
        data-test="episodeModal.storyNameField"
        maxLength={50}
        value={this.state.title}
        placeholder={getMessageFromId('give-your-story-a-name')}
        onChange={this.onChange}
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 3 arguments, but got 2.
        onKeyDown={this.onEnterSaveTitle}
        withoutDefaultMargin
      />
    );
  };

  renderNumberOfSnapsOption = () => {
    const {
      options: { isUpdateModal },
    } = this.props;
    if (isUpdateModal) {
      return null;
    }
    return (
      <SDSInput
        validateInput={validateMinMaxValue(1, 1000)}
        data-test="episodeModal.numberOfSnapsField"
        type={InputType.NUMBER}
        value={this.state.numberOfSnaps}
        onChange={this.onNumberOfSnapsChange}
        labelTitle={getMessageFromId('number-empty-snaps-field')}
        withoutDefaultMargin
      />
    );
  };

  renderIsPartOfShowToggle = () => {
    return (
      <div className={style.serialisedToggleContainer}>
        <SDSSwitch
          /* @ts-expect-error ts-migrate(2322) FIXME: Type '{ name: string; "data-test": string; onChang... Remove this comment to see the full error message */
          name="EpisodeModal.isPartOfShowToggle"
          data-test="episodeModal.isPartOfShowToggle"
          onChange={this.onIsPartOfShowChange}
          value={this.state.isPartOfShow}
        />
        {getMessageFromId('story-part-of-show-field')}
      </div>
    );
  };

  renderExtraToggle = () => {
    // Can't assign an already linked episode, need to unlink it first
    if (this.props.episode) {
      return null;
    }
    return (
      <div className={classNames({ [style.disabled]: this.isExtraToggleDisabled() })}>
        <SDSLabel labelTitle={getMessageFromId('extra-toggle-field')}>
          <SDSSwitch
            /* @ts-expect-error ts-migrate(2322) FIXME: Type '{ name: string; "data-test": string; onChang... Remove this comment to see the full error message */
            name="EpisodeModal.extraToggle"
            data-test="EpisodeModal.extraToggle"
            onChange={this.onExtraChange}
            value={this.state.isExtra}
            isReadOnly={this.isExtraToggleDisabled()}
          />
        </SDSLabel>
      </div>
    );
  };

  renderNewSeasonItem = () => {
    const newSeasonLabel = getLocalisedMessageFromId(this.context, 'new-season-dropdown');
    return { value: NEW_SEASON_ID, label: newSeasonLabel, menuLabel: newSeasonLabel };
  };

  renderSeasons = () => {
    const seasons = _.get(this.props.show, 'seasons');
    if (!seasons || seasons.length === 0) {
      return [this.renderNewSeasonItem()];
    }
    // Here we map through the seasons, creating two labels that are used for both the dropdown menu and the selected value
    // in the dropdown. If the season id matches the current season, we append a '(current)' to the end of the selected value
    // label.
    const availableSeasons = seasons.map(season => {
      const { displayName, seasonNumber, id } = season;
      const dropdownMenuLabel = `${seasonNumber} -${getLocalisedMessageFromId(
        this.context,
        'season-field'
      )} ${seasonNumber} ${displayName}`;
      const selectedValueLabel = this.isCurrentSeason(id)
        ? getLocalisedMessageFromId(this.context, 'season-dropdown-current', { seasonNumber })
        : seasonNumber;
      return { value: id, menuLabel: dropdownMenuLabel, label: selectedValueLabel };
    });
    return [...availableSeasons, this.renderNewSeasonItem()];
  };

  renderSeasonOption = () => {
    const { selectedSeasonId } = this.state;
    return (
      <SDSLabel labelTitle={getMessageFromId('season-field')} withoutDefaultMargin>
        <SDSDropdown
          disableClear
          value={selectedSeasonId}
          disabled={this.isSeasonDisabled()}
          onChange={this.onSeasonChange}
          type={DropdownType.GREY}
          optionLabelProp="label"
        >
          {createSDSDropdownOptionsWithPropLabel(this.renderSeasons())}
        </SDSDropdown>
      </SDSLabel>
    );
  };

  renderSeasonDisplayNameOption = () => {
    if (this.state.selectedSeasonId !== NEW_SEASON_ID) {
      return null;
    }
    // if the value is null it means the user never typed, if it's an empty string it's because the user deleted what was in the input
    const { seasonDisplayName } = this.state;
    const seasonName: string | undefined =
      seasonDisplayName === null ? this.getSelectedPlaceholderDisplayName() : seasonDisplayName;

    return (
      <SDSInput
        className={style.seasonNameInput}
        labelTitle={getLocalisedMessageFromId(this.context, 'season-field-display-name')}
        disabled={this.isSeasonDisabled()}
        value={seasonName}
        suffix={
          this.state.seasonDisplayName === null ? (
            <FormattedMessage
              id="suffix-default"
              description="Suffix shown when the user hasn't typed to explain the input value is default"
              defaultMessage="default"
            />
          ) : null
        }
        maxLength={20}
        onChange={this.onSeasonDisplayNameChange}
        onBlur={this.repopulateEmptyInput}
        data-test="episodeModal.seasonName.inputBox"
        withoutDefaultMargin
      />
    );
  };

  renderSerialisedOptions = () => {
    return (
      <div className={style.serialisedOptionsContainer}>
        {this.renderSeasonOption()}
        {!this.state.isExtra && this.renderEpisodeOption()}
      </div>
    );
  };

  renderSerialisedSection = () => {
    const { isPartOfShow } = this.state;
    return (
      <div className={style.serialisedSectionContainer}>
        {this.renderIsPartOfShowToggle()}
        {this.props.isExtrasEnabled && this.renderExtraToggle()}
        {isPartOfShow && this.renderSerialisedOptions()}
        {isPartOfShow && this.renderSeasonDisplayNameOption()}
      </div>
    );
  };

  renderMaxEpisodeError = () => {
    return (
      <div className={style.errorMessage} data-test="EpisodeModal.MaxEpisodesError.message">
        {getMessageFromId('warning-too-many-episodes-in-a-season', { maxNumberOfEpisodes: MAX_EPISODES_PER_SEASON })}
      </div>
    );
  };

  renderEpisodeOption = () => {
    return (
      <SDSInput
        labelTitle={getMessageFromId('episode-number-field')}
        data-test="episodeModal.episodeNumber"
        type={InputType.NUMBER}
        disabled={this.isEpisodeNumberDisabled()}
        value={this.state.selectedEpisodeNumber}
        onChange={this.onEpisodeChange}
        withoutDefaultMargin
      />
    );
  };

  handleOnUploadComplete = (fileInfo: { assetId: AssetID; type: AssetType; file: File }) => {
    this.setState({ singleAssetVideoId: fileInfo.assetId, singleAssetUploadState: SingleAssetUploadState.COMPLETE });
  };

  handleOnUploadStart = () => {
    this.setState({ singleAssetUploadState: SingleAssetUploadState.UPLOADING });
  };

  handleOnUploadFailed = (error: MediaError) => {
    this.setState({ singleAssetUploadState: SingleAssetUploadState.NONE });
    if (error?.errorType === ErrorType.HORIZONTAL_SINGLE_ASSET) {
      this.onHide();
      if (this.props.publisherId) {
        this.props.openEpisodeCreatorModal(this.props.publisherId);
      }
    }
  };

  handleCreateOnCompleteClick = () => {
    this.setState({ createStoryOnUploadComplete: true });
  };

  renderUploadSection = () => {
    return (
      <SingleAssetProgressUploadView
        uploadState={this.state.singleAssetUploadState}
        handleOnUploadStart={this.handleOnUploadStart}
        handleOnUploadComplete={this.handleOnUploadComplete}
        handleOnUploadFailed={this.handleOnUploadFailed}
        data-test="EpisodeModal.SingleAssetProgressUploadView"
      />
    );
  };

  onCancel = () => {
    const { singleAssetUploadState } = this.state;
    if (
      singleAssetUploadState === SingleAssetUploadState.UPLOADING ||
      singleAssetUploadState === SingleAssetUploadState.COMPLETE
    ) {
      // eslint-disable-next-line no-alert
      if (window.confirm(getLocalisedMessageFromId(this.context, 'single-asset-upload-unsaved-upload-text'))) {
        this.onHide();
      }
    } else {
      this.onHide();
    }
  };

  buildVideoConversionToolLink = () => {
    const conversionToolLink = creativeSuiteAPI.creative.createEpisode(this.props.isSnapSSO)({
      publisherId: this.props.publisherId,
    });
    return (
      <a href={conversionToolLink}>
        <FormattedMessage
          id="video-conversion-tool-link"
          description="Link to video conversion tool"
          defaultMessage="video conversion tool"
        />
      </a>
    );
  };

  renderShowSchedulingInfo = () => {
    return (
      <AlertBox type={AlertType.INFO}>
        <FormattedMessage
          id="show-scheduling-v2-info-title"
          description="info message to alert users of new feature"
          defaultMessage="Upload a vertical or horizontal video. Horizontal videos will open up our {videoConversionToolLink}."
          values={{ videoConversionToolLink: this.buildVideoConversionToolLink() }}
        />
      </AlertBox>
    );
  };

  renderEpisodeMetadataInputsOnly = () => {
    return (
      <div className={style.serialisedSectionContainer}>
        {this.props.isStoryEditable && this.renderStoryNameOption()}
        {this.renderSerialisedOptions()}
        {this.renderSeasonDisplayNameOption()}
      </div>
    );
  };

  renderAllMetadataInputs = () => {
    const {
      options: { isUpdateModal },
      isSingleAssetEnabled,
    } = this.props;
    const showValidation = !this.state.isPartOfShow || !this.state.hasMaxNumberOfEpisodes;
    return (
      <>
        {this.renderStoryNameOption()}
        {!isSingleAssetEnabled && this.renderNumberOfSnapsOption()}
        {!this.props.isAutomaticEpisodeAllocationEnabled
          ? this.renderSerialisedSection()
          : this.renderShowSchedulingInfo()}
        {isSingleAssetEnabled && !isUpdateModal && this.renderUploadSection()}
        {!showValidation && this.renderMaxEpisodeError()}
      </>
    );
  };

  render() {
    const {
      options: { isUpdateModal },
      isSingleAssetEnabled,
    } = this.props;
    const showValidation = !this.state.isPartOfShow || !this.state.hasMaxNumberOfEpisodes;
    const canCreateStory =
      isSingleAssetEnabled && !isUpdateModal ? this.state.singleAssetVideoId && showValidation : showValidation;
    let okText;
    if (this.state.singleAssetUploadState === SingleAssetUploadState.UPLOADING) {
      okText = (
        <FormattedMessage
          id="single-asset-upload-create-when-upload-completes"
          description="Text for button which when clicked, creates the story after upload completes"
          defaultMessage="Create story when upload completes"
        />
      );
    } else {
      okText = isUpdateModal ? getMessageFromId('save-changes') : getMessageFromId('create-story-field');
    }
    return (
      <SDSDialog
        visible
        width={500}
        title={
          isUpdateModal ? getMessageFromId('update-episode-modal-title') : getMessageFromId('title-create-new-story')
        }
        onCancel={this.onCancel}
        onOk={
          this.state.singleAssetUploadState === SingleAssetUploadState.UPLOADING
            ? this.handleCreateOnCompleteClick
            : this.saveTitle
        }
        okButtonProps={{
          disabled:
            this.state.singleAssetUploadState === SingleAssetUploadState.UPLOADING
              ? this.state.createStoryOnUploadComplete
              : !canCreateStory,
        }}
        okText={okText}
      >
        <div className={style.modalBodyContainer}>
          {this.props.isEpisodeEditable ? this.renderEpisodeMetadataInputsOnly() : this.renderAllMetadataInputs()}
        </div>
      </SDSDialog>
    );
  }
}
export default intlConnect(mapStateToProps, mapDispatchToProps)(EpisodeModal);
