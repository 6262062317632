import classNames from 'classnames';
import React from 'react';
import type { ReactNode } from 'react';

import SDSDropdown, { DropdownSize, DropdownType } from 'views/common/components/SDSDropdown/SDSDropdown';
import { createSDSDropdownOptions } from 'views/common/components/SDSDropdownOptions/SDSDropdownOptions';
import SDSLabel from 'views/common/components/SDSLabel/SDSLabel';

import style from './SettingDropdownForm.scss';

type OptionValue = any;

type Option = {
  value: OptionValue;
  label: string | ReactNode;
};

type Props = {
  title: string | ReactNode;
  enabledOptions: Option[] | undefined | null;
  currentOption: OptionValue;
  onChange: (value: string) => void;
};

export class SettingDropdownForm extends React.Component<Props> {
  render() {
    const buttons = this.props.enabledOptions;
    if (buttons === null || buttons === undefined) {
      return null;
    }
    return (
      <div className={classNames(style.inputContainer, style.formInputContainer)}>
        <SDSLabel labelTitle={this.props.title}>
          <SDSDropdown
            disableClear
            onChange={this.props.onChange}
            value={this.props.currentOption}
            size={DropdownSize.MEDIUM}
            type={DropdownType.GREY}
          >
            {createSDSDropdownOptions(buttons)}
          </SDSDropdown>
        </SDSLabel>
      </div>
    );
  }
}

export default SettingDropdownForm;
