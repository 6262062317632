import classnames from 'classnames';
import React from 'react';
import type { ReactNode, ChangeEvent } from 'react';
import { FormattedMessage, intlShape } from 'react-intl';

import { getMessageFromId, getLocalisedMessageFromId } from 'utils/intlMessages/intlMessages';

import SDSInput from 'views/common/components/SDSInput/SDSInput';

import style from './UserInfoForm.scss';

type Props = {
  snapUsername: string;
  username: string;
  email: string;
  handleSnapUsernameChange: (a: ChangeEvent<EventTarget>) => void;
  handleUsernameChange: (a: ChangeEvent<EventTarget>) => void;
  handleEmailChange: (a: ChangeEvent<EventTarget>) => void;
  isAddingNewUser: boolean;
  isHandlingEmployee: boolean;
  shouldShowEmailAndName: boolean;
};

export default class UserInfoForm extends React.Component<Props> {
  static contextTypes = {
    intl: intlShape,
  };

  getEmailPlaceholderMessageId = () => {
    if (this.props.isHandlingEmployee) {
      return 'create-employee-user-email-field-placeholder';
    }
    return 'email-edit-user-modal';
  };

  renderTextFormField = ({
    messageId,
    placeholderId,
    value,
    isReadOnly,
    onChange,
    dataTestId,
  }: {
    messageId: string;
    placeholderId: string;
    value: string;
    isReadOnly: boolean;
    onChange: (a: ChangeEvent<EventTarget>) => void;
    dataTestId: string;
  }): ReactNode => {
    return (
      <SDSInput
        labelTitle={getMessageFromId(messageId)}
        value={value || ''}
        placeholder={getLocalisedMessageFromId(this.context, placeholderId)}
        disabled={isReadOnly}
        onChange={onChange}
        data-test={dataTestId}
      />
    );
  };

  renderUsername = (): ReactNode => {
    return this.renderTextFormField({
      messageId: 'username-edit-user-modal',
      placeholderId: 'username-edit-user-modal',
      value: this.props.username,
      isReadOnly: false,
      onChange: this.props.handleUsernameChange,
      dataTestId: 'admin.newUserModal.username',
    });
  };

  // SnapUsername can be edited at any time except if the user is shown via contentShare relationship
  renderSnapUsername = (): ReactNode => {
    return this.renderTextFormField({
      messageId: 'snapUsername-edit-user-modal',
      placeholderId: 'snapUsername-edit-user-modal',
      value: this.props.snapUsername,
      isReadOnly: false,
      onChange: this.props.handleSnapUsernameChange,
      dataTestId: 'admin.newUserModal.snapUsername',
    });
  };

  renderEmail = (): ReactNode => {
    return this.renderTextFormField({
      messageId: 'email-edit-user-modal',
      placeholderId: this.getEmailPlaceholderMessageId(),
      value: this.props.email,
      isReadOnly: !this.props.isAddingNewUser && this.props.isHandlingEmployee,
      onChange: this.props.handleEmailChange,
      dataTestId: 'admin.newUserModal.email',
    });
  };

  renderInfo = (snapUsername: string): ReactNode => {
    return (
      <div className={style.info}>
        <FormattedMessage
          id="user-needs-email"
          description="Explanation"
          defaultMessage={
            '{snapUsername} has not used our tools before, so we need an email address in order to send an invite.'
          }
          values={{ snapUsername }}
        />
      </div>
    );
  };

  renderSnapAuthFlow(): ReactNode {
    const emailAndUsernameClasses = classnames(style.slider, {
      [style.closed]: !this.props.shouldShowEmailAndName,
    });

    return (
      <div className={style.form}>
        {this.renderSnapUsername()}
        <div className={emailAndUsernameClasses}>
          {!this.props.email && this.renderInfo(this.props.snapUsername)}
          {this.renderEmail()}
          {this.renderUsername()}
        </div>
      </div>
    );
  }

  renderGoogleAuthFlow(): ReactNode {
    return (
      <div className={style.form}>
        {this.renderEmail()}
        {this.renderUsername()}
      </div>
    );
  }

  render(): ReactNode {
    if (this.props.isHandlingEmployee) {
      return this.renderGoogleAuthFlow();
    }

    return this.renderSnapAuthFlow();
  }
}
