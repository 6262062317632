// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
import { Spinner } from '@snapchat/snapnet'; // discover-cms/no-snapnet
import classNames from 'classnames';
import { noop } from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import InlineSVG from 'svg-inline-react';

import { UploadPurpose, DropzoneTypeEnum } from 'config/constants';
import { EditionID } from 'src/types/editionID';
import { MediaValidation } from 'src/types/media';
import { assertArg } from 'utils/assertionUtils';

import MediaUploader from 'views/editor/containers/MediaUploader/MediaUploader';
import { updateIfPropsAndStateChanged } from 'views/propTypes/utils';

// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'images/upload.svg.inline' or i... Remove this comment to see the full error message
import uploadArrowIcon from 'images/upload.svg.inline';

import style from './ImageMediaUploader.scss';

import { AssetType, assertAssetId } from 'types/assets';
import { SnapId } from 'types/common';
import type { AssetID } from 'types/polls';

const defaultHeadline = (
  <FormattedMessage id="image-media-uploader-headline" description="Upload Image" defaultMessage="Upload Image" />
);

const defaultSubHeadline = (
  <FormattedMessage
    id="image-media-uploader-sub-headline"
    description="Drop file here"
    defaultMessage="Drop file here"
  />
);

type Props = {
  snapId: SnapId | null;
  editionId?: EditionID;
  purpose?: UploadPurpose;
  dropzoneType: DropzoneTypeEnum;
  isReadOnly: boolean;
  customValidationOptions?: MediaValidation;
  isReplace: boolean;
  headline: string | JSX.Element;
  subHeadline: string | JSX.Element;
  onUploadComplete: (obj: { assetId: AssetID; type: AssetType; file: File }) => void;
  onUploadStart: () => void;
  className?: string;
  processing: boolean;
  'data-test': string;
};

type OwnState = any;
type State = OwnState & typeof ImageMediaUploader.defaultProps;

export class ImageMediaUploader extends React.Component<Props, State> {
  static defaultProps = {
    headline: defaultHeadline,
    subHeadline: defaultSubHeadline,
    onUploadComplete: noop,
    onUploadStart: noop,
    processing: false,
  };

  shouldComponentUpdate(nextProps: any, nextState: State) {
    return updateIfPropsAndStateChanged(this.props, this.state, nextProps, nextState);
  }

  handleOnUploadComplete = ({ assetId, type, file }: { assetId: AssetID; type: AssetType; file: File }) => {
    assertAssetId(assetId);
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    assertArg(type).is.string();
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    assertArg(file).is.object();
    this.props.onUploadComplete({ assetId, type, file });
  };

  render() {
    const {
      editionId,
      snapId,
      customValidationOptions,
      isReadOnly,
      isReplace,
      purpose,
      dropzoneType,
      headline,
      subHeadline,
      className,
      processing,
    } = this.props;
    const mediaUploaderClass = classNames(style.mediaUploader, className, isReplace ? style.replace : null);

    return (
      <MediaUploader
        className={mediaUploaderClass}
        snapId={snapId}
        purpose={purpose}
        dropzoneType={dropzoneType}
        editionId={editionId}
        customValidationOptions={customValidationOptions}
        isReadOnly={isReadOnly}
        onUploadComplete={this.handleOnUploadComplete}
        onUploadStart={this.props.onUploadStart}
        processing={processing}
        data-test={this.props['data-test']}
      >
        <div className={style.dropZoneContainer}>
          <span className={style.icon}>
            <InlineSVG src={uploadArrowIcon} />
          </span>
          <span className={style.headline}>{headline}</span>
          <span className={style.subHeadline}>{subHeadline}</span>
          <Spinner className={style.spinner} />
        </div>
      </MediaUploader>
    );
  }
}

export default ImageMediaUploader;
