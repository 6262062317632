import classNames from 'classnames';
import { map, filter } from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { isAdvancedCurationEnabled } from 'state/features/selectors/featuresSelectors';
import { getOptionsForMediaInfo } from 'state/media/actions/mediaActions';
import * as mediaLibraryActions from 'state/mediaLibrary/actions/mediaLibraryActions';
import * as mediaLibraryTrayActions from 'state/mediaLibrary/actions/mediaLibraryTrayActions';
import { getMediaList, currentFilter } from 'state/mediaLibrary/selectors/mediaLibrarySelectors';
import * as mediaLibraryTraySelectors from 'state/mediaLibrary/selectors/mediaLibraryTraySelectors';
import { sendNotificationMessage } from 'state/notifications/actions/notificationsActions';
import { isPreviewMuted } from 'state/previews/selectors/previewsSelectors';
import * as publisherStoryEditorSelectors from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';
import * as publishersSelectors from 'state/publishers/selectors/publishersSelectors';
import { parseSnapId } from 'state/snaps/schema/snapEntityHelpers';
import * as snapsSelectors from 'state/snaps/selectors/snapsSelectors';
import { isCurationSnapDownloader, hasClaimForActivePublisher } from 'state/user/selectors/userSelectors';

import { FileType, UploadPurpose } from 'config/constants';
import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';
import { buildNotificationMessageParams } from 'utils/errors/media/mediaErrorUtils';
import { createAssetUrl } from 'utils/media/assetUtils';
import * as fileUtils from 'utils/media/fileUtils';
import * as mediaValidation from 'utils/media/mediaValidation';

import SnapGridInfoModal from 'views/common/containers/SnapGridInfoModal/SnapGridInfoModal';
import StoryDropzone from 'views/common/containers/StoryDropzone/StoryDropzone';
import MediaLibraryDrawerHeader from 'views/mediaLibrary/MediaLibraryDrawerHeader/MediaLibraryDrawerHeader';
import MediaLibraryFilters from 'views/mediaLibrary/MediaLibraryFilters/MediaLibraryFilters';
import MediaLibraryGrid from 'views/mediaLibrary/MediaLibraryGrid/MediaLibraryGrid';
import MediaLibraryHeader from 'views/mediaLibrary/MediaLibraryHeader/MediaLibraryHeader';
import MediaLibraryTray from 'views/mediaLibrary/MediaLibraryTray/MediaLibraryTray';
import MediaLibraryUploadModal from 'views/mediaLibrary/MediaLibraryUploadModal/MediaLibraryUploadModal';
import { isVideo, getDownloadUrl } from 'views/mediaLibrary/utils/MediaLibraryUtils';

import style from './MediaLibrary.scss';

import type { SnapId } from 'types/common';
import { TargetEnum, UploadAsEnum } from 'types/mediaLibrary';
import type { MediaItem, UploadedMedia, FilterByType } from 'types/mediaLibrary';
import { Claim } from 'types/permissions';
import type { Publisher, PublisherID } from 'types/publishers';

const dropZoneTitle = (
  <FormattedMessage
    id="media-library-drag-and-drop-title"
    description="Media Library drag and drop overlay title"
    defaultMessage="Drop to add Snaps to Media Library"
  />
);
type OwnProps = {
  drawerMode?: boolean; // True in Media Library Drawer,
  // The following three are provided only in Drag-and-Drop mode
  onDragStart?: () => void;
  onItemDropped?: () => void;
  onDragCancelled?: () => void;
  match: any;
};
type StateProps = {
  mediaList: MediaItem[];
  selectedMediaIdSet: {
    [x: string]: boolean;
  };
  muted: boolean;
  activePublisherId: PublisherID | undefined | null;
  currentFilter: FilterByType;
  getSnapById: (a?: SnapId | null) => any;
  activeEditionSnapMediaIds: Array<string | undefined | null>;
  activePublisher: Publisher;
  isAdvancedCurator: boolean;
  isCurationSnapDownloader: boolean;
};
type DispatchProps = {
  getMedia: typeof mediaLibraryActions.getMedia;
  toggleMedia: typeof mediaLibraryTrayActions.toggleMedia;
  sendNotificationMessage: typeof sendNotificationMessage;
};
type Props = OwnProps & StateProps & DispatchProps;
type OwnState = {
  isMetadataModalVisible: boolean;
  metadataModalMedia?: MediaItem;
  showUploadModal: boolean;
  droppedFiles: File[];
  droppedFilesMetadata: UploadedMedia[];
};
const mapStateToProps = (state: State) => {
  const publisherDetails = publishersSelectors.getActivePublisherDetails(state);
  return {
    mediaList: getMediaList(state),
    selectedMediaIdSet: mediaLibraryTraySelectors.getSelectedMediaIdSet(state),
    muted: isPreviewMuted(state),
    currentFilter: currentFilter(state),
    activePublisherId: publisherDetails ? publisherDetails.id : null,
    getSnapById: snapsSelectors.getSnapById(state),
    activeEditionSnapMediaIds: (publisherStoryEditorSelectors.getActiveEditionSnaps(state) as any[]).map(
      snap => snap.sourceMediaId
    ),
    activePublisher: publishersSelectors.getActivePublisherDetails(state),
    isAdvancedCurator: hasClaimForActivePublisher(state, Claim.ADVANCED_CURATOR),
    isCurationSnapDownloader: isCurationSnapDownloader(state) && isAdvancedCurationEnabled(state),
  };
};
const mapDispatchToProps = {
  getMedia: mediaLibraryActions.getMedia,
  toggleMedia: mediaLibraryTrayActions.toggleMedia,
  sendNotificationMessage,
};
export class MediaLibrary extends React.Component<Props, OwnState> {
  static path = '/publisher/:publisherId/media/edition/:editionId/snap/:snapId/attachment/:attachmentId';

  state = {
    isMetadataModalVisible: false,
    metadataModalMedia: undefined,
    showUploadModal: false,
    droppedFiles: [],
    droppedFilesMetadata: [],
  };

  componentDidMount() {
    this.props.getMedia({ filterBy: this.props.currentFilter, target: this.getTargetType() });
  }

  onDropFile = (files: any) => {
    const metadataPromises = map(files, async file => {
      const fileType = fileUtils.getFileType(file);
      let mediaInfo = {};
      let duration = 0;
      try {
        if (fileType === FileType.IMAGE) {
          mediaInfo = await mediaValidation.getImageInfo(file.blobUrl);
        } else {
          mediaInfo = await mediaValidation.getVideoInfo(file.blobUrl);
          duration = (mediaInfo as any).duration;
        }
      } catch (err) {
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
        this.props.sendNotificationMessage(buildNotificationMessageParams(err));
        return Promise.resolve(null);
      }
      // Find out what types the file can possibly be uploaded as
      // TODO: generalise logic and move to mediaValidation
      const allUploadAsOptions = [
        mediaValidation
          .validateMedia(
            file.blobUrl,
            fileType,
            getOptionsForMediaInfo({
              purpose: UploadPurpose.TOP_SNAP,
              maxDurationSeconds: this.props.activePublisher.topsnapLimit,
            })
          )
          .then(() => {
            return { uploadAsOption: UploadAsEnum.SNAP };
          })
          .catch((err: any) => {
            return { error: err.intlError };
          }),
      ];
      if (fileType === FileType.VIDEO) {
        // If it's a video, this could also possibly be a long form attachment
        allUploadAsOptions.push(
          mediaValidation
            .validateMedia(
              file.blobUrl,
              fileType,
              getOptionsForMediaInfo({
                purpose: UploadPurpose.LONGFORM_VIDEO,
              })
            )
            .then(() => {
              return { uploadAsOption: UploadAsEnum.ATTACHMENT };
            })
            .catch((err: any) => {
              return { error: err.intlError };
            })
        );
      }
      return Promise.all(allUploadAsOptions).then(uploadAsOptions => {
        // Each element in uploadAsOptions is either an object with uploadAsOption field if there's no error,
        // or an error field if the media cannot be validated
        const nonNullUploadAs = uploadAsOptions
          .map(uploadAsOrError => uploadAsOrError.uploadAsOption)
          .filter(uploadAs => !!uploadAs);
        // Only return error if the media can't be validated as any acceptable types
        const validationError =
          nonNullUploadAs.length > 0
            ? null
            : uploadAsOptions.map(uploadAsOrError => uploadAsOrError.error).find(error => !!error);
        return {
          fileType,
          width: (mediaInfo as any).width,
          height: (mediaInfo as any).height,
          duration,
          uploadAsOptions: nonNullUploadAs,
          validationError,
        };
      });
    });
    return Promise.all(metadataPromises).then(metadataList => {
      // @ts-expect-error ts-migrate(7015) FIXME: Element implicitly has an 'any' type because index... Remove this comment to see the full error message
      const filteredFiles = filter(files, (item, index) => !!metadataList[index]);
      const filteredMetadata = filter(metadataList, item => !!item);
      if (filteredFiles.length === 0) {
        return Promise.resolve();
      }
      this.setState({
        showUploadModal: true,
        droppedFiles: filteredFiles,
        // @ts-expect-error ts-migrate(2322) FIXME: Type '({ fileType: string | null | undefined; widt... Remove this comment to see the full error message
        droppedFilesMetadata: filteredMetadata,
      });
      return Promise.resolve();
    });
  };

  getTargetType = () => {
    if (this.props.drawerMode) {
      return TargetEnum.TOP_SNAP;
    }
    const topOrBottomSnapTarget = this.props.match.params.attachmentId
      ? TargetEnum.VIDEO_ATTACHMENT
      : TargetEnum.TOP_SNAP;
    return this.props.match.params.snapId ? topOrBottomSnapTarget : TargetEnum.ANY;
  };

  hideUploadModal = () => {
    this.setState({
      showUploadModal: false,
    });
  };

  showMetadataModal = (mediaItem: MediaItem) => {
    this.setState({
      metadataModalMedia: mediaItem,
      isMetadataModalVisible: true,
    });
  };

  hideMetadataModal = () => {
    this.setState({
      isMetadataModalVisible: false,
    });
  };

  renderHeader = () => {
    const hideCancelButton = !this.props.drawerMode && !this.props.match.params.editionId;
    if (this.props.drawerMode) {
      return <MediaLibraryDrawerHeader onDropFiles={this.onDropFile} />;
    }
    return (
      <MediaLibraryHeader
        onDropFiles={this.onDropFile}
        hideCancelButton={hideCancelButton}
        publisherId={this.props.activePublisherId}
        editionId={Number(this.props.match.params.editionId)}
        snapId={parseSnapId(this.props.match.params.snapId)}
        attachmentId={parseSnapId(this.props.match.params.attachmentId)}
      />
    );
  };

  renderMetadataModal = (
    mediaItem: MediaItem | undefined | null,
    isVisible: boolean,
    selectedMediaIdSet: {
      [x: string]: boolean;
    },
    muted: boolean
  ) => {
    if (!mediaItem) {
      return null;
    }
    // TODO: replace with a separate field for video preview
    const videoPreviewSrc = isVideo(mediaItem.mediaType) ? mediaItem.mediaUrl : undefined;
    return (
      <SnapGridInfoModal
        mediaId={mediaItem.id}
        mediaSrc={createAssetUrl(mediaItem.id)}
        allowDownloading={mediaItem.isCuratedMedia ? this.props.isCurationSnapDownloader : true}
        downloadUrl={getDownloadUrl(mediaItem)}
        videoPreviewSrc={videoPreviewSrc}
        isVideo={isVideo(mediaItem.mediaType)}
        fileName={mediaItem.fileName}
        dateTimestamp={mediaItem.createdAt}
        width={mediaItem.width}
        height={mediaItem.height}
        duration={mediaItem.duration}
        muted={muted}
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        isAddedToTray={selectedMediaIdSet[mediaItem.id]}
        isVisible={isVisible}
        hide={this.hideMetadataModal}
        onToggle={this.props.toggleMedia}
        creatorUsername={this.props.isAdvancedCurator ? mediaItem.creatorUsername : null}
      />
    );
  };

  renderTray = () => {
    if (this.props.drawerMode) {
      return null;
    }
    const activeEditionId = Number(this.props.match.params.editionId);
    const activeSnapId = parseSnapId(this.props.match.params.snapId);
    const activeAttachmentId = parseSnapId(this.props.match.params.attachmentId);
    return (
      <MediaLibraryTray
        activeEditionId={activeEditionId}
        activePublisherId={this.props.activePublisherId}
        activeSnapId={activeSnapId}
        activeAttachmentId={activeAttachmentId}
        showMetadataModal={this.showMetadataModal}
      />
    );
  };

  render() {
    const target = this.getTargetType();
    return (
      <StoryDropzone className={style.dropzone} onDrop={this.onDropFile} shallow title={dropZoneTitle}>
        <div className={classNames(style.container, { [style.drawerMode]: this.props.drawerMode })}>
          {this.renderHeader()}
          <div className={classNames(style.body, { [style.withPadding]: !this.props.drawerMode })}>
            {!this.props.drawerMode ? <MediaLibraryFilters target={target} /> : null}
            <MediaLibraryGrid
              mediaItems={this.props.mediaList}
              showMetadataModal={this.showMetadataModal}
              target={target}
              drawerMode={this.props.drawerMode}
              onDragStart={this.props.onDragStart}
              onItemDropped={this.props.onItemDropped}
              onDragCancelled={this.props.onDragCancelled}
              storySnapsMediaIds={this.props.activeEditionSnapMediaIds}
            />
          </div>
          {this.renderTray()}
          <MediaLibraryUploadModal
            visible={this.state.showUploadModal}
            hideModal={this.hideUploadModal}
            files={this.state.droppedFiles}
            mediaMetadata={this.state.droppedFilesMetadata}
            target={this.getTargetType()}
          />
          {this.renderMetadataModal(
            this.state.metadataModalMedia,
            this.state.isMetadataModalVisible,
            this.props.selectedMediaIdSet,
            this.props.muted
          )}
        </div>
      </StoryDropzone>
    );
  }
}
export default intlConnect(mapStateToProps, mapDispatchToProps)(MediaLibrary);
