import classNames from 'classnames';
import React from 'react';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { findSnapIndexInEdition } from 'state/editions/schema/editionEntityHelpers';
import * as editionsSelectors from 'state/editions/selectors/editionsSelectors';
import * as featuresSelectors from 'state/features/selectors/featuresSelectors';
import * as routerActions from 'state/router/actions/routerActions';

import { chevronUp, send, download } from 'icons/SDS/allIcons';
import secondaryButtonStyle from 'styles/secondaryButton.scss';
import { intlConnect } from 'utils/connectUtils';
import { bulkDownloadFiles } from 'utils/files/downloadFilesUtil';
import type { FileInput } from 'utils/files/downloadFilesUtil';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';
import * as intlMessages from 'utils/intlMessages/intlMessages';

import { CompositeSnapCheckbox } from 'views/common/components/CompositeSnapCheckbox/CompositeSnapCheckbox';
import Icon from 'views/common/components/Icon/Icon';
import SDSButton, { ButtonShape, ButtonType } from 'views/common/components/SDSButton/SDSButton';
import SDSTooltip, { TooltipPosition } from 'views/common/components/SDSTooltip/SDSTooltip';
import SnapTrayAddToStoryModal from 'views/common/containers/SnapTrayAddToStoryModal/SnapTrayAddToStoryModal';
import SnapTraySnapList from 'views/common/containers/SnapTraySnapList/SnapTraySnapList';

// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'images/trash.svg.inline' or it... Remove this comment to see the full error message
import binIcon from 'images/trash.svg.inline';

import style from './SnapTray.scss';

import type { SnapId } from 'types/common';
import type { CuratedSnap } from 'types/curation';
import type { EditionID } from 'types/editionID';
import type { MediaItem } from 'types/mediaLibrary';
import type { PublisherID } from 'types/publishers';
import type { State } from 'types/rootState';
import { SnapType } from 'types/snaps';
import type { TrayState } from 'types/tray';
import { TrayStateType } from 'types/tray';

const addToStoryMessage = (
  <FormattedMessage
    id="snap-tray-add-to-story"
    description="Add curated snap to story button"
    defaultMessage="Add to story"
  />
);

const addToMediaLibrary = (
  <FormattedMessage
    id="snap-tray-add-to-media-library"
    description="Add community snaps to media library button"
    defaultMessage="Add to Media Library"
  />
);

const singleAssetSingleSnapTooltip = (
  <FormattedMessage
    id="snap-tray-single-asset-single-snap-tooltip"
    description="A tooltip to signal to the user that single asset shows can't add snaps from media library"
    defaultMessage="Shows can't import snaps from media library"
  />
);

type TrayItemArray = MediaItem[] | CuratedSnap[];

type OwnProps = {
  selectedSnaps: TrayItemArray;
  targetSnapId: SnapId | undefined | null;
  targetEditionId: EditionID | undefined | null;
  targetAttachmentId: SnapId | undefined | null;
  publisherId: PublisherID | undefined | null;
  addSnapsToStory: (a: any) => any;
  addCuratedSnapsToMediaLibrary: (a: any) => any;
  clearTray: () => any;
  createItemView: (a: any) => ReactNode;
  allowDownloading?: boolean;
  getDownloadUrls?: () => FileInput[];
  isCuratedLayerEnabled: boolean;
};

type StateProps = {
  targetSnapIndex: number;
  isSingleAssetStoryEditorEnabled: boolean;
};

type DispatchProps = {
  goToPublisherStoryEditor: typeof routerActions.goToPublisherStoryEditor;
  goToSnap: typeof routerActions.goToSnap;
  goToAttachment: typeof routerActions.goToAttachment;
};

type Props = StateProps & OwnProps & DispatchProps;

type OwnState = {
  trayState: TrayState;
  isAddToStoryModalVisible: boolean;
};

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
  let targetSnapIndex = 0;
  if (ownProps.targetEditionId) {
    const edition = editionsSelectors.getEditionById(state)(ownProps.targetEditionId);
    targetSnapIndex = findSnapIndexInEdition(edition, ownProps.targetSnapId) || 0;
  }
  return {
    targetSnapIndex,
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ targetSnapIndex: number; isCuratedLayerEna... Remove this comment to see the full error message
    isCuratedLayerEnabled:
      featuresSelectors.isCuratedLayerEnabled(state) || featuresSelectors.isAdvancedCurationEnabled(state),
    isSingleAssetStoryEditorEnabled: featuresSelectors.isSingleAssetStoryEditorEnabled(state),
  };
};

const mapDispatchToProps = {
  goToPublisherStoryEditor: routerActions.goToPublisherStoryEditor,
  goToSnap: routerActions.goToSnap,
  goToAttachment: routerActions.goToAttachment,
};

export class SnapTray extends React.Component<Props, OwnState> {
  static getDerivedStateFromProps(props: Props, state: OwnState) {
    if (props.selectedSnaps.length === 0 && state.trayState !== TrayStateType.MINIMISED) {
      return {
        trayState: TrayStateType.MINIMISED,
      };
    }
    return {};
  }

  state = {
    trayState: TrayStateType.MINIMISED,
    isAddToStoryModalVisible: false,
  };

  onClickAddToStory = () => {
    if (!this.props.targetEditionId) {
      this.toggleAddToStoryModal(true);
      return;
    }
    this.addSnaps(this.props.targetEditionId);
  };

  onClickAddToMediaLibrary = () => {
    this.props.addCuratedSnapsToMediaLibrary(this.props.selectedSnaps);
    this.props.goToPublisherStoryEditor({
      publisherId: this.props.publisherId,
      editionId: this.props.targetEditionId,
      overwriteHistory: true,
    });
  };

  onEditionSelectedFromModal = (editionId: EditionID) => {
    this.addSnaps(editionId);
  };

  onClickExpandShrinkButton = () => {
    this.setState({
      trayState: this.state.trayState === TrayStateType.MINIMISED ? TrayStateType.MEDIUM : TrayStateType.MINIMISED,
    });
  };

  addSnaps = (targetEditionId?: EditionID | null) => {
    const { targetSnapIndex, publisherId, targetSnapId, targetAttachmentId, isCuratedLayerEnabled } = this.props;

    this.props.addSnapsToStory({
      snaps: this.props.selectedSnaps,
      snapId: targetSnapId,
      attachmentId: targetAttachmentId,
      editionId: targetEditionId,
      initialIndex: targetSnapIndex,
      isCuratedLayerEnabled,
      usingCurationProfile: false,
    });

    // If adding more than one snap/media to story, go to Story Editor
    if ((this.props.selectedSnaps.length > 1 || !targetSnapId) && publisherId) {
      this.props.goToPublisherStoryEditor({
        publisherId,
        editionId: targetEditionId,
        overwriteHistory: true,
      });
    } else if (targetSnapId) {
      // Otherwise, go to the page of that specific snap/attachment
      if (!targetAttachmentId) {
        this.props.goToSnap({
          publisherId,
          editionId: targetEditionId,
          snapId: targetSnapId,
          overwriteHistory: false,
        });
      } else {
        this.props.goToAttachment({
          publisherId,
          editionId: targetEditionId,
          snapId: targetSnapId,
          attachmentId: targetAttachmentId,
          attachmentType: SnapType.LONGFORM_VIDEO,
          overwriteHistory: false,
        });
      }
    }
  };

  toggleAddToStoryModal = (show: boolean) => {
    this.setState({
      isAddToStoryModalVisible: show,
    });
  };

  clickedDownloadButton(getDownloadUrls: () => FileInput[]) {
    return () => bulkDownloadFiles(getDownloadUrls());
  }

  renderDownloadButton = () => {
    const { getDownloadUrls } = this.props;
    if (this.props.allowDownloading && getDownloadUrls) {
      return (
        <SDSButton
          type={ButtonType.SECONDARY}
          inlineIcon={download}
          onClick={this.clickedDownloadButton(getDownloadUrls)}
          data-test="snapTray.download"
        >
          {intlMessages.getMessageFromId('download')}
        </SDSButton>
      );
    }
    return null;
  };

  renderAddToMediaLibraryButton = () => {
    if (this.props.addCuratedSnapsToMediaLibrary === undefined) {
      return null;
    }

    return (
      <SDSButton
        type={ButtonType.PRIMARY}
        inlineIcon={send}
        onClick={this.onClickAddToMediaLibrary}
        data-test="snapTray.addToMediaLibrary"
      >
        {addToMediaLibrary}
      </SDSButton>
    );
  };

  hideAddToStoryModel = () => this.toggleAddToStoryModal(false);

  getAddToStoryButtonDisabledAndTooltip = (): [boolean, React.ReactElement | null] => {
    if (this.props.isSingleAssetStoryEditorEnabled && !this.props.targetAttachmentId) {
      return [true, singleAssetSingleSnapTooltip];
    }

    return [false, null];
  };

  renderAddToStoryModal = () => {
    // If editionId is specified, no need to render modal to select which edition to add the snaps to
    if (this.props.targetEditionId) {
      return null;
    }
    return (
      <SnapTrayAddToStoryModal
        visible={this.state.isAddToStoryModalVisible}
        hideModal={this.hideAddToStoryModel}
        onEditionSelected={this.onEditionSelectedFromModal}
        numSelectedSnaps={this.props.selectedSnaps.length}
      />
    );
  };

  renderAddToStoryButton = () => {
    const [disableButton, tooltipMessage] = this.getAddToStoryButtonDisabledAndTooltip();

    return (
      <SDSTooltip
        title={tooltipMessage}
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        placement={TooltipPosition.TOP}
      >
        <SDSButton
          type={ButtonType.PRIMARY}
          onClick={this.onClickAddToStory}
          inlineIcon={send}
          data-test="snapTray.addToStory"
          disabled={disableButton}
        >
          {addToStoryMessage}
        </SDSButton>
      </SDSTooltip>
    );
  };

  render() {
    const numSelectedSnaps = this.props.selectedSnaps.length;
    if (numSelectedSnaps === 0) {
      return null;
    }
    const expandShrinkButtonClasses = classNames(secondaryButtonStyle.secondaryButton, {
      // @ts-expect-error ts-migrate(2367) FIXME: This condition will always return 'false' since th... Remove this comment to see the full error message
      [style.rotate180]: this.state.trayState === TrayStateType.MEDIUM,
    });
    const snapListClasses = classNames(style.snapList, {
      [style.hide]: this.state.trayState === TrayStateType.MINIMISED,
    });
    return (
      <div className={style.container} data-test="snapTray.container">
        <div className={style.minimisedTrayContent}>
          <div className={style.numSelectedAndBinContainer}>
            <div className={style.numSelectedSnapsContainer}>
              <CompositeSnapCheckbox className={style.checkbox} isChecked isDisabled />
              <div className={style.numSelectedSnapsText} data-test="snapTray.numberOfSnapsSelected">
                {getMessageFromId('snap-tray-snaps-selected', { snapCount: numSelectedSnaps })}
              </div>
            </div>

            <div className={style.binContainer} onClick={this.props.clearTray} data-test="snapTray.clearTrayButton">
              <Icon className={style.binIcon} inlineIcon={binIcon} />
              <div className={style.clearSnapsText}>
                <FormattedMessage
                  id="snap-tray-clear-snaps"
                  description="Clear selected snaps"
                  defaultMessage="Clear selected snaps"
                />
              </div>
            </div>
          </div>

          <div className={style.addExpandButtonsContainer}>
            {this.renderAddToStoryButton()}
            {this.renderAddToMediaLibraryButton()}
            {this.renderDownloadButton()}

            <SDSButton
              className={expandShrinkButtonClasses}
              type={ButtonType.SECONDARY}
              inlineIcon={chevronUp}
              shape={ButtonShape.CIRCLE}
              onClick={this.onClickExpandShrinkButton}
              data-test="snapTray.expandShrinkButton"
            />
          </div>
        </div>

        <div className={snapListClasses} data-test="snapTray.list">
          <SnapTraySnapList selectedItems={this.props.selectedSnaps} createItemView={this.props.createItemView} />
        </div>

        {this.renderAddToStoryModal()}
      </div>
    );
  }
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(SnapTray);
