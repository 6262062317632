import classNames from 'classnames';
import is from 'is_js';
import React from 'react';
import type { ReactElement } from 'react';
import ReactDOM from 'react-dom';
import { FormattedMessage } from 'react-intl';
import { Portal } from 'react-portal';

import FileDropzoneTarget, {
  fileAndMediaDropzoneTarget,
} from 'views/common/containers/FileDropzoneTarget/FileDropzoneTarget';

import style from './StoryDropzone.scss';

const defaultDropzoneTitle = (
  <FormattedMessage
    id="drag-and-drop-title"
    description="Drag and drop overlay title"
    defaultMessage="Drop to add Snap to story"
  />
);

type Props = {
  children: ReactElement<any>;
  className?: string;
  childBoxClassName?: string;
  showChildrenBorder?: boolean;
  onDrop?: (files?: any | null, mediaLibraryItems?: any | null) => any;
  shallow?: boolean;
  title?: string | ReactElement<any>;
  allowMediaLibraryItems?: boolean;
  isSubscribeSnap?: boolean;
};

export class StoryDropzone extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);
    this.childRef = React.createRef();
  }

  componentDidMount() {
    if (this.childRef) {
      this.childElement = ReactDOM.findDOMNode(this.childRef.current);
    }
  }

  childRef: {
    // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'React$ElementRef'.
    current: null | React$ElementRef<any>;
  };

  // @ts-expect-error ts-migrate(2564) FIXME: Property 'childElement' has no initializer and is ... Remove this comment to see the full error message
  childElement: null | Element | Text;

  renderChildrenBorder = () => {
    if (!this.props.showChildrenBorder || !(this.childElement instanceof Element)) {
      return null;
    }

    const childRect = this.childElement.getBoundingClientRect();
    const childrenBoxStyle = {
      top: childRect.top,
      left: childRect.left,
      width: childRect.width,
      height: childRect.height,
    };

    return <div style={childrenBoxStyle} className={classNames(style.childBox, this.props.childBoxClassName)} />;
  };

  renderPortal = (isDropzoneActive: boolean) => {
    if (!isDropzoneActive) {
      return null;
    }

    // Portal is a library that uses the React Portal API in the backend to render a component
    // outside the current DOM tree. In this case it will render this overlay outside the root
    // of the app. We need this to make sure this UI is always rendered correctly, regardless
    // of how the app html is structured.
    return (
      <Portal>
        {/* We need to render the children border outside the children element because the Portal has a higher
            stacking context, thus if we render the border within the children, it will be behind the Portal overlay.
            This would make the border appear greyed out as the Portal has a white background with some opacity set on it.
        */}
        {this.renderChildrenBorder()}
        <div className={style.portal}>
          <div className={classNames(style.overlay, { [style.greyedOverlay]: this.props.showChildrenBorder })} />
        </div>
        <div className={style.textContainer}>
          <div className={style.textBox}>
            <span className={style.textTitle}>{this.props.title || defaultDropzoneTitle}</span>
            <span className={style.textSubtitle}>
              <FormattedMessage
                id="drag-and-drop-hint"
                description="Drag and drop hint text"
                defaultMessage="Hint: Drag and drop now supports multiple videos!"
              />
            </span>
          </div>
        </div>
      </Portal>
    );
  };

  renderChildren = () => {
    const { children } = this.props;
    // If the children is a function, ref cannot be set, so just return the children element.
    // Note that this means that children border styling will not work if the children is a function
    // as we need the ref to find out the bounding box for the border styling.
    if (children && is.function(children.type)) {
      return children;
    }

    return React.cloneElement(children, { ref: this.childRef });
  };

  renderDropzoneContent = (isDropzoneActive: boolean) => {
    return (
      <div className={classNames(style.storyDropzone, this.props.className)}>
        {this.renderPortal(isDropzoneActive)}
        {this.renderChildren()}
      </div>
    );
  };

  render() {
    if (this.props.allowMediaLibraryItems) {
      const FileAndMediaDropzoneTarget = fileAndMediaDropzoneTarget(!!this.props.isSubscribeSnap);

      return (
        // @ts-expect-error ts-migrate(2322) FIXME: Type '((files?: any, mediaLibraryItems?: any) => a... Remove this comment to see the full error message
        <FileAndMediaDropzoneTarget onDrop={this.props.onDrop} shallow={this.props.shallow}>
          {isDropzoneActive => this.renderDropzoneContent(isDropzoneActive)}
        </FileAndMediaDropzoneTarget>
      );
    }
    return (
      // @ts-expect-error ts-migrate(2322) FIXME: Type '((files?: any, mediaLibraryItems?: any) => a... Remove this comment to see the full error message
      <FileDropzoneTarget onDrop={this.props.onDrop} shallow={this.props.shallow}>
        {isDropzoneActive => this.renderDropzoneContent(isDropzoneActive)}
      </FileDropzoneTarget>
    );
  }
}

export default StoryDropzone;
