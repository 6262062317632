import classNames from 'classnames';
import _ from 'lodash';
import React, { Component } from 'react';
import { defineMessages, intlShape, injectIntl } from 'react-intl';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'upde... Remove this comment to see the full error message
import u from 'updeep';

import RegionPicker from '../RegionPicker/RegionPicker';

import { CheckboxState } from 'config/constants';
import type { CheckboxStateEnum } from 'config/constants';
import { search } from 'icons/SDS/allIcons';
import {
  iterateAllCountries,
  defaultCategoryUsedInMultiSelection,
  generateCheckboxStateForRow,
} from 'utils/countryUtils';

import PickedCountryCounter from 'views/common/components/CountryPicker/PickedCountryCounter/PickedCountryCounter';
import enforceLocalScroll from 'views/common/components/CountryPicker/enforceLocalScroll';
import SDSInput from 'views/common/components/SDSInput/SDSInput';
import TriStateCheckbox from 'views/common/components/TriStateCheckbox/TriStateCheckbox';

import style from './CountryDropdown.scss';

import { CountryCodeCategory } from 'types/countries';
import type { Regions, DerivedState, CountryRegion } from 'types/countries';

type Props = {
  className?: string;
  onChange: (a: Regions) => void;
  countryCategories: CountryCodeCategory[];
  derivedState: DerivedState;
  regions: Regions;
};

type State = {
  regionToggleMap: {
    [x: string]: boolean;
  };
  query: string;
};

const messages = defineMessages({
  searchPlaceholder: {
    id: 'country-search-placeholder-label',
    description: 'Country Search placeholder label',
    defaultMessage: 'Filter Search',
  },
});

class CountryPicker extends Component<Props, State> {
  static contextTypes = {
    intl: intlShape,
  };

  state = {
    regionToggleMap: {},
    query: '',
  };

  // @ts-expect-error ts-migrate(2416) FIXME: Property 'UNSAFE_componentWillMount' in type 'Coun... Remove this comment to see the full error message
  UNSAFE_componentWillMount(props: any) {
    const regionToggleMap = {};
    const openRegionPatch = {};
    Object.keys(this.props.regions).forEach((regionName, idx) => {
      if (idx === 0) {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        openRegionPatch[regionName] = true;
      }
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      regionToggleMap[regionName] = false;
    });
    this.DEFAULT_REGION_TOGGLE_MAP = u({}, regionToggleMap);
    this.setState({ regionToggleMap: u(openRegionPatch, this.DEFAULT_REGION_TOGGLE_MAP) });
  }

  componentDidMount() {
    document.addEventListener('mousewheel', this.onMousewheel, true);
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    if (
      this.props.regions === nextProps.regions &&
      this.state.query === nextState.query &&
      this.state.regionToggleMap === nextState.regionToggleMap
    ) {
      return false;
    }
    return true;
  }

  componentWillUnmount() {
    document.removeEventListener('mousewheel', this.onMousewheel, true);
  }

  onMousewheel = (event: any) => enforceLocalScroll(event, this.refs.searchResults);

  onToggleRegion = (regionName: any, oldState: any) => {
    const newRegionToggleMap = {
      // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
      ...this.DEFAULT_REGION_TOGGLE_MAP,
      [regionName]: !oldState,
    };
    this.setState({ regionToggleMap: newRegionToggleMap });
  };

  onCheckCountry = (
    regionName: string,
    countryCode: string,
    category: CountryCodeCategory,
    oldCheckboxState: CheckboxStateEnum,
    event?: Event | null
  ) => {
    if (event) {
      event.stopPropagation();
    }

    let picked;
    if (defaultCategoryUsedInMultiSelection(this.props.countryCategories, category)) {
      picked = this.props.countryCategories.reduce((acc, currCategory) => {
        if (currCategory === CountryCodeCategory.DEFAULT) {
          return acc;
        }
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        acc[currCategory] = oldCheckboxState === CheckboxState.UNCHECKED;
        return acc;
      }, {});
    } else {
      picked = { [category]: oldCheckboxState === CheckboxState.UNCHECKED };
    }

    const patch = {
      [regionName]: {
        countries: {
          [countryCode]: {
            picked,
          },
        },
      },
    };
    const newRegions = u(patch, this.props.regions);
    this.props.onChange(newRegions);
  };

  onCheckRegion = (regionName: string, category: CountryCodeCategory, oldCheckboxState: CheckboxStateEnum) => {
    let shouldBePicked = false;
    if (oldCheckboxState === CheckboxState.UNCHECKED || oldCheckboxState === CheckboxState.INDETERMINATE) {
      shouldBePicked = true;
    }

    const picked = this.generatePickedCategories(category, shouldBePicked);

    const regionPatch = {};
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'countries' does not exist on type 'Regio... Remove this comment to see the full error message
    const { countries } = this.props.regions[regionName];
    Object.keys(countries).forEach(countryCode => {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      regionPatch[countryCode] = { picked };
    });

    const patch = { [regionName]: { countries: regionPatch } };
    const newRegions = u(patch, this.props.regions);
    this.props.onChange(newRegions);
  };

  onCheckGlobal = (category: CountryCodeCategory) => {
    let shouldBePicked = false;
    const oldCheckboxState = this.props.derivedState.checkboxState[category];
    if (oldCheckboxState === CheckboxState.UNCHECKED || oldCheckboxState === CheckboxState.INDETERMINATE) {
      shouldBePicked = true;
    }

    const picked = this.generatePickedCategories(category, shouldBePicked);

    const patch = {};
    iterateAllCountries((country: { code: string }, region?: { name: string } | null) => {
      if (region) {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        patch[region.name] = patch[region.name] || { countries: {} };
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        patch[region.name].countries[country.code] = { picked };
      }
    }, this.props.regions);
    const newRegions = u(patch, this.props.regions);
    this.props.onChange(newRegions);
  };

  onSearchChange = (event: any) => {
    const query = _.get(event, 'target.value', '');
    this.setState({ query });
  };

  getFilteredCountries = (query: string, regions: Regions): CountryRegion[] => {
    const filteredCountriesByName: any = [];
    const filteredCountriesByCode: any = [];

    iterateAllCountries((country, region) => {
      if (this.isMatchedCountryName(query, { country, region })) {
        filteredCountriesByName.push({ country, region });
      } else if (this.isMatchedCountryCode(query, { country, region })) {
        filteredCountriesByCode.push({ country, region });
      }
    }, regions);

    return [...filteredCountriesByName, ...filteredCountriesByCode];
  };

  generatePickedCategories = (category: any, shouldBePicked: any) => {
    let picked;
    if (defaultCategoryUsedInMultiSelection(this.props.countryCategories, category)) {
      picked = this.props.countryCategories.reduce((acc, currCategory) => {
        if (currCategory === CountryCodeCategory.DEFAULT) {
          return acc;
        }
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        acc[currCategory] = shouldBePicked;
        return acc;
      }, {});
    } else {
      picked = { [category]: shouldBePicked };
    }
    return picked;
  };

  isMatchedCountryName(query: string, { country, region }: any) {
    const name = country.name.toLowerCase();
    return name.includes(query.toLowerCase());
  }

  isMatchedCountryCode(query: string, { country, region }: any) {
    const code = country.code.toLowerCase();
    return code.includes(query.toLowerCase());
  }

  DEFAULT_REGION_TOGGLE_MAP = null;

  renderSearchBox() {
    return (
      <div className={style.headerParent}>
        <div className={style.search}>
          <SDSInput
            value={this.state.query}
            onChange={this.onSearchChange}
            placeholder={this.context.intl.formatMessage(messages.searchPlaceholder)}
            data-test="countryDropDown.searchInput"
            inlineIcon={search}
          />
        </div>
        <div className={style.categoryColumns}>
          {this.props.countryCategories.length > 1
            ? this.props.countryCategories.map(category => {
                if (defaultCategoryUsedInMultiSelection(this.props.countryCategories, category)) {
                  return null;
                }
                return <div className={style.category}>{category.toLowerCase()}</div>;
              })
            : null}
        </div>
      </div>
    );
  }

  renderRegions() {
    return Object.keys(this.props.regions).map(regionName => {
      const region = this.props.regions[regionName];
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      const isExpanded = this.state.regionToggleMap[regionName];

      const props = {
        key: regionName,
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        name: region.name,
        isExpanded,
        countryCategories: this.props.countryCategories,
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        countries: region.countries,
        onToggle: this.onToggleRegion,
        onCheckCountry: this.onCheckCountry,
        onCheckRegion: this.onCheckRegion,
      };

      return <RegionPicker {...props} />;
    });
  }

  renderSearchResult(filteredCountries: any) {
    return (
      <div ref="searchResults" className={style.searchResults}>
        {filteredCountries.map(({ region, country }: any) => {
          const checkboxState = generateCheckboxStateForRow(country, this.props.countryCategories);
          const onClickHandler = (event: any) =>
            this.onCheckCountry(region.name, country.code, CountryCodeCategory.DEFAULT, checkboxState, event);
          return (
            <div
              key={country.code}
              className={style.searchResult}
              onClick={onClickHandler} //eslint-disable-line
            >
              <TriStateCheckbox checkboxState={checkboxState} />
              <div className={style.label}>{country.name}</div>

              {this.props.countryCategories.map(category => {
                if (category === CountryCodeCategory.DEFAULT) {
                  return null;
                }

                const categoryCheckboxState =
                  country.picked && country.picked[category] ? CheckboxState.CHECKED : CheckboxState.UNCHECKED;

                const onChangeHandler = (event: any) =>
                  this.onCheckCountry(region.name, country.code, category, categoryCheckboxState, event);

                return (
                  <TriStateCheckbox
                    className={style.sideCheckbox}
                    checkboxState={categoryCheckboxState}
                    // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
                    onChange={onChangeHandler} // eslint-disable-line
                  />
                );
              })}
            </div>
          );
        })}
      </div>
    );
  }

  renderAllCountries(checkboxState: any, numPicked: any, numTotal: any) {
    return (
      <div>
        <div className={style.globalSelector} data-test="countryDropdown.globalSelector">
          <div>
            <TriStateCheckbox
              onChange={() => this.onCheckGlobal(CountryCodeCategory.DEFAULT)} // eslint-disable-line
              checkboxState={checkboxState[CountryCodeCategory.DEFAULT]}
            />
            <div className={style.label}>
              <span className={style.name}>Global</span>
              <span className={style.count}>
                <PickedCountryCounter numPicked={numPicked[CountryCodeCategory.DEFAULT]} numTotal={numTotal} />
              </span>
            </div>
          </div>
          <div>
            {this.props.countryCategories.map(category => {
              if (category === CountryCodeCategory.DEFAULT) {
                return null;
              }
              return (
                <TriStateCheckbox
                  className={style.sideCheckbox}
                  checkboxState={checkboxState[category]}
                  onChange={() => this.onCheckGlobal(category)} // eslint-disable-line
                />
              );
            })}
          </div>
        </div>

        {this.renderRegions()}
      </div>
    );
  }

  renderResults() {
    if (this.state.query) {
      const filteredCountries = this.getFilteredCountries(this.state.query, this.props.regions);
      return this.renderSearchResult(filteredCountries);
    }

    const { checkboxState, numPicked, numTotal } = this.props.derivedState;

    return this.renderAllCountries(checkboxState, numPicked, numTotal);
  }

  render() {
    return (
      <div className={classNames(style.dropdownPanel, this.props.className)}>
        {this.renderSearchBox()}
        {this.renderResults()}
      </div>
    );
  }
}

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'typeof CountryPicker' is not ass... Remove this comment to see the full error message
export default injectIntl(CountryPicker);
