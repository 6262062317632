import React from 'react';
import type { ReactNode } from 'react';
import InlineSVG from 'svg-inline-react';

// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'images/ghost_light_background.... Remove this comment to see the full error message
import ghostIcon from 'images/ghost_light_background.svg.inline';

import style from './GhostNavBarContainer.scss';

type Props = {
  children: ReactNode;
};

export const GhostNavBarContainer = (props: Props) => (
  <div className={style.root}>
    <div className={style.navbar}>
      <InlineSVG src={ghostIcon} className={style.ghost} alt="Ghostface Chillah" />
    </div>
    <div className={style.children}>{props.children}</div>
  </div>
);

export default GhostNavBarContainer;
