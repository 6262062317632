import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import ListItemWithIcon from 'views/common/components/ListItem/ListItemWithIcon';
import ListItemWithToggle from 'views/common/components/ListItem/ListItemWithToggle';
import PopoverCloseOnMovement from 'views/common/components/PopoverCloseOnMovement/PopoverCloseOnMovement';

import trashIcon from 'images/trash.svg';

import style from './RSSFeedOptionsButtonPopover.scss';

const enableFeedMsg = (
  <FormattedMessage
    id="enable-feed"
    description="Menu item to toggle Happening Now feed's enabled-ness"
    defaultMessage="Enable Feed"
  />
);

const deleteFeedMsg = (
  <FormattedMessage
    id="delete-feed"
    description="Menu item to delete a Happening Now feed"
    defaultMessage="Delete Feed"
  />
);

type OwnState = {
  changing: boolean;
};

type Props = {
  children: any;
  isEnabled: boolean;
  handleEnableToggle: () => void;
  handleDelete: () => void;
};

export class RSSFeedOptionsButtonPopover extends React.Component<Props, OwnState> {
  static contextTypes = {
    getScrollContainer: PropTypes.func,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      changing: false,
    };
  }

  updateChanging = async () => {
    this.setState(
      {
        changing: true,
      },
      async () => {
        await this.props.handleEnableToggle();
        this.setState({
          changing: false,
        });
      }
    );
  };

  renderEnableFeed = () => (
    <ListItemWithToggle
      text={enableFeedMsg}
      className={style.row}
      value={this.props.isEnabled}
      onChange={this.updateChanging}
      isReadOnly={this.state.changing}
      data-test="enable-feed-toggle"
    />
  );

  renderDeleteFeed = () => (
    <ListItemWithIcon
      text={deleteFeedMsg}
      icon={trashIcon}
      className={style.row}
      onClick={this.props.handleDelete}
      data-test="delete-feed-button"
    />
  );

  render() {
    return (
      <PopoverCloseOnMovement
        id="rssFeedOptionsPopover"
        target={this.props.children}
        arrowOffsetLeft="95px"
        className={style.popover}
        closeOnVerticalMovement
        container={this.context.getScrollContainer}
        data-test="rssFeed.option.menu"
      >
        {this.renderEnableFeed()}
        <div className={style.lineSeparator} />
        {this.renderDeleteFeed()}
      </PopoverCloseOnMovement>
    );
  }
}

export default RSSFeedOptionsButtonPopover;
