import classNames from 'classnames';
import React, { memo } from 'react';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import Icon from 'views/common/components/Icon/Icon';

// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/Warning.svg.inline' or i... Remove this comment to see the full error message
import warningBadge from 'icons/Warning.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/available.svg.inline' or... Remove this comment to see the full error message
import availableIcon from 'icons/available.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/not_available.svg.inline... Remove this comment to see the full error message
import notAvailableIcon from 'icons/not_available.svg.inline';

import styles from './StatusRow.scss';

import type { FeedStatusType } from 'types/rssFeed';
import { FeedStatusEnum } from 'types/rssFeed';

type StatusProperties = {
  icon: string;
  iconClassName?: string;
  title: ReactNode;
};

export const STATUS_PROPERTIES_MAP: {
  [k in FeedStatusType]: StatusProperties;
} = {
  [FeedStatusEnum.ACTIVE]: {
    icon: availableIcon,
    iconClassName: styles.active,
    title: (
      <FormattedMessage
        id="status-row-active-title"
        description="Title for when the status is active"
        defaultMessage="Active"
      />
    ),
  },
  [FeedStatusEnum.INACTIVE]: {
    icon: notAvailableIcon,
    iconClassName: styles.inactive,
    title: (
      <FormattedMessage
        id="status-row-inactive-title"
        description="Title for when the status is not active"
        defaultMessage="Inactive"
      />
    ),
  },
  [FeedStatusEnum.FAILED]: {
    icon: warningBadge,
    iconClassName: styles.failed,
    title: (
      <FormattedMessage
        id="status-row-failed-to-load-title"
        description="Title for when the status is failed to load"
        defaultMessage="Failed to load"
      />
    ),
  },
};

function getStatus(status: FeedStatusType): StatusProperties | null {
  if (STATUS_PROPERTIES_MAP[status]) {
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'StatusProperties | undefined' is not assigna... Remove this comment to see the full error message
    return STATUS_PROPERTIES_MAP[status];
  }

  return null;
}

function StatusRow({ status }: { status: FeedStatusType }) {
  const statusProperties = getStatus(status);
  return statusProperties === null ? null : (
    <div className={styles.container} data-test="hn.rssFeedModal.status.container">
      <Icon className={classNames(styles.icon, statusProperties.iconClassName)} inlineIcon={statusProperties.icon} />
      <span className={styles.title} data-test="hn.rssFeedModal.status.Text">
        {statusProperties.title}
      </span>
    </div>
  );
}

export default memo(StatusRow);
