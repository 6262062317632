import classNames from 'classnames';
import React from 'react';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { AutoSizer, Grid, InfiniteLoader } from 'react-virtualized';
import type { OverscanIndicesGetterParams } from 'react-virtualized';

import { intlConnect } from 'utils/connectUtils';

import SpinnerIcon from 'views/common/components/SpinnerIcon/SpinnerIcon';
import { getNumberOfCols, getGridSnapWidth, getGridSnapHeight } from 'views/common/utils/Grid';
import type { GridStyle } from 'views/common/utils/Grid';

import crossIcon from 'images/cross.svg';

import style from './SnapGrid.scss';

import type { CuratedSnap } from 'types/curation';
import type { Zoom, GridDimensions } from 'types/grid';
import { ZoomType } from 'types/grid';
import type { MediaItem } from 'types/mediaLibrary';
import type { CellRendererParams, InfiniteLoaderIndexes, RenderedSection } from 'types/virtualized';

const INFINITE_LOADER_SPINNER_ROW_HEIGHT = 84;
const INFINITE_LOADER_SPINNER_PADDING = 22;
// An arbitrarily large number. If data goes beyond the number, infinite scroll will stop
const NUM_INFINITE_SCROLL_ROWS = 10000000;
const NUM_ROW_OVERSCAN = 20;
const NUM_ROW_OVERSCAN_ZOOMED = 12;

type GridItem = MediaItem | CuratedSnap;

type Props = {
  className?: string;
  gridItems: GridItem[];
  dimensions: GridDimensions;
  blurred?: boolean;
  selectedItemsList: GridItem[];
  selectedItemIdSet: {
    [x: string]: boolean;
  };
  isLoading: boolean;
  zoom: Zoom;
  loadItems: (a: any) => void;
  createItemView: (d: string, c: any, b: boolean, a: GridItem) => ReactNode;
  gridStyle?: GridStyle;
  scrollToTop: boolean;
  gridHeaderElement?: ReactNode;
  gridHeaderWrapperClassName?: string;
  gridHeaderClassName?: string;
  centerGrid?: boolean;
  createGridView: (d: number, c: (a: boolean) => ReactNode, b: () => ReactNode, a: () => ReactNode) => Array<ReactNode>;
};

export class SnapGrid extends React.Component<Props> {
  componentDidUpdate(prevProps: Props) {
    if (prevProps.isLoading !== this.props.isLoading && this.gridRef) {
      this.gridRef.recomputeGridSize();
    }
  }

  onSectionRendered = ({ columnStartIndex, columnStopIndex, rowStartIndex, rowStopIndex }: RenderedSection) => {
    const startIndex = rowStartIndex * this.numCols + columnStartIndex;
    const stopIndex = rowStopIndex * this.numCols + columnStopIndex;
    this._onRowsRendered({ startIndex, stopIndex });
  };

  getRowHeight = ({ index }: { index: number }) => {
    if (!this.props.isLoading || index < this.numRows - 1) {
      return getGridSnapHeight(this.props.zoom, this.props.dimensions);
    }
    return INFINITE_LOADER_SPINNER_ROW_HEIGHT;
  };

  setGridRef = (registerChild: (a?: Element | null) => void) => (ref?: Grid | null) => {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Grid | null | undefined' is not ... Remove this comment to see the full error message
    registerChild(ref);
    this.gridRef = ref;
  };

  gridRef: Grid | undefined | null;

  numCols = 3;

  numRows = 1;

  // @ts-expect-error ts-migrate(2564) FIXME: Property '_onRowsRendered' has no initializer and ... Remove this comment to see the full error message
  _onRowsRendered: (params: InfiniteLoaderIndexes) => void;

  isRowLoaded = ({ index }: { index: number }) => {
    return index < this.props.gridItems.length;
  };

  loadMoreRows = ({ startIndex, stopIndex }: InfiniteLoaderIndexes) => {
    if (this.props.isLoading || this.props.blurred) {
      return null;
    }
    return this.props.loadItems({ keepExistingSnaps: true, startIndex, stopIndex });
  };

  cellRenderer = ({ rowIndex, columnIndex, key, style: cellStyle }: CellRendererParams) => {
    const index = rowIndex * this.numCols + columnIndex;
    if (!this.props.isLoading) {
      if (index >= this.props.gridItems.length) {
        // If no loading spinner should be shown, every cell after the last item view is null
        return null;
      }
    } else if (index >= this.props.gridItems.length) {
      if (columnIndex === 0 && rowIndex === this.numRows - 1) {
        // If loading spinner should be shown, the first cell on the row after the last snap should be spinne
        const spinnerStyle = {
          ...cellStyle,
          width: '100%',
          height: INFINITE_LOADER_SPINNER_ROW_HEIGHT,
          paddingTop: INFINITE_LOADER_SPINNER_PADDING,
          paddingBottom: INFINITE_LOADER_SPINNER_PADDING,
        };
        return (
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ width: string; height: number; paddingTop:... Remove this comment to see the full error message
          <div style={spinnerStyle} key={key}>
            <SpinnerIcon className={style.spinnerIcon} />
          </div>
        );
      }
      // And all other cells after the last snap is null
      return null;
    }
    const gridItem = this.props.gridItems[index];
    const itemViewStyle = {
      ...cellStyle,
      height: this.getRowHeight({ index: rowIndex }),
    };
    return this.props.createItemView(
      key,
      itemViewStyle,
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'boolean | undefined' is not assi... Remove this comment to see the full error message
      this.props.selectedItemIdSet[gridItem.id],
      gridItem
    );
  };

  overscanIndicesGetter = ({
    cellCount,
    overscanCellsCount,
    scrollDirection,
    startIndex,

    stopIndex,
  }: OverscanIndicesGetterParams) => {
    return {
      overscanStartIndex: Math.max(0, startIndex - overscanCellsCount),
      overscanStopIndex: Math.min(cellCount - 1, stopIndex + overscanCellsCount),
    };
  };

  scrollToTop = () => {
    if (this.gridRef) {
      this.gridRef.scrollToPosition({ scrollLeft: 0, scrollTop: 0 });
    }
  };

  scrollToTheTop = () => (this.props.scrollToTop ? 0 : null);

  renderEmptyView = () => {
    return (
      <div key="CurationGrid.emptyView" className={style.emptyView}>
        <img src={crossIcon} alt="" />
        <div className={style.noSnapsText}>
          <FormattedMessage
            id="snap-grid-no-snaps-found"
            description="No snaps found"
            defaultMessage="No Snaps found for current filters"
          />
        </div>
      </div>
    );
  };

  renderFullGridSpinner = () => {
    return (
      <div key="SnapGrid.spinner" className={style.fullGridSpinner}>
        <SpinnerIcon />
      </div>
    );
  };

  renderGridHeader = (containerWidth: number, gridWidth: number) => {
    return (
      <div style={{ width: containerWidth }} className={style.headerContainer}>
        <div className={this.props.gridHeaderWrapperClassName}>
          <div style={{ width: gridWidth }} className={this.props.gridHeaderClassName}>
            {this.props.gridHeaderElement}
          </div>
        </div>
      </div>
    );
  };

  // Passing selectedItemsList in so that if selection states change, this function is called again
  renderGrid = (
    selectedItemsList: GridItem[],
    gridItems: GridItem[],
    registerChild: (a?: Element | null) => void,
    shouldShowSpinner?: boolean
  ) => {
    return ({ height, width }: { height: number; width: number }) => {
      const leftPadding =
        this.props.gridStyle && this.props.gridStyle.paddingLeft ? this.props.gridStyle.paddingLeft : 0;
      const rightPadding =
        this.props.gridStyle && this.props.gridStyle.paddingRight ? this.props.gridStyle.paddingRight : 0;
      const availableWidth = width - leftPadding - rightPadding;
      this.numCols = getNumberOfCols(availableWidth, this.props.zoom, this.props.dimensions);
      this.numRows = Math.ceil(this.props.gridItems.length / this.numCols) + (shouldShowSpinner ? 1 : 0);

      const columnWidth = getGridSnapWidth(this.props.zoom, this.props.dimensions);
      const gridWidth = this.numCols * columnWidth;

      // We need calculate extra padding to horizontally center the grid content.
      let extraPadding = 0;
      if (this.props.centerGrid) {
        const extraSpace = availableWidth - gridWidth;
        extraPadding = extraSpace > 0 ? Math.floor(extraSpace / 2) : 0;
      }
      // Final padding for the grid content.
      const gridContentStyle = {
        paddingLeft: leftPadding + extraPadding,
        paddingRight: rightPadding + extraPadding,
      };
      return (
        <div>
          {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ leftPadding: number; rightPadding: number;... Remove this comment to see the full error message */}
          <div style={{ leftPadding, rightPadding }}>
            {this.props.gridHeaderElement && this.renderGridHeader(width, gridWidth)}
          </div>
          <div className={classNames({ [style.blurred]: this.props.blurred })}>
            {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
            <Grid
              ref={this.setGridRef(registerChild)}
              width={width}
              height={height}
              columnCount={this.numCols}
              columnWidth={columnWidth}
              rowCount={this.numRows}
              rowHeight={this.getRowHeight}
              estimatedRowSize={getGridSnapHeight(this.props.zoom, this.props.dimensions)}
              cellRenderer={this.cellRenderer}
              selectedItemsList={selectedItemsList}
              overscanRowCount={this.props.zoom === ZoomType.ZOOMED_OUT ? NUM_ROW_OVERSCAN : NUM_ROW_OVERSCAN_ZOOMED}
              onSectionRendered={this.onSectionRendered}
              gridItems={gridItems}
              scrollTop={this.scrollToTheTop()}
              style={gridContentStyle}
              overscanIndicesGetter={this.overscanIndicesGetter}
            />
          </div>
        </div>
      );
    };
  };

  renderInfiniteLoader = (shouldShowSpinner: boolean) => {
    return (
      <div
        key="SnapGrid.infiniteLoader"
        className={style.infiniteLoaderContainer}
        data-test="snapGrid.infiniteloaderContainer"
      >
        <InfiniteLoader
          isRowLoaded={this.isRowLoaded}
          // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
          loadMoreRows={this.loadMoreRows}
          rowCount={NUM_INFINITE_SCROLL_ROWS}
        >
          {({ onRowsRendered, registerChild }) => {
            this._onRowsRendered = onRowsRendered;
            return (
              <AutoSizer>
                {this.renderGrid(this.props.selectedItemsList, this.props.gridItems, registerChild, shouldShowSpinner)}
              </AutoSizer>
            );
          }}
        </InfiniteLoader>
      </div>
    );
  };

  render() {
    return (
      <div className={classNames(this.props.className, style.container)}>
        {this.props.createGridView(
          this.props.gridItems.length,
          this.renderInfiniteLoader,
          this.renderFullGridSpinner,
          this.renderEmptyView
        )}
      </div>
    );
  }
}

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ forwardRef: boolean; }' is not... Remove this comment to see the full error message
export default intlConnect(null, null, { forwardRef: true })(SnapGrid);
