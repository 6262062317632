import Menu from 'antd/lib/menu';
import classNames from 'classnames';
import React from 'react';

import style from './SDSMenu.scss';

type Props = {
  children: any;
  defaultSelectedKeys?: Array<string>;
  onClick: any;
  className?: string;
};

export class SDSMenu extends React.PureComponent<Props> {
  static Item = Menu.Item;

  render() {
    return (
      <Menu
        defaultSelectedKeys={this.props.defaultSelectedKeys}
        onClick={this.props.onClick}
        className={classNames(this.props.className, style.container)}
      >
        {this.props.children}
      </Menu>
    );
  }
}

export default SDSMenu;
