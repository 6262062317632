import moment from 'moment-timezone';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { resetActiveMediaId } from 'state/media/actions/mediaActions';
import { getActiveMediaUploadProgressPercent } from 'state/media/selectors/mediaSelectors';

import { DropzoneType, UploadPurpose } from 'config/constants';
import { checkCircle, upload } from 'icons/SDS/allIcons';
import { intlConnect } from 'utils/connectUtils';

import Icon from 'views/common/components/Icon/Icon';
import MediaUploader from 'views/editor/containers/MediaUploader/MediaUploader';

import style from './SingleAssetProgressUploadView.scss';

import { AssetType } from 'types/assets';
import type { AssetID } from 'types/polls';
import { ExtractDispatchProps } from 'types/redux';
import type { State as ReduxState } from 'types/rootState';
import { SingleAssetUploadState } from 'types/singleAssetStoryEditor';

type StateProps = {
  uploadProgressPercent: number;
};

type OwnProps = {
  uploadState: SingleAssetUploadState;
  handleOnUploadStart: () => void;
  handleOnUploadComplete: (fileInfo: { assetId: AssetID; type: AssetType; file: File }) => void;
  handleOnUploadFailed: () => void;
};

type State = {
  time: number;
  timeRemaining: number | undefined | null;
};

const mapStateToProps = (state: ReduxState): StateProps => {
  return {
    uploadProgressPercent: getActiveMediaUploadProgressPercent(state),
  };
};

const mapDispatchToProps = {
  resetActiveMediaId,
};

type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;
type Props = OwnProps & DispatchProps & StateProps;

export class SingleAssetProgressUploadView extends React.PureComponent<Props, State> {
  timer: NodeJS.Timeout | null = null;

  state = {
    time: 0,
    timeRemaining: null,
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      this.props.uploadState === SingleAssetUploadState.UPLOADING &&
      prevProps.uploadState === SingleAssetUploadState.NONE
    ) {
      if (!this.timer) {
        this.timer = setInterval(this.updateTime.bind(null, 2), 2000);
      }
    }

    if (
      (this.props.uploadState === SingleAssetUploadState.COMPLETE &&
        prevProps.uploadState === SingleAssetUploadState.UPLOADING) ||
      (this.props.uploadState === SingleAssetUploadState.NONE &&
        prevProps.uploadState === SingleAssetUploadState.UPLOADING)
    ) {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ time: 0, timeRemaining: null });
      this.props.resetActiveMediaId();
    }
  }

  componentWillUnmount(): void {
    if (this.timer) {
      clearInterval(this.timer);
    }
    this.props.resetActiveMediaId();
  }

  updateTime = (bySeconds: number) => {
    this.setState(prevState => {
      return {
        time: prevState.time + bySeconds, // This is triggered every 'bySeconds' seconds
        timeRemaining: this.calculateTimeRemaining(prevState.time + bySeconds),
      };
    });
  };

  calculateTimeRemaining = (time: number) => {
    if (this.props.uploadProgressPercent === 0) {
      return null;
    }

    const timeTakenForOnePercent = time / this.props.uploadProgressPercent;
    const percentageRemaining = 100 - this.props.uploadProgressPercent;

    return Math.ceil(percentageRemaining * timeTakenForOnePercent);
  };

  timeRemaining = () => {
    const time = this.state.timeRemaining;

    if (!time) {
      return '';
    }

    return moment().startOf('day').seconds(time).format('m:ss');
  };

  render() {
    let content;

    switch (this.props.uploadState) {
      case SingleAssetUploadState.NONE:
        content = (
          <MediaUploader
            className={style.mediaUploader}
            dropzoneType={DropzoneType.SINGLE_ASSET_STORY}
            purpose={UploadPurpose.SINGLE_ASSET_STORY}
            onUploadComplete={this.props.handleOnUploadComplete}
            onUploadStart={this.props.handleOnUploadStart}
            onUploadFailed={this.props.handleOnUploadFailed}
            data-test="SingleAssetProgressUploadView.dropZone"
          >
            <div className={style.dropZoneContainer}>
              <Icon inlineIcon={upload} />
              <FormattedMessage
                id="single-asset-upload-drag-and-drop-message"
                description="Telling the user they can drag and drop a file to upload it"
                defaultMessage="Drag and drop your file here or"
              />
              <FormattedMessage
                id="single-asset-upload-upload-video-message"
                description="Upload video button text"
                defaultMessage="Upload video"
              />
            </div>
          </MediaUploader>
        );
        break;
      case SingleAssetUploadState.UPLOADING:
        content = (
          <div className={style.progressBarSection} data-test="SingleAssetProgressUploadView.progressBarSection">
            {this.props.uploadProgressPercent < 100 ? (
              <FormattedMessage
                id="single-asset-file-uploading-message"
                description="File is uploading"
                defaultMessage="Uploading {progress}%"
                values={{ progress: `${this.props.uploadProgressPercent}` }}
              />
            ) : (
              <FormattedMessage
                id="single-asset-upload-preparing-media"
                description="Message telling the user that the upload has finished and media is being prepared"
                defaultMessage="Preparing media"
                data-test="SingleAssetProgressUploadView.preparingMediaText"
              />
            )}
            <div className={style.progressBar}>
              <div className={style.progressBarBackground}>
                <div
                  style={{ width: `${this.props.uploadProgressPercent}%` }}
                  data-test="SingleAssetProgressUploadView.progressBar"
                />
              </div>
            </div>
            {this.props.uploadProgressPercent < 100 && (
              <FormattedMessage
                id="single-asset-file-estimated-time-remaining-message"
                description="Estimated time remaining to upload the file"
                defaultMessage="Estimated time remaining: {time}"
                values={{
                  time: this.timeRemaining(),
                }}
                data-test="SingleAssetProgressUploadView.estimatedTimeRemainingText"
              />
            )}
          </div>
        );
        break;
      case SingleAssetUploadState.COMPLETE:
        content = (
          <div className={style.doneSection} data-test="SingleAssetProgressUploadView.doneSection">
            <Icon inlineIcon={checkCircle} />
            <FormattedMessage
              id="single-asset-upload-complete-message"
              description="The file upload is complete"
              defaultMessage="Upload Complete"
            />
          </div>
        );
        break;
      default:
        break;
    }

    return <div className={style.uploadSectionContainer}>{content}</div>;
  }
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(SingleAssetProgressUploadView);
