// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
import { Panel } from '@snapchat/snapnet'; // discover-cms/no-snapnet
import classNames from 'classnames';
import React from 'react';
import type { ReactNode } from 'react';

import SDSPanelColumn from 'views/common/components/SDSPanel/SDSPanelColumn/SDSPanelColumn';
import SDSPanelRow from 'views/common/components/SDSPanel/SDSPanelRow/SDSPanelRow';
import SpinnerIcon from 'views/common/components/SpinnerIcon/SpinnerIcon';

import style from './SDSPanel.scss';

type Props = {
  header?: string | ReactNode;
  children: ReactNode;
  className?: string;
  isLoading?: boolean;
};

export class SDSPanel extends React.PureComponent<Props> {
  static Column = SDSPanelColumn;

  static Row = SDSPanelRow;

  renderChildren = () => {
    if (this.props.isLoading) {
      return <SpinnerIcon className={style.loadingIcon} />;
    }

    return this.props.children;
  };

  render() {
    const className = classNames(style.panel, this.props.className);
    return (
      <Panel header={this.props.header} className={className}>
        {this.renderChildren()}
      </Panel>
    );
  }
}

export default SDSPanel;
