import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { SketchPicker } from 'react-color';
import reactCSS from 'reactcss';

import SDSLabel from 'views/common/components/SDSLabel/SDSLabel';

import style from './ColorPicker.scss';

let count = 0;
const DEFAULT_HEX = '#999999';

type OwnProps = {
  label?: React.ReactNode;
  onChange?: (...args: any[]) => any;
  colorHex?: string;
  isReadOnly?: boolean;
  fullWidth?: boolean;
  'data-test'?: string;
};

type State = any;

type Props = OwnProps & typeof ColorPicker.defaultProps;

export class ColorPicker extends React.Component<Props, State> {
  static defaultProps = {
    onChange: _.noop,
    isReadOnly: false,
    displayAbove: false,
  };

  state = {
    displayColorPicker: false,
    colorHex: '#FFFFFF',
  };

  UNSAFE_componentWillMount() {
    this.setState({ colorHex: this.props.colorHex });
  }

  UNSAFE_componentWillReceiveProps(props: any) {
    this.setState({ colorHex: props.colorHex });
  }

  id = `color-picker-${count++}`;

  _handleClick = () => {
    const { isReadOnly } = this.props;

    if (!isReadOnly) {
      this.setState({ displayColorPicker: !this.state.displayColorPicker });
    }
  };

  _handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  _handleChange = (color: any) => {
    const colorHex = color.hex;
    this.setState({ colorHex });
    this.props.onChange(colorHex);
  };

  handleInputChange = (event: any) => {
    const colorHex = event.target.value;
    const isAcceptable = /^#[0-9A-Fa-f]{0,6}$/i.test(colorHex);
    if (isAcceptable) {
      // Input is part of a potential valid input - let the user continue entering text
      this.setState({ colorHex });
      const isValid = /^#([0-9A-Fa-f]{3})|([0-9A-Fa-f]{6})$/i.test(colorHex);
      if (isValid) {
        // Input is valid - call onChange
        this.props.onChange(colorHex);
      }
    }
  };

  render() {
    const { isReadOnly, fullWidth } = this.props;

    const colorStyle = reactCSS({
      default: {
        color: {
          background: this.state.colorHex || DEFAULT_HEX,
        },
      },
    });

    const swatchClassNames = classNames({
      [style.swatch]: true,
      [style.fullWidth]: fullWidth,
      [style.disabled]: isReadOnly,
    });

    const popoverClassNames = classNames({
      [style.popover]: true,
      [style.fullWidth]: fullWidth,
    });

    return (
      <div className={style.container} id={this.id}>
        <SDSLabel labelTitle={this.props.label}>
          <div className={swatchClassNames} onClick={this._handleClick} data-test={this.props['data-test']}>
            <input
              className={style.input}
              value={this.state.colorHex || DEFAULT_HEX}
              onChange={this.handleInputChange}
              readOnly={this.props.isReadOnly}
              placeholder={DEFAULT_HEX}
            />
            <div className={style.color} style={colorStyle.color} />
          </div>
        </SDSLabel>
        {this.state.displayColorPicker && (
          <div onMouseLeave={this._handleClose} className={popoverClassNames}>
            <div className={style.cover} onClick={this._handleClose} />
            <SketchPicker color={this.state.colorHex || DEFAULT_HEX} onChange={this._handleChange} disableAlpha />
          </div>
        )}
      </div>
    );
  }
}

export default ColorPicker;
