import classNames from 'classnames';
import * as React from 'react';
import type { ReactNode } from 'react';

import style from './StatusMessageBar.scss';

type Props = {
  children?: ReactNode;
  className?: string;
  domRef?: (a?: HTMLElement | null) => void;
  icon?: ReactNode | null;
  message?: ReactNode;
  onClick?: () => void;
};

export default function StatusMessageBar({ className, icon, message, onClick, domRef, children }: Props) {
  return (
    <div className={classNames(className, style.status)} ref={domRef} onClick={onClick}>
      {icon}
      <span className={style.message}>{message}</span>
      {children}
    </div>
  );
}
