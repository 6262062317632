import React, { PureComponent } from 'react';

import style from './CommaSeparatedList.scss';

const DEFAULT_LIMIT = 8;

export const DEFAULT_ALL_VALUE = 'All';

type OwnProps = {
  values: string[];
  order?: string[];
  limit?: number;
  allValue?: string;
  numTotal?: number;
  preventOrdering?: boolean;
};

type Props = OwnProps & typeof CommaSeparatedList.defaultProps;

class CommaSeparatedList extends PureComponent<Props> {
  static defaultProps = {
    limit: DEFAULT_LIMIT,
    order: [''],
    allValue: DEFAULT_ALL_VALUE,
    numTotal: Number.MAX_VALUE,
  };

  orderMap: any;

  UNSAFE_componentWillMount() {
    this.orderMap = this.props.order.reduce((m, v, i) => ({ ...m, [v]: i + 1 }), {});
  }

  formatList = (values: any) => {
    if (values.length <= this.props.limit) {
      return values.join(', ');
    }

    return [...values.slice(0, this.props.limit), `+${values.length - this.props.limit}`].join(', ');
  };

  renderCSV() {
    if (this.props.values.length === this.props.numTotal) {
      return this.props.allValue;
    }

    if (this.props.preventOrdering) {
      return this.formatList(this.props.values);
    }

    const sortedValues = [...this.props.values].sort((c1, c2) => {
      if (c1 in this.orderMap && c2 in this.orderMap) {
        return this.orderMap[c1] - this.orderMap[c2];
      }

      if (c1 in this.orderMap) {
        return -1;
      }

      if (c2 in this.orderMap) {
        return 1;
      }

      if (c1 < c2) {
        return -1;
      }

      if (c1 > c2) {
        return 1;
      }

      return 0;
    });

    /**
     * https://snapchat.quip.com/4k01ALINvaEe
     * If there is no more available space,
     * a + symbol followed by a number will indicate how many more countries are selected. e.g. US, GB, CA, AU +65
     */
    return this.formatList(sortedValues);
  }

  render = () => <div className={style.root}>{this.renderCSV()}</div>;
}

export default CommaSeparatedList;
